import * as React from "react";
import { Button, Box, Paper, Container } from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import Heading from "./components/DashboardHeading";

import { Link } from "react-router-dom";

function MainComponent() {
    const [iframeHeight, setIframeHeight] = React.useState(0);
    return (
        <Container maxWidth={false} sx={{ mb: 3 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <BusinessIcon sx={{ mb: "-3px", mr: 0.75 }} />{" "}
                <span>Cerere pentru emiterea certificatului de urbanism</span>
            </Heading>
            <iframe
                height={iframeHeight}
                width="100%"
                scrolling="no"
                style={{
                    border: "none",
                }}
                onLoad={(e) => {
                    const { contentWindow } = e.target;
                    setIframeHeight(contentWindow.document.body.scrollHeight);

                    contentWindow.addEventListener("resize", function (e) {
                        setIframeHeight(contentWindow.document.body.scrollHeight);
                    });
                }}
                src="/html/plata.html"
            ></iframe>
            <hr />

            <Box sx={{ p: "20px" }}>
                <Button
                    variant="contained"
                    size="large"
                    color="inherit"
                    sx={{ marginRight: "1rem" }}
                    to="/dashboard/certificat-sumar"
                    component={Link}
                >
                    Pasul anterior
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    to="/dashboard/certificat-succes"
                    component={Link}
                >
                    Autorizareza Plata
                </Button>
            </Box>
        </Container>
    );
}

export default function DashboardCertificatPlata() {
    return <MainComponent />;
}
