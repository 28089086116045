import * as React from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Box, Checkbox } from "@mui/material";

import HartaSesizari from "./HartaSesizari";
import { categoriiSesizari } from "./utils/sesizariUtils";

import AnnouncementIcon from "@mui/icons-material/Announcement";

import { Link } from "react-router-dom";

function Sesizare() {
    const [files, setFiles] = React.useState([]);
    const [selectSesizare, setSelectSesizare] = React.useState("");
    const [sesizare, setSesizare] = React.useState({
        name: "kim",
        books: [
            {
                title: "Learn JavaScript",
                year: "2005"
            },
            {
                title: "Learn React",
                year: "2010",
            },
        ],
    });

    const handleChange = (event) => {
        setSelectSesizare(event.target.value);
    };

    function handleFileUpload(e) {
        const newFiles = e.target.files;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(URL.createObjectURL(newFiles[i]));
        }
        setFiles([...files, ...newFilesArray]);
    }

    React.useEffect(() => {
        document.getElementById("dashboard-scroll").scrollTo({
            top: 0,
            behavior: "smooth",
        });
        // https://stackoverflow.com/questions/53945763/componentdidmount-equivalent-on-a-react-function-hooks-component
    }, []);

    return (
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #ed6c02, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <AnnouncementIcon sx={{ mb: "-6px", mr: 0.75 }} />{" "}
                <span>Sesizare catre primaria Cluj-Napoca</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Adauga Locatia pe Harta:</Heading>
                    <HartaSesizari />
                </Paper>

                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Categorie:</Heading>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Alege o categorie</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectSesizare}
                                label="Alege o categorie"
                                onChange={handleChange}
                                sx={{ background: "#FFF" }}
                            >
                                {categoriiSesizari.map((sesizare, index) => (
                                    <MenuItem key={index} value={index}>
                                        {sesizare.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>

                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Descrierea sesizarii:</Heading>
                    <Box component="form" noValidate autoComplete="off">
                        <p>Va rugam sa furnizati informatii cat se poate de concludente:</p>
                        <TextField sx={{ background: "#FFF" }} fullWidth placeholder="Descriere" />
                    </Box>
                </Paper>

                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Poze atasate:</Heading>
                    <h2
                        style={{
                            padding: "10px",
                            border: "1px dotted",
                            fontSize: "14px",
                            borderRadius: 4,
                        }}
                    >
                        {!files.length && <Box sx={{ p: 2 }}>Nu aveti poze atasate.</Box>}

                        <Stack direction="row" alignItems="center" spacing={3}>
                            {files.map((file, index) => (
                                <img
                                    key={index}
                                    src={file}
                                    style={{ maxWidth: 160, maxHeight: 100 }}
                                />
                            ))}
                        </Stack>
                    </h2>

                    <Button
                        variant="contained"
                        component="label"
                        size="large"
                        sx={{ width: "100%" }}
                    >
                        <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleFileUpload}
                        />
                        Adauga Poze
                    </Button>
                </Paper>
                <Paper sx={{ mb: 4, mt: -2, background: "transparent" }} elevation={0}>
                    <FormGroup sx={{ paddingTop: "1rem" }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Sunt de acord cu Termenii si Conditiile"
                        />
                    </FormGroup>
                    <p>
                        Pentru intervenția operativă a Poliției Locale vă rugăm să apelați numerele
                        de telefon:
                        <br />
                        <br />
                        <strong>0264/955</strong> - Dispeceratul Poliției Locale
                        <br />
                        <br />
                        <strong>0264/984</strong> - Telefonul Primarului
                    </p>
                </Paper>

                <Button
                    variant="outlined"
                    size="large"
                    to="/dashboard/sesizarile-mele"
                    component={Link}
                    sx={{ mr: "1rem" }}
                >
                    Anuleaza
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    to={"/dashboard/sesizarile-mele/" + base64_encode(JSON.stringify(sesizare))}
                    component={Link}
                >
                    Trimite Sesizarea
                </Button>
            </Box>
        </Container>
    );
}

export default Sesizare;
