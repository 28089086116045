import Map from "./MapLeaf";
import Cookies from "./Cookies";
import Disclaimer from "./Disclaimer";
import Toolbar from "./Toolbar";
import Sidebar from "./Sidebar";
import Inspector from "./Inspector";
import { Outlet } from "react-router-dom";

function Homepage() {
    return (
        <div className="App">
            <Toolbar />
            <Sidebar />
            <div id="map-wrapper" className="App-content">
                <Inspector />
                <Map />
            </div>
            {/* <Cookies /> */}
            <Disclaimer />
            <Outlet />
        </div>
    );
}

export default Homepage;
