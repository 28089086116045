import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TextPath from "react-leaflet-textpath";

import L from "leaflet";

import * as turf from "@turf/turf";
import * as d3 from "d3";

import { canFitLabel, containsPolygon, layerToPolygonObject } from "./utils/mapUtils";
import polylabel from "./utils/polylabel";

import axios from "axios";
import pngMap from "./images/pngmap.png";

import { useMap } from "react-leaflet";

const PARCARI = require("./geojson/parcari.json");

const EXTENDED_LIMITS = require("./geojson/bistrita/limitExtended.json");

let allData = {};

let layersOpacity = {};

let renderedData = {
    parcels: null,
    buildings: null,
    imageOverlay: null,
    pug: null,
    pipes: null,
    pipes2: null,
    electrics: null,
    parcari: null,
    uats: null,
    puz: null,
};

let layerGroups = {
    parcels: null,
    buildings: null,
    imageOverlay: null,
    pug: null,
    pipes: null,
    pipes2: null,
    electrics: null,
    parcari: null,
    uats: null,
    puz: null,
};

let selectedLayer = null;
let parcelClicked = false;
let zoomLevel = 13;
let layerControl = null;

function isSelectedLayer(layer) {
    return selectedLayer && selectedLayer.feature.properties.cad === layer.feature.properties.cad;
}

function getFilteredData(data, map, isSimplePoly) {
    return {
        type: "FeatureCollection",
        features: data.features.filter((poly) =>
            containsPolygon(
                map.getBounds(),
                isSimplePoly ? poly.geometry.coordinates : poly.geometry.coordinates[0]
            )
        ),
    };
}

function removeCustomEvents(map) {
    let clickevents = [];
    for (let i = 0; i < map._events.click.length; i++) {
        if (map._events.click[i].fn.name !== "handleMapClick")
            clickevents.push(map._events.click[i]);
    }
    map._events.click = clickevents;

    let moveendevents = [];
    for (let i = 0; i < map._events.moveend.length; i++) {
        if (map._events.moveend[i].fn.name !== "renderLayers")
            moveendevents.push(map._events.moveend[i]);
    }
    map._events.moveend = moveendevents;

    let zoomstartevents = [];
    if (map._events.zoomstart) {
        for (let i = 0; i < map._events.zoomstart.length; i++) {
            if (map._events.zoomstart[i].fn.name !== "handleZoomStart")
                zoomstartevents.push(map._events.zoomstart[i]);
        }
    }
    map._events.zoomstart = zoomstartevents;
}

function setLayerData(data) {
    allData = data;
    allData.parcari = PARCARI;
    allData.parcels.features.forEach(function (parcel, index) {
        parcel.properties.poleOfInaccessibility = polylabel(parcel.geometry.coordinates[0], 1.0);
    });
    allData.parcari.features.forEach(function (parcare, index) {
        parcare.properties.status = Math.round(Math.random());
    });
    preparePugColors();
}

function resetDataAndLayers() {
    allData = {};
    layersOpacity = {};
    renderedData = {
        parcels: null,
        buildings: null,
        imageOverlay: null,
        pug: null,
        pipes: null,
        pipes2: null,
        electrics: null,
        uats: null,
        parcari: null,
        puz: null,
    };
    selectedLayer = null;
}

const getLayerOpacity = function (layer) {
    const opacity = layersOpacity[layer] !== undefined ? layersOpacity[layer] : 100;
    return opacity / 100;
};

const defaultLayerStyle = function () {
    return {
        fillColor: "rgba(255,255,0,0.6)",
        weight: 0.5,
        color: "rgba(0,0,0,0.6)",
        fillOpacity: getLayerOpacity("parcels"),
        opacity: getLayerOpacity("parcels"),
    };
};
const selectedLayerStyle = {
    fillColor: "rgba(50, 235, 50, 0.8)",
};

const hoverLayerStyle = {
    fillColor: "rgba(255,255,0,0.8)",
};

const defaultBuildingStyle = function () {
    return {
        fillColor: "rgba(147,112,219,0.8)",
        weight: 1,
        color: "rgba(0,0,0,0.2)",
        pointerEvents: "none",
        fillOpacity: getLayerOpacity("buildings"),
        opacity: getLayerOpacity("buildings"),
    };
};

function randomColor() {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * (100 + 1)) + "%";
    const lightness = Math.floor(Math.random() * (100 / 2 + 1)) + "%";
    return "hsl(" + hue + ", " + "100%" + ", " + "60%" + ")";
}

const pipesColor = "dodgerblue";
const electricsColor = "darkviolet";
const regionsColor = randomColor();

let pugColors = {};

function preparePugColors() {
    let colorsKeys = [];

    allData.pug.features.forEach(function (pug, index) {
        if (!colorsKeys.includes(pug.properties.name)) colorsKeys.push(pug.properties.name);
    });

    let myColor = d3
        .scaleSequential()
        .domain([1, colorsKeys.length])
        .interpolator(d3.interpolateWarm);
    // myColor = d3.scaleLinear().domain([1, colorsKeys.length]).range([ "teal", "brown", "orange"]);

    colorsKeys.forEach((element, index) => {
        pugColors[element] = myColor(index);
    });

    // override some colors
    pugColors["V1"] = "lightseagreen";
    pugColors["V2"] = "mediumseagreen";

    // console.log(pugColors);
}

document.pugClick = {};

let tooltipGroup = L.layerGroup();

const ParcelsLayer = ({
    // map,
    showParcelCard,
    onDataLoaded,
    onDataUnloaded,
    mapWrapperID,
    handleZoneClick,
}) => {
    const stateLayersOpacity = useSelector((state) => state.layers.layersOpacity);
    const mapLayers = useSelector((state) => state.layers.mapLayers);

    let map = useMap();
    // redux stuff
    // const { data, loading, error } = useSelector((state) => state.geo);
    // const dispatch = useDispatch();

    // component mount
    useEffect(() => {
        // more redux stuff
        // dispatch(getGeoJson());

        if (!document.layerData) {
            const getData = async () => {
                const response = await axios
                    .get(
                        // "https://gist.githubusercontent.com/dnz986/73e0c607db51c9953cc0ec4328f2126d/raw/7fc0f34df7d858eb2efb615684b39e4b6bd5dd92/gistfile1.txt"
                        // "http://192.168.1.122:8080/ancpi/parcels?location=55838"
                        // "http://10.10.10.76:8080/ancpi/parcels?location=55838"
                        // "https://gist.githubusercontent.com/flaviusmatis/9ab53c92bab48a4403c6db880b1f5aa0/raw/7aaaa830411182ccb76b5720c0a8341ab31ba624/parcele.json"
                        // "/api/all"
                        "/api?location=32394"
                    )
                    .catch(function (error) {
                        console.log(error.toJSON());
                    });

                if (response) {
                    document.layerData = response.data;
                    initLayers();
                }
            };
            getData();
        } else {
            // TODO: timeout to avoid some concurrency issues - find a better way to do this
            window.setTimeout(function () {
                initLayers();
            }, 10);
        }

        // component unmount
        return () => {
            removeLayers();
            removeCustomEvents(map);
            resetDataAndLayers();
            onDataUnloaded();
            map = null;
        };
    }, []);

    function onEachFeature(feature, layer) {
        // https://github.com/makinacorpus/Leaflet.TextPath
        // https://www.npmjs.com/package/react-leaflet-textpath
        // layer.setText(feature.properties.cad.toString(), {offset: -10, center: true});

        if (isSelectedLayer(layer)) layer.setStyle(selectedLayerStyle);

        const poleOfInaccessibility = feature.properties.poleOfInaccessibility;

        // console.log("poleOfInaccessibility", poleOfInaccessibility);
        // if (canFitLabel(map, layer, 60, true)) {
        if (
            (poleOfInaccessibility.distance > 0.00015 && zoomLevel == 17) ||
            (poleOfInaccessibility.distance > 0.00007 && zoomLevel > 17) ||
            (poleOfInaccessibility.distance > 0.00004 && zoomLevel > 18)
        ) {
            // layer.bindTooltip(feature.properties.cad.toString(), {
            //     permanent: true,
            //     direction: "center",
            //     className: "parcelLabel",
            // });

            L.tooltip({
                permanent: true,
                direction: "center",
                className: "parcelLabel",
            })
                .setContent(feature.properties.cad.toString())
                .setLatLng({ lat: poleOfInaccessibility[1], lng: poleOfInaccessibility[0] })
                .addTo(tooltipGroup);

            // L.circle([poleOfInaccessibility[1], poleOfInaccessibility[0]], {radius: poleOfInaccessibility.distance*100000}).addTo(map);
        } else {
            layer.bindTooltip(feature.properties.cad.toString(), {
                direction: "top",
            });
        }
    }

    function onEachFeaturePopup(feature, layer) {
        if (isSelectedLayer(layer)) layer.setStyle(selectedLayerStyle);
        layer.bindTooltip(feature.properties.cad.toString(), {
            direction: "top",
        });
    }

    const renderers = {
        parcels: function () {
            if (allData.parcels) {
                if (zoomLevel > 16) {
                    renderedData.parcels = L.geoJSON(getFilteredData(allData.parcels, map), {
                        onEachFeature: (feature, layer) => onEachFeature(feature, layer, zoomLevel),
                        style: defaultLayerStyle(),
                        pane: "parcels",
                    });
                } else if (zoomLevel >= 15) {
                    renderedData.parcels = L.geoJSON(getFilteredData(allData.parcels, map), {
                        onEachFeature: onEachFeaturePopup,
                        style: defaultLayerStyle(),
                        pane: "parcels",
                    });
                } else {
                    if (selectedLayer && selectedLayer.feature.properties.cad) {
                        renderedData.parcels = L.geoJSON(
                            {
                                type: "FeatureCollection",
                                features: [selectedLayer.feature],
                            },
                            {
                                onEachFeature: onEachFeaturePopup,
                                style: defaultLayerStyle(),
                                pane: "parcels",
                            }
                        );
                    } else {
                        renderedData.parcels = null;
                    }
                }
            } else {
                renderedData.parcels = null;
            }
            if (renderedData.parcels) {
                layerGroups.parcels = L.featureGroup([renderedData.parcels])
                    .setStyle({
                        opacity: getLayerOpacity("parcels"),
                        fillOpacity: getLayerOpacity("parcels"),
                    })
                    .on("click", function (e) {
                        handleParcelClick(e, e.propagatedFrom);
                    })
                    .on("mouseover", function (e) {
                        const layer = e.propagatedFrom;
                        if (!isSelectedLayer(layer)) layer.setStyle(hoverLayerStyle);
                        // layer.bindTooltip(layer.feature.properties.cad.toString(), {
                        //     direction: "top",
                        //     permanent: true,
                        // });
                    })
                    .on("mouseout", function (e) {
                        const layer = e.propagatedFrom;
                        if (!isSelectedLayer(layer)) layer.setStyle(defaultLayerStyle());
                        // layer.unbindTooltip();
                    });
                // .bindTooltip(
                //     function (layer) {
                //         return layer.feature.properties.cad.toString(); //merely sets the tooltip text
                //     },
                //     {
                //         direction: "top",
                //     }
                // );

                map.addLayer(layerGroups.parcels);
            }
        },
        overlay: function () {
            if (zoomLevel < 15 && allData.parcels) {
                const toggleImageOverlay = false;
                if (toggleImageOverlay) {
                    const imageUrl = pngMap;
                    const imageBounds = [
                        [47.2285109243, 24.6517852482],
                        [47.0516886505, 24.3865050851],
                    ];
                    renderedData.imageOverlay = L.imageOverlay(imageUrl, imageBounds, {
                        opacity: 0.7,
                        pane: "parcels",
                    });
                } else {
                    renderedData.imageOverlay = L.geoJSON(EXTENDED_LIMITS, {
                        onEachFeature: function (feature, layer) {
                            layer.bindTooltip("<div></div>", {
                                direction: "top",
                                sticky: true,
                                // permanent: true,
                                className: "overlay-tooltip",
                            });
                            layer.on("click", function (e) {
                                // set timeout to avoid ugly tooltip effect
                                window.setTimeout(function () {
                                    map.setZoomAround(e.latlng, 15);
                                }, 10);
                            });
                        },
                        className: "overlay-element",
                        pane: "parcels",
                        style: {
                            fillColor: "rgba(255,255,0,0.2)",
                            fillOpacity: 1,
                            weight: 0,
                        },
                    });
                }
            } else {
                renderedData.imageOverlay = null;
            }
            if (renderedData.imageOverlay) {
                layerGroups.imageOverlay = L.featureGroup([renderedData.imageOverlay]).setStyle({
                    opacity: getLayerOpacity("parcels"),
                    fillOpacity: getLayerOpacity("parcels"),
                });
                map.addLayer(layerGroups.imageOverlay);
            }
        },
        buildings: function () {
            if (zoomLevel > 16 && allData.buildings) {
                renderedData.buildings = L.geoJSON(getFilteredData(allData.buildings, map), {
                    style: defaultBuildingStyle(),
                    interactive: false,
                    pane: "parcels",
                });
            } else {
                renderedData.buildings = null;
            }
            if (renderedData.buildings) {
                layerGroups.buildings = L.featureGroup([renderedData.buildings]);
                map.addLayer(layerGroups.buildings);
            }
        },
        parcari: function () {
            if (zoomLevel > 17 && allData.parcari) {
                renderedData.parcari = L.geoJSON(getFilteredData(allData.parcari, map), {
                    onEachFeature: function (feature, layer) {
                        if (feature.properties.status) {
                            layer.setStyle({
                                fillColor: "#ef5350",
                            });
                        }
                    },
                    pane: "parcels",
                    style: {
                        color: "white",
                        weight: 0.75,
                        fillOpacity: 0.8,
                        pointerEvents: "none",
                        fillColor: "limegreen",
                    },
                });
            } else {
                renderedData.parcari = null;
            }
            if (renderedData.parcari) {
                layerGroups.parcari = L.featureGroup([renderedData.parcari])
                    .on("mouseover", function (e) {
                        const layer = e.propagatedFrom;
                        layer.setStyle({
                            fillOpacity: 1,
                        });
                        layer.bindTooltip(
                            "<div><strong>Parcare: </strong>" +
                                layer.feature.properties.numar +
                                "<br/><strong>Status: </strong>" +
                                (layer.feature.properties.status ? "OCUPAT" : "LIBER") +
                                "</div>",
                            {
                                permanent: true,
                                direction: "top",
                                sticky: true,
                            }
                        );
                    })
                    .on("mouseout", function (e) {
                        const layer = e.propagatedFrom;
                        layer.setStyle({
                            fillOpacity: 0.8,
                        });
                        layer.unbindTooltip();
                    });
                map.addLayer(layerGroups.parcari);
            }
        },
        pug: function () {
            if (allData.pug) {
                // renderedData.pug = L.geoJSON(getFilteredData(allData.pug, map, true), {
                renderedData.pug = L.geoJSON(allData.pug, {
                    onEachFeature: function (feature, layer) {
                        const newColor = pugColors[feature.properties.name] || "tomato";
                        const zoneName = feature.properties.name;
                        const completeZoneName = feature.properties.parent + " - " + zoneName;

                        layer.setStyle({
                            fillColor: newColor,
                            color: newColor,
                            weight: 0.5,
                        });

                        if (selectedLayer && selectedLayer.feature.id === layer.feature.id) {
                            layer.setStyle({
                                fillOpacity: 0.6,
                            });
                        }

                        // TODO: this is not the way to do it REFACTOR THIS!
                        document.pugClick[feature.properties.name] = function () {
                            map.fitBounds(layer.getBounds(), { maxZoom: 18 });
                            handleZoneClick({
                                position: {
                                    latlng: { lat: 47.116006142448015, lng: 24.445087757532946 },
                                },
                                name: completeZoneName,
                                color: newColor,
                                description: feature.properties.desc,
                            });
                            layer.setStyle({
                                fillOpacity: 0.6,
                            });
                            selectedLayer = layer;
                        };
                    },
                    style: {
                        fillColor: regionsColor,
                        weight: 1,
                        color: regionsColor,
                        fillOpacity: 0.4,
                        opacity: 1,
                    },
                    pane: "parcels",
                });
            } else {
                renderedData.pug = null;
            }
            if (renderedData.pug) {
                layerGroups.pug = L.featureGroup([renderedData.pug])
                    .on("mouseover", function (e) {
                        const layer = e.propagatedFrom;

                        layer.setStyle({
                            fillOpacity: 0.6,
                        });

                        layer.bindTooltip(layer.feature.properties.name, {
                            direction: "top",
                            permanent: true,
                        });
                    })
                    .on("mouseout", function (e) {
                        const layer = e.propagatedFrom;
                        if (selectedLayer && selectedLayer.feature.id === layer.feature.id) {
                            // do nothing
                        } else {
                            layer.setStyle({
                                fillOpacity: 0.4,
                            });
                        }
                        layer.unbindTooltip();
                    })
                    .on("click", function (e) {
                        const layer = e.propagatedFrom;
                        const newColor = pugColors[layer.feature.properties.name] || "tomato";
                        const zoneName = layer.feature.properties.name;
                        const completeZoneName = layer.feature.properties.parent + " - " + zoneName;

                        map.fitBounds(layer.getBounds(), { maxZoom: 18 });
                        handleZoneClick({
                            position: e,
                            name: completeZoneName,
                            color: newColor,
                            description: layer.feature.properties.desc,
                        });
                        layer.setStyle({
                            fillOpacity: 0.6,
                        });
                        selectedLayer = layer;
                        parcelClicked = true;
                    });
                map.addLayer(layerGroups.pug);
            }
        },
        puz: function () {
            if (allData.puz) {
                renderedData.puz = L.geoJSON(getFilteredData(allData.puz, map, true), {
                    onEachFeature: function (feature, layer) {
                        const newColor = pugColors[feature.properties.name] || "tomato";
                        layer.setStyle({
                            fillColor: newColor,
                            color: newColor,
                            weight: 0.5,
                        });
                    },
                    style: {
                        fillColor: regionsColor,
                        weight: 1,
                        color: regionsColor,
                        fillOpacity: 0.4,
                        opacity: 1,
                    },
                    pane: "parcels",
                });
            } else {
                renderedData.puz = null;
            }
            if (renderedData.puz) {
                layerGroups.puz = L.featureGroup([renderedData.puz])
                    .on("mouseover", function (e) {
                        const layer = e.propagatedFrom;
                        layer.setStyle({
                            fillOpacity: 0.6,
                        });
                        layer.bindTooltip(layer.feature.properties.name, {
                            direction: "top",
                            permanent: true,
                        });
                    })
                    .on("mouseout", function (e) {
                        const layer = e.propagatedFrom;
                        layer.setStyle({
                            fillOpacity: 0.4,
                        });
                        layer.unbindTooltip();
                    })
                    .on("click", function (e) {
                        const layer = e.propagatedFrom;
                        const newColor = pugColors[layer.feature.properties.name] || "tomato";
                        const zoneName = layer.feature.properties.name;
                        const completeZoneName = layer.feature.properties.parent + " - " + zoneName;

                        map.fitBounds(layer.getBounds(), { maxZoom: 18 });
                        handleZoneClick({
                            position: e,
                            name: completeZoneName,
                            color: newColor,
                            description: layer.feature.properties.desc,
                        });
                    });
                map.addLayer(layerGroups.puz);
            }
        },
        uats: function () {
            const exceptions = ["TIHA BARGAULUI", "CHIUZA", "NUSENI", "SANT", "TELCIU", "TEACA"];
            if (allData.uats) {
                renderedData.uats = L.geoJSON(getFilteredData(allData.uats, map), {
                    onEachFeature: function (feature, layer) {
                        if (exceptions.includes(feature.properties.name)) {
                            layer.setStyle({
                                fillColor: "yellow",
                                color: "steelblue",
                                weight: 1,
                                fillOpacity: 0.2,
                            });
                        }
                    },
                    style: {
                        fillColor: "steelblue",
                        color: "steelblue",
                        weight: 1,
                        fillOpacity: 0.1,
                    },
                    pane: "parcels",
                    filter: function (feature, layer) {
                        return feature.properties.name !== "BISTRITA";
                    },
                });
            } else {
                renderedData.uats = null;
            }
            if (renderedData.uats) {
                layerGroups.uats = L.featureGroup([renderedData.uats])
                    .on("mouseover", function (e) {
                        const layer = e.propagatedFrom;
                        if (exceptions.includes(layer.feature.properties.name)) {
                            layer.setStyle({
                                fillOpacity: 0.3,
                            });
                        } else {
                            layer.setStyle({
                                fillOpacity: 0.3,
                            });
                        }
                    })

                    .on("mouseout", function (e) {
                        const layer = e.propagatedFrom;
                        if (exceptions.includes(layer.feature.properties.name)) {
                            layer.setStyle({
                                fillColor: "yellow",
                                color: "steelblue",
                                weight: 1,
                                fillOpacity: 0.2,
                            });
                        } else {
                            layer.setStyle({
                                fillOpacity: 0.1,
                            });
                        }
                    })
                    .on("click", function (e) {
                        const layer = e.propagatedFrom;
                        map.fitBounds(layer.getBounds(), { maxZoom: 18 });
                    })
                    .bindTooltip(
                        function (layer) {
                            return (
                                "<div><strong>UAT: </strong>" +
                                layer.feature.properties.name +
                                "<br/><strong>Populatie: </strong>" +
                                layer.feature.properties.population +
                                "</div>"
                            );
                        },
                        {
                            direction: "top",
                            sticky: true,
                        }
                    );
                map.addLayer(layerGroups.uats);
            }
        },
        zonaProtectie: function () {
            let groupArray = [];
            if (allData.pipes) {
                renderedData.pipes = L.geoJSON(allData.pipes, {
                    onEachFeature: function (feature, layer) {
                        layer.bindTooltip("Conducta apa", {
                            direction: "top",
                            sticky: true,
                        });
                    },
                    style: {
                        weight: 15,
                        color: "cyan",
                        opacity: 0.2,
                    },
                    pane: "parcels",
                });
                groupArray.push(renderedData.pipes);
                renderedData.pipes2 = L.geoJSON(allData.pipes, {
                    onEachFeature: function (feature, layer) {
                        layer.bindTooltip("Conducta apa", {
                            direction: "top",
                            sticky: true,
                        });
                    },
                    style: {
                        weight: 5,
                        color: pipesColor,
                    },
                    pane: "parcels",
                });
                groupArray.push(renderedData.pipes2);
            }

            if (allData.electrics) {
                renderedData.electrics = L.geoJSON(allData.electrics, {
                    onEachFeature: function (feature, layer) {
                        layer.bindTooltip("Retea electrica", {
                            direction: "top",
                            sticky: true,
                        });
                    },
                    style: {
                        fillColor: electricsColor,
                        weight: 2,
                        dashArray: "4",
                        color: electricsColor,
                    },
                    pane: "parcels",
                });
                groupArray.push(renderedData.electrics);
            }

            layerGroups.protectedZones = L.featureGroup(groupArray);
            map.addLayer(layerGroups.protectedZones);
        },
    };

    function renderLayers() {
        // console.time("render layers");

        removeLayers();
        zoomLevel = map.getZoom();

        if (layerControl.parcels) renderers.parcels();
        if (layerControl.parcels) renderers.overlay();
        if (layerControl.buildings) renderers.buildings();
        if (layerControl.parcari) renderers.parcari();
        if (layerControl.pug) renderers.pug();
        if (layerControl.puz) renderers.puz();
        if (layerControl.uats) renderers.uats();
        if (layerControl.zoneProtectie) renderers.zonaProtectie();

        // console.timeEnd("render layers");
    }

    function removeLayers() {
        const layers = [
            layerGroups.parcels,
            layerGroups.imageOverlay,
            layerGroups.buildings,
            layerGroups.parcari,
            layerGroups.pug,
            layerGroups.puz,
            layerGroups.uats,
            layerGroups.protectedZones,
        ];
        layers.forEach((layer) => {
            if (map.hasLayer(layer)) map.removeLayer(layer);
        });
        tooltipGroup.clearLayers();
    }

    function searchParcel(cad) {
        let geojsondata = { type: "FeatureCollection", features: [] };

        for (let i = 0; i < allData.parcels.features.length; i++) {
            if (cad === allData.parcels.features[i].properties.cad.toString())
                geojsondata.features.push(allData.parcels.features[i]);
        }

        // we create a virtual geojson in order to have getBounds available
        let geojsongroup = L.geoJSON(geojsondata, {
            onEachFeature: function (feature, layer) {
                selectedLayer = layer;

                // we need to calculate a point on the polygon since there's no click event
                var center = turf.centerOfMass(turf.polygon(layerToPolygonObject(layer)));

                // we get lat and lng from calculated point
                const lat = center.geometry.coordinates[1];
                const lng = center.geometry.coordinates[0];

                // show parcel card
                showParcelCard(feature, [lat, lng]);
            },
        });

        // move to our parcel
        map.fitBounds(geojsongroup.getBounds(), { maxZoom: 18 });
    }

    document.searchParcel = searchParcel;

    function handleParcelClick(e, layer) {
        // need this for instant visual effect
        // console.time("instant styles");

        if (selectedLayer) {
            renderedData.parcels.eachLayer(function (featureInstanceLayer) {
                if (isSelectedLayer(featureInstanceLayer))
                    featureInstanceLayer.setStyle(defaultLayerStyle());
            });
        }
        layer.setStyle(selectedLayerStyle);

        // console.timeEnd("instant styles");
        // need this for instant visual effect

        selectedLayer = layer;

        // console.log(layer, layer._latlngs[0]);

        // var polygon = turf.polygon(layerToPolygonObject(layer));

        // console.log("polylabel", polylabel(layerToPolygonObject(layer), 1.0));

        // var centroid = turf.centroid(polygon);

        // console.log(centroid, centroid.geometry.coordinates);

        // map.setView(L.latLng(centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]), 16);

        map.fitBounds(layer.getBounds(), { maxZoom: 18 });

        const { lat, lng } = e.latlng;

        showParcelCard(layer.feature, [lat, lng]);

        // TODO: this is probably not the best way to hand this; find a better solution
        parcelClicked = true;
    }

    function handleMapClick(e) {
        const mapWrapper = document.querySelector("#" + mapWrapperID);
        if (renderedData.parcels && mapWrapper.contains(e.originalEvent.target) && !parcelClicked) {
            selectedLayer = null;
            renderLayers();
        }
        parcelClicked = false;
    }

    function handleZoomStart(e) {
        tooltipGroup.clearLayers();
        if (renderedData.parcels && layerGroups.parcels) layerGroups.parcels.unbindTooltip();
    }

    function resetMapEvents() {
        removeCustomEvents(map);
        map.on("click", handleMapClick);
        map.on("moveend", renderLayers);
        map.on("zoomstart", handleZoomStart);
        renderLayers();
    }

    function initLayers() {
        if (!map) return;
        // https://stackoverflow.com/questions/51723916/leaflet-geojson-layers-hidden-outside-viewport
        // map.getRenderer(map).options.padding = 100;
        setLayerData(document.layerData);
        tooltipGroup.addTo(map);
        resetMapEvents();
        onDataLoaded(allData);
    }

    useEffect(() => {
        // TODO: fix this (force renderer to get new props)
        layerControl = { ...mapLayers };

        resetMapEvents();
    }, [mapLayers]);

    useEffect(() => {
        ["parcels"].forEach(function (layer) {
            if (layersOpacity[layer] !== stateLayersOpacity[layer]) {
                layersOpacity = stateLayersOpacity;
                if (layerGroups[layer])
                    layerGroups[layer].setStyle({
                        opacity: getLayerOpacity(layer),
                        fillOpacity: getLayerOpacity(layer),
                    });
                if (layerGroups.imageOverlay)
                    layerGroups.imageOverlay.setStyle({
                        opacity: getLayerOpacity(layer),
                        fillOpacity: getLayerOpacity(layer),
                    });
            }
        });
        ["buildings", "parcari", "uats"].forEach(function (layer) {
            if (layersOpacity[layer] !== stateLayersOpacity[layer]) {
                layersOpacity = stateLayersOpacity;
                if (layerGroups[layer])
                    layerGroups[layer].setStyle({
                        opacity: getLayerOpacity(layer),
                        fillOpacity: getLayerOpacity(layer),
                    });
            }
        });
    }, [stateLayersOpacity]);

    return null;
};

export default ParcelsLayer;
