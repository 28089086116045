import * as React from "react";
import {
    XAxis,
    YAxis,
    ResponsiveContainer,
    AreaChart,
    Area,
    CartesianGrid,
    Tooltip,
} from "recharts";
import Heading from "./components/DashboardHeading";

// Generate Sales Data
function createData(time, Conturi) {
    return { time, Conturi };
}

const data = [
    createData("14.09", 10),
    createData("15.09", 5),
    createData("16.09", 8),
    createData("17.09", 3),
    createData("18.09", 12),
    createData("19.09", 8),
    createData("21.09", 12),
    createData("24.09", 18),
];

export default function Chart() {

    return (
        <React.Fragment>
            <Heading>Utilizatori Activi</Heading>
            <ResponsiveContainer>
                <AreaChart
                    width={400}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <Tooltip />
                    <Area type="monotone" dataKey="Conturi" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
