import * as React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import DraftEditor from "./components/DraftEditor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import HeadingCertificatPrint from "./components/HeadingCertificatPrint";
import draftToHtml from "draftjs-to-html";

import Spinner from "./components/Spinner";
import axios from "axios";

let mode = "edit";

const getH1 = (node) => {
    return {
        type: "h1",
        children: [
            {
                text: node.text,
            },
        ],
    };
};

const getH2 = (node) => {
    return {
        type: "h2",
        children: [
            {
                text: node.text,
            },
        ],
    };
};

const getTitle = (node) => {
    return {
        type: "title",
        children: [
            {
                text: node.text,
            },
        ],
    };
};

const getParagraphs = (paragraphs) => {
    let children = [];
    paragraphs.items.forEach((paragraph) => {
        children.push({
            type: "p",
            children: [
                {
                    text: paragraph,
                },
            ],
        });
    });
    return children;
};

const getListItems = (list) => {
    let children = [];
    list.items.forEach((item) => {
        if (typeof item === "string") {
            children.push({
                type: "li",
                children: [
                    {
                        text: item,
                    },
                ],
            });
        } else {
            const index = children.length - 1;
            if (item.type === "options") {
                children[index].children = [...children[index].children, item];
            } else if (item.blocks) {
                children[index].children = [
                    ...children[index].children,
                    {
                        type: "blocks",
                        content: item,
                    },
                ];
            } else {
                children[index].children = [...children[index].children, getListItems(item)];
            }
        }
    });
    return {
        type: list.type,
        className: list.className,
        children: children,
    };
};

const getSegments = (segments) => {
    let model = {
        type: "ol",
        children: [],
    };
    segments.items.forEach((segment) => {
        model.children.push({
            type: "li",
            children: fromServerToClient(segment),
        });
    });
    return model;
};

const fromServerToClient = (nodes) => {
    const nodesArray = [];
    nodes.forEach((node) => {
        switch (node.type) {
            case "h1":
                nodesArray.push(getH1(node));
                return;
            case "h2":
                nodesArray.push(getH2(node));
                return;
            case "title":
                nodesArray.push(getTitle(node));
                return;
            case "p":
                nodesArray.push(...getParagraphs(node));
                return;
            case "ul":
            case "ol":
                nodesArray.push(getListItems(node));
                return;
            case "segments":
                nodesArray.push(getSegments(node));
                return;
            default:
                nodesArray.push(node);
                return;
        }
    });
    return nodesArray;
};

const serialize2HTML = (node) => {
    if (!node) return;
    if (!node.type && !node.children) {
        return node.text;
    }

    if (node.type == "options") {
        return SelectableOptions(node.data);
    }

    if (node.type == "custom") {
        return CustomText(node);
    }

    if (node.type == "blocks") {
        const draftcontent = EditorState.createWithContent(convertFromRaw(node.content));
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: draftToHtml(convertToRaw(draftcontent.getCurrentContent())),
                }}
            />
        );
    }

    const children = node.children.map((n) => serialize2HTML(n));
    const childrenHTML = <React.Fragment>{children.map((child) => child)}</React.Fragment>;

    switch (node.type) {
        case "paragraph":
            return <p>{childrenHTML}</p>;
        case "h1":
            return <h1>{childrenHTML}</h1>;
        case "h2":
            return <h2>{childrenHTML}</h2>;
        case "title":
            return <strong>{childrenHTML}</strong>;
        case "p":
            return <p>{childrenHTML}</p>;
        case "ol":
            return <ol className={node.className}>{childrenHTML}</ol>;
        case "ul":
            return <ul className={node.className}>{childrenHTML}</ul>;
        case "li":
            return <li>{childrenHTML}</li>;
        default:
            return childrenHTML;
    }
};

function SelectableOptions(data) {
    return (
        <Box sx={{ pl: "1rem", py: "0.5rem" }}>
            {data.map((item, index) => (
                <FormControlLabel
                    sx={{
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "inherit",
                        },
                    }}
                    control={
                        <Checkbox
                            defaultChecked={item.checked}
                            color={mode == "view" ? "default" : "primary"}
                            disabled={mode === "view"}
                        />
                    }
                    label={item.label}
                    key={index}
                />
            ))}
        </Box>
    );
}

function CustomText(node) {
    let content = null;
    const rawContent =
        '{"blocks":[{"key":"7jb7u","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';
    let draftcontent = EditorState.createWithContent(convertFromRaw(JSON.parse(rawContent)));
    if (mode === "edit") {
        content = <DraftEditor defaultContent={node.defaultContent} />;
    } else {
        content = (
            <div
                dangerouslySetInnerHTML={{
                    __html: draftToHtml(convertToRaw(draftcontent.getCurrentContent())),
                }}
            />
        );
    }
    return <div style={{ position: "relative" }}>{content}</div>;
}

const serverModel = [
    { type: "h1", text: "CERTIFICAT DE URBANISM" },
    { type: "h2", text: "Nr. înregistrare CU/1448/2022/08-11-2022" },
    {
        type: "p",
        items: [
            "În scopul:",
            "Elaborare documentație pentru autorizarea executării lucrărilor de desființare CORPURI C2 ȘI C4 CONFORM CF 89118 Bistrița, respectiv construire a obiectivului EXTINDERE CORP C1, împrejmuire, branșamente și racorduri noi la rețelele de utilități urbane;",
            "Ca urmare a cererii adresate de LUAE SOAGILDA înregistrată la nr.180/2022 din 01-11- 2022, pentru imobilul situat in județul Bistrița-Năsăud, municipiul Bistrița, identificat prin CF nr. 89118 În temeiul reglementărilor documentației de urbanism: faza PUG, aprobata prin H.C.L. Bistrița 27/1999, cu modificarea H.C.L. Bistrița 85/2020.",
            "În conformitate cu prevederile Legii nr. 50/1991 a executării lucrărilor de construcții, republicată, cu modificările și completările ulterioare,",
            "SE CERTIFICĂ:",
        ],
    },
    {
        type: "segments",
        items: [
            [
                { type: "title", text: "REGIMUL JURIDIC" },
                {
                    type: "ol",
                    items: [
                        "În conformitate cu prevederile PUG Bistrița, Localitatea Bistrița și cu CF nr. 89118 Bistrița, imobilul este situat în intravilan, în afara perimetrului de protecție fată de obiective cu valoare de patrimoniu.",
                        "Branșamentele și racordurile la rețelele de utilități urbane (alimentare cu apă, electricitate, ş.a.m.d.) se vor realiza pe domeniul public al Municipiului Bistrița - strada localității, conform cuAnexa la H.G. nr. 969/2002 privind atestarea domeniului public al județului Bistrița-Năsăud precum și al municipiilor, orașelor și comunelor județului Bistrița-Năsăud, cu modificările și completările ulterioare.",
                        "Servituți care afectează terenul:",
                        {
                            type: "ul",
                            items: [
                                "Servituți care afectează terenul- nu e cazul",
                                "Dreptul de preemțiune - nu e cazul",
                                "Zona de utilități publice - zona de utilitate publică a străzii localității",
                            ],
                        },
                        "Imobilul nu este inclus în listele monumentelor istorice și/sau ale naturii, ori în zona de protecție a acestora.",
                    ],
                },
            ],
            [
                { type: "title", text: "REGIMUL ECONOMIC" },
                {
                    type: "ol",
                    items: [
                        "Folosința actuală: arabil, curți-construcții, conform extrasului de carte funciară nr. 89118 Bistrița",
                        "Destinația stabilită prin planurile de urbanism și de amenajare a teritoriului aprobate: Conform P.U.G. – Zona de locuințe șifuncțiuni complementare L2– Subzona locuințe actuale pe Amplasament: intravilan. Funcțiunea dominantă: locuire, funcțiuni complementare: instituții și servicii Utilizări premise: locuințe individuale Utilizări interzise: activități de servicii și producție care generează zgomot, praf, alte noxe și/ sau care implică un trafic important de mărfuri.",
                        "Se vor respecta reglementările fiscale specifice localității sau zonei, stabilite prin acte administrative de către Consiliul Local Bistrița.",
                        "Alte prevederirezultate din hotărârile consiliului local sau județean cu privire la zona în care se află imobilul - nu sunt.",
                    ],
                },
            ],
            [
                { type: "title", text: "REGIMUL TEHNIC" },
                {
                    type: "ol",
                    items: [
                        "S teren = 500,00 mp",
                        "Restricții impuse: Documentația pentru obținerea autorizației de desființare respectiv construire se vor prezenta conform cadrului conținut din Legea nr. 50/ 1991 privind autorizarea executării lucrărilor de construcții, republicată cu modificările și completările ulterioare",
                        "Obligații/constrângeri de natură urbanistică ce vor fi avute în vedere la proiectarea investiției: Conform P.U.G. – Zona de locuințe și funcțiuni complementare L– Subzona locuințe actuale L2",
                        {
                            type: "ol",
                            items: [
                                "regimul de aliniere a terenului și construcțiilor față de drumurile publice adiacente: construcțiile noi se vor dispune pe aliniament sau retrase de la aliniament, cu respectarea coerenței și caracterului zonei, cu o distanță cuprinsă între 4 și 6 m",
                                "retragerile și distanțele obligatorii la amplasarea construcțiilor față de proprietățile vecine: construcțiile noi se vor amplasa la o distanță minimă față de una din limitele laterale și față de limita posterioară egală cu jumătate din înălțimea construcției la cornișă. Se vor respecta prevederile Codului Civil pentru distanța față de limita opusă. Amplasarea pe parcelă va respecta cutuma locală.",
                                "elemente privind volumetria și/sau aspectul general al clădirilor în raport cu imobilele învecinate, precum și alte prevederi extrase din documentații de urbanism, din regulamentul local de urbanism, din P.U.Z., P.U.D. sau din Regulamentul General de Urbanism : Se vor alege soluții arhitecturale încadrate în aspectul specific local al zonei. Aspectul exterior să nu contravină funcțiunii acestora și nu depreciază aspectul general al zonei.",
                                "înălțimea maximă admisă pentru construcțiile noi (totală, la cornișă, la coamă, după caz) și caracteristicile volumetrice ale acestora, exprimate atât în număr de niveluri, cât și în dimensiunireale (metri): maxim P+1 cu înălțimea de 8 m la cornișă",
                                "procentul maxim de ocupare a terenului (POT) și coeficientul maxim de utilizare a terenului (CUT), raportate la suprafața de teren corespunzătoare zonei din parcelă care face obiectul solicitării: POT max = 30%.",
                                "dimensiunile și suprafețele minime și/sau maxime ale parcelelor (în cazul proiectelor de parcelare): front la stradă minim 12 m, suprafața minimă a parcelei 200 mp.",
                            ],
                        },
                        "Echiparea cu utilități existente și referințe cu privire la noi capacități prevăzute prin studiile și documentațiile anterior aprobate (apă, canalizare, gaze, energie electrică, energie termică, telecomunicații, transport urban etc.): În conformitate cu P.U.G. în zonă există rețele de energie electrică, apă potabilă și telefonie.",
                        "Circulația pietonilor și a autovehiculelor, accesele auto și parcajele necesare în zonă, potrivit studiilor și proiectelor anterior aprobate: Se va prevedea loc de parcare auto în limita proprietății.",
                    ],
                },
            ],
            [
                { type: "title", text: "REGIMUL DE ACTUALIZARE AL DOCUMENTAŢIEI DE URBANISM" },
                {
                    type: "p",
                    items: [
                        'În baza HCL nr. XXXX/20XX se modifică parţial şi se completează Regulamentul Local de Urbanism aferent documentaţiei "Actualizare Plan Urbanistic General al municipiului XXXXXXXXXXXX", aprobat cu HCL nr. 493/22.12.2014.',
                        'Nota: S-a solicitat eliberarea unui Certificat de Urbanism in scopul "Construire conform art.32 alin (1) litera e) din Legea 350/2001,imobil cu 2 unitati locative, refacere imprejmuire,amenajare teren,racorduri si bransamente la utilitati"',
                        'În conformitate cu prevederile Legii nr. 350/2001, actualizată, art. 32, alin. (1), pct. e): "Autoritatea publică locală, are dreptul, după caz, prin certificatul de urbanism: e) să permită întocmirea documentatiei tehnice pentru autorizatia de construire, fără elaborarea unei documentalii de urbanism, în situatia construirii pe o parcelă în cadrul unui front preexistent, în care clădirile de pe parcelele adiacente au acelas regim de înăltime cu cel solicitat".',
                        "În vederea sustinerii conditiilor stipulate de art. 32, al. (1), lit e) din Legea nr 350/2001 actualizata, s-a anexat adresa nr 128480/43/2021 a Comisiei Tehnice de Amenajare a Teritoriului si Urbanism prin care se mentioneaza ca pentru parcela identificata prin nr. cad. 252300 se poate intocmi documentatie tehnica pentru emiterea autorizatiei de construire fara elaborarea unei documentalii de urbanism.",
                        "Parcela cu nr. cad. 252300, in suprafata de 500 mp, este situata pe str. Vanatorului nr. FN, unde se remarca un front preexistent, cu cladiri dispuse in regim de construire izolat si retrase de la aliament.",
                        "Pe parcelele alaturate parcelei studiate exista front construit, case familiale pe:",
                        "str. Vanatorului nr. 46, conform nr. cad. 328136, imobil colectiv cu 3 unitati locative, cu regim de inaltime D+P+E+M",
                        "str. Vanatorului nr. 42, conform nr. cad. 324332, imobil colectiv cu 3 unitati locative, cu regim de inaltime S+P+E+M.",
                        "Din actele depuse la dosar decurg urmatoarele:",
                        "C.F. nr. 313359 a fost dezmembrat in mod succesiv, rezultand C.F. nr. 328136, cu imobilul colectiv avand regim de inaltime D+P+E+M si curtile aferente fiecarei unitati locative, precum si un teren pentru drum in suprafata de 37 mp.",
                        "C.F nr. 294157 a fost dezmembrat in mod succesiv, rezultand C.F nr. 324332, cu imobilul colectiv avand regim de inaltime D+P+E+M si curtile aferente fiecarei unitati locative, precum si un teren pentru drum in suprafata de 26 mp.",
                        'In conformitate art.7 alin 1 din Legea 50/1991 privind autorizarea executarii lucrarilor de constructii completata si modificata "Autorizatia de construire se emite pentru executarea lucrărilor de bază si a celor aferente organizării executării lucrărilor, în cel mult 30 de zile de la data depunerii documentatiei pentru autorizarea executării lucrărilor de constructii"',
                        "În conformitate cu prevederile Legii nr. 50/1991 privind autorizarea executării lucrărilor de constructii completată şi modificată: Art. 1, alin. (1 Al) Pentru realizarea lucrărilor de construcţii şi/sau deviere care privesc exclusiv racordarea/branşarea la reţelele tehnico-edilitare existente în zonă, acordul/autorizaţia administratorului drumului se emite de autorităţile competente în numele operatorilor de reţele tehnicoedilitare, pe baza cererii formulate de beneficiarul obiectivului şi în considerarea drepturilor legale de care beneficiază operatorul de reţea. Autorizaţiile de construire pentru imobil şi acordul/autorizaţia administratorului drumului pentru instalaţiile de racordare la utilităţi se emit concomitent. Imposibilitatea emiterii unui/unei acord/autorizaţii al/a administratorului drumului pentru realizarea instalaţiei de racordare la una din utilităţi nu va bloca procesul de emitere a celorlalte autorizaţii de construire pentru imobil şi alte utilităţi.",
                        "Art. (7) Se pot executa fără autorizaţie de construire: e) lucrări de branşamente şi racorduri executate pe domeniul public la infrastructura tehnico-edilitară existentă în zonă, cu acordul/autorizaţia administratorului drumului.",
                        'Strada Vanatorului pentru care solicitaţi emiterea certificatului de urbanism pentru lucrarea „Construire conform art.32 alin (1) litera e) din . Legea 350/2001,imobil cu 2 unitati locative, refacere imprejmuire,amenajare teren,racorduri si bransamente la utilitati" pe imobilul-teren identificat prin C.F. nr. 252300, număr cadastral 252300 se află în domeniului public, iar lucrările propuse spre avizare se încadrează în prevederile art.11, alin.7, fit. e) din Legea nr. 50/1991 actualizată, potrivit căruia sunt lucrări care nu necesită emiterea autorizaţiei de construire, cu condiţia obţinerii acordului/autorizaţiei administratorului drumului.',
                        "Documentatia pentru obtinerea autorizatiei de construire si desfiintare se va prezenta conform cadrului continut din Legea nr. 50/1991 privind autorizarea executarii lucrarilor de constructii, republicata, cu modificarile si completarile ulterioare ; de asemenea organizarea de santier se va face strict pe terenul proprietate fara a afecta vecinatatiile.",
                        "Prezentul certificat de urbanism poate fi utilizat în scopul declarat pentru:",
                        "Elaborare documentație pentru autorizarea executării lucrărilor de desființare CORPURI C2 ȘI C4 CONFORM CF 89118 Bistrița, respectiv construire a obiectivului EXTINDERE CORP C1, împrejmuire, branșamente și racorduri noi la rețelele de utilități urbane;",
                        "Certificatul de urbanism nu ține loc de autorizație de construire / desființare și nu conferă dreptul de a executa lucrări de construcții.",
                    ],
                },
            ],
            [
                { type: "title", text: "OBLIGAŢII ALE TITULARULUI CERTIFICATULUI DE URBANISM" },
                {
                    type: "p",
                    items: [
                        "În scopul elaborării documentației pentru autorizarea executării lucrărilor de construcții - de construire/de desființare - solicitantul se va adresa autorității competente pentru protecția mediului:",
                        "În aplicarea Directivei Consiliului 85/337/CEE (Directiva EIA) privind evaluarea efectelor anumitor proiecte publice și private asupra mediului, modificată prin Directiva Consiliului 97/11/CE și prin Directiva Consiliului și Parlamentului European 2003/35/CE privind participarea publicului la elaborarea anumitor planuri și programe în legătură cu mediul și modificarea, cu privire la participarea publicului și accesul la justiție, a Directivei 85/337/CEE și a Directivei 96/61/CE, prin certificatul de urbanism se comunică solicitantului obligația de a contacta autoritatea teritorială de mediu pentru ca aceasta să analizeze și să decidă, după caz, încadrarea/neîncadrarea proiectului investiției publice/private în lista proiectelor supuse evaluării impactului asupra mediului.",
                        "În aplicarea prevederilor Directivei Consiliului 85/337/CEE, procedura de emitere a acordului de mediu se desfășoară după emiterea certificatului de urbanism, anterior depunerii documentației pentru autorizarea executării lucrărilor de construcții la autoritatea administrației publice competente.",
                        "În vederea satisfacerii cerințelor cu privire la procedura de emitere a acordului de mediu, autoritatea competentă pentru protecția mediului stabilește mecanismul asigurării consultării publice, centralizării opțiunilor publicului și formulării unui punct de vedere oficial cu privire la realizarea investiției în acord cu rezultatele consultării publice.",
                        "În aceste condiții:",
                    ],
                },
                {
                    type: "ul",
                    items: [
                        "După primirea prezentului certificat de urbanism, titularul are obligația de a se prezenta la autoritatea competentă pentru protecția mediului în vederea evaluării inițiale a investiției și stabilirii demarării procedurii de evaluare a impactului asupra mediului și/sau a procedurii de evaluare adecvată. În urma evaluării inițiale a notificării privind intenția de realizare a proiectului se va emite punctul de vedere al autorității competente pentru protecția mediului.",
                        "În situația în care autoritatea competentă pentru protecția mediului stabilește efectuarea evaluării impactului asupra mediului și/sau a evaluării adecvate, solicitantul are obligația de a notifica acest fapt autorității administrației publice competente cu privire la menținerea cererii pentru autorizarea executării lucrărilor de construcții.",
                        "În situația în care, după emiterea certificatului de urbanism ori pe parcursul derulării procedurii de evaluare a impactului asupra mediului, solicitantul renunță la intenția de realizare a investiției, acesta are obligația de a notifica acest fapt autorității administrației publice competente.",
                    ],
                },
                {
                    id: "custom-data-1",
                    type: "custom",
                },
            ],
            [
                {
                    type: "title",
                    text: "CEREREA DE EMITERE A AUTORIZAŢIEI DE CONSTRUIRE/DESFIINŢARE",
                },
                {
                    type: "p",
                    items: ["va fi însoțită de următoarele documente:"],
                },
                {
                    type: "ol",
                    className: "roman",
                    items: [
                        "certificatul de urbanism (copie)",
                        "dovada, în copie conformă cu originalul, a titlului asupra imobilului, teren şi/sau construcții, și, după caz, extrasul de plan cadastral actualizat la zi şi extrasul de carte funciară de informare actualizat la zi, în cazul în care legea nu dispune altfel;",
                        "documentația tehnică — D.T., după caz (2 exemplare originale sau digital):",
                        {
                            type: "options",
                            data: [
                                { id: "option-x", label: "D.T.A.C", checked: true },
                                { id: "option-y", label: "D.T.O.E", checked: true },
                                { id: "option-z", label: "D.T.A.D", checked: true },
                            ],
                        },
                        "avizele și acordurile de amplasament stabilite prin certificatul de urbanism:",
                        {
                            type: "ol",
                            items: [
                                "avize și acorduri privind utilitățile urbane și infrastructura (copie):",
                                {
                                    type: "options",
                                    data: [
                                        {
                                            id: "option-1",
                                            label: "alimentare cu apa",
                                            checked: true,
                                        },
                                        {
                                            id: "option-2",
                                            label: "alimentare cu energie electrica",
                                            checked: true,
                                        },
                                        { id: "option-3", label: "gaze naturale", checked: true },
                                        { id: "option-4", label: "salubritate", checked: true },
                                        { id: "option-5", label: "canalizare", checked: true },
                                        {
                                            id: "option-6",
                                            label: "alimentare c u energie termica",
                                            checked: true,
                                        },
                                        { id: "option-7", label: "telefonizare", checked: false },
                                        {
                                            id: "option-8",
                                            label: "siguranța circulației",
                                            checked: true,
                                        },
                                    ],
                                },
                                "avize și acorduri privind:",
                                {
                                    type: "options",
                                    data: [
                                        {
                                            id: "option-a",
                                            label: "securitatea la incendiu",
                                            checked: true,
                                        },
                                        {
                                            id: "option-b",
                                            label: "protecția civila",
                                            checked: false,
                                        },
                                        {
                                            id: "option-c",
                                            label: "sănătatea populației",
                                            checked: true,
                                        },
                                    ],
                                },
                                "avize/acorduri specifice ale administrației publice centrale și/sau ale serviciilor descentralizate ale acestora (copie):",
                                {
                                    type: "ul",
                                    items: [
                                        "Plan topografic vizat de Oficiul de Cadastru și publicitate imobiliară pentru întocmirea DTAD/DTOE/DTAC (plan de încadrare în zonă și plan de situație – în format analogic și digital – format .dxf sistem de coordonate Stereo 70) inclusiv procesul verbal de recepție O.C.P.I. ;",
                                        "Certificat fiscal privind valoarea de impunere a clădirilor propuse pentru desființare",
                                    ],
                                },
                                "studii de specialitate (1 exemplar original)",
                                {
                                    type: "ul",
                                    items: [
                                        "Studiu geotehnic verificat la cerința „Af”;",
                                        "Studiu de integrare în specificul arhitecturii locale;",
                                        "Verificator tehnic;",
                                        "Expertiză tehnică",
                                        "Raport privind performanța energetica a clădirii conform Legii nr 372/2005 – cu prezentarea tabelului privind indicatorii de performanta: a) clasa energetica, b) consumul total specific de energie primara, c) indicele de emisii echivalent CO2,",
                                        "consumul total specific de energie din surse regenerabile)",
                                    ],
                                },
                                "punctul de vedere/actul administrativ al autorității competente pentru protecția mediului (copie); Act de reglementare emis de autoritatea competentă pentru protecția mediului la DTAD",
                                "Documentele de plată ale următoarelor taxe (copie)",
                            ],
                        },
                    ],
                },
                {
                    id: "custom-data-2",
                    type: "custom",
                },
            ],
        ],
    },
    {
        type: "p",
        items: [
            "Prezentul certificat de urbanism are valabilitate de 24 luni de la data emiterii.",
            "Prezentul certificat de urbanism a fost transmis solicitantului prin poșta electronică.",
            "În conformitate cu prevederile Legii nr. 50/1991 privind autorizarea executării lucrărilor de construcții, republicată, cu modificările și completările ulterioare.",
        ],
    },
];

function DocPreview({ parcelId = 89118, renderMode = "edit" }) {
    const [model, setModel] = React.useState(null);

    React.useEffect(() => {
        if (model) return;
        const getData = async () => {
            const response = await axios.get("/api/cu-data?parcelId=" + parcelId);
            const data = response.data;
            if (data && data.serverModel)
                setModel({
                    children: fromServerToClient(data.serverModel),
                });
        };
        getData();
        mode = renderMode;
    }, []);

    return (
        <div className="list-wrapper">
            {!model && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 10,
                        minHeight: 900,
                    }}
                >
                    <Spinner size={32} />
                </Box>
            )}
            {model && <HeadingCertificatPrint />}
            {serialize2HTML(model)}
        </div>
    );
}

export default DocPreview;
