import * as React from "react";

import { Button, Box, TextField, InputAdornment, OutlinedInput, Grid } from "@mui/material";

import { Link, useParams } from "react-router-dom";

import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let saveModel = null;

const parseJSON = (inputString) => {
    if (inputString) {
        try {
            return JSON.parse(inputString);
        } catch (e) {
            return inputString;
        }
    }
};

function DraftEditor({ model, modelKey, placeholder, isCompact }) {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const [hasFocus, setHasFocus] = React.useState(false);

    React.useEffect(() => {
        if (!editorState) return;
        if (saveModel && saveModel[modelKey]) {
            saveModel[modelKey].content = JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            );
        }
    }, [editorState]);

    React.useEffect(() => {
        if (!model) return;
        const section = model[modelKey];
        if (section && section.content) {
            const parsedData = parseJSON(section.content);
            if (typeof parsedData === "object") {
                setEditorState(EditorState.createWithContent(convertFromRaw(parsedData)));
            } else {
                setEditorState(
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML("<p>" + parsedData + "</p>")
                        )
                    )
                );
            }
        }
    }, [model]);

    return (
        <Editor
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            editorState={editorState}
            wrapperClassName={hasFocus ? "draft-wrapper-rlu focused" : "draft-wrapper-rlu"}
            editorClassName={isCompact ? "draft-editor compact" : "draft-editor"}
            toolbarClassName="draft-toolbar"
            onEditorStateChange={setEditorState}
            placeholder={placeholder}
            stripPastedStyles={true}
            toolbar={{
                options: ["inline", "list", "remove", "history"],
                inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline", "superscript", "subscript"],
                },
                list: {
                    options: ["unordered", "ordered"],
                },
            }}
        />
    );
}

function EditRluForm({ model, rluId, cancelEdit, saveRlu }) {
    let { parcelId } = useParams();
    parcelId = parcelId ? parseInt(parcelId) : 89118;

    React.useEffect(() => {
        saveModel = model;
        if (model) {
            let categoriiPOTData = model["section-4-15-categories"];
            if (!categoriiPOTData || !categoriiPOTData.length)
                categoriiPOTData = [{ name: "", value: "" }];
            setCategoriiPOT([...categoriiPOTData]);
            let categoriiCUTData = model["section-4-16-categories"];
            if (!categoriiCUTData || !categoriiCUTData.length)
                categoriiCUTData = [{ name: "", value: "" }];
            setCategoriiCUT([...categoriiCUTData]);
        }
        console.log(model);
    }, [model]);

    const [categoriiPOT, setCategoriiPOT] = React.useState([]);
    const [categoriiCUT, setCategoriiCUT] = React.useState([]);

    const updateCategoriiPOT = (e, index) => {
        let temporary = categoriiPOT;
        const name = e.target.name;
        temporary[index][name] = e.target.value;
        setCategoriiPOT([...temporary]);
        saveModel["section-4-15-categories"] = [...temporary];
    };

    const updateCategoriiCUT = (e, index) => {
        let temporary = categoriiCUT;
        const name = e.target.name;
        temporary[index][name] = e.target.value;
        setCategoriiCUT([...temporary]);
        saveModel["section-4-16-categories"] = [...temporary];
    };

    const renderRow = (modelKey, index) => {
        const data = model[modelKey];
        return (
            <Box sx={{ mb: 3 }} key={index}>
                <label>
                    <Box
                        id={modelKey}
                        sx={{
                            mb: 1,
                        }}
                    >
                        {data.title}
                    </Box>
                    <DraftEditor model={model} modelKey={modelKey} />
                </label>
            </Box>
        );
    };

    const renderMainHeading = (modelKey, model) => {
        const data = model[modelKey];
        return (
            <Box sx={{ mb: 3 }}>
                <Box id={modelKey} component="h4" sx={{ mb: 1.5 }}>
                    {data.title}
                </Box>
                <DraftEditor
                    placeholder="Introducere sectiune"
                    isCompact={true}
                    model={model}
                    modelKey={modelKey}
                />
            </Box>
        );
    };

    const saveData = () => {
        console.log(saveModel);
        saveRlu(saveModel);
    };

    return (
        <Box key={rluId}>
            {renderMainHeading("section-1-intro", model)}

            {["section-1-a", "section-1-b", "section-1-c"].map((modelKey, index) =>
                renderRow(modelKey, index)
            )}

            {renderMainHeading("section-2-intro", model)}

            {["section-2-1", "section-2-2", "section-2-3"].map((modelKey, index) =>
                renderRow(modelKey, index)
            )}

            {renderMainHeading("section-3-intro", model)}

            {[
                "section-3-4",
                "section-3-5",
                "section-3-6",
                "section-3-7",
                "section-3-8",
                "section-3-9",
                "section-3-10",
                "section-3-11",
                "section-3-12",
                "section-3-13",
                "section-3-14",
            ].map((modelKey, index) => renderRow(modelKey, index))}

            {renderMainHeading("section-4-intro", model)}

            <label>
                <Box
                    sx={{
                        mb: 1,
                    }}
                >
                    {model["section-4-15"].title}
                </Box>
            </label>

            {categoriiPOT.map((row, index) => (
                <Grid key={index} container spacing={2} mb={2}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            value={row.name}
                            name="name"
                            onChange={(e) => updateCategoriiPOT(e, index)}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="Descriere categorie:"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <OutlinedInput
                            value={row.value}
                            name="value"
                            onChange={(e) => updateCategoriiPOT(e, index)}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="P.O.T."
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        />
                    </Grid>
                </Grid>
            ))}

            <Button
                variant="outlined"
                size="large"
                sx={{ mr: "1rem", mb: 3 }}
                onClick={() => setCategoriiPOT([...categoriiPOT, { name: "", value: "" }])}
            >
                + Adauga o categorie
            </Button>

            <Box sx={{ mb: 3 }}>
                <DraftEditor
                    placeholder="Alte detalii..."
                    isCompact={true}
                    model={model}
                    modelKey={"section-4-15"}
                />
            </Box>

            <label>
                <Box
                    sx={{
                        mb: 1,
                    }}
                >
                    {model["section-4-16"].title}
                </Box>
            </label>

            {categoriiCUT.map((row, index) => (
                <Grid key={index} container spacing={2} mb={2}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            value={row.name}
                            name="name"
                            onChange={(e) => updateCategoriiCUT(e, index)}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="Descriere categorie:"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <OutlinedInput
                            value={row.value}
                            name="value"
                            onChange={(e) => updateCategoriiCUT(e, index)}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="C.U.T."
                        />
                    </Grid>
                </Grid>
            ))}

            <Button
                variant="outlined"
                size="large"
                sx={{ mr: "1rem", mb: 3 }}
                onClick={() => setCategoriiCUT([...categoriiCUT, { name: "", value: "" }])}
            >
                + Adauga o categorie
            </Button>

            <Box sx={{ mb: 3 }}>
                <DraftEditor
                    placeholder="Alte detalii..."
                    isCompact={true}
                    model={model}
                    modelKey={"section-4-16"}
                />
            </Box>

            <Button variant="outlined" size="large" sx={{ mr: "1rem", mb: 3 }} onClick={cancelEdit}>
                Anuleaza
            </Button>

            <Button variant="contained" size="large" sx={{ mr: "1rem", mb: 3 }} onClick={saveData}>
                Salveaza Modificarile
            </Button>
        </Box>
    );
}

export default EditRluForm;
