import * as React from "react";

import Heading from "./components/DashboardHeading";
import Comment from "./components/Comment";

import {
    Button,
    Box,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    Container,
    Divider,
} from "@mui/material";

import SubjectIcon from "@mui/icons-material/Subject";
import SendIcon from "@mui/icons-material/Send";

import DashboardDocsList from "./DashboardDocsList";
import DashboardDocPreview from "./DashboardDocPreview";
import RespingereCerere from "./RespingereCerere";
import TrimiteMesaj from "./TrimiteMesaj";
import MesajSolicitant from "./MesajSolicitant";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";

import EmailIcon from "@mui/icons-material/Email";
import SaveIcon from "@mui/icons-material/Save";
import PDFSample from "./images/certificat.pdf";
import UploadIcon from "@mui/icons-material/Upload";

import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import ReplayIcon from "@mui/icons-material/Replay";
import CancelIcon from "@mui/icons-material/Cancel";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import PrevizualizareHarta from "./PrevizualizareHartaPrint";

function DocumentItem(props) {
    let icon = <PictureAsPdfIcon sx={{ color: "red" }} />;
    if (props.type === "doc") icon = <ArticleIcon color="primary" />;
    return (
        <Paper
            sx={{
                px: 2,
                py: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1.5,
                "& h2": {
                    margin: 0,
                    fontSize: "1.75em",
                },
                "& .MuiButton-root": {
                    opacity: 0.5,
                },
                "&:hover": {
                    transition: "background 0.35s",
                    cursor: "pointer",
                    "& .MuiButton-root": {
                        opacity: 1,
                    },
                },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                    sx={{
                        background: props.new ? "paleturquoise" : "rgba(0,0,0,0.05)",
                        borderRadius: "40px",
                        width: 40,
                        height: 40,
                        mr: 1.5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                </Box>
                <Box>{props.children}</Box>
            </Box>
            <Button size="small" color="inherit" sx={{ px: 1 }} startIcon={<ZoomInIcon />}>
                vizualizare
            </Button>
        </Paper>
    );
}

function Autorizare() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [iframeHeight, setIframeHeight] = React.useState(0);

    const [canBeSigned, setCanBeSigned] = React.useState(true);
    const [openRespingeCerere, setOpenRespingeCerere] = React.useState(false);
    const [openTrimiteMesaj, setOpenTrimiteMesaj] = React.useState(false);
    const [comments, setComments] = React.useState([
        {
            text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
            username: "Ion Popescu",
            date: new Date(),
            blocking: false,
            replies: [
                {
                    text: "Nu am inteles care este problema.",
                    username: "Radu Mihai",
                    date: new Date(),
                },
                {
                    text: "Explicatia este simpla...",
                    username: "Dan Pop",
                    date: new Date(),
                },
            ],
        },
        // {
        //     text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
        //     username: "Ion Popescu",
        //     date: new Date(),
        //     blocking: false,
        //     replies: [],
        // },
    ]);

    const [anexe, setAnexe] = React.useState([]);

    const onChange = (e) => {
        const newFiles = e.target.files;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push({
                name: newFiles[i].name,
                url: URL.createObjectURL(newFiles[i]),
                newUpload: true,
            });
        }
        newFiles.length > 0 && setAnexe([...anexe, ...newFilesArray]);
    };

    const stergeAnexa = (index) => {
        var array = [...anexe]; // make a separate copy of the array
        array.splice(index, 1);
        setAnexe([...array]);
    };

    const setExistingAnexe = (array) => {
        const newFilesArray = [];
        for (let i = 0, numFiles = array.length; i < numFiles; i++) {
            newFilesArray.push({
                name: "Document - Anexa " + array[i] + ".pdf",
                url: "/anexa.pdf",
            });
        }
        for (let i = 0, numFiles = anexe.length; i < numFiles; i++) {
            if (anexe[i].newUpload) newFilesArray.push(anexe[i]);
        }
        setAnexe([...newFilesArray]);
    };

    const adaugaObservatie = () => {
        if (!comments.length || comments[comments.length - 1].text) {
            setComments([
                ...comments,
                {
                    text: "",
                    username: "Teodor Dumitru",
                    date: new Date(),
                    replies: [],
                },
            ]);
        }
    };

    const saveComment = (index, text, blocking) => {
        if (text.trim()) {
            let commentsCopy = comments;
            commentsCopy[index].text = text;
            commentsCopy[index].blocking = blocking;
            setComments([...commentsCopy]);
            enqueueSnackbar("Observatia a fost adaugata!", { variant: "success" });
        }
    };

    const deleteComment = (index) => {
        let commentsCopy = comments;
        commentsCopy.splice(index, 1);
        setComments([...commentsCopy]);
    };

    const addReply = (index, text, willUnblockIssue) => {
        if (text.trim()) {
            let commentsCopy = comments;
            commentsCopy[index].replies.push({
                text: text,
                username: "Teodor Dumitru",
                date: new Date(),
            });
            if (willUnblockIssue) commentsCopy[index].blocking = false;
            setComments([...commentsCopy]);
            enqueueSnackbar("Raspunsul a fost adaugat!", { variant: "success" });
        }
    };

    React.useEffect(() => {
        let hasBlockingIssues = false;
        comments.forEach((element) => {
            if (element.blocking) hasBlockingIssues = true;
        });
        setCanBeSigned(!hasBlockingIssues);
    }, [comments]);

    React.useEffect(() => {
        console.log(document.comments);
        if (document.comments && document.comments.length) setComments([...document.comments]);
    }, []);

    const [openPopper, setOpenPopper] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = (event, index) => {
        setOpenPopper(false);
    };

    const handleToggle = () => {
        setOpenPopper((prevOpen) => !prevOpen);
    };

    const handleClosePopper = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopper(false);
    };

    const complexButton = (
        <React.Fragment>
            <Button
                variant="contained"
                size="large"
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    background: "slategrey",
                    "&:hover": {
                        background: "#4e5a65",
                    },
                }}
                ref={anchorRef}
                onClick={handleToggle}
            >
                Alte optiuni...
            </Button>

            <Popper
                sx={{
                    zIndex: 3000,
                }}
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4],
                        },
                    },
                ]}
                placement="top-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ border: "1px solid rgba(0,0,0,0.4)" }} elevation={3}>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList id="split-button-menu">
                                    {/* <MenuItem
                                        onClick={(event) => {
                                            setOpenTrimiteMesaj(true);
                                            handleMenuItemClick(event);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <EmailIcon color="primary" />
                                        </ListItemIcon>
                                        Trimite Mesaj
                                    </MenuItem>
                                    <Divider /> */}
                                    <MenuItem
                                        onClick={(event) => {
                                            setOpenRespingeCerere(true);
                                            handleMenuItemClick(event);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <CancelIcon sx={{ color: "red" }} />
                                        </ListItemIcon>
                                        Respinge Cererea
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={(event) => handleMenuItemClick(event)}
                                        to="/dashboard/cereri"
                                        component={Link}
                                    >
                                        <ListItemIcon>
                                            <ReplayIcon />
                                        </ListItemIcon>
                                        Anuleaza Modificarile
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );

    return (
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <SubjectIcon sx={{ mb: "-5px", mr: 1.25 }} />
                <span>Autorizare cerere certificat urbanism</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Paper sx={{ mb: 1, background: "transparent" }} elevation={0}>
                    {/* <iframe
                        height={iframeHeight}
                        width="100%"
                        scrolling="no"
                        style={{
                            border: "none",
                        }}
                        onLoad={(e) => {
                            const { contentWindow } = e.target;
                            setIframeHeight(contentWindow.document.body.scrollHeight);

                            contentWindow.addEventListener("resize", function (e) {
                                setIframeHeight(contentWindow.document.body.scrollHeight);
                            });
                        }}
                        src="/cu/index.html"
                    ></iframe> */}

                    <DashboardDocPreview />

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Previzualizare amplasamente:</Heading>

                        <PrevizualizareHarta
                            setScale={() => {
                                return null;
                            }}
                            setParcelData={() => {
                                return null;
                            }}
                            extended={true}
                            parcelId={80547}
                        />
                    </Paper>

                    <Heading sx={{ pb: 1, mt: 3 }}>Documente anexate de solicitant:</Heading>

                    <DocumentItem>Document-atasat.pdf</DocumentItem>
                    <DocumentItem type="doc">Document-atasat.doc</DocumentItem>

                    <Heading sx={{ pb: 1, mt: 3 }}>Mesaje solicitant:</Heading>

                    <MesajSolicitant />

                    <Heading sx={{ pb: 1, mt: 3 }}>Anexare documente:</Heading>

                    <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js`}
                    >
                        {anexe.map((anexa, index) => (
                            <div
                                style={{
                                    border: "1px solid #b2b2b2",
                                    marginBottom: "1rem",
                                    borderRadius: "4px",
                                    overflow: "hidden",
                                }}
                                key={index}
                            >
                                <div
                                    style={{
                                        background: "#EEE",
                                        padding: "0.25rem 0.25rem 0.25rem 0.75rem",
                                        fontWeight: 600,
                                        fontSize: "0.9rem",
                                        borderBottom: "1px solid #d6d6d6",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            textOverflow: "ellipsis",
                                            display: "block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            maxWidth: "80%",
                                        }}
                                    >
                                        {anexa.name}
                                    </span>
                                    <Tooltip title="Elimina Anexa" placement="top">
                                        <IconButton
                                            onClick={() => stergeAnexa(index)}
                                            sx={{
                                                "&:hover": {
                                                    color: "tomato",
                                                },
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div
                                    style={{
                                        height: "842px",
                                        position: "relative",
                                    }}
                                >
                                    <Viewer fileUrl={anexa.url} />
                                    {/* <Viewer
                                        fileUrl={
                                            "https://www.onrc.ro/templates/site/formulare/anexa_1_inregistrare_fiscala.pdf"
                                        }
                                    /> */}
                                </div>
                            </div>
                        ))}
                        {!anexe.length && (
                            <div
                                style={{
                                    alignItems: "center",
                                    border: "1px dashed rgba(0, 0, 0, .6)",
                                    display: "flex",
                                    height: "100px",
                                    justifyContent: "center",
                                    opacity: 0.5,
                                    marginBottom: "1rem",
                                }}
                            >
                                Nu aveti documente anexate.
                            </div>
                        )}

                        <DashboardDocsList setExistingAnexe={setExistingAnexe} />

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            component="label"
                            startIcon={<UploadIcon />}
                            sx={{
                                minWidth: 300,
                                background: "slategrey",
                                mb: "1rem",
                                "&:hover": {
                                    background: "#4e5a65",
                                },
                            }}
                            elevation={0}
                        >
                            Incarca document nou...
                            <input type="file" accept=".pdf" onChange={onChange} multiple hidden />
                        </Button>
                    </Worker>

                    <Heading sx={{ pb: 1, mt: 3 }}>Observatii:</Heading>

                    {!comments.length && (
                        <div
                            style={{
                                alignItems: "center",
                                border: "1px dashed rgba(0, 0, 0, .6)",
                                display: "flex",
                                height: "100px",
                                justifyContent: "center",
                                opacity: 0.5,
                                marginBottom: "1rem",
                            }}
                        >
                            Nu exista observatii.
                        </div>
                    )}
                    {comments.map((comment, index) => (
                        <Comment
                            data={comment}
                            index={index}
                            key={index}
                            saveComment={saveComment}
                            deleteComment={deleteComment}
                            addReply={addReply}
                            sx={{ mb: 2 }}
                        />
                    ))}

                    <br />
                </Paper>

                {canBeSigned ? (
                    <Button
                    onClick={() =>
                        enqueueSnackbar("Documentul a fost transmis pentru aprobare", { variant: "success" })
                    }
                        variant="contained"
                        color="secondary"
                        size="large"
                        to="/dashboard/cereri/aprobat"
                        component={Link}
                        sx={{ float: "right" }}
                        startIcon={<SendIcon />}
                    >
                        Trimite spre aprobare
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        to="/dashboard/cereri/aprobat"
                        component={Link}
                        sx={{ float: "right" }}
                        startIcon={<SendIcon />}
                        disabled
                    >
                        Trimite spre aprobare
                    </Button>
                )}

                {/* <Button
                    variant="outlined"
                    size="large"
                    to="/dashboard/cereri"
                    component={Link}
                    sx={{ mr: 1 }}
                >
                    Anuleaza
                </Button> */}

                {/* <Button
                    variant="contained"
                    size="large"
                    color="inherit"
                    endIcon={<PictureAsPdfIcon sx={{ color: "red" }} />}
                    sx={{ background: "#FFF", minWidth: 300, mr: "1rem" }}
                    href={PDFSample}
                    target="_blank"
                >
                    Previzualizare Document
                </Button> */}

                <Button
                    onClick={() =>
                        enqueueSnackbar("Modificarile au fost salvate", { variant: "success" })
                    }
                    startIcon={<SaveIcon />}
                    variant="contained"
                    size="large"
                    sx={{ mr: 1 }}
                >
                    Salveaza Modificarile
                </Button>

                {complexButton}

                {openRespingeCerere && (
                    <RespingereCerere handleModalClose={() => setOpenRespingeCerere(false)} />
                )}
                {openTrimiteMesaj && (
                    <TrimiteMesaj
                        handleModalClose={() => setOpenTrimiteMesaj(false)}
                    />
                )}
            </Box>
        </Container>
    );
}

export default Autorizare;
