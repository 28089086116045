import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PostAddIcon from "@mui/icons-material/PostAdd";

import { Link } from "react-router-dom";

import Chart from "./Chart";
import Deposits from "./Deposits";
import Notificari from "./Notificari";

import LinkButton from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Heading from "./components/DashboardHeading";

import TabelCereri from "./TabelCereri";

import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import Chip from "@mui/material/Chip";

import { useSelector, useDispatch } from "react-redux";

function ChartChip(props) {
    let DirectionIcon = props.direction === "up" ? <TrendingUpIcon /> : <TrendingDownIcon />;
    return (
        <Chip
            icon={DirectionIcon}
            color={props.direction === "up" ? "success" : "warning"}
            variant="outlined"
            clickable
            sx={{
                width: 60,
                height: 50,
                border: "none",
                color: props.direction === "up" ? "#3cb371" : "#ff4500",
                background:
                    props.direction === "up"
                        ? "rgba(84, 214, 44, 0.16)"
                        : "rgba(255, 72, 66, 0.16)",
                "& .MuiSvgIcon-root": {
                    width: "2rem",
                    height: "2rem",
                    margin: 0,
                },
                "& .MuiChip-label": {
                    display: "none",
                },
            }}
        />
    );
}

function SmallGridItem(props) {
    return (
        <Grid item xs={6} md={4} lg={4}>
            <Paper
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "linear-gradient(314deg, #dceeff, #FFF)",
                    "& h2": {
                        margin: 0,
                        fontSize: "1.75em",
                    },
                    "&:hover": {
                        background: "#fefefe",
                        transition: "background 0.35s",
                        cursor: "pointer",
                    },
                }}
            >
                {props.children}
            </Paper>
        </Grid>
    );
}

function CustomHeading(props) {
    return (
        <Heading
            sx={{
                px: 2,
                pb: 2,
                pt: 2,
                mb: 4,
                borderBottom: "1px dotted rgba(0,0,0,0.5)",
                textTransform: "uppercase",
                fontSize: "1.1rem",
                height: 6,
                overflow: "visible",
            }}
        >
            <Box
                sx={{
                    background: "#eef4fc",
                    display: "inline-block",
                    p: 1,
                    borderRadius: "200px",
                }}
            >
                {props.children}
            </Box>
        </Heading>
    );
}

function DashboardSumar() {
    const userData = useSelector((state) => state.auth.userData);
    const [userRole, setUserRole] = React.useState(null);

    React.useEffect(() => {
        if (userData) setUserRole(userData.role);
    }, [userData]);
    return (
        <React.Fragment>
            <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
                {["PRIMAR"].includes(userRole) && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={7} lg={8}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    height: 220,
                                    background: "linear-gradient(0deg, #dceeff, #FFF)",
                                }}
                            >
                                <Chart />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={5} lg={4}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    height: 220,
                                    background: "linear-gradient(315deg, #dceeff, #FFF)",
                                }}
                            >
                                <Deposits />
                            </Paper>
                        </Grid>
                        <SmallGridItem>
                            <div>
                                <Typography color="text.secondary">Certificate Emise</Typography>
                                <h2>765</h2>
                            </div>
                            <ChartChip direction="up" />
                        </SmallGridItem>
                        <SmallGridItem>
                            <div>
                                <Typography color="text.secondary">Conturi Inregistrate</Typography>
                                <h2>1338</h2>
                            </div>
                            <ChartChip direction="up" />
                        </SmallGridItem>
                        <SmallGridItem>
                            <div>
                                <Typography color="text.secondary">Reclamatii Deschise</Typography>
                                <h2>46</h2>
                            </div>
                            <ChartChip direction="down" />
                        </SmallGridItem>
                    </Grid>
                )}

                {["EDITOR"].includes(userRole) && (
                    <Grid container spacing={3} maxWidth={1200}>
                        {/* <Grid item xs={12} md={6}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    minHeight: 75,
                                    height: "100%",
                                    boxSizing: "border-box",
                                    background: "linear-gradient(315deg, #dceeff, #FFF)",
                                }}
                            >
                                <Heading>Notificari</Heading>
                                <Typography>
                                    Nu ai alerte sau notificari recente. Pentru a seta o alerta
                                    noua, mergi la sectiunea{" "}
                                    <LinkButton
                                        color="primary"
                                        to="/dashboard/alerte"
                                        component={Link}
                                    >
                                        <strong>Alertele Mele</strong>
                                    </LinkButton>{" "}
                                    din meniul principal.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    minHeight: 75,
                                    height: "100%",
                                    boxSizing: "border-box",
                                    background: "linear-gradient(315deg, #fff698, #FFF)",
                                }}
                            >
                                <Heading>Sesizari</Heading>
                                <Typography>
                                    Aveti <strong>3</strong> sesizari noi. Pentru a vizualiza si
                                    raspunde sesizarilor mergi la sectiunea{" "}
                                    <LinkButton
                                        color="primary"
                                        to="/dashboard/sesizari"
                                        component={Link}
                                    >
                                        <strong>Sesizari</strong>
                                    </LinkButton>{" "}
                                    din meniul principal.
                                </Typography>
                            </Paper>
                        </Grid> */}
                    </Grid>
                )}

                {userRole === "USER" && (
                    <Grid container spacing={3} maxWidth={1200}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    height: 260,
                                    boxSizing: "border-box",
                                    background: "linear-gradient(315deg, #dceeff, #FFF)",
                                }}
                            >
                                <Heading>Certificatul de Urbanism</Heading>
                                <Typography component="p">
                                    Cel mai simplu mod pentru obtinerea tuturor tipurilor de
                                    certificate de urbanism: pentru informare, demolare, construire
                                    sau notariat.
                                </Typography>
                                <br />
                                <Button
                                    to="/dashboard/certificat"
                                    component={Link}
                                    variant="contained"
                                    endIcon={<PostAddIcon />}
                                    sx={{ maxWidth: "300px" }}
                                >
                                    Solicita Certificat Urbanism
                                </Button>
                                <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
                                <div>
                                    <LinkButton
                                        color="primary"
                                        to="/dashboard/certificate"
                                        component={Link}
                                    >
                                        Vezi Cerficiatele Emise...
                                    </LinkButton>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    height: 260,
                                    boxSizing: "border-box",
                                    background: "linear-gradient(315deg, #fff698, #FFF)",
                                }}
                            >
                                <Heading>Depune o Sesizare</Heading>
                                <Typography component="p">
                                    Ai o sesizare sau o reclamatie pentru Primarie? Noi ne ocupam de
                                    transmiterea lor, urmărirea rezolvării şi transmiterea
                                    răspunsului.
                                </Typography>
                                <br />
                                <Button
                                    to="/dashboard/sesizare"
                                    component={Link}
                                    variant="contained"
                                    color="warning"
                                    endIcon={<PostAddIcon />}
                                    sx={{ maxWidth: "200px" }}
                                >
                                    Adauga Sesizare
                                </Button>
                                <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
                                <div>
                                    <LinkButton
                                        color="primary"
                                        to="/dashboard/sesizarile-mele"
                                        component={Link}
                                    >
                                        Vezi Stadiul Sesizarile Depuse...
                                    </LinkButton>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomHeading>Alerte si Notificari</CustomHeading>
                            <Notificari />
                            <Box sx={{px: 2, py: 1}}>
                                <LinkButton
                                    color="primary"
                                    to="/dashboard/notificari"
                                    component={Link}
                                >
                                    Vezi Toate Notificarile...
                                </LinkButton>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>

            {["PRIMAR", "EDITOR"].includes(userRole) && (
                <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
                    <Paper
                        sx={{
                            p: 3,
                            mb: 3,
                            display: "flex",
                            flexDirection: "column",
                            background: "linear-gradient(316deg, #f6f6f6, #FFF)",
                        }}
                    >
                        <Heading>Cereri Recente</Heading>
                        <TabelCereri onlyPending={true} />
                        <LinkButton
                            to="/dashboard/cereri"
                            component={Link}
                            color="primary"
                            sx={{ mt: 3 }}
                        >
                            Vezi toate cererile
                        </LinkButton>
                    </Paper>
                    <CustomHeading>Notificari Recente</CustomHeading>
                    <Notificari />
                    <Box sx={{px: 2, py: 1}}>
                                <LinkButton
                                    color="primary"
                                    to="/dashboard/notificari"
                                    component={Link}
                                >
                                    Vezi Toate Notificarile...
                                </LinkButton>
                            </Box>
                </Container>
            )}
        </React.Fragment>
    );
}

export default function Dashboard() {
    return <DashboardSumar />;
}
