import * as React from "react";

import { Button, Container, Grid, Paper } from "@mui/material/";
import { Table, TableContainer, TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubjectIcon from "@mui/icons-material/Subject";

import Heading from "./components/DashboardHeading";
import AdaugaAlerta from "./AdaugaAlerta";
import StergeAlerta from "./StergeAlerta";

import { useSnackbar } from "notistack";

// Generate Order Data
function createData(id, date, obiectiv, tip, newEntry) {
    return { id, date, obiectiv, tip, newEntry };
}

function Alerte() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [alerta, setAlerta] = React.useState({});
    const [notificari, setNotificari] = React.useState([
        createData(0, "25 Mar, 2022", "PUG lc_a", "Detalii Zona", true),
        createData(1, "18 Mar, 2022", "PUZ lc_b", "Detalii Zona", true),
        createData(2, "18 Mar, 2022", "PUZ lc_b", "Documente", true),
        createData(3, "18 Mar, 2022", "PUG lc_a", "Documente", false),
        createData(4, "18 Mar, 2022", "PUG lc_a", "Documente", false),
        createData(5, "18 Mar, 2022", "PUZ lc_b", "Documente", false),
        createData(6, "18 Mar, 2022", "PUZ lc_b", "Detalii Zona", false),
    ]);
    const [alerte, setAlerte] = React.useState([
        {
            obiectiv: "PUG lc_a",
            alerta_detalii: true,
            alerta_documente: true,
        },
        {
            obiectiv: "PUZ lc_b",
            alerta_detalii: true,
            alerta_documente: false,
        },
    ]);
    const [dialogEditeazaAlerta, setDialogEditeazaAlerta] = React.useState(false);
    const [dialogStergeAlerta, setDialogStergeAlerta] = React.useState(false);

    const salveazaAlerta = (alertaNoua) => {
        setAlerte([...alerte.map((el) => (el.obiectiv === alertaNoua.obiectiv ? alertaNoua : el))]);
        handleEditeazaAlertaClose();
        enqueueSnackbar("Setarile alertei au fost salvate", {variant: "success"});
    };

    const handleEditeazaAlertaOpen = (alerta) => {
        setDialogEditeazaAlerta(true);
        setAlerta(alerta);
    };

    const handleEditeazaAlertaClose = () => {
        setDialogEditeazaAlerta(false);
    };

    const handleStergeAlertaOpen = (alerta) => {
        setDialogStergeAlerta(true);
        setAlerta(alerta);
    };

    const stergeAlerta = () => {
        setAlerte([
            ...alerte.filter(function (item) {
                return item.obiectiv !== alerta.obiectiv;
            }),
        ]);
        setNotificari([
            ...notificari.filter(function (notificare) {
                return notificare.obiectiv !== alerta.obiectiv;
            }),
        ]);
        handleStergeAlertaClose();
        enqueueSnackbar("Alerta a fost stearsa", {variant: "info"});
    };

    const handleStergeAlertaClose = () => {
        setDialogStergeAlerta(false);
    };

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7} lg={8}>
                    <Paper
                        sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Heading>Alerte Primite</Heading>
                        <TableContainer>
                            <Table
                                size="small"
                                sx={{
                                    minWidth: 650,
                                    "& .MuiTableCell-root": {
                                        fontSize: "100%",
                                    },
                                }}
                            >
                                {!!notificari.length && (
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Obiectiv</TableCell>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Tip Alerta</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {!notificari.length && (
                                        <TableRow>
                                            <TableCell
                                                rowSpan={2}
                                                sx={{ p: 0, pt: 1, border: "none", opacity: 0.7 }}
                                            >
                                                Nu aveti notificari primite.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {notificari.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ background: row.newEntry ? "aliceblue" : "" }}
                                        >
                                            <TableCell>
                                                <strong>{row.obiectiv}</strong>
                                            </TableCell>
                                            <TableCell>{row.date}</TableCell>
                                            <TableCell>
                                                {
                                                    {
                                                        "Detalii Zona": (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <SubjectIcon sx={{ mr: 1 }} />
                                                                <span>{row.tip}</span>
                                                            </div>
                                                        ),
                                                        Documente: (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <AttachFileIcon sx={{ mr: 1 }} />
                                                                <span>{row.tip}</span>
                                                            </div>
                                                        ),
                                                    }[row.tip]
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    {
                                                        "Detalii Zona": (
                                                            <Button
                                                                component={Link}
                                                                to="/detalii-zona"
                                                            >
                                                                Vezi modificarile
                                                            </Button>
                                                        ),
                                                        Documente: (
                                                            <Button
                                                                component={Link}
                                                                to="/documente-avize"
                                                            >
                                                                Vezi modificarile
                                                            </Button>
                                                        ),
                                                    }[row.tip]
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Paper
                        sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            height: 280,
                        }}
                    >
                        <Heading>Configurare Alerte</Heading>
                        <Table
                            size="small"
                            sx={{
                                "& .MuiTableCell-root": {
                                    fontSize: "100%",
                                },
                            }}
                        >
                            <TableBody>
                                {alerte.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.obiectiv}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditeazaAlertaOpen(row)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleStergeAlertaOpen(row)}
                                            >
                                                <DeleteForeverIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {!alerte.length && (
                                    <TableRow>
                                        <TableCell
                                            rowSpan={2}
                                            sx={{ p: 0, pt: 1, border: "none", opacity: 0.7 }}
                                        >
                                            Nu aveti alerte configurate.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <br />
                        <Button variant="contained" to="/manual-utilizare/alerte" component={Link}>
                            Adauga o alerta noua
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
            <AdaugaAlerta
                open={dialogEditeazaAlerta}
                handleClose={handleEditeazaAlertaClose}
                handleSave={salveazaAlerta}
                alerta={alerta}
            />
            <StergeAlerta
                open={dialogStergeAlerta}
                handleClose={handleStergeAlertaClose}
                handleDelete={stergeAlerta}
                alerta={alerta}
            />
        </Container>
    );
}

export default function DashboardAlerte() {
    return <Alerte />;
}
