import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import PrevizualizareHarta from "./PrevizualizareHarta";

function Previzualizare(props) {
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>
                    {props.parcelData.properties.type === "puz"
                        ? props.parcelData.properties.name
                        : props.parcelData.properties.type}
                </span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ minWidth: "600px", width: "800px" }}>
                <PrevizualizareHarta parcelData={props.parcelData} />
            </DialogContent>
        </Dialog>
    );
}

export default Previzualizare;
