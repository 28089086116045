import React from "react";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogTitle, DialogActions, Alert } from "@mui/material";
import { Button, IconButton, Box, Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

function AdaugaAlerta({ open, alerta, handleClose, handleSave }) {
    const [formError, setFormError] = React.useState(false);
    const [formData, setFormData] = React.useState({
        obiectiv: "",
        alerta_detalii: true,
        alerta_documente: false,
    });

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const handleFormSave = (e) => {
        if (!formData["alerta_detalii"] && !formData["alerta_documente"]) {
            setFormError(true);
        } else {
            setFormError(false);
            handleSave(formData);
        }
    };

    React.useEffect(() => {
        // const newFormData = Object.assign(formData, alerta);
        setFormData({ ...formData, ...alerta });
    }, [alerta]);

    return (
        <Dialog
            open={open}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{
                "& .MuiDialog-paper": { minHeight: 200, width: 720 },
            }}
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Seteaza alerta</span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                {formError && (
                    <React.Fragment>
                        <Alert severity="error">
                            Pentru a salva o alerta, trebuie sa bifezi cel putin o optiune.
                        </Alert>
                        <br />
                    </React.Fragment>
                )}
                <Box
                    sx={{
                        m: 1,
                    }}
                >
                    <p>
                        Optiuni alerta pentru zona{" "}
                        <strong
                            style={{ background: "aliceblue", padding: "10px", borderRadius: 4 }}
                        >
                            {alerta.obiectiv}
                        </strong>
                    </p>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="alerta_detalii"
                                    checked={formData.alerta_detalii}
                                    onChange={handleFormChange}
                                />
                            }
                            label="Primeste alerta cand apar modificari la detaliile zonei"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="alerta_documente"
                                    checked={formData.alerta_documente}
                                    onChange={handleFormChange}
                                />
                            }
                            label="Primeste alerta cand apar documente noi pentru aceasta zona"
                        />
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleFormSave}>
                    Salveaza alerta
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AdaugaAlerta.propTypes = {
    open: PropTypes.bool,
    alerta: PropTypes.object,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
};

export default AdaugaAlerta;
