import React from "react";
import { Button } from "@mui/material";
import GoogleLogo from "../images/google.svg";

function GoogleButton({ children, ...other }) {
    return (
        <Button fullWidth variant="outlined" {...other}>
            <img src={GoogleLogo} style={{ marginRight: "1rem" }} />
            {children}
        </Button>
    );
}

export default GoogleButton;
