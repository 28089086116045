import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import ReplyIcon from "@mui/icons-material/Reply";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function AddComment({ data, index, saveComment, deleteComment }) {
    const [text, setText] = React.useState("");
    const [blocking, setBlocking] = React.useState(false);
    return (
        <Box sx={{}}>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Scrie aici..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={6}
                        sx={{ mb: 1 }}
                        autoFocus
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={blocking}
                                    onChange={(e) => setBlocking(e.target.checked)}
                                />
                            }
                            label="Necesita rezolvare"
                        />
                        <Box
                            sx={{
                                padding: 1,
                                background: "#F6F6F6",
                                borderRadius: "4px",
                                mb: 2,
                                fontSize: "90%",
                                opacity: 0.75,
                                lineHeight: 1.5,
                            }}
                        >
                            In cazul in care se bifeaza optiunea "
                            <strong>Necesita rezolvare</strong>", semnarea documentului va fi
                            suspendata pana cand un editor va rezolva problema sesizata, iar
                            semanturile existente vor fi anulate.
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => saveComment(index, text, blocking)}
                    >
                        Salvează
                    </Button>
                    <Button variant="outlined" size="small" onClick={() => deleteComment(index)}>
                        Anulează
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function Reply({ data, index, addReply, setReplyMode, willUnblockIssue, setWillUnblockIssue }) {
    const [text, setText] = React.useState("");

    const saveReply = (index, text) => {
        addReply(index, text, willUnblockIssue);
        setReplyMode(false);
        setWillUnblockIssue(false);
    };

    const cancelReply = () => {
        setReplyMode(false);
        setWillUnblockIssue(false);
    };

    return (
        <Box sx={{ pb: 2 }}>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                <Avatar
                    sx={{ mr: 1, mt: "8px", bgcolor: stringToColor(data.username) }}
                    alt={data.username}
                />
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Răspuns..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        maxRows={4}
                        sx={{ mb: 1 }}
                        autoFocus
                    />
                    {willUnblockIssue ? (
                        <Button
                            variant="contained"
                            size="small"
                            color="warning"
                            sx={{ mr: 1 }}
                            onClick={() => saveReply(index, text)}
                        >
                            Rezolvă
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => saveReply(index, text)}
                        >
                            Răspunde
                        </Button>
                    )}

                    <Button variant="outlined" size="small" onClick={cancelReply}>
                        Anulează
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function Comment({ children, data, index, saveComment, deleteComment, addReply, sx, ...other }) {
    const [replyMode, setReplyMode] = React.useState(false);
    const [willUnblockIssue, setWillUnblockIssue] = React.useState(false);

    const resolveIssue = () => {
        setReplyMode(true);
        setWillUnblockIssue(true);
    };

    return (
        <Paper
            sx={{
                p: 2,
                ...sx,
            }}
            {...other}
        >
            <Box sx={{ mb: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                        <Avatar
                            sx={{ mr: 1, bgcolor: stringToColor(data.username) }}
                            alt={data.username}
                        />
                        <Box>
                            <Box sx={{ fontWeight: 500, pb: 0.25 }}>{data.username}</Box>
                            <Box sx={{ fontSize: "85%", opacity: 0.75 }}>
                                {data.date.toDateString()}
                            </Box>
                        </Box>
                    </Box>
                    {data.text && (
                        <Box sx={{ pt: 0.5 }}>
                            {data.blocking && (
                                <Button
                                    variant="contained"
                                    color="warning"
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={resolveIssue}
                                >
                                    Rezolvă
                                </Button>
                            )}
                            <Button
                                startIcon={<ReplyIcon />}
                                variant="outlined"
                                size="small"
                                onClick={() => setReplyMode(true)}
                            >
                                Răspunde
                            </Button>
                        </Box>
                    )}
                </Box>
                {data.text && (
                    <Alert
                        icon={data.blocking ? undefined : false}
                        severity={data.blocking ? "warning" : "info"}
                    >
                        {data.text}
                    </Alert>
                )}
                {!data.text && (
                    <AddComment
                        data={data}
                        index={index}
                        saveComment={saveComment}
                        deleteComment={deleteComment}
                    />
                )}
            </Box>
            {!!data.replies.length && (
                <Box>
                    <Divider sx={{ mb: 2 }} />

                    {data.replies.map((reply, index) => (
                        <Box sx={{ pl: "48px", mb: 2, position: "relative" }} key={index}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "5px",
                                    left: "10px",
                                    fontSize: "1.5rem",
                                    opacity: 0.25,
                                }}
                            >
                                ⤷
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                                <Avatar
                                    sx={{ mr: 1, bgcolor: stringToColor(reply.username) }}
                                    alt={reply.username}
                                />
                                <Box>
                                    <Box sx={{ fontWeight: 500, pb: 0.25 }}>{reply.username}</Box>
                                    <Box sx={{ fontSize: "85%", opacity: 0.75 }}>
                                        {reply.date.toDateString()}
                                    </Box>
                                </Box>
                            </Box>
                            <Alert icon={false} severity="info" sx={{ background: "#F6F6F6" }}>
                                {reply.text}
                            </Alert>
                        </Box>
                    ))}
                </Box>
            )}
            {replyMode && (
                <Reply
                    data={data}
                    index={index}
                    setReplyMode={setReplyMode}
                    addReply={addReply}
                    setWillUnblockIssue={setWillUnblockIssue}
                    willUnblockIssue={willUnblockIssue}
                />
            )}

            {children}
        </Paper>
    );
}

Comment.propTypes = {
    // children: PropTypes.node,
    data: PropTypes.object,
};

export default Comment;
