import * as React from "react";
import { Box, InputLabel, MenuItem, FormControl, Select, Alert } from "@mui/material";
import EditRluForm from "./EditRLUForm";
import axios from "axios";

import { useSnackbar } from "notistack";

const model = {
    "section-1-intro": {
        title: "SECŢIUNEA 1. CARACTERUL ZONEI",
        content: "",
    },
    "section-1-a": {
        title: "A. CONDIŢIONĂRI PRIMARE",
        content: "",
    },
    "section-1-b": {
        title: "B. SERVITUȚI PENTRU OBIECTIVE DE UTILITATE PUBLICĂ AFLATE ÎN ZONĂ, ALTE RESTRICȚII",
        content: "",
    },
    "section-1-c": {
        title: "C. REGLEMENTĂRI PENTRU SPAȚIUL PUBLIC",
        content: "",
    },
    "section-2-intro": {
        title: "SECŢIUNEA 2. UTILIZARE FUNCŢIONALĂ",
        content: "",
    },
    "section-2-1": {
        title: "1. UTILIZĂRI ADMISE",
        content: "",
    },
    "section-2-2": {
        title: "2. UTILIZĂRI ADMISE CU CONDIŢIONĂRI",
        content: "",
    },
    "section-2-3": {
        title: "3. UTILIZĂRI INTERZISE",
        content: "",
    },
    "section-3-intro": {
        title: "SECŢIUNEA 3. CONDIŢII DE AMPLASARE, ECHIPARE ŞI CONFIGURARE A CLĂDIRILOR",
        content: "",
    },
    "section-3-4": {
        title: "4. CARACTERISTICILE PARCELELOR: SUPRAFEŢE, FORME, DIMENSIUNI",
        content: "",
    },
    "section-3-5": {
        title: "5. AMPLASAREA CLĂDIRILOR FAŢĂ DE ALINIAMENT",
        content: "",
    },
    "section-3-6": {
        title: "6. AMPLASAREA CLĂDIRILOR FAŢĂ DE LIMITELE LATERALE ŞI POSTERIOARE ALE PARCELELOR",
        content: "",
    },
    "section-3-7": {
        title: "7. AMPLASAREA CLĂDIRILOR UNELE FAŢĂ DE ALTELE PE ACEEAŞI PARCELĂ",

        content: "",
    },
    "section-3-8": {
        title: "8. CIRCULAŢII ŞI ACCESE",

        content: "",
    },
    "section-3-9": {
        title: "9. STAŢIONAREA AUTOVEHICULELOR",

        content: "",
    },
    "section-3-10": {
        title: "10. ÎNĂLŢIMEA MAXIMĂ ADMISĂ A CLĂDIRILOR",

        content: "",
    },
    "section-3-11": {
        title: "11. ASPECTUL EXTERIOR AL CLĂDIRILOR",
        content: "",
    },
    "section-3-12": {
        title: "12. CONDIŢII DE ECHIPARE EDILITARĂ ŞI EVACUAREA DEŞEURILOR",

        content: "",
    },
    "section-3-13": {
        title: "13. SPAŢII LIBERE ŞI SPAŢII PLANTATE",
        content: "",
    },
    "section-3-14": {
        title: "14. ÎMPREJMUIRI",

        content: "",
    },
    "section-4-intro": {
        title: "SECŢIUNEA 4. POSIBILITĂŢI MAXIME DE OCUPARE ŞI UTILIZARE A TERENULUI",
        content: "",
    },
    "section-4-15": {
        title: "15. PROCENT MAXIM DE OCUPARE A TERENULUI (P.O.T.)",
        content: "",
    },
    "section-4-15-categories": [{ name: "", value: "" }],
    "section-4-16": {
        title: "16. COEFICIENT MAXIM DE UTILIZARE A TERENULUI (C.U.T.)",
        content: "",
    },
    "section-4-16-categories": [{ name: "", value: "" }],
};

function EditRlu() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [selectedRlu, setSelectedRlu] = React.useState("");
    const [currentModel, setCurrentModel] = React.useState(null);
    const [RLUs, setRLUs] = React.useState([]);

    const handleChange = (event) => {
        const rluId = event.target.value;
        const getData = async () => {
            const response = await axios.get("/api/rlu/" + rluId).catch(function (error) {
                console.log(error.toJSON());
            });

            if (response) {
                // TODO: don't do this
                setSelectedRlu("");
                setCurrentModel(JSON.parse(JSON.stringify(response.data)));
                setSelectedRlu(rluId);
            }
        };
        getData();
    };

    React.useEffect(() => {
        const getData = async () => {
            const response = await axios.get("/api/rlu?location=32394").catch(function (error) {
                console.log(error.toJSON());
            });
            if (response) {
                setRLUs([...response.data]);
            }
        };
        getData();
        return () => {
            setRLUs([]);
        };
    }, []);

    const saveRlu = (data) => {
        let deepDataCopy = JSON.parse(JSON.stringify(data));

        for (let key in deepDataCopy) {
            if (typeof deepDataCopy[key] == "object") deepDataCopy[key].title = undefined;
        }

        const getData = async () => {
            const response = await axios
                .post("/api/rlu/" + selectedRlu, deepDataCopy)
                .catch(function (error) {
                    console.log(error.toJSON());
                    enqueueSnackbar("Datele nu au putut fi salvate", { variant: "error" });
                });

            if (response) {
                enqueueSnackbar("Datele au fost salvate cu succes!", { variant: "success" });
            }
        };
        getData();
    };

    return (
        <Box sx={{ minHeight: "100vh", background: "#EEE" }}>
            <Box sx={{ p: 4, maxWidth: 800, margin: "0 auto" }}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel>RLU-uri</InputLabel>
                        <Select
                            value={selectedRlu}
                            label="RLU-uri"
                            onChange={handleChange}
                            sx={{ background: "#FFF" }}
                        >
                            {RLUs.map((rlu, index) => (
                                <MenuItem key={index} value={rlu.id}>
                                    {rlu.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box
                    component="hr"
                    sx={{
                        margin: "24px auto 16px auto",
                        maxWidth: 800,
                        border: "1px solid #DDD",
                        background: "transparent",
                    }}
                />

                {selectedRlu ? (
                    <EditRluForm
                        rluId={selectedRlu}
                        cancelEdit={() => setSelectedRlu("")}
                        model={currentModel}
                        saveRlu={saveRlu}
                    />
                ) : (
                    <Alert color="info" icon={false}>
                        Selecteaza un RLU pentru editare.
                    </Alert>
                )}
            </Box>
        </Box>
    );
}

export default EditRlu;
