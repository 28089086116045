import * as React from "react";
import { Button, Box, Paper, Container } from "@mui/material";

import PDFSample from "./images/certificat.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import BusinessIcon from "@mui/icons-material/Business";
import Heading from "./components/DashboardHeading";

import { useNavigate } from "react-router-dom";

function MainComponent() {
    let navigate = useNavigate();
    React.useEffect(() => {
        window.setTimeout(function () {
            navigate("/dashboard/certificate");
        }, 2000);
    }, []);
    return (
        <Container maxWidth={false} sx={{ mb: 3 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <BusinessIcon sx={{ mb: "-3px", mr: 0.75 }} />{" "}
                <span>Cerere pentru emiterea certificatului de urbanism</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Paper sx={{ p: 3, mb: 1, mt: 5, textAlign: "center" }}>
                    <Heading sx={{ textTransform: "uppercase" }}>
                        Cerere Inregistrata cu Succes
                    </Heading>
                    <p style={{ lineHeight: 2 }}>
                        Cererea cu numarul #28433 din data de 12.12.2022 a fost inregistrata.
                        <br />
                        Certificatul a fost generat si poate fi descarcat apasand butonul de mai
                        jos:
                    </p>
                </Paper>
                <Button
                    variant="contained"
                    size="large"
                    color="inherit"
                    endIcon={<PictureAsPdfIcon />}
                    sx={{ background: "#FFF" }}
                    fullWidth
                    href={PDFSample}
                    target="_blank"
                >
                    Descarca Certificatul
                </Button>
            </Box>
        </Container>
    );
}

export default function DashboardCertificatSucces() {
    return <MainComponent />;
}
