import * as React from "react";

import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material/";
import { Button, Box, Chip } from "@mui/material/";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import StergeDocumentatie from "./StergeDocumentatie";
import PrevizualizareHartaDialog from "./PrevizualizareHartaDialog";
import PrevizualizareHarta from "./PrevizualizareHartaPuz";
import IstoricDocumentatie from "./IstoricDocumentatie";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";

import { Link as RouterLink, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { deletePuz } from "./redux/puz.slice";

import { useSnackbar } from "notistack";

const UTR = require("./geojson/utr.json");

const ETAPE = {
    anunt: "Anunt de Intentie",
    elaborare: "Elaborarea propunerilor",
    avizare: "Avizarea propunerilor",
    aprobare: "Aprobarea propunerilor",
    aprobat: "Aprobat de Consiliul Local",
    suspendat: "Suspendat",
    anulat: "Anulat",
    expirat: "Expirat",
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box pt={2}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function Documentatie() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const puzData = useSelector((state) => state.puz.data);

    const [deleteItem, setDeleteItem] = React.useState(null);

    const [historyItem, setHistoryItem] = React.useState(null);

    const [polygonData, setPolygonData] = React.useState(null);
    const [vizualizareHarta, setVizualizareHarta] = React.useState(false);

    const [allPolygons, setAllPolygons] = React.useState(null);

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleHistoryClose = () => {
        setHistoryItem(null);
    };

    const stergeDocumentatie = () => {
        dispatch(deletePuz(deleteItem));
        setDeleteItem(null);
        enqueueSnackbar("Documentul a fost sters", { variant: "default" });
    };

    const previzualizareHarta = (item) => {
        setPolygonData(item[0]);
        setVizualizareHarta(true);
    };

    React.useEffect(() => {
        let featureCollection = {
            type: "FeatureCollection",
            features: [],
        };

        if (puzData) {
            puzData.map((item) => {
                if (item.desen) {
                    featureCollection.features.push(...item.desen);
                }
            });
            setAllPolygons(featureCollection);
        }
    }, [puzData]);

    return (
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{
                        "& .MuiTab-root": {
                            // pl: 0,
                            // mr: 1
                        },
                    }}
                >
                    <Tab label="Planuri urbanistice zonale (PUZ)" {...a11yProps(0)} />
                    <Tab label="Planuri Urbanistice de Detaliu (PUD)" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <PrevizualizareHarta
                    parcelData={allPolygons}
                    handleHistoryOpen={(id) => setHistoryItem(id)}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0.25rem 0 0.75rem 0",
                    }}
                ></Box>
                <TableContainer>
                    <Table
                        size="small"
                        sx={{
                            minWidth: 650,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Denumire</TableCell>
                                <TableCell align="center">Memoriu</TableCell>
                                <TableCell>HCL</TableCell>
                                <TableCell align="center">RLU</TableCell>
                                <TableCell>Planuri</TableCell>
                                <TableCell>Etapa</TableCell>
                                <TableCell align="center" sx={{ width: 110 }}>
                                    Operatiuni
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {puzData.map((item, index) => (
                                <React.Fragment>
                                    {item.desen && (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: 200 }}>
                                                {item.descriere}
                                            </TableCell>

                                            <TableCell align="center">
                                                <IconButton>
                                                    <PictureAsPdfIcon sx={{ color: "red" }} />
                                                </IconButton>
                                            </TableCell>

                                            <TableCell>
                                                {item.hcl.length === 0 ? (
                                                    <BlockIcon sx={{ color: "#AAA" }} />
                                                ) : (
                                                    <CheckCircleIcon
                                                        sx={{ color: "mediumseagreen" }}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton>
                                                    <PictureAsPdfIcon sx={{ color: "red" }} />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                {item.planuri.map((plan, index) => (
                                                    <React.Fragment>
                                                        <Link href="#" underline="hover">
                                                            {plan}
                                                        </Link>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                                {[
                                                    "anunt",
                                                    "elaborare",
                                                    "avizare",
                                                    "aprobare",
                                                ].includes(item.etapa) && (
                                                    <Chip
                                                        size="small"
                                                        variant="filled"
                                                        color="default"
                                                        label={ETAPE[item.etapa]}
                                                    ></Chip>
                                                )}
                                                {["aprobat"].includes(item.etapa) && (
                                                    <Chip
                                                        size="small"
                                                        variant="filled"
                                                        color="primary"
                                                        label={ETAPE[item.etapa]}
                                                    ></Chip>
                                                )}
                                                {["suspendat", "anulat", "expirat"].includes(
                                                    item.etapa
                                                ) && (
                                                    <Chip
                                                        size="small"
                                                        variant="outlined"
                                                        color="error"
                                                        label={ETAPE[item.etapa]}
                                                    ></Chip>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    component={RouterLink}
                                                    color="primary"
                                                    to={"/dashboard/puz/" + item.id}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => previzualizareHarta(item.desen)}
                                                >
                                                    <ZoomInIcon fontSize="small" />
                                                </IconButton>
                                                {/* <IconButton
                                                    color="primary"
                                                    onClick={() => setDeleteItem(item)}
                                                >
                                                    <DeleteForeverIcon fontSize="small" />
                                                </IconButton> */}
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => setHistoryItem(item.id)}
                                                >
                                                    <HistoryIcon color="warning" fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0.25rem 0 0.75rem 0",
                    }}
                ></Box>

                <TableContainer>
                    <Table
                        size="small"
                        sx={{
                            minWidth: 650,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Tip</TableCell>
                                <TableCell>Denumire</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center">RLU</TableCell>
                                <TableCell>Operatiuni</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {UTR.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.properties.name.toUpperCase()}</TableCell>
                                    <TableCell>{item.properties.type}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <IconButton>
                                            <PictureAsPdfIcon sx={{ color: "red" }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color="primary" href="/dashboard/puz">
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => previzualizareHarta(item)}
                                        >
                                            <ZoomInIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => setDeleteItem(index)}
                                        >
                                            <DeleteForeverIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            {deleteItem && (
                <StergeDocumentatie handleClose={stergeDocumentatie} item={deleteItem} />
            )}
            {historyItem && (
                <IstoricDocumentatie handleClose={handleHistoryClose} id={historyItem} />
            )}

            {vizualizareHarta && (
                <PrevizualizareHartaDialog
                    parcelData={polygonData}
                    handleClose={() => setVizualizareHarta(false)}
                />
            )}
        </React.Fragment>
    );
}

export default function DashboardDocumentatie() {
    return <Documentatie />;
}
