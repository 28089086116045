import React from "react";

import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";

import { grey, purple, blue } from "@mui/material/colors";

import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";

import logo from "./images/logo.png";

import ReplayIcon from "@mui/icons-material/Replay";
import BugReportIcon from "@mui/icons-material/BugReport";
import LayersIcon from "@mui/icons-material/Layers";

import simpleLayer from "./images/layers/simple.gif";
import topoLayer from "./images/layers/topo.gif";
import droneLayer from "./images/layers/drone.gif";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Slider from "@mui/material/Slider";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PubSub from "pubsub-js";

import PDFSample from "./images/Functionalitati si pret.pdf";

import SidebarSearchV2 from "./SidebarSearchV2";

import { useSnackbar } from "notistack";

import { useSelector, useDispatch } from "react-redux";
import { updateLayerVisibility, updateLayerOpacity, updateMapType } from "./redux/layers.slice";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

const hideOnMobile = {
    display: { xs: "none", md: "inline-block" },
};

const showOnMobile = {
    display: { xs: "block", md: "none !important" },
};

const tipuriHarti = {
    default: "Harta Standard",
    dark: "Harta Monocromatica",
    satellite: "Harta Satelit",
};

function Sidebar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const mapLayers = useSelector((state) => state.layers.mapLayers);
    const layersOpacity = useSelector((state) => state.layers.layersOpacity);
    const mapType = useSelector((state) => state.layers.mapType);

    const mapZoom = useSelector((state) => state.layers.mapZoom);
    const mapCenter = useSelector((state) => state.layers.mapCenter);

    const userData = useSelector((state) => state.auth.userData);

    const [optionsVisible, setOptionsVisible] = React.useState(false);

    const [bugReportErrors, setBugReportErrors] = React.useState({
        email: false,
        phone: false,
        description: false,
    });

    const [bugReportData, setBugReportData] = React.useState({
        email: userData ? userData.email : "",
        phone: "",
        description: "",
    });

    const toggleOptions = () => {
        setOptionsVisible(!optionsVisible);
    };

    const handleHideOptions = () => {
        setOptionsVisible(false);
    };

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const [openReset, setOpenReset] = React.useState(false);

    const handleResetOpen = () => {
        setOpenReset(true);
    };

    const handleResetClose = () => {
        setOpenReset(false);
    };

    const [openBug, setOpenBug] = React.useState(false);

    const handleBugOpen = () => {
        setOpenBug(true);
    };

    const sendBugTicket = () => {
        let errors = {
            email: false,
            phone: false,
            description: false,
        };
        if (!bugReportData.email || !bugReportData.phone || !bugReportData.description) {
            if (!bugReportData.email) errors.email = true;
            if (!bugReportData.phone) errors.phone = true;
            if (!bugReportData.description) errors.description = true;
            setBugReportErrors(errors);
            return;
        }
        setBugReportErrors(errors);
        fetch(API_URL + `tickets`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    ...bugReportData,
                    context: {
                        mapType: tipuriHarti[mapType],
                        layers: getStraturiAfisate(),
                        zoom: mapZoom,
                        lat: mapCenter[0],
                        lng: mapCenter[1],
                        link: window.location.href,
                    },
                },
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log("Response:", response);
                // if (response.error) {
                //     setErrorData(response.error, setRegisterErrors);
                // } else {
                //     authenticate(response);
                // }
            })
            .catch((error) => {
                console.log("An error occurred:", error.response);
            });
        handleBugClose();
        enqueueSnackbar("Problema descrisa a fost raportata", {
            variant: "default",
        });
    };

    const handleBugClose = () => {
        setOpenBug(false);
    };

    const handleMapSelect = (type) => {
        dispatch(updateMapType(type));
    };

    const handleLayerControl = (e) => {
        const layerName = e.target.name;
        dispatch(
            updateLayerVisibility({
                [layerName]: e.target.checked,
            })
        );
    };

    const handleOpacityChange = (e, opacity) => {
        const layerName = e.target.name.split(".")[0];
        dispatch(
            updateLayerOpacity({
                [layerName]: opacity,
            })
        );
    };

    const checkedMapStyle = (type) => {
        if (mapType === type) {
            return { background: "rgba(0,0,0,0.85)", color: "#FFF" };
        } else {
            return { background: "rgba(255,255,255,0.85)" };
        }
    };

    const handleSubmit = () => {
        return false;
    };

    const getStraturiAfisate = () => {
        let arrayStraturi = [];
        const map = {
            pug: "PUG",
            puz: "PUZ",
            pud: "PUD",
            zoneProtectie: "Zone Protectie",
            certificate: "Certificate Emise",
            autorizatii: "Autorizatii de Construire",
            parcels: "Parcele",
            buildings: "Cladiri",
            parcari: "Parcari",
            accesGradinite: "Acces Gradinite",
            accesScoli: "Acces Scoli",
            accesSpitale: "Acces Spitale",
            intravilan: "Limita Intravilan",
            limita: "Limita Administrativa",
            judet: "Limita Judet",
            uats: "UAT",
        };

        for (const key in mapLayers) {
            if (mapLayers[key]) arrayStraturi.push(map[key]);
        }
        return arrayStraturi;
    };

    const categoriiCautare = [
        "PUG",
        "PUZ",
        "Cereri cerfiticate",
        "Certificate emise",
        "Autorizatii de construire",
    ];

    const [selected, setSelected] = React.useState([
        "PUG",
        "PUZ",
        "Cereri cerfiticate",
        "Cereri AADP",
    ]);

    return (
        <div className="App-sidebar">
            <Paper
                component="form"
                sx={{
                    marginBottom: "1rem",
                    ...hideOnMobile,
                }}
                onSubmit={handleSubmit}
            >
                <Stack
                    direction="row"
                    sx={{
                        p: "2px 4px",
                        width: 330,
                    }}
                >
                    <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={toggleOptions}>
                        <TuneIcon sx={{ color: grey[800] }} />
                    </IconButton>
                    <SidebarSearchV2 />
                    <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Stack>

                {optionsVisible && (
                    <ClickAwayListener onClickAway={handleHideOptions}>
                        <div>
                            <Divider />
                            <FormGroup sx={{ p: 1 }}>
                                <label className="select-all">
                                    <Checkbox
                                        checked={selected.length === categoriiCautare.length}
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < categoriiCautare.length
                                        }
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelected(categoriiCautare);
                                            } else {
                                                setSelected([]);
                                            }
                                        }}
                                    />
                                    Toate categoriile
                                </label>

                                {categoriiCautare.map((item, index) => (
                                    <label className="item" key={index}>
                                        <Checkbox
                                            checked={selected.includes(item)}
                                            onChange={() => {
                                                if (selected.includes(item)) {
                                                    setSelected((s) => s.filter((i) => i !== item));
                                                } else {
                                                    setSelected((s) => [...s, item]);
                                                }
                                            }}
                                        />
                                        {item}
                                    </label>
                                ))}
                            </FormGroup>
                        </div>
                    </ClickAwayListener>
                )}
            </Paper>
            <Paper
                className="action-button"
                sx={{
                    ...showOnMobile,
                }}
            >
                <Tooltip title="Cauta" placement="right">
                    <IconButton>
                        <SearchIcon sx={{ color: grey[800] }} />
                    </IconButton>
                </Tooltip>
            </Paper>
            <div className="action-button">
                <Tooltip title="Configureaza Harta" placement="right">
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{
                            color: grey[800],
                            "&:hover": {
                                color: "#304ffe",
                            },
                        }}
                    >
                        <LayersIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="action-button">
                <Tooltip title="Harta 3D" placement="right">
                    <IconButton
                        href="https://gis-3d-flaviusmatis.vercel.app/?xyz=47.130067775987754%2C24.489809675659558%2C13.568194122837877&pitch=50"
                        target="_blank"
                        sx={{
                            color: grey[800],
                            "&:hover": {
                                color: "#304ffe",
                            },
                        }}
                    >
                        <ThreeDRotationIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="action-button">
                <Tooltip title="Revenire la Harta Initiala" placement="right">
                    <IconButton
                        onClick={handleResetOpen}
                        sx={{
                            color: grey[800],
                            "&:hover": {
                                color: "#304ffe",
                            },
                        }}
                    >
                        <ReplayIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="action-button">
                <Tooltip title="Raporteaza o problema" placement="right">
                    <IconButton
                        onClick={handleBugOpen}
                        sx={{
                            color: grey[800],
                            "&:hover": {
                                color: "#ef6c00",
                            },
                        }}
                    >
                        <BugReportIcon />
                    </IconButton>
                </Tooltip>
            </div>

            <Link className="App-logo" href={PDFSample} target="_blank" underline="none">
                <img src={logo} alt="logo" />
                <span>CUBITRON</span>
                <em>&nbsp;</em>
            </Link>

            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                    "& .MuiDrawer-paper": {
                        boxShadow:
                            "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
                        overflow: "visible",
                    },
                }}
            >
                <IconButton
                    sx={{
                        p: "7px 10px 7px 7px",
                        position: "absolute",
                        right: "-40px",
                        top: 9,
                        background: "#FFF",
                        borderRadius: "0 50% 50% 0",
                        boxShadow: "3px 3px 5px rgba(0,0,0,0.1)",
                        "&:hover": {
                            color: "#000",
                            background: "#FFF",
                        },
                    }}
                    onClick={toggleDrawer}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{ width: 260, padding: "0 0.75rem", height: "100vh", overflow: "auto" }}
                    role="presentation"
                >
                    <h4 style={{ paddingLeft: "1rem" }}>Tipul hartii:</h4>
                    <List sx={{ p: 0 }}>
                        <ListItem button onClick={() => handleMapSelect("default")}>
                            <img
                                src={topoLayer}
                                style={{ width: "250px", height: "90px", objectFit: "cover" }}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: 14,
                                    left: "1rem",
                                    padding: "4px 0.75rem",
                                    borderRadius: "0 5px 5px 0",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    ...checkedMapStyle("default"),
                                }}
                            >
                                {tipuriHarti["default"]}
                            </span>
                        </ListItem>
                        <ListItem button onClick={() => handleMapSelect("dark")}>
                            <img
                                src={simpleLayer}
                                style={{ width: "250px", height: "90px", objectFit: "cover" }}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: 14,
                                    left: "1rem",
                                    padding: "4px 0.75rem",
                                    borderRadius: "0 5px 5px 0",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    ...checkedMapStyle("dark"),
                                }}
                            >
                                {tipuriHarti["dark"]}
                            </span>
                        </ListItem>
                        <ListItem button onClick={() => handleMapSelect("satellite")}>
                            <img
                                src={droneLayer}
                                style={{ width: "250px", height: "90px", objectFit: "cover" }}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: 14,
                                    left: "1rem",
                                    padding: "4px 0.75rem",
                                    borderRadius: "0 5px 5px 0",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    ...checkedMapStyle("satellite"),
                                }}
                            >
                                {tipuriHarti["satellite"]}
                            </span>
                        </ListItem>
                    </List>
                    <br />

                    <Divider />
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0 }}>
                        Reglementari Urbanistice:
                    </h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="pug"
                                    checked={mapLayers.pug}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="PUG"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="puz"
                                    checked={mapLayers.puz}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="PUZ"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="pud"
                                    checked={mapLayers.pud}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="PUD"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="zoneProtectie"
                                    checked={mapLayers.zoneProtectie}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Zone Protectie"
                        />
                    </FormGroup>
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0, marginTop: 0 }}>
                        Straturi Tematice:
                    </h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="parcels"
                                    checked={mapLayers.parcels}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Parcele"
                        />
                        <Collapse in={mapLayers.parcels}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="parcels.opacity"
                                value={layersOpacity.parcels}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="buildings"
                                    checked={mapLayers.buildings}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Cladiri"
                        />
                        <Collapse in={mapLayers.buildings}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="buildings.opacity"
                                value={layersOpacity.buildings}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="parcari"
                                    checked={mapLayers.parcari}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Parcari"
                        />
                        <Collapse in={mapLayers.parcari}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="parcari.opacity"
                                value={layersOpacity.parcari}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                    </FormGroup>
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0, marginTop: 0 }}>
                        Documentatii Urbanism:
                    </h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="certificate"
                                    checked={mapLayers.certificate}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Certificate Emise"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="autorizatii"
                                    checked={mapLayers.autorizatii}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Autorizatii de Construire"
                        />
                    </FormGroup>
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0, marginTop: 0 }}>Analize:</h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="accesGradinite"
                                    checked={mapLayers.accesGradinite}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Acces Gradinite"
                        />
                        <Collapse in={mapLayers.accesGradinite}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="accesGradinite.opacity"
                                value={layersOpacity.accesGradinite}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="accesScoli"
                                    checked={mapLayers.accesScoli}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Acces Scoli"
                        />
                        <Collapse in={mapLayers.accesScoli}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="accesScoli.opacity"
                                value={layersOpacity.accesScoli}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="accesSpitale"
                                    checked={mapLayers.accesSpitale}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Acces Spitale"
                        />
                        <Collapse in={mapLayers.accesSpitale}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="accesSpitale.opacity"
                                value={layersOpacity.accesSpitale}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                    </FormGroup>
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0, marginTop: 0 }}>Limite:</h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="intravilan"
                                    checked={mapLayers.intravilan}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Limita Intravilan"
                        />
                        <Collapse in={mapLayers.intravilan}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="intravilan.opacity"
                                value={layersOpacity.intravilan}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="limita"
                                    checked={mapLayers.limita}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Limita Administrativa"
                        />
                        <Collapse in={mapLayers.limita}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="limita.opacity"
                                value={layersOpacity.limita}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="judet"
                                    checked={mapLayers.judet}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="Limita Judet"
                        />
                        <Collapse in={mapLayers.judet}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="judet.opacity"
                                value={layersOpacity.judet}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                    </FormGroup>
                    <h4 style={{ paddingLeft: "1rem", marginBottom: 0, marginTop: 0 }}>Judet:</h4>
                    <FormGroup sx={{ p: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    name="uats"
                                    checked={mapLayers.uats}
                                    onChange={handleLayerControl}
                                />
                            }
                            label="UAT"
                        />
                        <Collapse in={mapLayers.uats}>
                            <Slider
                                size="small"
                                defaultValue={0}
                                valueLabelDisplay="auto"
                                name="uats.opacity"
                                value={layersOpacity.uats}
                                onChange={handleOpacityChange}
                            />
                        </Collapse>
                    </FormGroup>
                </Box>
            </Drawer>
            <Dialog
                open={openReset}
                onClose={handleResetClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Revenire la Harta Initiala"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Toate configuratiile hartii si locatia implicita vor fi resetate. Esti sigur
                        ca doresti acest lucru?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2} sx={{ m: 1 }} direction="row">
                        <Button variant="outlined" onClick={handleResetClose}>
                            Anulare
                        </Button>
                        <Button variant="contained" href="/" autoFocus>
                            Confirmare
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openBug}
                onClose={handleBugClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Raporteaza o problema"}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Adresa email"
                        name="email"
                        autoComplete="email"
                        value={bugReportData["email"]}
                        onChange={(event) => {
                            setBugReportData({
                                ...bugReportData,
                                [event.target.name]: event.target.value,
                            });
                        }}
                        autoFocus
                        error={bugReportErrors.email}
                        helperText={
                            bugReportErrors.email ? "Te rugam sa completezi adresa de email." : ""
                        }
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="phone"
                        label="Telefon"
                        type="text"
                        value={bugReportData["phone"]}
                        onChange={(event) => {
                            setBugReportData({
                                ...bugReportData,
                                [event.target.name]: event.target.value,
                            });
                        }}
                        error={bugReportErrors.phone}
                        helperText={
                            bugReportErrors.phone ? "Te rugam sa completezi numarul de telefon." : ""
                        }
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="description"
                        label="Descriere"
                        type="text"
                        value={bugReportData["description"]}
                        onChange={(event) => {
                            setBugReportData({
                                ...bugReportData,
                                [event.target.name]: event.target.value,
                            });
                        }}
                        multiline
                        rows={3}
                        error={bugReportErrors.description}
                        helperText={
                            bugReportErrors.description
                                ? "Te rugam sa completezi descrierea problemei."
                                : ""
                        }
                    />

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Metadata</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <strong>Tipul Hartii</strong>: {tipuriHarti[mapType]}
                            </Typography>

                            {mapCenter && (
                                <Typography>
                                    <strong>Nivel de Zoom</strong>: {mapZoom}
                                    <br />
                                    <strong>Latitudine</strong>: {mapCenter[0]}
                                    <br />
                                    <strong>Longitudine</strong>: {mapCenter[1]}
                                    <br />
                                </Typography>
                            )}

                            <Typography sx={{ pb: 1, pt: 1.5 }}>
                                <strong>Straturi Afisate</strong>:
                            </Typography>

                            {getStraturiAfisate().map((item, index) => (
                                <Chip label={item} sx={{ mr: 1, mb: 1 }} key={index}></Chip>
                            ))}

                            <Typography sx={{ pb: 1, pt: 1 }}>
                                <strong>Link Harta</strong>:
                            </Typography>

                            <Link
                                href={window.location.href}
                                target="_blank"
                                underline="none"
                                sx={{ wordWrap: "break-word" }}
                            >
                                {window.location.href}
                            </Link>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2} sx={{ m: 1 }} direction="row">
                        <Button variant="outlined" onClick={handleBugClose}>
                            Anulare
                        </Button>
                        <Button variant="contained" autoFocus onClick={sendBugTicket}>
                            Trimite
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Sidebar;
