import { useState, useEffect, Fragment } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    GeoJSON,
    ZoomControl,
    Tooltip,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link, useNavigate } from "react-router-dom";


import Toolbar from "./Toolbar";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

import { fontAwesomeIconFat } from "./utils/leafletIcons";

import SesizareCard from "./SesizareCard";

import { BISTRITA as CITY } from "./geojson/mapConfig.js";

import { categoriiSesizari, tipuriSesizari, getStatusColor, getStatusLabel, getCategoryIcon, getCategoryLabel } from "./utils/sesizariUtils";
import { Button } from "@mui/material";

const center = [47.135393, 24.4990538];

const sesizari = require("./geojson/sesizari.json");

const limitaAdministrativa = (
    <GeoJSON
        style={{
            weight: 2,
            fillOpacity: 0,
        }}
        data={CITY.limits}
    />
);

const Legenda = ({ toggleStatus, showStatus, isComponent }) => {
    return (
        <Paper
            sx={{
                position: "absolute",
                top: 10,
                left: 10,
                zIndex: 1000,
                maxWidth: 320,
                minWidth: 180,
                maxHeight: "calc(100vh - 78px)",
                overflow: "auto",
                fontSize: "1rem",
                p: 2,
            }}
        >
            <Box sx={{ mb: 1.5, fontWeight: 600 }}>Legendă Sesizări:</Box>
            <Divider sx={{ mb: 1.5, mt: 1 }} />

            {tipuriSesizari.map((sesizare, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        justifyContent: "",
                        alignItems: "center",
                        py: 0.75,
                        cursor: "pointer",
                        opacity: !showStatus[sesizare.status] ? "0.5" : "1",
                        textDecoration: !showStatus[sesizare.status] ? "line-through" : "none",
                        "&:hover": {
                            opacity: 0.85,
                            background: "linear-gradient(90deg, transparent, #F6F6F6)"
                        }

                    }}
                    onClick={() => toggleStatus(sesizare.status)}
                >
                    <Box
                        sx={{
                            width: "16px",
                            height: "16px",
                            borderRadius: "16px",
                            backgroundColor: sesizare.color,
                            mr: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {showStatus[sesizare.status] && <CheckIcon sx={{ width: "13px", height: "13px", color: "#FFF" }} />}
                    </Box>
                    <Box>{sesizare.label}</Box>
                </Box>
            ))}
            {!isComponent && <Divider sx={{ mb: 1.5, mt: 1.5 }} />}
            {!isComponent && <Button component={Link} to="/dashboard/sesizare" variant="contained" fullWidth>Adaugă Sesizare</Button>}
        </Paper>
    );
};

const MapWrapper = ({isComponent}) => {
    const [map, setMap] = useState(null);
    const [sesizare, setSesizare] = useState(null);
    const [showStatus, setShowStatus] = useState({
        depus: true,
        redirectionat: true,
        planificat: true,
        lucru: true,
        rezolvat: true,
    });
    const [sesizariFiltrate, setSesizariFiltrate] = useState(sesizari.features);

    const handleClick = (data) => {
        setSesizare(data);
    };

    const toggleStatus = (status) => {
        setShowStatus({
            ...showStatus,
            [status]: !showStatus[status]
        });
    };

    useEffect(() => {
        let newArray = [...sesizari.features];
        setSesizariFiltrate(newArray.filter((sesizare) => showStatus[sesizare.properties.status]));
    }, [showStatus]);

    useEffect(() => {
        if (map){
            map.on("click", () => handleClick());
        }
    }, [map]);

    return (
        <div className="App">
            {!isComponent && <Toolbar />}
            <div id="map-wrapper" className="App-content">
                <Legenda toggleStatus={toggleStatus} showStatus={showStatus} isComponent={isComponent} />
                <SesizareCard sesizare={sesizare} />
                <MapContainer
                    whenCreated={setMap}
                    center={center}
                    zoom={13}
                    style={{ height: "calc(100vh - 58px)", width: "100%" }}
                    zoomControl={false}
                    debounceMoveend={true}
                >
                    <ZoomControl position="bottomright" />
                    {/* <TileLayer {...tileLayer} /> */}
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                    />
                    {limitaAdministrativa}
                        <MarkerClusterGroup disableClusteringAtZoom={13}>
                            {sesizariFiltrate.map((sesizare, index) => (
                                    <Marker
                                        key={index}
                                        position={[
                                            sesizare.geometry.coordinates[1],
                                            sesizare.geometry.coordinates[0],
                                        ]}
                                        icon={fontAwesomeIconFat({
                                            color: getStatusColor(sesizare.properties.status),
                                            icon: getCategoryIcon(sesizare.properties.category),
                                        })}
                                        eventHandlers={{
                                            click(e) {
                                                handleClick(sesizare);
                                            },
                                        }}
                                    >
                                        <Tooltip direction="top">
                                            <strong>
                                                {getCategoryLabel(sesizare.properties.category)}
                                            </strong>
                                            <br />
                                            Data sesizare: 01.02.2023
                                        </Tooltip>
                                    </Marker>
                                ))}
                        </MarkerClusterGroup>
                </MapContainer>
            </div>
        </div>
    );
};

export default MapWrapper;
