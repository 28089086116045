import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function Heading({children, sx, ...other}) {
    return (
        <Typography component="h2" variant="h6" gutterBottom sx={{ fontSize: "1.35rem", ...sx }} {...other}>
            {children}
        </Typography>
    );
}

Heading.propTypes = {
    children: PropTypes.node,
};

export default Heading;