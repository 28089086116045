import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Box, Grid, Alert } from "@mui/material";

import AmplasamentSearch from "./AmplasamentSearch";

import axios from "axios";

function AdaugaAmplasament(props) {
    const [judet, setJudet] = React.useState("Bistrita-Nasaud");
    const [uat, setUat] = React.useState("Bistrita");
    const [options, setOptions] = React.useState([]);
    const [parcelArea, setParcelArea] = React.useState("");
    const [amplasamentId, setAmplasamentId] = React.useState("");
    const [isValidParcel, setIsValidParcel] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);

    const handleJudetChange = (event) => {
        setJudet(event.target.value);
    };

    const handleUatChange = (event) => {
        setUat(event.target.value);
    };

    const initOptions = () => {
        let tempOptions = [];
        const parcels = document.layerData.parcels;
        for (let i = 0; i < parcels.features.length; i++) {
            tempOptions.push({
                label: parcels.features[i].properties.cad.toString(),
            });
        }
        setOptions(tempOptions);
    };

    React.useEffect(() => {
        if (!document.layerData) {
            const getData = async () => {
                const response = await axios
                    .get(
                        // "https://gist.githubusercontent.com/dnz986/73e0c607db51c9953cc0ec4328f2126d/raw/7fc0f34df7d858eb2efb615684b39e4b6bd5dd92/gistfile1.txt"
                        // "http://192.168.1.122:8080/ancpi/parcels?location=55838"
                        // "http://10.10.10.76:8080/ancpi/parcels?location=55838"
                        // "https://gist.githubusercontent.com/flaviusmatis/9ab53c92bab48a4403c6db880b1f5aa0/raw/7aaaa830411182ccb76b5720c0a8341ab31ba624/parcele.json"
                        // "/api/all"
                        "/api?location=32394"
                    )
                    .catch(function (error) {
                        console.log(error.toJSON());
                    });

                if (response) {
                    document.layerData = response.data;
                    initOptions();
                }
            };
            getData();
        } else {
            initOptions();
        }
    }, []);

    React.useEffect(() => {
        setParcelArea("");
        setIsValidParcel(false);
        setShowAlert(false);
    }, [props.open]);

    const handleHighlightChange = (e, string) => {
        let parcelFound = false;
        let parcelData = null;
        for (let i = 0; i < options.length; i++) {
            if (options[i].label === string) {
                parcelFound = true;
                parcelData = document.layerData.parcels.features.filter(
                    (parcel) => parcel.properties.cad.toString() === string
                )[0];
                break;
            }
        }
        if (parcelFound) {
            setParcelArea(parcelData.properties.area);
        } else {
            setParcelArea("");
        }
        setIsValidParcel(parcelFound);
        setAmplasamentId(string);
    };

    const handleSave = () => {
        if (isValidParcel) {
            props.handleSaveAmplasament(amplasamentId);
        } else {
            setShowAlert(true);
        }
    };

    return (
        <Dialog
            open={props.open}
            scroll="body"
            onBackdropClick={props.handleModalClose}
            onClose={props.handleModalClose}
            maxWidth="lg"
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Adauga amplasament</span>
                <IconButton color="default" component="span" onClick={props.handleModalClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                {showAlert && (
                    <Alert color="error" sx={{ mb: 1 }}>
                        Amplasamentul nu a fost gasit in baza de date.
                    </Alert>
                )}
                <Grid container spacing={2} sx={{ paddingTop: 1 }}>
                    <Grid item xs={12} md={6}>
                        <AmplasamentSearch
                            data={options}
                            handleHighlightChange={handleHighlightChange}
                            value="51352"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Suprafata"
                            disabled
                            value={parcelArea}
                            onChange={(e) => setParcelArea(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Judet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={judet}
                                label="Judet"
                                onChange={handleJudetChange}
                                readOnly
                            >
                                <MenuItem value="Bistrita-Nasaud">Bistrita-Nasaud</MenuItem>
                                <MenuItem value="Cluj">Cluj</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">UAT</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={uat}
                                label="UAT"
                                onChange={handleUatChange}
                                readOnly
                            >
                                <MenuItem value="Bistrita">Bistrita</MenuItem>
                                <MenuItem value="Cluj-Napoca">Cluj-Napoca</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                    p: 0,
                }}
            >
                <Button onClick={props.handleModalClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSave}>
                    Salveaza amplasamentul
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaAmplasament;
