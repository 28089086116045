import React from "react";

import { Button, Stack, Chip, Box } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import SubjectIcon from "@mui/icons-material/Subject";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

import { useSelector, useDispatch } from "react-redux";

const ETAPE = {
    anunt: "Anunt de Intentie",
    elaborare: "Elaborarea propunerilor",
    avizare: "Avizarea propunerilor",
    aprobare: "Aprobarea propunerilor",
    aprobat: "Aprobat de Consiliul Local",
    suspendat: "Suspendat",
    anulat: "Anulat",
    expirat: "Expirat",
};

function IstoricDocumentatie(props) {
    const puzData = useSelector((state) => state.puz.data);
    const puzHistory = useSelector((state) => state.puz.puzHistory);

    const [puz, setPuz] = React.useState(null);
    const [myHistory, setMyHistory] = React.useState(null);

    const handleDelete = () => {
        props.handleClose(true);
    };

    const handleClose = () => {
        props.handleClose();
    };

    React.useEffect(() => {
        if (props.id && puzData) {
            setPuz(
                puzData.filter(function (el) {
                    return el.id === props.id;
                })[0]
            );
        }
        console.log(puzHistory);
    }, [puzData]);

    React.useEffect(() => {
        if (props.id && puzHistory) {
            setMyHistory(
                puzHistory.filter(function (el) {
                    return el.after.id === props.id;
                })
            );
        }
    }, [puzHistory]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title"
            sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
            <span>  Istoric Editare</span>  
            
            <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton></DialogTitle>
            {puz && (
                <DialogContent>
                    <DialogContentText sx={{ pt: 2 }}>
                        {myHistory.map((item, index) => (
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <div style={{ minWidth: 120 }}>
                                    <Chip
                                        variant="filled"
                                        color="default"
                                        label={new Date(item.ts_ms).toLocaleDateString()}
                                        icon={<EditIcon sx={{ fontSize: "1rem", pl: 0.5 }} />}
                                        sx={{ fontWeight: 500, background: "aliceblue" }}
                                    ></Chip>
                                </div>
                                <Box
                                    sx={{
                                        marginLeft: "1rem",
                                        paddingLeft: "1rem",
                                        marginBottom: "2.5rem",
                                        borderLeft: "1px solid",
                                        position: "relative",
                                        fontSize: "96%",
                                        lineHeight: 1,
                                        "&:before": {
                                            content: '""',
                                            position: "absolute",
                                            left: -1,
                                            bottom: -22,
                                            display: "block",
                                            height: "20px",
                                            borderLeft: "1px dashed",
                                        },
                                    }}
                                >
                                    Documentul a fost editat de{" "}
                                    <strong>{item.user.fullname}</strong>{" "}
                                    <span style={{ fontSize: "90%" }}>
                                        <a href="#" style={{ color: "dodgerblue" }}>
                                            ({item.user.email})
                                        </a>
                                    </span>
                                    <div
                                        style={{
                                            paddingTop: "0.5rem",
                                            lineHeight: 2,
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "0.5rem",
                                            }}
                                        >
                                            Etapa:
                                        </span>
                                        {["anunt", "elaborare", "avizare", "aprobare"].includes(
                                            item.after.etapa
                                        ) && (
                                            <Chip
                                                variant="filled"
                                                color="default"
                                                label={ETAPE[item.after.etapa]}
                                                sx={{ fontWeight: "bold" }}
                                                size="small"
                                            ></Chip>
                                        )}
                                        {["aprobat"].includes(item.after.etapa) && (
                                            <Chip
                                                variant="filled"
                                                color="primary"
                                                label={ETAPE[item.after.etapa]}
                                                sx={{ fontWeight: "bold" }}
                                                size="small"
                                            ></Chip>
                                        )}
                                        {["suspendat", "anulat", "expirat"].includes(
                                            item.after.etapa
                                        ) && (
                                            <Chip
                                                variant="outlined"
                                                color="error"
                                                label={ETAPE[item.after.etapa]}
                                                sx={{ fontWeight: "bold" }}
                                                size="small"
                                            ></Chip>
                                        )}{" "}
                                    </div>
                                </Box>
                            </Box>
                        ))}
                    </DialogContentText>

                    <DialogContentText id="alert-dialog-description">
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <div style={{ minWidth: 120 }}>
                                <Chip
                                    size="medium"
                                    color="success"
                                    variant="outlined"
                                    sx={{ fontWeight: "bold" }}
                                    icon={<AddCircleIcon />}
                                    label={new Date(puz.createdAt).toLocaleDateString()}
                                ></Chip>
                            </div>
                            <Box
                                sx={{
                                    marginLeft: "1rem",
                                    paddingLeft: "1rem",
                                    borderLeft: "1px solid",
                                    position: "relative",
                                    lineHeight: "2rem",
                                    background: "#F6F6F6",
                                    flexGrow: 1,
                                    pr: 4
                                }}
                            >
                                Documentul <strong>{puz.descriere}</strong> a fost creat
                            </Box>
                        </div>
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
}

export default IstoricDocumentatie;
