import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import DashboardSidebarItems from "./DashboardSidebarItems";

import Toolbar from "./Toolbar";
import { Outlet } from "react-router-dom";

const mdTheme = createTheme();

const hideOnMobile = {
    display: { xs: "none", md: "block" },
};

function DashboardContent() {
    return (
        <ThemeProvider theme={mdTheme}>
            <Toolbar isDashboard={true} />
            <Box sx={{ display: "flex" }}>
                {/* <CssBaseline /> */}
                <List
                    component="nav"
                    sx={{
                        ...hideOnMobile,
                        minWidth: 240,
                        maxWidth: 320,
                        paddingTop: "1rem",
                        boxShadow: "rgb(0 0 0 / 10%) 0px 0px 6px 1px",
                        maxHeight: "calc(100vh - 58px - 1rem - 8px)",
                        overflow: "hidden",
                        zIndex: 5,
                        "& .MuiListItemButton-root": {
                            color: "#212121",
                        },
                        "& .MuiListItemIcon-root": {
                            color: "inherit",
                        },
                        "& .MuiListItemButton-root.Mui-selected": {
                            background:
                                "linear-gradient(45deg, rgba(25, 118, 210, 0.14), transparent)",
                        },
                    }}
                >
                    <DashboardSidebarItems />
                </List>
                <Box
                    component="main"
                    sx={{
                        // backgroundColor: "rgba(25, 118, 210, 0.08)",
                        background: "rgb(238 244 252)",
                        flexGrow: 1,
                        height: "calc(100vh - 58px)",
                        overflow: "auto",
                        position: "relative"
                    }}
                    id="dashboard-scroll"
                >
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
