import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";

import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import PDFSample from "./images/HCL nr 122 din 24 03 2021 PUZ CJ Calea Dorobantilor.pdf";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein, expirare, observatie) {
    return { name, calories, fat, carbs, protein, expirare, observatie };
}

const rows = [
    createData(
        121,
        "10.02.2021",
        "HCL PUZ",
        "CONSILIUL JUDETEAN CLUJ",
        "SC ST ARH ARHITECTURA CONSTRUCTII DESIGN SRL SC MORARESCU DESIGN STUDIO SRL",
        "20.04.2024",
        "RLU PUZ"
    ),
    createData(
        132,
        "14.07.2021",
        "HCL PUZ",
        "CONSILIUL JUDETEAN CLUJ",
        "SC MORARESCU DESIGN STUDIO SRL",
        "25.03.2024",
        "RLU PUZ"
    ),
    createData(
        55,
        "09.08.2021",
        "HCL PUZ",
        "CONSILIUL JUDETEAN CLUJ",
        "SC ST ARH ARHITECTURA CONSTRUCTII SRL",
        "12.07.2024",
        "RLU PUZ"
    ),
];

function DocumenteAvize() {
    let navigate = useNavigate();

    const handleModalClose = () => {
        navigate("/");
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleModalClose}
            onClose={handleModalClose}
            maxWidth="lg"
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>PUG lc_a - Regim Tehnic</span>
                <IconButton color="default" component="span" onClick={handleModalClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow
                                sx={{ "& th": { borderBottom: "1px solid", background: "#EEE" } }}
                            >
                                <TableCell>Nr. HCL</TableCell>
                                <TableCell align="right">Data HCL</TableCell>
                                <TableCell align="right">Tip</TableCell>
                                <TableCell align="right">Beneficiar</TableCell>
                                <TableCell align="right">Proiectant</TableCell>
                                <TableCell align="right">Expirare</TableCell>
                                <TableCell align="right">Observatie</TableCell>
                                <TableCell align="right">Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                    <TableCell align="right">{row.protein}</TableCell>
                                    <TableCell align="right">{row.expirare}</TableCell>
                                    <TableCell align="right">{row.observatie}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            href={PDFSample}
                                            target="_blank"
                                            endIcon={<PictureAsPdfIcon sx={{ color: "red" }} />}
                                        ></Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}

export default DocumenteAvize;
