import React from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
    Button,
    IconButton,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import GoogleButton from "./components/GoogleButton";

import { useSelector, useDispatch } from "react-redux";
import { logIn, logOut } from "./redux/auth.slice";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

const cookies = new Cookies();

function Signup() {
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const setDefaultSessionData = (sessionData) => {
        cookies.set("sessionData", sessionData, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 3600 * 48),
        });
        dispatch(logIn({ jwt: sessionData }));
    };

    const handleSubmit = (event) => {
        const data = new FormData(event.currentTarget);

        let sessionData = "USER";

        if (data.get("email").toLowerCase().includes("primar")) sessionData = "PRIMAR";
        if (data.get("email").toLowerCase().includes("editor")) sessionData = "EDITOR";

        handleSignup(data)
            .then((response) => {
                if (response.jwt) {
                    cookies.set("sessionData", response.jwt, {
                        path: "/",
                        expires: new Date(Date.now() + 1000 * 3600 * 48),
                    });
                    dispatch(logIn(response));
                } else {
                    setDefaultSessionData(sessionData);
                }
            })
            .catch((error) => {
                setDefaultSessionData(sessionData);
            });

        event.preventDefault();
    };

    async function handleSignup(data) {
        try {
            const response = await fetch(API_URL + `auth/local/register`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    fullname: data.get("fullname"),
                    email: data.get("email"),
                    password: data.get("password"),
                    username: data.get("email"),
                }),
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    const handleModalClose = () => {
        navigate("/");
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleModalClose}
            onClose={handleModalClose}
            maxWidth="xs"
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Creează un cont nou</span>
                <IconButton
                    color="default"
                    component="span"
                    onClick={handleModalClose}
                    sx={{ marginRight: "-0.75rem" }}
                >
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="fullname"
                        label="Nume"
                        name="fullname"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresa email"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Parola"
                        type="password"
                        id="password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Accept politica de confidentialitate, termenii si conditiile"
                        sx={{
                            width: "100%",
                            "& .MuiTypography-root": {
                                fontSize: "13px",
                            },
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2, p: 1.5 }}
                    >
                        Creează cont
                    </Button>
                    <Divider sx={{ fontSize: "12px" }}>SAU</Divider>
                    <GoogleButton sx={{ mt: 2, mb: 1, p: 1.5 }}>
                        Foloseste un cont Google
                    </GoogleButton>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2, p: 1.5 }}
                        component={Link}
                        to="/login"
                    >
                        Ai cont deja? Conecteaza-te!
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Signup;
