import { useEffect } from "react";
import L from "leaflet";

// RandomMarkerInMapBounds
const RandomMarkerInMapBounds = ({ map }) => {
    useEffect(() => {
        if (!map) return;

        // add feature group to map
        const fg = L.featureGroup().addTo(map);

        // create random marker
        function randomMarker() {
            // get bounds of map
            const bounds = map.getBounds();

            let southWest = bounds.getSouthWest();
            let northEast = bounds.getNorthEast();
            let lngSpan = northEast.lng - southWest.lng;
            let latSpan = northEast.lat - southWest.lat;

            let allPoints = [];
            let geoPoints = [];

            // generate random points and add to array 'allPoints'
            for (let i = 0; i < 30; i++) {
                const lat = southWest.lat + latSpan * Math.random();
                const lng = southWest.lng + lngSpan * Math.random();
                allPoints.push([lat, lng]);
                geoPoints.push({ lat: lat, lng: lng });
            }

            // add markers to feature group
            for (let i = 0; i < allPoints.length; i++) {
                L.marker(allPoints[i], {
                    icon: L.divIcon({
                        className: "custom-icon-marker",
                        iconSize: L.point(40, 40),
                        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker"><path fill-opacity="0.25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/><path stroke="#fff" fill="tomato" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/></svg>`,
                        iconAnchor: [12, 24],
                    }),
                }).addTo(fg);
            }

            // zoom to feature group and add padding
            map.fitBounds(fg.getBounds(), { padding: [10, 10] });

            // log the random points
            console.log(JSON.stringify(geoPoints));
        }

        // initialize random marker
        document.randomMarker = randomMarker;
    }, [map]);

    return null;
};

export default RandomMarkerInMapBounds;
