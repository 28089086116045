import * as React from "react";

import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Heading from "./components/DashboardHeading";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Button, Chip } from "@mui/material/";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import AdaugaNumarInregistrare from "./AdaugaNumarInregistrare";

// Generate Order Data
function createData( nr, date, nrext, dateext, emitent, continut, compartiment, newOrder) {
    return { nr, date, nrext, dateext, emitent, continut, compartiment, newOrder };
}

function Registratura() {
    const [adaugaInregistrareVisibility, setAdaugaInregistrareVisibility] = React.useState(false);
    const [rows, setRows] = React.useState([
        createData("16", "26.06.2022", "123", "26.06.22", "Primaria Cluj", "Donatie", "Secretariat", false),
        createData("15", "26.06.2022", "122", "26.06.22", "Primaria Cluj", "Certificat Urbanism", "Stare civila", false),
        createData("14", "25.06.2022", "340", "25.06.22", "Primaria Cluj", "Factura Electrica", "Urbanism", false),
        createData("13", "25.06.2022", "339", "25.06.22", "Primaria Cluj", "Donatie", "Urbanism", false),
        createData("12", "24.06.2022", "338", "24.06.22", "Primaria Cluj", "Petrecere", "Secretariat", false),
        createData("11", "23.06.2022", "B61", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("10", "22.06.2022", "A42", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("9", "22.06.2022", "A41", "23.06.22", "Primaria Cluj", "Donatie", "Secretariat", false),
        createData("8", "22.06.2022", "337", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("7", "22.06.2022", "336", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("6", "22.06.2022", "335", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("5", "22.06.2022", "B60", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("4", "22.06.2022", "A40", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("3", "22.06.2022", "12", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("2", "22.06.2022", "743", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
        createData("1", "22.06.2022", "334", "23.06.22", "Primaria Cluj", "Certificat Urbanism", "Secretariat", false),
    ]);

    const adaugaInregistrare = (inregistrare) => {
        setAdaugaInregistrareVisibility(false);
        if (inregistrare){
            setRows([inregistrare, ...rows]);
        }
        //
    };

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Heading>Documente interne - Registratura generala</Heading>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0.25rem 0 0.75rem 0",
                            }}
                        >
                            <Button variant="contained" startIcon={<BookmarkBorderIcon />}
                        onClick={()=> setAdaugaInregistrareVisibility(true)}>
                                Rezerva numar inregistrare
                            </Button>
                            {/* <Button variant="contained" startIcon={<UploadFileIcon />} color="success">
                                Adauga document
                            </Button> */}
                        </div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nr. Inreg.</TableCell>
                                    <TableCell>Data Inreg.</TableCell>
                                    <TableCell>Nr. Extern</TableCell>
                                    <TableCell>Data Externa</TableCell>
                                    <TableCell>Emitent</TableCell>
                                    <TableCell>Continut pe scurt</TableCell>
                                    <TableCell>Compartiment</TableCell>
                                    <TableCell>Atasamente</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ background: row.newOrder ? "aliceblue" : "" }}
                                    >
                                        <TableCell>
                                            <Chip label={"#" + row.nr} sx={{backgroundColor: "#333", color: "#FFF"}} size="small" />
                                        </TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.nrext}</TableCell>
                                        <TableCell>{row.dateext}</TableCell>
                                        <TableCell>{row.emitent}</TableCell>
                                        <TableCell>{row.continut}</TableCell>
                                        <TableCell>{row.compartiment}</TableCell>
                                        <TableCell sx={{textAlign: "center"}}>
                                            <PictureAsPdfIcon sx={{ color: "red" }} />
                                            <ReceiptLongIcon sx={{ color: "green" }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
            {adaugaInregistrareVisibility && <AdaugaNumarInregistrare
                handleModalClose={adaugaInregistrare}
                lastNumber={parseInt(rows[0].nr)}
            />}
        </Container>
    );
}

export default function DashboardRegistratura() {
    return <Registratura />;
}
