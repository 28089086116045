import * as React from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material/";
import { Button, Grid, Paper, Tooltip } from "@mui/material/";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";

import AdaugaRol from "./AdaugaRol";

import { useSelector, useDispatch } from "react-redux";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

// Generate Order Data
function createData(nume, descriere, users, rol) {
    return { nume, descriere, users, rol };
}

function Conturi() {
    const jwt = useSelector((state) => state.auth.jwt);

    const userData = useSelector((state) => state.auth.userData);

    const [rows, setRows] = React.useState([
        createData(
            "Cetatean",
            "Rol de baza pentru cetatenii autentificati",
            "500+",
            "Administrator"
        ),
        createData("Editor", "Rol de editare", "11", "Primar"),
        createData("Primar", "Rol de editare, verficare si semnare", "1", "Editor"),
        createData("Secretar", "Rol de editare, verficare si semnare", "2", "Secretar"),
        createData("Administrator", "Super rol cu permisiuni complete", "1", "Editor"),
    ]);
    const [adaugaContVisibility, setAdaugaContVisibility] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(null);
    const [deleteIndex, setDeleteIndex] = React.useState(null);

    const [schimbaRol, setSchimbaRol] = React.useState(null);

    const [conturi, setConturi] = React.useState(null);
    const [roluri, setRoluri] = React.useState(null);

    const promptAdd = () => {
        setAdaugaContVisibility(true);
    };

    const promptEdit = (index) => {
        setEditIndex(index);
        setAdaugaContVisibility(true);
    };

    const promptDelete = (index) => {
        setDeleteIndex(index);
    };

    React.useEffect(() => {
        if (userData && userData.originalRole && userData.originalRole.type === "superuser")
            getAccounts()
                .then((response) => {
                    if (response) {
                        setConturi(response);
                        console.log(response);
                        // updateUser();
                        getRoles().then((response) => {
                            if (response && response.roles) {
                                setRoluri(response.roles);
                            }
                        });
                    }
                })
                .catch((error) => {
                    //
                });
    }, [userData]);

    async function updateUser(userId, newRole) {
        try {
            const response = await fetch(API_URL + `users/` + userId, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                    role: newRole,
                }),
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    async function getRoles() {
        try {
            const response = await fetch(API_URL + `users-permissions/roles`, {
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    async function getAccounts(data) {
        try {
            const response = await fetch(API_URL + `users?populate=role`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nume</TableCell>
                            <TableCell>Descriere</TableCell>
                            <TableCell>Utilizatori</TableCell>
                            <TableCell align="right">Operatiuni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{row.nume}</TableCell>
                                <TableCell>{row.descriere}</TableCell>
                                <TableCell>{row.users}</TableCell>
                                <TableCell align="right">
                                <Tooltip title="Editeaza Rolul">

                                        <IconButton
                                            color="primary"
                                            onClick={() => promptEdit(index)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        </Tooltip>
                                    <Tooltip title="Sterge Rolul">

                                        <IconButton
                                            color="error"
                                            onClick={() => promptDelete(index)}
                                        >
                                            <DeleteForeverIcon fontSize="small" />
                                        </IconButton>
                                        </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    variant="contained"
                    startIcon={<PersonAddAltIcon />}
                    onClick={promptAdd}
                    sx={{ mt: 2 }}
                >
                    Adauga Rol Nou
                </Button>
            </Paper>
            {adaugaContVisibility && (
                <AdaugaRol />
            )}
        </Grid>
    );
}

export default function DashboardConturi() {
    return <Conturi />;
}
