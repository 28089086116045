import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function MapFeedbackBox({ children, sx, ...other }) {
    return (
        <Box
            sx={{
                position: "absolute",
                top: 20,
                left: "50%",
                transform: "translate(-50%,0)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                background: "rgba(255,255,255,0.7)",
                padding: "10px",
                borderRadius: "40px",
                fontWeight: 500,
                fontSize: "0.9rem",
                ...sx
            }}
            {...other}
        >
            {children}
        </Box>
    );
}

MapFeedbackBox.propTypes = {
    children: PropTypes.node,
};

export default MapFeedbackBox;
