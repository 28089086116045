import * as React from "react";
import logoBistrita from ".././images/logo-b.png";

function Heading({ logoHeight, ...other }) {
    return (
        <div
            style={{
                marginBottom: "2rem",
                lineHeight: 1.3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <img style={{ marginRight: "1rem" }} height={logoHeight || 75} alt="logo" src={logoBistrita} />
                <aside>
                    PRIMARIA SI <br />
                    CONSILIUL LOCAL <br />
                    <strong>BISTRITA</strong>
                </aside>
            </div>
            <div style={{ textAlign: "right" }}>
                <strong>ROMÂNIA</strong> <br />
                Județul Bistrița-Năsăud <br />
                Municipiul Bistrița <br />
            </div>
        </div>
    );
}

export default Heading;
