import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";

import TextField from "@mui/material/TextField";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PlaceIcon from "@mui/icons-material/Place";
import axios from "axios";

Date.prototype.toDateInputValue = function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
};

function AdaugaReclamatie(props) {
    const [address, setAddress] = React.useState();
    const [files, setFiles] = React.useState([]);
    const [date, setDate] = React.useState(new Date().toDateInputValue());

    React.useEffect(() => {
        if (props.point) {
            getPosition(props.point);
        } else {
            setAddress();
        }
    }, [props.point]);

    const getPosition = (location) => {
        const getDisplayName = async () => {
            const response = await axios.get("https://nominatim.openstreetmap.org/search.php", {
                params: { q: location.join(","), polygon_geojson: 1, format: "json" },
            });
            setAddress(response.data[0].display_name.split(", Cluj-Napoca")[0]);
        };
        getDisplayName();
    };

    function handleFileUpload(e) {
        const newFiles = e.target.files;

        console.log(newFiles);
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(newFiles[i].name);
        }
        setFiles([...files, ...newFilesArray]);
    }

    return (
        <Box component="form" noValidate autoComplete="off">
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pb: 3,
                }}
            >
                <PlaceIcon
                    sx={{ fontSize: "2.5rem", mr: 1, color: props.point ? "dodgerblue" : "#DDD" }}
                />
                {!address && (
                    <Box sx={{ flexGrow: 1 }}>
                        Click pe harta pentru adaugarea unei amenzi sau{" "}
                        <Link
                            sx={{ cursor: "pointer" }}
                            onClick={props.handleAutoLocation}
                            underline="always"
                        >
                            citeste locatia curenta
                        </Link>
                    </Box>
                )}
                {address && <Box sx={{ flexGrow: 1 }}>{address}</Box>}
            </Box>

            <Box sx={{ mb: 2 }}>
                <TextField
                    type="date"
                    label="Data emitere"
                    data-date-format="DD/MM/YYYY"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField label="Descriere" multiline minRows={2} fullWidth />
            </Box>

            <Box
                sx={{
                    mb: 2.5,
                    border: "1px dotted #333",
                    borderRadius: "10px",

                    background: "#F6F6F6",
                    fontSize: "0.85rem",
                    lineHeight: 1.3,
                    fontWeight: 500,
                    overflow: "hidden",
                }}
            >
                {files.length > 0 && (
                    <Box
                        sx={{
                            p: 2,
                            fontWeight: 400,
                            borderBottom: "1px dotted",
                            background: "#E6E6E6",
                        }}
                    >
                        {files.map((file, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "0.8rem",
                                    "&:not(:last-child)": {
                                        mb: 1,
                                    },
                                }}
                            >
                                <FilePresentIcon sx={{ fontSize: "1.3rem", mr: 0.5 }} /> {file}
                            </Box>
                        ))}
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        p: 2,
                        "&:hover": {
                            opacity: 0.7,
                            background: "white",
                        },
                    }}
                    component="label"
                >
                    <DriveFolderUploadIcon sx={{ marginRight: "1rem" }} />{" "}
                    <span>Adauga imagini sau documente pentru aceasta amenda</span>
                    <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleFileUpload}
                    />
                </Box>
            </Box>

            <Button onClick={props.toggleEditMode} sx={{ mr: 1 }}>
                Anuleaza
            </Button>
            <Button variant="contained" onClick={props.salveazaAmenda}>
                Adauga Amenda
            </Button>
        </Box>
    );
}

export default function AmenziReclamatiiLayerControl({
    editMode,
    handleAmenzi,
    handleReclamatii,
    handleAutoLocation,
    toggleEditMode,
    point,
    salveazaAmenda,
}) {
    return (
        <Card
            sx={{
                position: "absolute",
                top: 10,
                left: 10,
                zIndex: 1000,
                maxWidth: 360,
                minWidth: 200,
                maxHeight: "calc(100vh - 78px)",
                overflow: "auto",
                transition: "1s all",
            }}
        >
            <CardContent>
                <Box sx={{ mb: 1, fontWeight: 500, opacity: 0.8 }}>Straturi afișate:</Box>

                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox size="small" defaultChecked onChange={handleAmenzi} />}
                        label="Amenzi"
                        sx={{ mb: -0.5 }}
                    />
                    {/* <FormControlLabel
                        control={<Checkbox size="small" onChange={handleReclamatii} />}
                        label="Reclamații"
                    /> */}
                </FormGroup>
                <Divider sx={{ mb: 2, mt: 1 }} />
                {!editMode && (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ mb: "-8px" }}
                        onClick={toggleEditMode}
                    >
                        Adaugă Amendă Nouă
                    </Button>
                )}

                {editMode && (
                    <AdaugaReclamatie
                        toggleEditMode={toggleEditMode}
                        handleAutoLocation={handleAutoLocation}
                        editMode={editMode}
                        point={point}
                        salveazaAmenda={salveazaAmenda}
                    />
                )}
            </CardContent>
        </Card>
    );
}
