import * as React from "react";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import { Link } from "react-router-dom";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArticleIcon from "@mui/icons-material/Article";

import Chip from "@mui/material/Chip";
import PDFSample from "./images/certificat.pdf";

import CampaignIcon from "@mui/icons-material/Campaign";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LayersIcon from "@mui/icons-material/Layers";

import { useSelector, useDispatch } from "react-redux";

function Notificare(props) {
    let icon = <NotificationsNoneIcon />;
    if (props.type === "alerta") icon = <CampaignIcon />;
    if (props.type === "sesizare") icon = <FmdGoodIcon />;
    if (props.type === "cerere") icon = <ArticleIcon />;
    if (props.type === "urbanism") icon = <LayersIcon />;
    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                background: props.new ? "#FFF" : "rgba(255,255,255,0.25)",
                mb: 1.5,
                "& h2": {
                    margin: 0,
                    fontSize: "1.75em",
                },
                "& .MuiButton-root": {
                    opacity: 0.5,
                },
                "&:hover": {
                    background: "linear-gradient(314deg, #dceeff, #FFF)",
                    // boxShadow: "inset 0 0 0 1px blue",
                    transition: "background 0.35s",
                    cursor: "pointer",
                    "& .MuiButton-root": {
                        opacity: 1,
                    },
                },
                boxShadow: props.new ? "" : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
            }}
            elevation={props.new ? 1 : 0}
        >
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        background: props.new ? "paleturquoise" : "rgba(0,0,0,0.05)",
                        borderRadius: "40px",
                        width: 40,
                        height: 40,
                        mr: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                </Box>
                <Box sx={{ lineHeight: 1.3 }}>
                    {props.children}
                    <br />
                    <span style={{ fontSize: "12px", opacity: 0.5 }}>{props.date}</span>
                </Box>
            </Box>
            <Button
                size="small"
                color="inherit"
                sx={{ px: 1 }}
                component={props.component}
                to={props.to}
                target={props.target}
            >
                vezi detalii &raquo;
            </Button>
        </Paper>
    );
}

export default function Notificari({ showAllData }) {
    const userData = useSelector((state) => state.auth.userData);
    const [userRole, setUserRole] = React.useState(null);

    React.useEffect(() => {
        if (userData) setUserRole(userData.role);
    }, [userData]);
    return (
        <React.Fragment>
            {
                {
                    USER: (
                        <React.Fragment>
                            <Notificare
                                new={true}
                                type="cerere"
                                date="Acum 2 minute"
                                to={PDFSample}
                                target="_blank"
                                component={Link}
                            >
                                A fost emis certificatul pentru cererea <strong>Nr. 122</strong> din{" "}
                                <strong>20 August 2022</strong>
                            </Notificare>
                            <Notificare new={true} type="alerta" date="Acum 4 ore">
                                Au aparut modificari la detaliile zonei <strong>PUG lc_a</strong>
                            </Notificare>
                            <Notificare new={true} type="sesizare" date="Acum 11 zile">
                                Sesizarea <strong>Nr. 342</strong> din{" "}
                                <strong>18 Martie 2022</strong> a fost rezolvata
                            </Notificare>
                            {showAllData && (
                                <React.Fragment>
                                    <Notificare type="cerere" date="Acum 12 zile">
                                        Cererea <strong>Nr. 122</strong> din{" "}
                                        <strong>20 August 2022</strong> a fost respinsa
                                    </Notificare>
                                    <Notificare type="alerta" date="Acum o luna">
                                        Au aparut modificari la detaliile zonei <strong>M1a</strong>
                                    </Notificare>
                                    <Notificare type="alerta" date="Acum o luna">
                                        Au aparut modificari la detaliile zonei <strong>M2a</strong>
                                    </Notificare>
                                    <Notificare type="alerta" date="Acum o luna">
                                        Au aparut modificari la detaliile zonei <strong>M2a</strong>
                                    </Notificare>
                                    <Notificare type="alerta" date="Acum o luna">
                                        Au aparut modificari la detaliile zonei <strong>M4a</strong>
                                    </Notificare>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ),
                    EDITOR: (
                        <React.Fragment>
                            <Notificare new={true} type="cerere" date="Acum 12 zile">
                                Cererea <strong>Nr. 123</strong> din <strong>20 August 2022</strong>{" "}
                                a primit un comentariu nou
                            </Notificare>
                            <Notificare new={true} type="cerere" date="Acum 12 zile">
                                Cererea <strong>Nr. 122</strong> din <strong>20 August 2022</strong>{" "}
                                a primit o observatie care necesita rezolvare
                            </Notificare>
                            {showAllData && (
                                <React.Fragment>
                                    <Notificare type="cerere" date="Acum 12 zile">
                                        Cererea <strong>Nr. 121</strong> din{" "}
                                        <strong>20 August 2022</strong> a fost respinsa
                                    </Notificare>
                                    <Notificare type="sesizare" date="Acum o luna">
                                        A fost inregistrata o sesizare noua in categoria{" "}
                                        <strong>Construcții și terenuri</strong>
                                    </Notificare>
                                    <Notificare type="sesizare" date="Acum 2 luni">
                                        A fost inregistrata o sesizare noua in categoria{" "}
                                        <strong>Trafic rutier și semne de circulație</strong>
                                    </Notificare>
                                    <Notificare type="sesizare" date="Acum o luna">
                                        A fost inregistrata o sesizare noua in categoria{" "}
                                        <strong>Construcții și terenuri</strong>
                                    </Notificare>
                                    <Notificare type="sesizare" date="Acum o luna">
                                        A fost inregistrata o sesizare noua in categoria{" "}
                                        <strong>Construcții și terenuri</strong>
                                    </Notificare>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ),
                    PRIMAR: (
                        <React.Fragment>
                            <Notificare
                                new={true}
                                type="cerere"
                                date="Acum 12 zile"
                                component={Link}
                                to="/dashboard/semnare"
                            >
                                Cererea <strong>Nr. 123</strong> necesita semnatura electronica
                            </Notificare>
                            <Notificare
                                new={true}
                                type="cerere"
                                date="Acum 12 zile"
                                component={Link}
                                to="/dashboard/semnare"
                            >
                                Cererea <strong>Nr. 122</strong> necesita semnatura electronica
                            </Notificare>
                            {showAllData && (
                                <React.Fragment>
                                    <Notificare type="urbanism" date="Acum o luna">
                                        Documentatia <strong>PUZ Zefirului</strong> necesita
                                        aprobare
                                    </Notificare>
                                    <Notificare type="urbanism" date="Acum 2 luni">
                                        Documentatia <strong>PUZ Alunului</strong> necesita aprobare
                                    </Notificare>
                                    <Notificare type="urbanism" date="Acum o luna">
                                        Documentatia <strong>PUZ Zefirului</strong> necesita
                                        aprobare
                                    </Notificare>
                                    <Notificare type="cerere" date="Acum 2 luni">
                                        Cererea <strong>Nr. 120</strong> necesita semnatura
                                        electronica
                                    </Notificare>
                                    <Notificare type="cerere" date="Acum 2 luni">
                                        Cererea <strong>Nr. 119</strong> necesita semnatura
                                        electronica
                                    </Notificare>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ),
                }[userRole]
            }
        </React.Fragment>
    );
}
