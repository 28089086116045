import React from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

function Logout() {
    const navigate = useNavigate();
    const cookies = new Cookies();

    const onCookieDelete = ({ name, value }) => {
        // console.log(name, value);
        // console.log(cookies.get("sessionData"));
    };

    cookies.addChangeListener(onCookieDelete);
    cookies.remove("sessionData", {
        path: "/",
    });

    React.useEffect(() => {
        // navigate("/");
        window.location = "/";
    }, []);

    return null;
}

export default Logout;
