import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Box } from "@mui/material";

function SchimbaRol(props) {
    const handleSubmit = () => {
        props.handleModalClose();
    };

    const handleClose = () => {
        props.handleModalClose();
    };

    React.useEffect(() => {}, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="md"
            sx={{ "& .MuiDialog-paper": { minHeight: 300 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Adauga Rol</span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <TextField name="name" label="Nume Rol" fullWidth />
                <br />
                <br />
                <TextField name="description" label="Descriere" fullWidth />

                <h3>Permisii</h3>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>
                            <strong>Cereri</strong>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction="row" spacing={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label="Creare"
                                />
                                <FormControlLabel required control={<Checkbox />} label="Cautare" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Modificare" />
                                <FormControlLabel
                                    required
                                    control={<Checkbox />}
                                    label="Stergere"
                                />
                            </FormGroup>
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>
                            <strong>Amenzi</strong>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Stack direction="row" spacing={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label="Creare"
                                />
                                <FormControlLabel required control={<Checkbox />} label="Cautare" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Modificare" />
                                <FormControlLabel
                                    required
                                    control={<Checkbox />}
                                    label="Stergere"
                                />
                            </FormGroup>
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>
                            <strong>Sesizari</strong>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <DialogActions
                    sx={{
                        mt: 2,
                    }}
                >
                    <Button onClick={handleClose}>Anuleaza</Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Salveaza modificarile
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default SchimbaRol;
