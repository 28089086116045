import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import { Button, Box, Alert, Stack } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { Link } from "react-router-dom";

function SetariSiguranta() {
    const [formData, setFormData] = React.useState({ parola: "", parolaNoua: "" });
    const [formErrors, setFormErrors] = React.useState({});
    const [parolaSchimbata, setParolaSchimbata] = React.useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const validateInput = (name, value) => {
        const validInput = !!value.trim();
        if (!validInput) {
            setFormErrors({ ...formErrors, [name]: true });
        } else {
            setFormErrors({ ...formErrors, [name]: false });
        }
    };

    const isValidForm = (inputs) => {
        let isValid = true;
        let errors = {};
        inputs.forEach((name) => {
            const validInput = !!formData[name].trim();
            if (!validInput) {
                errors[name] = true;
                isValid = false;
            } else {
                errors[name] = false;
            }
        });
        setFormErrors({ ...formErrors, ...errors });
        return isValid;
    };

    const handleSubmit = (e) => {
        setParolaSchimbata(false);
        if (isValidForm(["parola", "parolaNoua"])) {
            setFormData({ parola: "", parolaNoua: "" });
            setParolaSchimbata(true);
        }
        e.preventDefault();
    };

    const confirmDelete = () => {
        setShowConfirmationDialog(true);
    };

    const handleDelete = () => {
        //
    };

    const handleClose = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Paper
                sx={{
                    p: 2,
                    pl: 1,
                    "& .MuiTextField-root": { m: 2, width: "40ch", display: "flex" },
                    "& .MuiButton-root": { ml: 2, mb: 1 },
                    "& .MuiTypography-root": {
                        px: 2,
                        pt: 1,
                    },
                    "& .MuiAlert-root": { mb: 2, ml: 1 },
                }}
            >
                {parolaSchimbata && (
                    <React.Fragment>
                        <Alert severity="info">Parola a fost schimbata cu succes.</Alert>
                    </React.Fragment>
                )}
                <Heading>Schimba-ti parola</Heading>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        label="Parola actuală *"
                        type="password"
                        name="parola"
                        value={formData.parola}
                        onChange={handleFormChange}
                        error={formErrors.parola}
                        helperText={formErrors.parola ? "Camp obligatoriu" : ""}
                    />
                    <TextField
                        label="Parola noua *"
                        type="password"
                        name="parolaNoua"
                        value={formData.parolaNoua}
                        onChange={handleFormChange}
                        error={formErrors.parolaNoua}
                        helperText={formErrors.parolaNoua ? "Camp obligatoriu" : ""}
                    />
                    <Button type="submit" variant="contained" size="large">
                        Schimba parola
                    </Button>
                </Box>
            </Paper>
            <br />

            <Paper
                sx={{
                    p: 3,
                    "& .MuiTextField-root": { m: 1, width: "40ch" },
                    "& .MuiButton-root": { mt: 2 },
                }}
            >
                <Heading>Stergerea contului</Heading>
                <p>
                    Stergerea contului este o actiune ireversibila prin care datele tale vor fi
                    inlaturate.
                    <br />
                    <br />
                    Ai in vedere ca din motive legale, unele date pot fi pastrate.
                </p>
                <Button variant="contained" size="large" color="error" onClick={confirmDelete}>
                    Sterge contul
                </Button>
            </Paper>
            <br />

            {showConfirmationDialog && (
                <Dialog
                    open={true}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Sterge cont</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Stergerea contului este o actiune ireversibila prin care datele tale vor
                            fi inlaturate. Esti sigur ca doresti acesti lucru?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Stack spacing={2} sx={{ m: 1 }} direction="row">
                            <Button variant="contained" color="success" onClick={handleClose}>
                                Nu, anuleaza actiunea
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                autoFocus
                                to="/logout"
                                component={Link}
                            >
                                Da, sterge contul
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
}

export default function DashboardSiguranta() {
    return <SetariSiguranta />;
}
