import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import semnatura1 from "./images/semnaturi/sign1.svg";
import semnatura2 from "./images/semnaturi/sign2.svg";
import semnatura3 from "./images/semnaturi/sign3.svg";

function AdaugaCont(props) {
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                Semneaza Certificatul
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <p style={{ marginTop: 0 }}>Selecteaza una dintre semnaturile de mai jos:</p>
                <Box sx={{ display: "flex" }}>
                    <Box
                        sx={{
                            padding: 3,
                            border: "1px solid blue",
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            mb: 2,
                            maxWidth: 100,
                            mr: 2,
                            background: "aliceblue",
                        }}
                    >
                        <img src={semnatura3} style={{ maxWidth: 100, height: 60 }} />
                    </Box>{" "}
                    {/* <Box
                        sx={{
                            padding: 3,
                            border: "1px solid",
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            mb: 2,
                            mr: 2,

                            maxWidth: 100,
                        }}
                    >
                        <img src={semnatura2} style={{ maxWidth: 100, height: 60 }} />
                    </Box> */}
                    <Box
                        sx={{
                            padding: 3,
                            border: "1px dashed",
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            mb: 2,
                            maxWidth: 200,
                            textAlign: "center",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            "&:hover": {
                                border: "1px solid blue",
                                background: "aliceblue",
                                cursor: "pointer"
                            },
                        }}
                    >
                        + Adauga o semnatura noua
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={props.handleSign}>
                    Aplica Semnatura Selectata
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaCont;
