import { configureStore } from "@reduxjs/toolkit";
import { loadFromLocalStorage, saveToLocalStorage } from "./localstorage";
import geoReducer from "./geo.slice";
import layersReducer from "./layers.slice";
import authReducer from "./auth.slice";
import puzReducer from "./puz.slice";
import gpsReducer from "./gps.slice";

const reducer = {
    //Reducers come here
    geo: geoReducer,
    layers: layersReducer,
    auth: authReducer,
    puz: puzReducer,
    gps: gpsReducer,
};

const store = configureStore({
    reducer,
    preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => {
    // saveToLocalStorage(store.getState())
    saveToLocalStorage({ puz: store.getState().puz, gps: store.getState().gps });
});

export default store;
