import React from "react";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Cookies from "universal-cookie";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const cookies = new Cookies();

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function DisclaimerDialog() {
    const [acceptedCookies, setAcceptedCookies] = React.useState(false);

    const acceptCookies = () => {
        cookies.set("acceptedDisclaimer", "1", {
            path: "/",
            expires: new Date(Date.now() + 1000 * 3600 * 48),
        });
        setAcceptedCookies(true);
    };

    React.useEffect(() => {
        const readCookies = cookies.get("acceptedDisclaimer");
        if (readCookies) setAcceptedCookies(readCookies);
    });

    const cookiesModal = (
        <BootstrapDialog
            onClose={acceptCookies}
            aria-labelledby="customized-dialog-title"
            open={!acceptedCookies}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={acceptCookies}>
                Smartcity360 - DEMO
            </BootstrapDialogTitle>
            <DialogContent
                dividers
                sx={{
                    "& .MuiTypography-root": {
                        fontSize: "15px !important",
                    },
                }}
            >
                <Typography sx={{ paddingBottom: "1rem" }}>
                    Disclaimer privind versiunea demo a Geoportalului GIS bazat pe solutia
                    Smartcity360 de la CUBITRON SRL
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    <strong>Versiunea Demo:</strong> Această pagină servește ca o demonstrație a
                    capacităților soluției noastre geoportal GIS. Vă rugăm să aveți în vedere că
                    funcționalitățile prezentate în această demonstrație sunt o selecție redusă a
                    capabilităților totale ale platformei noastre.
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    <strong>Adaptabilitate:</strong> Acest demo este destinat să ofere
                    utilizatorilor o privire generală asupra posibilităților oferite de soluția
                    noastră. În practică, soluția finală va fi adaptată pentru a răspunde
                    specificului și cerințelor beneficiarilor finali, garantând astfel cea mai bună
                    potrivire pentru nevoile dvs.
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    <strong>Versiunea 2D vs. 3D:</strong> Atât funcționalitățile 2D, cât și cele 3D
                    prezentate aici pot avea variații sau caracteristici suplimentare în versiunea
                    completă a produsului. Vă încurajăm să ne contactați pentru a discuta detalii
                    specifice sau pentru a solicita o demonstrație completă.
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    <strong>Precizie și Actualizare:</strong> Datele și informațiile prezentate în
                    această demonstrație pot fi de natură exemplificativă și ar putea să nu reflecte
                    cele mai recente actualizări sau niveluri de precizie pe care le oferim în
                    versiunile complete ale produsului.
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    <strong>Limitarea Răspunderii:</strong> Compania noastră nu își asumă
                    răspunderea pentru decizii luate pe baza acestei demonstrații sau pentru orice
                    neînțelegere care ar putea apărea în urma utilizării acestui demo.
                </Typography>

                <Typography sx={{ paddingBottom: "1rem" }}>
                    Vă mulțumim pentru înțelegere și vă stăm la dispoziție pentru orice întrebări
                    sau solicitări adiționale!
                </Typography>
                <Typography sx={{ paddingBottom: "1rem" }}>
                    Echipa Cubitron | <a href="mailto:info@cubitron.ro">info@cubitron.ro</a>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={acceptCookies}>
                    Inchide Notificarea
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );

    return acceptedCookies ? null : cookiesModal;
}

export default DisclaimerDialog;
