const key = "state";

export function saveToLocalStorage(state) {
    const now = new Date()
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: state,
        expiry: now.getTime() + 24 * 60 * 60 * 1000,
    }

    try {
        const serializedState = JSON.stringify(item);
        localStorage.setItem(key, serializedState);
    } catch (e) {
        console.log(e);
    }
}

export function loadFromLocalStorage() {
    if (typeof window === 'undefined') {
        //Added this in order to avoid errors on the server side rendering
        return
    }

    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) return undefined;

        const item = JSON.parse(serializedState)
        const now = new Date()

        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return undefined
        }
        return item.value
    } catch (e) {
        console.log(e);
    }
}
