import React from "react";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import manualScreenshot from "./images/screenshot.jpg";
import alerteTutorial1 from "./images/alerte1.png";
import alerteTutorial2 from "./images/alerte2.png";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

const dialogStyle = {
    "& .MuiDialog-paper": { width: 800 },
};

const mainParagraph = {
    borderTop: "1px dashed",
    // borderBottom: "1px dotted",
    background: "#dcefff",
    padding: "20px",

    boxShadow: "rgba(33, 35, 38, 0.2) 0px 10px 10px -10px",
    "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
    },
    "& ul li": {
        display: "block",
        cursor: "pointer",
        padding: "0.75rem 0",
        fontWeight: "bold",
    },
    "& ul li.sub": {
        marginLeft: "1rem",
        fontWeight: "400",
    },
    "& ul li:hover": {
        // backgroundColor: "rgba(255,255,255,0.5)"
        textDecoration: "underline",
    },
};

function ManualUtilizare() {
    let navigate = useNavigate();
    const { section } = useParams();

    const handleModalClose = () => {
        navigate("/");
    };

    React.useEffect(() => {
        if (section) {
            setTimeout(() => {
                let element = document.getElementById("alerte");
                element.scrollIntoView({ behavior: "smooth", block: "start" });
                element.style.backgroundColor = "aliceblue";
                setTimeout(() => {
                    element.style.backgroundColor = "white";
                }, "2000");
            }, "100");
        }
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleModalClose}
            onClose={handleModalClose}
            maxWidth="lg"
            sx={dialogStyle}
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Manual Utilizare Aplicatie GIS</span>
                <IconButton color="default" component="span" onClick={handleModalClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>

            <DialogContent style={{ padding: "0 1.5rem" }}>
                <Box sx={mainParagraph}>
                    <ul>
                        <li>
                            <a>
                                <strong>1.</strong> Utilizarea aplicației
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.1</strong> Ce este opțiunea Acasă?
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.2</strong> Cum pot Căuta pe harta?
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.3</strong> Ce face opțiunea Straturi?
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.4</strong> Cum se manipulează straturile
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.5</strong> Fereastra de Informații
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.6</strong> Ce face opțiunea Legendă?
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.7</strong> Opțiunea Manual
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>1.8</strong> Alte opțiuni
                            </a>
                        </li>
                        <li>
                            <a>
                                <strong>2.</strong> Crearea unei Cereri online
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>2.1</strong> Atașarea documentelor aferente Cererii
                            </a>
                        </li>
                        <li className="sub">
                            <a>
                                <strong>2.2</strong> Regăsirea unei cereri, atașarea de documente
                                suplimentare
                            </a>
                        </li>
                    </ul>
                </Box>

                <h4>1. Utilizarea Aplicatiei</h4>
                <Typography>
                    Lorem Ipsum este pur şi simplu o machetă pentru text a industriei tipografice.
                    Lorem Ipsum a fost macheta standard a industriei încă din secolul al XVI-lea,
                    când un tipograf anonim a luat o planşetă de litere şi le-a amestecat pentru a
                    crea o carte demonstrativă pentru literele respective. Nu doar că a supravieţuit
                    timp de cinci secole, dar şi a facut saltul în tipografia electronică practic
                    neschimbată. A fost popularizată în anii '60 odată cu ieşirea colilor Letraset
                    care conţineau pasaje Lorem Ipsum, iar mai recent, prin programele de publicare
                    pentru calculator, ca Aldus PageMaker care includeau versiuni de Lorem Ipsum.
                </Typography>
                <br />
                <Typography>
                    <img style={{ maxWidth: "100%" }} src={manualScreenshot} />
                </Typography>
                <br />
                <Typography>
                    Oricare dintre opțiunile menționate anterior poate fi activată prin selectarea
                    butonului corespunzător. Pentru a dezactiva opțiunea în lucru se va da iar un
                    click pe buton.
                </Typography>
                <h4>1.1 Ce este opțiunea Acasă?</h4>
                <Typography>
                    E un fapt bine stabilit că cititorul va fi sustras de conţinutul citibil al unei
                    pagini atunci când se uită la aşezarea în pagină. Scopul utilizării a Lorem
                    Ipsum, este acela că are o distribuţie a literelor mai mult sau mai puţin
                    normale, faţă de utilizarea a ceva de genul "Conţinut aici, conţinut acolo",
                    făcându-l să arate ca o engleză citibilă. Multe pachete de publicare pentru
                    calculator şi editoare de pagini web folosesc acum Lorem Ipsum ca model standard
                    de text, iar o cautare de "lorem ipsum" va rezulta în o mulţime de site-uri web
                    în dezvoltare. Pe parcursul anilor, diferite versiuni au evoluat, uneori din
                    intâmplare, uneori intenţionat (infiltrându-se elemente de umor sau altceva de
                    acest gen).
                </Typography>
                <h4>1.2 Cum pot Căuta pe harta?</h4>
                <Typography>
                    E un fapt bine stabilit că cititorul va fi sustras de conţinutul citibil al unei
                    pagini atunci când se uită la aşezarea în pagină. Scopul utilizării a Lorem
                    Ipsum, este acela că are o distribuţie a literelor mai mult sau mai puţin
                    normale, faţă de utilizarea a ceva de genul "Conţinut aici, conţinut acolo",
                    făcându-l să arate ca o engleză citibilă. Multe pachete de publicare pentru
                    calculator şi editoare de pagini web folosesc acum Lorem Ipsum ca model standard
                    de text, iar o cautare de "lorem ipsum" va rezulta în o mulţime de site-uri web
                    în dezvoltare. Pe parcursul anilor, diferite versiuni au evoluat, uneori din
                    intâmplare, uneori intenţionat (infiltrându-se elemente de umor sau altceva de
                    acest gen).
                </Typography>
                <h4>1.3 Ce face opțiunea Straturi?</h4>
                <Typography>
                    E un fapt bine stabilit că cititorul va fi sustras de conţinutul citibil al unei
                    pagini atunci când se uită la aşezarea în pagină. Scopul utilizării a Lorem
                    Ipsum, este acela că are o distribuţie a literelor mai mult sau mai puţin
                    normale, faţă de utilizarea a ceva de genul "Conţinut aici, conţinut acolo",
                    făcându-l să arate ca o engleză citibilă. Multe pachete de publicare pentru
                    calculator şi editoare de pagini web folosesc acum Lorem Ipsum ca model standard
                    de text, iar o cautare de "lorem ipsum" va rezulta în o mulţime de site-uri web
                    în dezvoltare. Pe parcursul anilor, diferite versiuni au evoluat, uneori din
                    intâmplare, uneori intenţionat (infiltrându-se elemente de umor sau altceva de
                    acest gen).
                </Typography>
                <h4>1.4 Cum se manipulează straturile</h4>
                <Typography>
                    E un fapt bine stabilit că cititorul va fi sustras de conţinutul citibil al unei
                    pagini atunci când se uită la aşezarea în pagină. Scopul utilizării a Lorem
                    Ipsum, este acela că are o distribuţie a literelor mai mult sau mai puţin
                    normale, faţă de utilizarea a ceva de genul "Conţinut aici, conţinut acolo",
                    făcându-l să arate ca o engleză citibilă. Multe pachete de publicare pentru
                    calculator şi editoare de pagini web folosesc acum Lorem Ipsum ca model standard
                    de text, iar o cautare de "lorem ipsum" va rezulta în o mulţime de site-uri web
                    în dezvoltare. Pe parcursul anilor, diferite versiuni au evoluat, uneori din
                    intâmplare, uneori intenţionat (infiltrându-se elemente de umor sau altceva de
                    acest gen).
                </Typography>
                <div id="alerte" className="manual-section">
                    <h4>1.5 Cum setez o alerta</h4>
                    <img src={alerteTutorial1} />
                    <p>
                        Pentru setarea unei alerte selectati o zona de pe harta, iar apoi apasati
                        butonul "<strong>Vezi Detalii Zona</strong>". In dialogul afisat veti gasi optiunea pentru
                        setarea alertei conform capturii de ecran de mai jos:
                    </p>
                    <img src={alerteTutorial2} />
                </div>
                <br />
            </DialogContent>
        </Dialog>
    );
}

export default ManualUtilizare;
