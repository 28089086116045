import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from "draft-js";

function parseJSON(inputString) {
    if (inputString) {
        try {
            return JSON.parse(inputString);
        } catch (e) {
            return inputString;
        }
    }
}

function getPlainText(text) {
    const parsedContent = parseJSON(text);
    let plainText = "";
    if (typeof parsedContent === "object") {
        const currentState = EditorState.createWithContent(convertFromRaw(parsedContent));
        plainText = currentState.getCurrentContent().getPlainText('\u0001');
    } else {
        plainText = parsedContent;
    }
    return plainText;
}

export { getPlainText };
