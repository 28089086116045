import * as React from "react";

import Heading from "./components/DashboardHeading";

import { Button, Container, Grid, Paper, Box, Chip } from "@mui/material/";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CircularProgress from "@mui/material/CircularProgress";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "white",
        color: theme.palette.common.black,
        borderBottom: "1px solid black",
        fontSize: "1rem"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "td, th": {
        border: 0,
    },
}));

function ExportDate() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [exportInProgress, setExportInProgress] = React.useState(false);
    const [rows, setRows] = React.useState([
        {
            date: "14/01/23",
            status: 1,
        },
        {
            date: "14/12/22",
            status: 1,
        },
        {
            date: "14/11/22",
            status: 1,
        },
        {
            date: "14/10/22",
            status: 1,
        },
        {
            date: "14/09/22",
            status: 1,
        },
        {
            date: "14/09/22",
            status: 0,
        },
        {
            date: "14/08/22",
            status: 1,
        },
        {
            date: "14/07/22",
            status: 1,
        },
        {
            date: "14/06/22",
            status: 1,
        },
        {
            date: "14/05/22",
            status: 1,
        },
    ]);
    const timer = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleExport = () => {
        setRows([
            {
                date: "14/02/23",
                status: 2,
            },
            ...rows,
        ]);
        setExportInProgress(true);

        timer.current = window.setTimeout(() => handleExportSuccess(), 2000);
    };

    const handleExportSuccess = () => {
        const tempRows = [...rows];
        tempRows[0].status = 1;
        setRows(tempRows);
        enqueueSnackbar("Datele au fost exportate cu succes!", { variant: "success" });
        setTipExport("all");
        setExportInProgress(false);
    };

    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const checkAll = (value) => {
        if (checked.length === categorii.length) {
            setChecked([]);
        } else {
            setChecked(categorii);
        }
    };

    const [tipExport, setTipExport] = React.useState("all");

    const handleChangeTipExport = (event) => {
        setTipExport(event.target.value);
    };

    let categorii = [1, 2, 3, 4, 5, 6];

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Heading>Export catre Observatorul Teritorial</Heading>
                        <Box sx={{ p: "1rem 0" }}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label" sx={{ pb: 1 }}>
                                    Optiuni Export
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={tipExport}
                                    onChange={handleChangeTipExport}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label="Toatele documentatiile de urbanism"
                                    />
                                    <FormControlLabel
                                        value="custom"
                                        control={<Radio />}
                                        label="Alege documentatii..."
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        {tipExport === "custom" && (
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                    maxHeight: "250px",
                                    overflow: "scroll",
                                    padding: 0,
                                    border: "1px solid",
                                    mb: 2,
                                    borderRadius: "4px",
                                }}
                            >
                                <ListItem key="all" disablePadding>
                                    <ListItemButton
                                        role={undefined}
                                        onClick={() => checkAll()}
                                        dense
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: 600,
                                            background: "#EEE",
                                        }}
                                    >
                                        <ListItemIcon sx={{ minWidth: 0 }}>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.length === categorii.length}
                                                indeterminate={
                                                    checked.length > 0 &&
                                                    checked.length < categorii.length
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ "aria-labelledby": "all" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id="all" primary="Selecteaza toate" />
                                    </ListItemButton>
                                </ListItem>
                                {categorii.map((value, index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton
                                                role={undefined}
                                                onClick={() => handleToggle(value)}
                                                dense
                                            >
                                                <ListItemIcon sx={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={`PUZ ${value}`}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}

                        <Button
                            size="large"
                            variant="contained"
                            disabled={exportInProgress}
                            onClick={handleExport}
                        >
                            Lanseaza Export Date
                            {exportInProgress && (
                                <CircularProgress
                                    size={32}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-16px",
                                        marginLeft: "-16px",
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TableContainer>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Istoric Export</StyledTableCell>
                                        <StyledTableCell align="right" sx={{ width: 65 }}>
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    width: 65,
                                                    textAlign: "center",
                                                }}
                                            >
                                                Status
                                            </span>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.date}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {
                                                    {
                                                        0: (
                                                            <Chip
                                                                variant="outlined"
                                                                color="warning"
                                                                label="Eroare"
                                                            />
                                                        ),
                                                        1: <Chip color="info" label="Succes" />,
                                                        2: <Chip label="Se proceseaza..." />,
                                                    }[row.status]
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ExportDate;
