import * as React from "react";

import { Button, Chip, Box } from "@mui/material/";

function TabelAutorizatii(props) {
    const [data, setData] = React.useState([]);

    return (
        <Box>
            {!data.length && (
                <p style={{ textAlign: "center", padding: "4rem 0" }}>
                    Nu aveti cereri depuse pentru autorizatii de construire.
                </p>
            )}
        </Box>
    );
}

export default TabelAutorizatii;
