import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { Link } from "react-router-dom";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PostAddIcon from "@mui/icons-material/PostAdd";
import GradingIcon from "@mui/icons-material/Grading";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Heading from "./components/DashboardHeading";
import CommentIcon from "@mui/icons-material/Comment";
import CancelIcon from "@mui/icons-material/Cancel";

import PDFSample from "./images/certificat.pdf";
import Spinner from "./components/Spinner";
import TrimiteMesaj from "./TrimiteMesaj";

import axios from "axios";

const certificateDefault = [
    {
        id: 234234324,
        createDate: 1674669525250,
        status: "draft",
        solicitant: {
            nume: "Popescu",
            prenume: "Ion",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie casa",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "89118",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {
            type: "1",
            booleanOptions: [
                {
                    lucrari1: false,
                },
                {
                    lucrari2: false,
                },
                {
                    lucrari3: true,
                },
                {
                    lucrari4: false,
                },
                {
                    lucrari5: false,
                },
                {
                    lucrari6: false,
                },
            ],
            textOptions: [
                {
                    descriere: "Text descriere suplimentara...",
                },
            ],
            uploadOptions: [],
        },
    },
    {
        id: 234234325,
        createDate: 1674369525250,
        status: "lucru",
        solicitant: {
            nume: "Popescu",
            prenume: "Ion",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Extindere constructie existenta",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "76487",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {
            type: "1",
            booleanOptions: [
                {
                    lucrari1: false,
                },
                {
                    lucrari2: false,
                },
                {
                    lucrari3: true,
                },
                {
                    lucrari4: false,
                },
                {
                    lucrari5: false,
                },
                {
                    lucrari6: false,
                },
            ],
            textOptions: [
                {
                    descriere: "Text descriere suplimentara...",
                },
            ],
            uploadOptions: [],
        },
    },
    {
        id: 234234326,
        createDate: 1674369525260,
        status: "autorizat",
        solicitant: {
            nume: "Popescu",
            prenume: "Maria",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie duplex",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Ion",
                prenume: "Popescu",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "54764",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
            {
                cad: "54763",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {
            type: "1",
            booleanOptions: [
                {
                    lucrari1: false,
                },
                {
                    lucrari2: false,
                },
                {
                    lucrari3: true,
                },
                {
                    lucrari4: false,
                },
                {
                    lucrari5: false,
                },
                {
                    lucrari6: false,
                },
            ],
            textOptions: [
                {
                    descriere: "Text descriere suplimentara...",
                },
            ],
            uploadOptions: [],
        },
    },
];

const datestring = function (d) {
    const date = new Date(d);
    const month = date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    return date.getDate() + "/" + month + "/" + date.getFullYear();
};

const getAmplasamente = function (amplasamente) {
    let tempArray = [];
    if (amplasamente)
        amplasamente.forEach((element) => {
            tempArray.push(element.cad);
        });

    return tempArray.join(", ");
};

const getSolicitant = function (solicitant) {
    let nume = "";
    if (solicitant) nume += solicitant.prenume + " " + solicitant.nume;
    return nume;
};

function TabelCertificate({ certificate, openComments, deleteCerere }) {
    return (
        <TableContainer>
            <Table
                size="small"
                sx={{
                    minWidth: 650,
                    "& .MuiTableCell-root": {
                        fontSize: "100%",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Nr. Inreg.</TableCell>
                        <TableCell>Data Cerere</TableCell>
                        <TableCell>Beneficiar</TableCell>
                        <TableCell>Obiectiv</TableCell>
                        <TableCell>Amplasamente</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certificate.map((item, index) => (
                        <TableRow key={item.id}>
                            <TableCell onClick={() => deleteCerere(index, item.id)}>
                                #1{item.id}
                            </TableCell>
                            <TableCell>{datestring(item.createDate)}</TableCell>
                            <TableCell>{getSolicitant(item.solicitant)}</TableCell>
                            <TableCell>{item.obiectiv}</TableCell>
                            <TableCell>{getAmplasamente(item.amplasamente)}</TableCell>
                            <TableCell align="right">
                                <Button
                                    color="inherit"
                                    endIcon={
                                        <CommentIcon
                                            sx={{
                                                color: [1, 2].includes(index)
                                                    ? "skyblue"
                                                    : "rgba(0,0,0,0.2)",
                                            }}
                                        />
                                    }
                                    sx={{ color: "#222 !important" }}
                                    disabled={![1, 2].includes(index)}
                                    onClick={openComments}
                                >
                                    <span>{[1, 2].includes(index) && 1}</span>
                                </Button>
                                {
                                    {
                                        draft: <Chip sx={{ mr: 1 }} label="DRAFT" />,
                                        depus: (
                                            <Chip sx={{ mr: 1 }} color="secondary" label="DEPUS" />
                                        ),
                                        lucru: (
                                            <Chip sx={{ mr: 1 }} color="warning" label="IN LUCRU" />
                                        ),
                                        autorizat: (
                                            <Chip
                                                sx={{ mr: 1 }}
                                                color="primary"
                                                label="AUTORIZAT"
                                            />
                                        ),
                                        respins: (
                                            <Chip
                                                variant="outlined"
                                                label="CERERE RESPINSA"
                                                size="small"
                                                sx={{ fontSize: "0.75rem" }}
                                                icon={<CancelIcon />}
                                            />
                                        ),
                                    }[item.status]
                                }
                                {
                                    {
                                        draft: (
                                            <React.Fragment>
                                                <IconButton
                                                    to="/dashboard/certificat"
                                                    component={Link}
                                                    color="primary"
                                                >
                                                    <GradingIcon />
                                                </IconButton>
                                                <IconButton disabled>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </React.Fragment>
                                        ),
                                        depus: (
                                            <React.Fragment>
                                                <IconButton
                                                    to="/dashboard/certificat"
                                                    component={Link}
                                                    color="primary"
                                                >
                                                    <GradingIcon />
                                                </IconButton>
                                                <IconButton disabled>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </React.Fragment>
                                        ),
                                        lucru: (
                                            <React.Fragment>
                                                <IconButton
                                                    to="/dashboard/certificat"
                                                    component={Link}
                                                    color="primary"
                                                >
                                                    <GradingIcon />
                                                </IconButton>
                                                <IconButton disabled>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </React.Fragment>
                                        ),
                                        autorizat: (
                                            <React.Fragment>
                                                <IconButton
                                                    to="/dashboard/certificat"
                                                    component={Link}
                                                    color="primary"
                                                    disabled
                                                >
                                                    <GradingIcon />
                                                </IconButton>
                                                <IconButton href={PDFSample} target="_blank">
                                                    <PictureAsPdfIcon sx={{ color: "red" }} />
                                                </IconButton>
                                            </React.Fragment>
                                        ),
                                        respins: null,
                                    }[item.status]
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function Certificate() {
    const [certificate, setCertificate] = React.useState([]);
    const [openTrimiteMesaj, setOpenTrimiteMesaj] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            const response = await axios.get("/api/certificate").catch(function (error) {
                console.log(error.toJSON());
            });

            if (response) {
                setCertificate(response.data.reverse());
            } else {
                setCertificate(certificateDefault);
            }
        };
        getData();
    }, []);

    const deleteCerere = (index, id) => {
        const deleteEntry = async () => {
            const response = await axios.delete("/api/certificate/" + id).catch(function (error) {
                console.log(error.toJSON());
            });
        };

        if (window.confirm("Sterge cererea?") == true) {
            deleteEntry();
        }
    };

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ p: 3 }}>
                <Heading sx={{ mb: 2 }}>Cererile Mele</Heading>

                {!certificate.length && (
                    <Box
                        sx={{
                            display: "flex",
                            minHeight: 160,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Spinner />
                    </Box>
                )}
                {certificate.length > 0 && (
                    <TabelCertificate
                        certificate={certificate}
                        openComments={() => setOpenTrimiteMesaj(true)}
                        deleteCerere={deleteCerere}
                    />
                )}
                <Button
                    endIcon={<PostAddIcon />}
                    variant="contained"
                    to="/dashboard/certificat"
                    component={Link}
                    sx={{ mt: 3 }}
                >
                    Solicita un Certificat Nou
                </Button>
            </Paper>
            {openTrimiteMesaj && (
                <TrimiteMesaj
                    handleModalClose={() => setOpenTrimiteMesaj(false)}
                />
            )}
        </Container>
    );
}

export default Certificate;
