import React from "react";
import { EditorState, convertToRaw, convertFromRaw, RichUtils, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const parseJSON = (inputString) => {
    if (inputString) {
        try {
            return JSON.parse(inputString);
        } catch (e) {
            return inputString;
        }
    }
};

const content =
    '{"blocks":[{"key":"7jb7u","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

const minimalContent = {
    blocks: [
        {
            text: "Initialized from content state.",
        },
        {
            text: "Initialized from content state.",
        },
    ],
    entityMap: {},
};

function DraftEditor({ defaultContent, defaultType }) {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const [hasFocus, setHasFocus] = React.useState(false);
    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
    // );

    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createWithContent(convertFromRaw(minimalContent))
    // );

    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createWithContent(ContentState.createFromText("Hello World"))
    // );

    React.useEffect(() => {
        if (!editorState) return;
        // console.log(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    React.useEffect(() => {
        if (defaultType !== "unordered-list") {
            const contentUnorderedList = {
                blocks: [
                    {
                        key: "637gr",
                        text: "",
                        type: "unordered-list-item",
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                    },
                ],
                entityMap: {},
            };
            setEditorState(EditorState.createWithContent(convertFromRaw(contentUnorderedList)));
            // RichUtils.toggleBlockType(editorState, "unordered-list-item");
        }
        if (defaultContent)
            setEditorState(EditorState.createWithContent(convertFromRaw(defaultContent)));
    }, []);

    return (
        <div>
            <Editor
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                editorState={editorState}
                wrapperClassName={hasFocus ? "draft-wrapper draft-wrapper-custom focused" : "draft-wrapper draft-wrapper-custom"}
                editorClassName="draft-editor"
                toolbarClassName="draft-toolbar"
                onEditorStateChange={setEditorState}
                // placeholder="Alte informatii sau completari..."
                stripPastedStyles={true}
                // editorRef={setEditorReference}
                // onFocus={doSomething}
                // toolbarHidden={true}
                toolbar={{
                    options: ["inline", "list", "remove", "history"],
                    inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline", "superscript", "subscript"],
                    },
                    list: {
                        options: ["unordered"],
                        // options: ["unordered", "ordered"],
                    },
                }}
            />
        </div>
    );
}

export default DraftEditor;
