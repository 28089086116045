import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Grid } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const defaultData = {
    nume: "",
    prenume: "",
    email: "",
    telefon: "",
    adresa: "",
    cnp: "",
    cif: "",
    companie: "",
    tip: "fizica",
};

function AdaugaBeneficiar({ open, handleModalClose, handleBeneficiarSave, editData }) {
    const [formData, setFormData] = React.useState({});
    const [formErrors, setFormErrors] = React.useState({});

    const inputsToValidate = {
        fizica: ["nume", "prenume", "cnp", "email", "telefon", "adresa"],
        juridica: ["companie", "cif", "email", "telefon", "adresa"],
    };

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const handleTipChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // reset errors
        setFormErrors({});
    };

    const validateInput = (name, value) => {
        const validInput = !!value.trim();
        if (!validInput) {
            setFormErrors({ ...formErrors, [name]: true });
        } else {
            setFormErrors({ ...formErrors, [name]: false });
        }
    };

    const isValidForm = (inputs) => {
        let isValid = true;
        let errors = {};
        inputs.forEach((name) => {
            const validInput = !!formData[name].trim();
            if (!validInput) {
                errors[name] = true;
                isValid = false;
            } else {
                errors[name] = false;
            }
        });
        setFormErrors({ ...formErrors, ...errors });
        return isValid;
    };

    const handleSubmit = () => {
        if (isValidForm(inputsToValidate[formData.tip])) {
            handleBeneficiarSave(formData);
            // setFormData(defaultData);
        }
    };

    React.useEffect(() => {
        setFormData(editData || defaultData);
    }, []);

    return (
        <Dialog
            open={open}
            scroll="body"
            onBackdropClick={handleModalClose}
            onClose={handleModalClose}
            maxWidth="lg"
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Adauga beneficiar</span>
                <IconButton color="default" component="span" onClick={handleModalClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box sx={{ mb: 1 }}>
                    <RadioGroup name="tip" value={formData.tip} onChange={handleTipChange} row>
                        <FormControlLabel
                            value="fizica"
                            control={<Radio />}
                            label="Persoana Fizica"
                        />
                        <FormControlLabel
                            value="juridica"
                            control={<Radio />}
                            label="Persoana Juridica"
                        />
                    </RadioGroup>
                </Box>
                <Box
                    sx={{
                        maxWidth: "81.6ch",
                    }}
                >
                    <Grid container spacing={2}>
                        {
                            {
                                fizica: (
                                    <React.Fragment>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="nume"
                                                value={formData.nume}
                                                onChange={handleFormChange}
                                                label="Nume"
                                                error={formErrors.nume}
                                                helperText={
                                                    formErrors.nume ? "Camp obligatoriu" : ""
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="prenume"
                                                value={formData.prenume}
                                                onChange={handleFormChange}
                                                label="Prenume"
                                                error={formErrors.prenume}
                                                helperText={
                                                    formErrors.prenume ? "Camp obligatoriu" : ""
                                                }
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="cnp"
                                                value={formData.cnp}
                                                onChange={handleFormChange}
                                                label="CNP"
                                                error={formErrors.cnp}
                                                helperText={
                                                    formErrors.cnp ? "Camp obligatoriu" : ""
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ),
                                juridica: (
                                    <React.Fragment>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="companie"
                                                value={formData.companie}
                                                onChange={handleFormChange}
                                                label="Denumire companie"
                                                error={formErrors.companie}
                                                helperText={
                                                    formErrors.companie ? "Camp obligatoriu" : ""
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="cif"
                                                value={formData.cif}
                                                onChange={handleFormChange}
                                                label="CIF"
                                                error={formErrors.cif}
                                                helperText={
                                                    formErrors.cif ? "Camp obligatoriu" : ""
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ),
                            }[formData.tip]
                        }
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="email"
                                value={formData.email}
                                onChange={handleFormChange}
                                label="Adresa Email"
                                error={formErrors.email}
                                helperText={formErrors.email ? "Camp obligatoriu" : ""}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="telefon"
                                value={formData.telefon}
                                onChange={handleFormChange}
                                label="Telefon"
                                error={formErrors.telefon}
                                helperText={formErrors.telefon ? "Camp obligatoriu" : ""}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                name="adresa"
                                value={formData.adresa}
                                onChange={handleFormChange}
                                label="Adresa"
                                error={formErrors.adresa}
                                helperText={formErrors.adresa ? "Camp obligatoriu" : ""}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    m: 3,
                    mt: 1,
                    p: 0,
                }}
            >
                <Button onClick={handleModalClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSubmit}>
                    Salveaza beneficarul
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaBeneficiar;
