import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";

import PubSub from "pubsub-js";
import { fontWeight } from "@mui/system";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        // top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader
                key={dataSet.key}
                component="div"
                style={{
                    ...inlineStyle,
                    fontWeight: 800,
                    background: "#eee",
                    color: "#000",
                    textTransform: "uppercase",
                    borderBottom: "1px solid #e6e6e6",
                    // padding: "4px"
                }}
            >
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={{ ...inlineStyle }}>
            {dataSet[1].label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

export default function AmplasamentSearch({ data, value, handleHighlightChange }) {
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        if (data) setOptions(data);
    }, [data]);

    return (
        <React.Fragment>
            <Autocomplete
                sx={{}}
                disableListWrap
                PopperComponent={(props) => (
                    <StyledPopper
                        {...props}
                        modifiers={[
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 5],
                                },
                            },
                        ]}
                    ></StyledPopper>
                )}
                ListboxComponent={ListboxComponent}
                options={options}
                // groupBy={(option) => option.group}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => <TextField {...params} placeholder="Numar cadastral" value={value} autoFocus />}
                renderOption={(props, option) => [props, option]}
                // TODO: Post React 18 update - validate this conversion, look like a hidden bug
                renderGroup={(params) => params}
                onInputChange={handleHighlightChange}
                forcePopupIcon={false}
                autoHighlight={true}
                selectOnFocus={true}
                disableClearable
                // freeSolo
            />
        </React.Fragment>
    );
}
