import React, { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArticleIcon from "@mui/icons-material/Article";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CalculateIcon from "@mui/icons-material/Calculate";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CampaignIcon from "@mui/icons-material/Campaign";
import DataObjectIcon from "@mui/icons-material/DataObject";
import BugReportIcon from "@mui/icons-material/BugReport";

import Divider from "@mui/material/Divider";

import { Link, useMatch } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

function UserOptions() {
    return (
        <React.Fragment>
            <ListItemButton
                to="/dashboard/certificate"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/certificate" })}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Cereri" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/sesizarile-mele"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/sesizarile-mele" })}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText primary="Sesizari" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/alerte"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/alerte" })}
            >
                <ListItemIcon>
                    <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Alerte" />
                <Chip label="3" size="small" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/notificari"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/notificari" })}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notificari" />
                <Chip label="2" size="small" />
            </ListItemButton>
        </React.Fragment>
    );
}

function PrimarOptions() {
    return (
        <React.Fragment>
            <ListItemButton
                to="/dashboard/cereri"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/cereri" })}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Cereri" />
                <Chip label="3" size="small" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/amenzi"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/amenzi" })}
            >
                <ListItemIcon>
                    <LocalPoliceIcon />
                </ListItemIcon>
                <ListItemText primary="Amenzi" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/sesizari"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/sesizari" })}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText primary="Sesizari" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/registratura"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/registratura" })}
            >
                <ListItemIcon>
                    <CalculateIcon />
                </ListItemIcon>
                <ListItemText primary="Registratura" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/gestionare-conturi"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/gestionare-conturi" })}
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Gestionare Conturi" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/export"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/export" })}
            >
                <ListItemIcon>
                    <UnarchiveIcon />
                </ListItemIcon>
                <ListItemText primary="Export Date" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/notificari"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/notificari" })}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notificari" />
                <Chip label="2" size="small" />
            </ListItemButton>
        </React.Fragment>
    );
}

function EditorOptions() {
    return (
        <React.Fragment>
            <ListItemButton
                to="/dashboard/cereri"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/cereri" })}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Cereri" />
                <Chip label="3" size="small" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/sesizari"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/sesizari" })}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText primary="Sesizari" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/registratura"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/registratura" })}
            >
                <ListItemIcon>
                    <CalculateIcon />
                </ListItemIcon>
                <ListItemText primary="Registratura" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/import"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/import" })}
            >
                <ListItemIcon>
                    <DataObjectIcon />
                </ListItemIcon>
                <ListItemText primary="Import Date" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/tichete"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/tichete" })}
            >
                <ListItemIcon>
                    <BugReportIcon />
                </ListItemIcon>
                <ListItemText primary="Tichete" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/notificari"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/notificari" })}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notificari" />
                <Chip label="2" size="small" />
            </ListItemButton>
        </React.Fragment>
    );
}

function DashboardSidebarItems() {
    const userData = useSelector((state) => state.auth.userData);
    const [userRole, setUserRole] = React.useState(null);

    React.useEffect(() => {
        if (userData) setUserRole(userData.role);
    }, [userData]);
    
    return (
        <React.Fragment>
            <ListItemButton
                to="/dashboard"
                component={Link}
                selected={!!useMatch({ path: "/dashboard" })}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>

            {userRole === "USER" && <UserOptions />}

            {["PRIMAR"].includes(userRole) && <PrimarOptions />}

            {["EDITOR"].includes(userRole) && <EditorOptions />}

            <Divider sx={{ m: 2 }} />

            <ListItemButton to="/manual-utilizare" component={Link}>
                <ListItemIcon>
                    <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Manual utilizare" />
            </ListItemButton>
            <Divider sx={{ m: 2 }} />
            <ListItemButton
                to="/dashboard/cont"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/cont" })}
            >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Contul meu" />
            </ListItemButton>
            <ListItemButton
                to="/dashboard/siguranta"
                component={Link}
                selected={!!useMatch({ path: "/dashboard/siguranta" })}
            >
                <ListItemIcon>
                    <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary="Setari siguranta" />
            </ListItemButton>
            <ListItemButton to="/logout" component={Link}>
                <ListItemIcon>
                    <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary="Deconectare" />
            </ListItemButton>
            <ListSubheader
                component={Link}
                to="/harta-sesizari"
                sx={{ fontWeight: 300, fontSize: "0.8rem", textDecoration: "none" }}
            >
                © 2022 CUBITRON S.R.L.
            </ListSubheader>
        </React.Fragment>
    );
}

export default DashboardSidebarItems;
