import { useState, useEffect, Fragment } from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import cityImg from "./images/city.jpg";
import cityImg2 from "./images/city2.jpg";

import { getStatusColor, getStatusLabel, getCategoryIcon, getCategoryLabel } from "./utils/sesizariUtils";

const SesizareCard = ({ sesizare }) => {
    const [childKey, setKey] = useState(0);

    useEffect(() => {
        setKey(childKey + 1);
    }, [sesizare]);

    if (!sesizare) return null;

    return (
        <Paper
            sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 1000,
                maxWidth: 320,
                minWidth: 180,
                maxHeight: "calc(100vh - 78px)",
                overflow: "auto",
                fontSize: "1rem",
                p: 2,
            }}
        >
            <Box sx={{ mb: 1.5, fontWeight: 600 }} key={childKey}>
                <i className={"fa-solid " + getCategoryIcon(sesizare.properties.category)}></i>
                <Box ml={1} component="span">
                    {getCategoryLabel(sesizare.properties.category)}
                </Box>
            </Box>
            <Divider sx={{ mb: 1.5, mt: 1 }} />
            <Box mb={1}>
                Data sesizare: <strong>02.11.2022</strong>
            </Box>
            <Box mb={1}>
                Stadiu sesizare:{" "}
                <Chip
                    label={getStatusLabel(sesizare.properties.status)}
                    sx={{
                        background: getStatusColor(sesizare.properties.status),
                        color: "#FFF",
                        height: "28px",
                        textTransform: "uppercase",
                        fontWeight: 500
                    }}
                />
            </Box>
            <Box mb={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Box>

            {sesizare.properties.status === "depus" && (
                <Fragment>
                    <Box
                        mb={1}
                        sx={{ textTransform: "uppercase", fontWeight: 600, fontSize: "85%" }}
                    >
                        Atasamente:
                    </Box>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            "& img": {
                                maxWidth: 120,
                                borderRadius: "4px",
                            },
                        }}
                    >
                        <img src={cityImg} />

                        <img src={cityImg2} />
                    </Stack>
                </Fragment>
            )}
        </Paper>
    );
};

export default SesizareCard;
