import * as React from "react";

import Heading from "./components/DashboardHeading";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { Table, TableBody, TableCell, TableHead, TableRow, Chip } from "@mui/material/";
import { Button, Container, Grid, Paper } from "@mui/material/";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import LockIcon from "@mui/icons-material/Lock";

import AdaugaCont from "./AdaugaCont";
import StergeCont from "./StergeCont";
import Roluri from "./DashboardRoluri";
import Grupuri from "./DashboardGrupuri";

import { useSelector, useDispatch } from "react-redux";
import SchimbaRol from "./SchimbaRol";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

// Generate Order Data
function createData(nume, prenume, email, telefon, rol, activ) {
    return { nume, prenume, email, telefon, rol, activ };
}

function Conturi() {
    const jwt = useSelector((state) => state.auth.jwt);

    const userData = useSelector((state) => state.auth.userData);

    const [rows, setRows] = React.useState([
        createData("Popescu", "Ion", "ion@primarie.ro", "0728 500 222", "Administrator", true),
        createData("Toma", "Mihai", "mihai@primarie.ro", "0728 392 333", "Primar", true),
        createData("Ionescu", "Georgiana", "georgiana@orasul.ro", "0780 400 300", "Editor", true),
        createData("Pop", "Adela", "andreea.pop@gmail.ro", "0780 400 300", "Secretar", true),
        createData("Marc", "George", "george@orasul.ro", "0760 200 560", "Editor", false),
    ]);
    const [adaugaContVisibility, setAdaugaContVisibility] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(null);
    const [deleteIndex, setDeleteIndex] = React.useState(null);

    const [schimbaRol, setSchimbaRol] = React.useState(null);

    const [conturi, setConturi] = React.useState(null);
    const [roluri, setRoluri] = React.useState(null);

    const [activeTab, setActiveTab] = React.useState("conturi");

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const promptAdd = () => {
        setAdaugaContVisibility(true);
    };

    const promptEdit = (index) => {
        setEditIndex(index);
        setAdaugaContVisibility(true);
    };

    const promptDelete = (index) => {
        setDeleteIndex(index);
    };

    const stergeCont = (isConfirmed) => {
        if (isConfirmed === true) setRows(rows.filter((row, index) => index !== deleteIndex));
        setDeleteIndex(null);
    };

    const proceseazaCont = (data) => {
        if (data) {
            if (!editIndex) {
                let contNou = createData(
                    data.nume || "",
                    data.prenume || "",
                    data.email || "",
                    data.telefon || "",
                    data.rol
                );
                setRows([...rows, contNou]);
            } else {
                const updatedArray = [...rows];
                updatedArray[editIndex] = data;
                setRows(updatedArray);
            }
        }
        setEditIndex(null);
        setAdaugaContVisibility(false);
    };

    React.useEffect(() => {
        if (userData && userData.originalRole && userData.originalRole.type === "superuser")
            getAccounts()
                .then((response) => {
                    if (response) {
                        setConturi(response);
                        console.log(response);
                        // updateUser();
                        getRoles().then((response) => {
                            if (response && response.roles) {
                                setRoluri(response.roles);
                            }
                        });
                    }
                })
                .catch((error) => {
                    //
                });
    }, [userData]);

    const handleSchimbaRol = (newRole) => {
        if (newRole) {
            updateUser(schimbaRol.id, newRole);

            getAccounts()
                .then((response) => {
                    if (response) {
                        setConturi(response);
                    }
                })
                .catch((error) => {
                    //
                });
        }

        setSchimbaRol(null);
    };

    async function updateUser(userId, newRole) {
        try {
            const response = await fetch(API_URL + `users/` + userId, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                    role: newRole,
                }),
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    async function getRoles() {
        try {
            const response = await fetch(API_URL + `users-permissions/roles`, {
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    async function getAccounts(data) {
        try {
            const response = await fetch(API_URL + `users?populate=role`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Box
                sx={{
                    width: "100%",
                    mb: 2,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    "& .MuiTab-root": { fontSize: "1rem" },
                }}
            >
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="conturi" label={<Box>Gestionare Conturi</Box>}></Tab>
                    <Tab value="roluri" label={<Box>Roluri Utilizatori</Box>} />
                    <Tab value="grupuri" label={<Box>Grupuri de Lucru</Box>} />
                </Tabs>
            </Box>
            <Grid container spacing={3}>
                {activeTab === "conturi" && (
                    <Grid item xs={12}>
                        {!conturi && (
                            <Paper sx={{ p: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nume</TableCell>
                                            <TableCell>Adresa Email</TableCell>
                                            <TableCell>Telefon</TableCell>
                                            <TableCell>Rol</TableCell>
                                            <TableCell>Cont Activ</TableCell>
                                            <TableCell>Operatiuni</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={
                                                    !row.activ
                                                        ? {
                                                              background: "#EEE",
                                                              "& .MuiTableCell-root": {
                                                                  color: "#666",
                                                              },
                                                          }
                                                        : {}
                                                }
                                            >
                                                <TableCell>
                                                    {row.prenume} {row.nume}
                                                </TableCell>
                                                <TableCell>
                                                    <a href="mailto:">{row.email}</a>
                                                </TableCell>
                                                <TableCell>{row.telefon}</TableCell>
                                                <TableCell>{row.rol}</TableCell>
                                                <TableCell>{row.activ ? "DA" : "NU"}</TableCell>
                                                <TableCell sx={{}}>
                                                    {row.rol !== "Administrator" ? (
                                                        <React.Fragment>
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => promptEdit(index)}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => promptDelete(index)}
                                                            >
                                                                <DeleteForeverIcon fontSize="small" />
                                                            </IconButton>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <IconButton disabled>
                                                                <LockIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton disabled>
                                                                <BlockIcon fontSize="small" />
                                                            </IconButton>
                                                        </React.Fragment>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button
                                    variant="contained"
                                    startIcon={<PersonAddAltIcon />}
                                    onClick={promptAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Adauga Cont Nou
                                </Button>
                            </Paper>
                        )}

                        {conturi && (
                            <Paper sx={{ p: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nume</TableCell>
                                            <TableCell>Adresa Email</TableCell>
                                            <TableCell>Telefon</TableCell>
                                            <TableCell>
                                                <IconButton color="primary" sx={{ opacity: 0 }}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                Rol
                                            </TableCell>
                                            <TableCell>Cont Activ</TableCell>
                                            <TableCell>Operatiuni</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {conturi.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={
                                                    row.role.type === "superuser"
                                                        ? {
                                                              background: "#EEE",
                                                              "& .MuiTableCell-root": {
                                                                  color: "#666",
                                                              },
                                                          }
                                                        : {}
                                                }
                                            >
                                                <TableCell>{row.fullname}</TableCell>
                                                <TableCell>
                                                    <a
                                                        href="mailto:"
                                                        style={
                                                            row.role.type === "superuser"
                                                                ? {
                                                                      color: "#666",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {row.email}
                                                    </a>
                                                </TableCell>
                                                <TableCell>
                                                    {row.telefon || "---- --- ---"}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => setSchimbaRol(row)}
                                                        disabled={row.role.type === "superuser"}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    {row.role.name}
                                                </TableCell>
                                                <TableCell>{row.confirmed ? "DA" : "NU"}</TableCell>
                                                <TableCell sx={{}}>
                                                    <React.Fragment>
                                                        <IconButton disabled>
                                                            <LockIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton disabled>
                                                            <BlockIcon fontSize="small" />
                                                        </IconButton>
                                                    </React.Fragment>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button
                                    variant="contained"
                                    startIcon={<PersonAddAltIcon />}
                                    onClick={promptAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Adauga Cont Nou
                                </Button>
                            </Paper>
                        )}
                    </Grid>
                )}

                {activeTab === "roluri" && <Roluri/>}
                {activeTab === "grupuri" && <Grupuri/>}
            </Grid>
            {adaugaContVisibility && (
                <AdaugaCont handleModalClose={proceseazaCont} cont={editIndex && rows[editIndex]} />
            )}
            {deleteIndex && <StergeCont handleClose={stergeCont} cont={rows[deleteIndex]} />}

            {schimbaRol && (
                <SchimbaRol handleModalClose={handleSchimbaRol} cont={schimbaRol} roluri={roluri} />
            )}
        </Container>
    );
}

export default function DashboardConturi() {
    return <Conturi />;
}
