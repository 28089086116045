import * as React from "react";
import {
    Button,
    Box,
    Radio,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    InputLabel,
    Stack,
    Avatar,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Paper,
    Grid,
    Container,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import BusinessIcon from "@mui/icons-material/Business";
import Heading from "./components/DashboardHeading";

import { blue, green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { Link } from "react-router-dom";

function CustomCard(props) {
    return (
        <Card
            sx={{
                width: "100%",
                "& .MuiCardHeader-title": {
                    fontSize: 16,
                },
                p: 1,
            }}
        >
            {props.children}
        </Card>
    );
}

function MainComponent() {
    const [pretSemnatura, setPretSemnatura] = React.useState("10");

    const handleChange = (event) => {
        setPretSemnatura(event.target.value);
    };

    React.useEffect(() => {
        document.getElementById("dashboard-scroll").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    });

    return (
        <Container maxWidth={false} sx={{ mb: 3 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <BusinessIcon sx={{ mb: "-3px", mr: 0.75 }} />{" "}
                <span>Cerere pentru emiterea certificatului de urbanism</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Paper sx={{ p: 3, mb: 3, mt: 5 }}>
                    <Heading>Alege modalitatea de semnare</Heading>
                    <p>
                        Pentru depunerea cererii online aceasta va fi semnată digital prin semnătură
                        digitală. Procesul de semnare se poate face de la distanță, iar pentru
                        aceasta aveți nevoie de o semnătură digitală.
                    </p>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "40ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControlLabel
                            value="female"
                            control={<Radio defaultChecked />}
                            label="Semnatura Online"
                        />
                        <FormControlLabel
                            value="disabled"
                            disabled
                            control={<Radio />}
                            label="Semnatura Offline"
                        />
                    </Box>

                    <h4>
                        Semnaturi digitale necesare: <span style={{ color: blue[700] }}>1</span>
                    </h4>
                    <p style={{ fontSize: 13, marginTop: "-0.5rem" }}>
                        Costul acestora va fi inclus în total.
                    </p>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Pret Semnatura</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pretSemnatura}
                            label="Pret Semnatura"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Pay As You Go - 1 RON</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>

                <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                    <CustomCard>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                                    <CheckIcon />
                                </Avatar>
                            }
                            title="Modalitate de semnare"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Semnătură electronică la distanță</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Vlad Popescu - 0785123433
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Pay as you go
                            </Typography>
                        </CardContent>
                    </CustomCard>
                    <CustomCard>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                                    <CheckIcon />
                                </Avatar>
                            }
                            title="Detalii cerere"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Obiectivul cererii</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Certificat Urbanism
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Solicitant</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Vlad Popescu - Persoană fizică
                            </Typography>
                        </CardContent>
                    </CustomCard>
                    <CustomCard>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                                    <CheckIcon />
                                </Avatar>
                            }
                            title="Modalitate de plata"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Card online</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                După inițierea plății, vei fi redirecționat către procesatorul de
                                plăți pentru a efectua plata.
                            </Typography>
                        </CardContent>
                    </CustomCard>
                </Stack>

                <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                    <CustomCard>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                                    <CheckIcon />
                                </Avatar>
                            }
                            title="Desfasurator"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>1 x Taxă Certificat Urbanism (Aiton)</td>
                                            <td align="right">17 RON</td>
                                        </tr>
                                        <tr>
                                            <td>1 x Taxă Aviz Primar (Aiton)</td>
                                            <td align="right">15 RON</td>
                                        </tr>
                                        <tr>
                                            <td>1 x Semnatură digitală</td>
                                            <td align="right">1 RON</td>
                                        </tr>
                                        <br />
                                        <tr style={{ fontWeight: "bold" }}>
                                            <td>Total taxe și avize</td>
                                            <td align="right">32 RON</td>
                                        </tr>
                                        <tr style={{ fontWeight: "bold" }}>
                                            <td>Total costuri certificate digitale</td>
                                            <td align="right">1 RON</td>
                                        </tr>
                                        <br />
                                        <tr style={{ fontWeight: "bold" }}>
                                            <td style={{ borderTop: "1px solid" }}>
                                                <br />
                                                Total de plata
                                            </td>
                                            <td style={{ borderTop: "1px solid" }} align="right">
                                                <br />
                                                33 RON
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </CardContent>
                    </CustomCard>
                </Stack>

                <Button
                    variant="contained"
                    size="large"
                    color="inherit"
                    sx={{ marginRight: "1rem" }}
                    to="/dashboard/certificat"
                    component={Link}
                >
                    Pasul anterior
                </Button>
                <Button variant="contained" size="large" to="/dashboard/certificat-plata" component={Link}>
                    Plateste
                </Button>
            </Box>
        </Container>
    );
}

export default function DashboardCertificatSumar() {
    return <MainComponent />;
}
