import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import { Button, Box, Alert, Stack } from "@mui/material";

import UploadIcon from "@mui/icons-material/Upload";

import { gpx } from "@tmcw/togeojson";

import PrevizualizareHarta from "./PrevizualizareHarta";

import { useSelector, useDispatch } from "react-redux";
import { addGPS, deleteGPS } from "./redux/gps.slice";

import { useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";

function ImportGPS() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const gpsData = useSelector((state) => state.gps.data);

    const [xml, setXml] = React.useState("");
    const [geojson, setGeojson] = React.useState("");
    const [fileName, setFilename] = React.useState("");

    function handleUpload(e) {
        console.log("handle file");
        const file = e.target.files[0];
        // You could insert a check here to ensure proper file type
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = function () {
            setXml(reader.result);
            setGeojson(
                JSON.stringify(
                    gpx(new DOMParser().parseFromString(reader.result, "text/xml")),
                    null,
                    4
                )
            );
        };
        setFilename(file.name);
    }

    function handleCancel(e) {
        setXml("");
        setGeojson("");
        setFilename("");
    }

    function handleSave(e) {
        const date = Date.now();

        dispatch(
            addGPS({
                id: date,
                created: date,
                name: fileName,
                jsonData: JSON.parse(geojson),
            })
        );

        setXml("");
        setGeojson("");
        setFilename("");

        enqueueSnackbar("Datele au fost importate cu succes", {
            variant: "info",
        });
    }

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Paper
                sx={{
                    p: 3,
                    "& .MuiButton-root": { mt: 2 },
                }}
            >
                <Heading>Import date GPS</Heading>
                <p>Acest modul permite importul datelor în format GPX/GPS Exchange Format</p>

                <Button
                    variant="contained"
                    component="label"
                    size="large"
                    startIcon={<UploadIcon />}
                    disabled={!!xml}
                >
                    {!xml && <input hidden type="file" onChange={handleUpload} />}
                    Incarca fisier GPX/GPS
                </Button>

                {xml && (
                    <React.Fragment>
                        <p style={{ marginTop: "2rem" }}>
                            <TextField
                                label="GPS (XML DATA)"
                                type="text"
                                name="xml"
                                value={xml}
                                multiline
                                rows={5}
                                fullWidth={true}
                            />
                        </p>
                        <p>
                            <TextField
                                label="GeoJSON"
                                type="text"
                                name="xml"
                                value={geojson}
                                multiline
                                rows={5}
                                fullWidth={true}
                            />
                        </p>

                        <PrevizualizareHarta
                            parcelData={geojson ? JSON.parse(geojson) : undefined}
                        />

                        <Button
                            variant="contained"
                            size="large"
                            color="inherit"
                            sx={{ mr: 2 }}
                            onClick={handleCancel}
                        >
                            Anuleaza
                        </Button>

                        <Button variant="contained" size="large" onClick={handleSave}>
                            Salveaza Datele
                        </Button>
                    </React.Fragment>
                )}

                <hr style={{ marginTop: "2rem" }} />

                <TableContainer>
                    {!gpsData.length && (
                        <p
                            style={{
                                padding: "1rem",
                                background: "#F6F6F6",
                                borderRadius: 4,
                                color: "#666",
                                margin: "1rem 0 0 0",
                            }}
                        >
                            Nu aveti date salvate.
                        </p>
                    )}
                    {gpsData.length > 0 && (
                        <Table
                            size="medium"
                            sx={{
                                minWidth: 650,
                                "& .MuiTableCell-root": {
                                    fontSize: "100%",
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Data Import</TableCell>
                                    <TableCell>Denumire</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gpsData.map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            {new Date(item.created).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton color="primary">
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => dispatch(deleteGPS({ id: item.id }))}
                                            >
                                                <DeleteForeverIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
            </Paper>
            <br />
        </Container>
    );
}

export default function DashboardImport() {
    return <ImportGPS />;
}
