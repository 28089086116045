import * as React from "react";

import Container from "@mui/material/Container";

import Notificari from "./Notificari";
import Heading from "./components/DashboardHeading";

function DashboardNotificari() {
    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Heading>Notificari</Heading>
            <Notificari showAllData={true} />
        </Container>
    );
}

export default DashboardNotificari;
