function inBounds(point, bounds) {
    let lng = (point.lng - bounds._northEast.lng) * (point.lng - bounds._southWest.lng) < 0;
    let lat = (point.lat - bounds._northEast.lat) * (point.lat - bounds._southWest.lat) < 0;
    return lng && lat;
}

function containsPolygon(bounds, poly) {
    for (let i = 0; i < poly.length; i++) {
        const cluster = poly[i];
        for (let j = 0; j < cluster.length; j++) {
            const coord = cluster[j];
            // bounds.contains({ lng: coord[0], lat: coord[1] })
            if (inBounds({ lng: coord[0], lat: coord[1] }, bounds)) {
                return true;
            }
        }
    }
    return false;
}

function canFitLabel(map, polygon, minSize, isGreedy) {
    // Stolen from here:
    // https://github.com/oliverroick/Leaflet.Deflate/blob/master/src/L.Deflate.js

    const bounds = polygon._bounds;

    const northEastPixels = map.project(bounds._northEast, map.getZoom());
    const southWestPixels = map.project(bounds._southWest, map.getZoom());

    const width = Math.abs(northEastPixels.x - southWestPixels.x);
    const height = Math.abs(southWestPixels.y - northEastPixels.y);

    if (isGreedy) {
        return height > minSize || width > minSize;
    }
    return height > minSize && width > minSize;
}

function layerToPolygonObject(layer) {
    return layer.feature.geometry.coordinates[0];
}

export { canFitLabel, containsPolygon, layerToPolygonObject };
