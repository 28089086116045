import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Checkbox } from "@mui/material";

const defaultData = {
    nume: "",
    prenume: "",
    email: "",
    telefon: "",
    rol: "Editor",
};

function AdaugaCont(props) {
    const [formData, setFormData] = React.useState({});
    const [formErrors, setFormErrors] = React.useState({});

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const validateInput = (name, value) => {
        const validInput = !!value.trim();
        if (!validInput) {
            setFormErrors({ ...formErrors, [name]: true });
        } else {
            setFormErrors({ ...formErrors, [name]: false });
        }
    };

    const isValidForm = (inputs) => {
        let isValid = true;
        let errors = {};
        inputs.forEach((name) => {
            const validInput = !!formData[name].trim();
            if (!validInput) {
                errors[name] = true;
                isValid = false;
            } else {
                errors[name] = false;
            }
        });
        setFormErrors({ ...formErrors, ...errors });
        return isValid;
    };

    const handleSubmit = () => {
        if (isValidForm(["nume", "prenume", "email", "telefon"])) {
            props.handleModalClose(formData);
            setFormData(defaultData);
        }
    };

    const handleClose = () => {
        props.handleModalClose();
        if (props.cont) setFormData(defaultData);
    };

    React.useEffect(() => {
        setFormData(props.cont || defaultData);
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>
                    {props.cont ? "Editeaza contul" : "Adauga un cont nou"}
                </span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box
                    sx={{
                        "& .MuiFormControl-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel>Rol</InputLabel>
                        <Select
                            name="rol"
                            value={formData.rol || "Editor"}
                            label="Rol"
                            onChange={handleFormChange}
                        >
                            <MenuItem value="Primar">Primar</MenuItem>
                            <MenuItem value="Secretar">Secretar</MenuItem>
                            <MenuItem value="Editor">Editor</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "40ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="nume"
                            value={formData.nume}
                            onChange={handleFormChange}
                            label="Nume"
                            error={formErrors.nume}
                            helperText={formErrors.nume ? "Camp obligatoriu" : ""}
                        />
                        <TextField
                            name="prenume"
                            value={formData.prenume}
                            onChange={handleFormChange}
                            label="Prenume"
                            error={formErrors.prenume}
                            helperText={formErrors.prenume ? "Camp obligatoriu" : ""}
                        />
                    </div>
                </Box>{" "}
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "40ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="email"
                            value={formData.email}
                            onChange={handleFormChange}
                            label="Adresa Email"
                            error={formErrors.email}
                            helperText={formErrors.email ? "Camp obligatoriu" : ""}
                        />
                        <TextField
                            name="telefon"
                            value={formData.telefon}
                            onChange={handleFormChange}
                            label="Telefon"
                            error={formErrors.telefon}
                            helperText={formErrors.telefon ? "Camp obligatoriu" : ""}
                        />
                    </div>
                </Box>
                {!props.cont && (
                    <FormGroup
                        sx={{
                            m: 1,
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Trimite email de notificare dupa adaugare"
                        />
                    </FormGroup>
                )}
            </DialogContent>

            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSubmit}>
                    {props.cont ? "Salveaza modificarile" : "Salveaza contul"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaCont;
