import { createSlice, createAsyncThunk }  from '@reduxjs/toolkit';
import axios from "axios";

export const getGeoJson = createAsyncThunk("geo/getGeoJson", async () => {
    const response = await axios.get("/api?location=11111");
    return response.data;
});

export const geoSlice = createSlice({
    name: 'geo',
    initialState: {
        data: [],
        loading: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGeoJson.pending, (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
            }
        });

        builder.addCase(getGeoJson.fulfilled, (state, action) => {
            if (state.loading === "pending") {
                state.data = action.payload;
                state.loading = "idle";
            }
        });

        builder.addCase(getGeoJson.rejected, (state, action) => {
            if (state.loading === "pending") {
                state.loading = "idle";
                state.error = "Error";
            }
        });
    },
})

export default geoSlice.reducer;
