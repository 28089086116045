import { useState, useEffect } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    FeatureGroup,
    LayersControl,
    useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import tileLayer from "./utils/tileLayer";
import axios from "axios";
import { customPinIcon, fontAwesomeIconFat } from "./utils/leafletIcons";
import { getCategoryIcon, getStatusColor } from "./utils/sesizariUtils";

const sesizari = require("./geojson/sesizari.json");

const center = [47.1392, 24.4973];

const sesizarileMele = [
    {
        type: "Feature",
        properties: {
            status: "depus",
            category: "constructii",
        },
        geometry: {
            coordinates: [24.47325785732591, 47.14242625297381],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            status: "lucru",
            category: "mediu",
        },
        geometry: {
            coordinates: [24.48090555927395, 47.13109644609369],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            status: "rezolvat",
            category: "strazi",
        },
        geometry: {
            coordinates: [24.488673747241126, 47.141669107837465],
            type: "Point",
        },
    },
];

const ControllingGroup = () => {
    const map = useMapEvent({
        overlayadd(e) {
            let bounds = new L.LatLngBounds();
            map.eachLayer(function (layer) {
                console.log(layer);
                if (layer instanceof L.FeatureGroup) {
                    bounds.extend(layer.getBounds());
                }
            });

            if (bounds.isValid()) {
                map.flyToBounds(bounds);
            }
        },
    });
    return null;
};

const ShowMarkers = ({ markers, getPosition, deleteMarker }) => {
    return markers.map((marker, index) => {
        return (
            <Marker
                key={index}
                position={marker}
                draggable={true}
                eventHandlers={{
                    moveend(e) {
                        const { lat, lng } = e.target.getLatLng();
                        getPosition([lat, lng]);
                    },
                }}
                icon={customPinIcon("dodgerblue")}
            >
                <Popup>
                    <button onClick={() => deleteMarker()}>sterge locatia ❌</button>
                </Popup>
            </Marker>
        );
    });
};

const MyMarkers = ({ map }) => {
    const [markers, setMarkers] = useState([]);
    const [infoRef, setInfoRef] = useState();
    const legend = L.control({ position: "bottomleft" });
    const info = L.DomUtil.create("div", "legend");

    legend.onAdd = () => {
        info.textContent = "Click pe harta pentru a seta o locatie.";
        setInfoRef(info);
        return info;
    };

    const handleDelete = () => {
        setMarkers([]);
    };

    const getPosition = (location) => {
        console.log(location);
        const getDisplayName = async () => {
            const response = await axios.get("https://nominatim.openstreetmap.org/search.php", {
                params: { q: location.join(","), polygon_geojson: 1, format: "json" },
            });
            infoRef.textContent = `Locatie aproximativa: ${
                response.data[0].display_name.split(", Cluj-Napoca")[0]
            }`;
        };
        getDisplayName();
    };

    useEffect(() => {
        const defaultInfo = "Click pe harta pentru a seta o locatie.";

        if (markers.length) {
            getPosition(markers[0]);
        } else {
            infoRef ? (infoRef.textContent = defaultInfo) : (info.textContent = defaultInfo);
        }
    }, [markers]);

    useEffect(() => {
        if (!map) return;

        legend.addTo(map);

        map.on("click", (e) => {
            const { lat, lng } = e.latlng;
            // setMarker((mar) => [...mar, [lat, lng]]);
            setMarkers([[lat, lng]]);
        });
    }, [map]);

    return markers.length > 0 ? (
        <ShowMarkers markers={markers} getPosition={getPosition} deleteMarker={handleDelete} />
    ) : null;
};

const HartaSesizari = () => {
    const [map, setMap] = useState(null);
    const randomTitle = () =>
        Math.floor(Math.random() * 16777215)
            .toString(16)
            .toUpperCase();

    return (
        <div
            style={{
                height: "320px",
                width: "100%",
                padding: "5px",
                borderRadius: "4px",
                background: "#FFF",
                boxSizing: "border-box",
            }}
        >
            <MapContainer
                whenCreated={setMap}
                center={center}
                zoom={14}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer {...tileLayer} />
                <LayersControl position="topright" collapsed={false}>
                    <LayersControl.Overlay name="Toate sesizarile">
                        <FeatureGroup>
                            {sesizari.features.map((sesizare, index) => (
                                <Marker
                                    key={index}
                                    position={[
                                        sesizare.geometry.coordinates[1],
                                        sesizare.geometry.coordinates[0],
                                    ]}
                                    icon={fontAwesomeIconFat({
                                        color: "lightslategrey",
                                        // color: getStatusColor(sesizare.properties.status),
                                        icon: getCategoryIcon(sesizare.properties.category),
                                    })}
                                >
                                    <Popup>{randomTitle()}</Popup>
                                </Marker>
                            ))}
                        </FeatureGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Sesizarile mele">
                        <FeatureGroup>
                            {sesizarileMele.map((sesizare, index) => (
                                <Marker
                                    key={index}
                                    position={[
                                        sesizare.geometry.coordinates[1],
                                        sesizare.geometry.coordinates[0],
                                    ]}
                                    icon={fontAwesomeIconFat({
                                        color: getStatusColor(sesizare.properties.status),
                                        icon: getCategoryIcon(sesizare.properties.category),
                                    })}
                                >
                                    <Popup>{randomTitle()}</Popup>
                                </Marker>
                            ))}
                        </FeatureGroup>
                    </LayersControl.Overlay>
                </LayersControl>

                {/* <ControllingGroup /> */}
                <MyMarkers map={map} />
            </MapContainer>
        </div>
    );
};

export default HartaSesizari;
