import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, ScaleControl, Pane } from "react-leaflet";
import { Box } from "@mui/material";
import L from "leaflet";
import tileLayer from "./utils/tileLayer";
import { polygon, intersect } from "@turf/turf";

import { canFitLabel, containsPolygon, layerToPolygonObject } from "./utils/mapUtils";
import polylabel from "./utils/polylabel";

import axios from "axios";

import proj4 from "proj4";
import ReactLeafletTextPath from "react-leaflet-textpath";
import Analize from "./MapLeafAnalize";

// EPSG:3844
var customProjection =
    "+proj=sterea +lat_0=46 +lat_ts=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs";

// function getFilteredData(data, map, isSimplePoly) {
//     const mapBounds = map.getBounds();
//     return {
//         type: "FeatureCollection",
//         features: data.features.filter((poly) =>
//             containsPolygon(
//                 mapBounds,
//                 isSimplePoly ? poly.geometry.coordinates : poly.geometry.coordinates[0]
//             )
//         ),
//     };
// }

function getFilteredData(data, map, isSimplePoly) {
    const mapPoly = polygon(createBasicPolygonFromBounds(map.getBounds()));
    return {
        type: "FeatureCollection",
        features: data.features.filter(
            (poly) =>
                !!intersect(
                    mapPoly,
                    isSimplePoly
                        ? polygon(poly.geometry.coordinates)
                        : polygon(poly.geometry.coordinates[0])
                )
        ),
    };
}

let allData = null;

function renderParcels(parcels, map, parcelId) {
    function onEachFeature(feature, layer) {
        const tooltipText = feature.id ? feature.id.toString() : null;
        const zoomLevel = map.getZoom();
        if (tooltipText) {
            const poleOfInaccessibility = feature.properties.poleOfInaccessibility;
            if (
                (poleOfInaccessibility.distance > 0.0007 && zoomLevel == 15) ||
                (poleOfInaccessibility.distance > 0.0004 && zoomLevel == 16) ||
                (poleOfInaccessibility.distance > 0.00015 && zoomLevel == 17) ||
                (poleOfInaccessibility.distance > 0.00007 && zoomLevel == 18) ||
                (poleOfInaccessibility.distance > 0.00004 && zoomLevel == 19)
            ) {
                layer.bindTooltip(tooltipText, {
                    permanent: true,
                    direction: "center",
                    className: "parcelLabel",
                });
            }
        }
    }

    return L.geoJSON(getFilteredData(parcels, map), {
        style: {
            fillOpacity: 1,
            opacity: 1,
            fillColor: "rgba(255,255,0,0.3)",
            weight: 0.5,
            color: "rgba(0,0,0,0.6)",
        },
        onEachFeature: onEachFeature,
        filter: function (feature, layer) {
            return feature.properties.cad !== parcelId;
        },
    });

    // L.geoJSON(getFilteredData(allData.buildings, map), {
    //     style: {
    //         fillOpacity: 1,
    //         opacity: 1,
    //         fillColor: "rgba(147,112,219,0.8)",
    //         weight: 1,
    //         color: "rgba(0,0,0,0.2)",
    //     },
    // }).addTo(map);
}

const zoomToScale = {
    19: "1:1000",
    18: "1:2000",
    17: "1:4000",
    16: "1:8000",
    15: "1:16000",
    14: "1:32000",
    13: "1:64000",
    12: "1:128000",
    11: "1:256000",
    10: "1:512000",
};

function createLeafletPolygonFromBounds(latLngBounds) {
    let latlngs = [];

    latlngs.push(latLngBounds.getSouthWest()); //bottom left
    latlngs.push(latLngBounds.getSouthEast()); //bottom right
    latlngs.push(latLngBounds.getNorthEast()); //top right
    latlngs.push(latLngBounds.getNorthWest()); //top left

    return new L.polygon(latlngs);
}

function createBasicPolygonFromBounds(latLngBounds) {
    let latlngs = [];

    latlngs.push([latLngBounds.getSouthWest().lng, latLngBounds.getSouthWest().lat]); //bottom left
    latlngs.push([latLngBounds.getSouthEast().lng, latLngBounds.getSouthEast().lat]); //bottom right
    latlngs.push([latLngBounds.getNorthEast().lng, latLngBounds.getNorthEast().lat]); //top right
    latlngs.push([latLngBounds.getNorthWest().lng, latLngBounds.getNorthWest().lat]); //top left
    latlngs.push([latLngBounds.getSouthWest().lng, latLngBounds.getSouthWest().lat]); //bottom left again

    return [latlngs];
}

const Parcela = ({ map, parcelData, setBounds, setZoom }) => {
    const [initialized, setInitialized] = useState(false);

    if (map && parcelData && !initialized) {
        setInitialized(true);

        const geoJsonStyle = {
            fillColor: "rgba(50, 235, 50, 0.7)",
            weight: 1,
            opacity: 1,
            color: "black",
            fillOpacity: 1,
        };

        let layerRef = null;
        let renderedParcels = null;

        function zoomToParcel() {
            map.fitBounds(layerRef.getBounds(), { maxZoom: 19 });
        }

        let labeledParcel = L.geoJSON(parcelData, {
            onEachFeature: onEachFeature,
            style: geoJsonStyle,
            pane: "parcel",
        }).addTo(map);

        function onEachFeature(feature, layer) {
            const tooltipText = feature.id ? feature.id.toString() : null;
            const zoomLevel = map.getZoom();
            if (tooltipText) {
                const poleOfInaccessibility = feature.properties.poleOfInaccessibility;
                if (
                    (poleOfInaccessibility.distance > 0.0012 && zoomLevel == 14) ||
                    (poleOfInaccessibility.distance > 0.0006 && zoomLevel == 15) ||
                    (poleOfInaccessibility.distance > 0.0003 && zoomLevel == 16) ||
                    (poleOfInaccessibility.distance > 0.00015 && zoomLevel == 17) ||
                    (poleOfInaccessibility.distance > 0.00007 && zoomLevel == 18) ||
                    (poleOfInaccessibility.distance > 0.00004 && zoomLevel == 19)
                ) {
                    layer.bindTooltip(tooltipText, {
                        permanent: true,
                        direction: "center",
                        className: "parcelLabel",
                    });
                } else {
                    layer.bindTooltip(tooltipText, {
                        direction: "top",
                        permanent: true,
                        opacity: 1,
                    });
                }
            }

            layerRef = layer;
        }

        zoomToParcel();

        map.on("moveend", function () {
            window.setTimeout(function () {
                onMoveEnd();
            }, 100);
        });

        window.setTimeout(function () {
            onMoveEnd();
        }, 100);

        function onMoveEnd() {
            if (map.hasLayer(labeledParcel)) map.removeLayer(labeledParcel);
            labeledParcel = L.geoJSON(parcelData, {
                onEachFeature: onEachFeature,
                style: geoJsonStyle,
                pane: "parcel",
            });
            map.addLayer(labeledParcel);

            // setBounds(map.getBounds());

            setZoom(map.getZoom());

            const _northEast = map.getBounds()._northEast;
            const _southWest = map.getBounds()._southWest;

            const _northEast3844 = proj4(customProjection, [_northEast.lng, _northEast.lat]);
            const _southWest3844 = proj4(customProjection, [_southWest.lng, _southWest.lat]);

            setBounds({
                _northEast: { lat: _northEast3844[1], lng: _northEast3844[0] },
                _southWest: { lat: _southWest3844[1], lng: _southWest3844[0] },
            });

            if (map.hasLayer(renderedParcels)) map.removeLayer(renderedParcels);
            renderedParcels = renderParcels(allData.parcels, map, parcelData.id);
            map.addLayer(renderedParcels);
        }
    }

    return null;
};

const PrevizualizareHarta = ({ setScale, setParcelData, parcelId, extended }) => {
    const [map, setMap] = useState(null);
    const [selectedParcel, setSelectedParcel] = useState();
    const [bounds, setBounds] = useState({
        _northEast: { lat: 0, lng: 0 },
        _southWest: { lat: 0, lng: 0 },
    });
    const [zoom, setZoom] = useState(19);
    const [showAnalize, setShowAnalize] = useState(false);

    useEffect(() => {
        setScale(zoomToScale[zoom]);
    }, [zoom]);

    useEffect(() => {
        if (!map) return;
        if (allData) return;
        const getData = async () => {
            const response = await axios.get("/api?location=32394");
            allData = response.data;
            allData.parcels.features.forEach(function (parcel, index) {
                parcel.properties.poleOfInaccessibility = polylabel(
                    parcel.geometry.coordinates[0],
                    1.0
                );
                if (parcel.id === parcelId) {
                    setParcelData(parcel);
                    setSelectedParcel(parcel);
                }
            });
        };

        getData();
        setShowAnalize(true);
    }, [map]);

    const defaultStyles = {
        position: "absolute",
        fontSize: "0.8rem",
        height: "15px",
        width: "67px",
        pointerEvents: "none",
        userSelect: "none",
    };

    return (
        <Box
            sx={{
                height: extended ? "600px" : "1032px",
                width: "100%",
                border: "1px solid",
                position: "relative",
                "& .leaflet-control-attribution": {
                    display: "none",
                },
                "& .leaflet-bottom .leaflet-control-scale": {
                    marginBottom: "10px",
                },
            }}
        >
            {!extended && (
                <React.Fragment>
                    <div style={{ ...defaultStyles, top: "-18px", right: 0, width: "auto" }}>
                        {bounds._northEast.lat.toFixed(3)}
                    </div>
                    <div
                        style={{
                            ...defaultStyles,
                            right: "-85px",
                            // width + 18px
                            top: 0,
                            transform: "rotate(90deg)",
                            transformOrigin: "left top 0px",
                        }}
                    >
                        {bounds._northEast.lng.toFixed(3)}
                    </div>

                    <div style={{ ...defaultStyles, bottom: "-18px", left: 0 }}>
                        {bounds._southWest.lat.toFixed(3)}
                    </div>

                    <div
                        style={{
                            ...defaultStyles,
                            left: -2,
                            bottom: 60,
                            // width - 15px
                            transform: "rotate(90deg)",
                            transformOrigin: "left top 0px",
                        }}
                    >
                        {bounds._southWest.lng.toFixed(3)}
                    </div>
                </React.Fragment>
            )}

            <MapContainer
                whenCreated={setMap}
                zoom={19}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%" }}
                zoomControl={!!extended}
                maxZoom={19}
                maxNativeZoom={19}
            >
                <TileLayer {...tileLayer} attribution="" maxNativeZoom={19} maxZoom={19} />
                <Pane name="parcel" style={{ zIndex: 401 }}>
                    {selectedParcel && (
                        <Parcela
                            map={map}
                            parcelData={selectedParcel}
                            setBounds={setBounds}
                            setZoom={setZoom}
                        />
                    )}
                </Pane>
                <Pane name="accesGradinite" style={{ zIndex: 402 }}></Pane>
                <Pane name="accesScoli" style={{ zIndex: 403 }}></Pane>
                <Pane name="accesSpitale" style={{ zIndex: 404 }}></Pane>

                {map && extended && (
                    <Analize
                        map={map}
                        showAccesGradinite={showAnalize}
                        showAccesScoli={showAnalize}
                        showAccesSpitale={false}
                    />
                )}
                <ScaleControl position="bottomright" imperial={false} maxWidth="400" />
            </MapContainer>
        </Box>
    );
};

export default PrevizualizareHarta;
