import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

import TabelCereri from "./TabelCereri";
import TabelAutorizatii from "./TabelAutorizatii";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PostAddIcon from "@mui/icons-material/PostAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import DashboardDocumentatie from "./DashboardDocumentatie";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import { Link, useNavigate, useParams } from "react-router-dom";

import { grey, purple, blue } from "@mui/material/colors";

import { useSelector, useDispatch } from "react-redux";

const options = [
    { label: "Certificat de Urbanism", url: "/dashboard/certificat" },
    { label: "Autorizatie de Construire", url: "/dashboard/certificat" },
    { label: "Plan Urbanistic Zonal (PUZ)", url: "/dashboard/puz" },
    { label: "Plan Urbanistic de Detaliu (PUD)", url: "/dashboard/puz" },
];

function Cereri({}) {
    let { param } = useParams();
    const userData = useSelector((state) => state.auth.userData);
    const [value, setValue] = React.useState("certificate");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openPopper, setOpenPopper] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = (event, index) => {
        setOpenPopper(false);
    };

    const handleToggle = () => {
        setOpenPopper((prevOpen) => !prevOpen);
    };

    const handleClosePopper = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopper(false);
    };

    React.useEffect(() => {
        if (param === "autorizatii") setValue("autorizatii");
        if (param === "documentatii") setValue("urbanism");
    }, []);

    const complexButton = (
        <Box
            sx={{
                float: "right",
                mt: 1,
            }}
        >
            <Button
                variant="contained"
                startIcon={<PostAddIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ marginRight: 1, backgroundColor: grey[900] }}
                ref={anchorRef}
                onClick={handleToggle}
            >
                Adauga cerere
            </Button>

            <Popper
                sx={{
                    zIndex: 3000,
                }}
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4],
                        },
                    },
                ]}
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ border: "1px solid rgba(0,0,0,0.4)" }} elevation={3}>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                    sx={{ textTransform: "", textAlign: "right" }}
                                >
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            to={option.url}
                                            component={Link}
                                            sx={{ textAlign: "right !important" }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Box
                sx={{
                    width: "100%",
                    mb: 2,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    "& .MuiTab-root": { fontSize: "1rem" },
                }}
            >
                {userData && userData.role === "EDITOR" && complexButton}
                <Tabs value={value} onChange={handleChange}>
                    <Tab
                        value="certificate"
                        label={
                            <Box>
                                Certificate de Urbanism
                                <Chip
                                    color={value === "certificate" ? "primary" : "default"}
                                    label="+3"
                                    sx={{ ml: 1.5 }}
                                />
                            </Box>
                        }
                    ></Tab>
                    <Tab
                        value="autorizatii"
                        label={
                            <Box>
                                Autorizatii de Construire
                                {/* <Chip color="default" label="0" sx={{ ml: 1.5 }} /> */}
                            </Box>
                        }
                    />
                    <Tab
                        value="urbanism"
                        label={
                            <Box>
                                Documentatii Urbanism
                                {/* <Chip color="default" label="0" sx={{ ml: 1.5 }} /> */}
                            </Box>
                        }
                    />
                </Tabs>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        {value === "certificate" && <TabelCereri param={param} />}
                        {value === "autorizatii" && <TabelAutorizatii />}
                        {value === "urbanism" && <DashboardDocumentatie />}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Cereri;
