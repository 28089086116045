import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector, useDispatch } from "react-redux";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function AddComment({ handleSubmit }) {
    const [text, setText] = React.useState("");
    const [notificare, setNotificare] = React.useState(false);

    const saveComment = (e) => {
        if (text.trim()) {
            handleSubmit(text);
            setText("");
        }
    };
    return (
        <Box sx={{}}>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Scrie mesajul aici..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={6}
                        sx={{ mb: 1, background: "#FFF" }}
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notificare}
                                    onChange={(e) => setNotificare(e.target.checked)}
                                />
                            }
                            label="Trimite notificare prin email"
                        />
                        <Box
                            sx={{
                                padding: 1,
                                background: "#FFF",
                                borderRadius: "4px",
                                mb: 2,
                                fontSize: "90%",
                                opacity: 0.75,
                                lineHeight: 1.5,
                            }}
                        >
                            In cazul in care se bifeaza optiunea "
                            <strong>Trimite notificare prin email</strong>", solicitantul
                            documentului va fi notificat in legatura cu mesajul primit.
                        </Box>
                    </Box>
                    <Button variant="contained" sx={{ mr: 1 }} onClick={saveComment}>
                        Trimite Mesaj
                    </Button>
                    <Button variant="outlined" color="inherit" startIcon={<AttachFileIcon />}>
                        Ataseaza Fisiere
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function Reply({ username, handleSubmit }) {
    const [text, setText] = React.useState("");

    const saveComment = (e) => {
        if (text.trim()) {
            handleSubmit(text);
            setText("");
        }
    };
    return (
        <Box sx={{ pb: 2, pt: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                {/* <Avatar
                    sx={{ mr: 1, mt: "8px", bgcolor: stringToColor(username) }}
                    alt={username}
                /> */}
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Scrie mesajul aici..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        maxRows={4}
                        minRows={3}
                        sx={{ mb: 1, background: "#FFF" }}
                    />

                    <Button variant="contained" sx={{ mr: 1 }} onClick={saveComment}>
                        Trimite Mesaj
                    </Button>

                    <Button variant="outlined" color="inherit" startIcon={<AttachFileIcon />}>
                        Ataseaza Fisiere
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function MesajSolicitant(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [comments, setComments] = React.useState([]);
    const userData = useSelector((state) => state.auth.userData);

    const handleSubmit = (text) => {
        // props.handleModalClose();
        setComments([
            ...comments,
            {
                text: text,
                username: userData.fullname,
                date: new Date(),
            },
        ]);
        enqueueSnackbar("Mesajul a fost trimis", { variant: "info" });
    };

    const handleClose = () => {
        props.handleModalClose();
    };

    React.useEffect(() => {
        if (userData && userData.role === "USER")
            setComments([
                {
                    text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
                    username: "Radu Mihai",
                    date: new Date(),
                },
            ]);
    }, []);

    return (
        <React.Fragment>
            {comments.map((comment, index) => (
                <Box sx={{ mb: 2 }} key={index}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                mb: 1,
                            }}
                        >
                            <Avatar
                                sx={{ mr: 1, bgcolor: stringToColor(comment.username) }}
                                alt={comment.username}
                            />
                            <Box sx={{ fontSize: "96%" }}>
                                <Box sx={{ fontWeight: 500, pb: 0.25 }}>{comment.username}</Box>
                                <Box sx={{ fontSize: "85%", opacity: 0.75 }}>
                                    {comment.date.toDateString()}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {comment.text && (
                        <Paper sx={{ p: 2 }} elevation={1}>
                            {comment.text}
                        </Paper>
                    )}
                </Box>
            ))}

            {comments.length === 0 && <AddComment handleSubmit={handleSubmit} />}

            {comments.length !== 0 && (
                <Reply data={userData.fullname} handleSubmit={handleSubmit} />
            )}
        </React.Fragment>
    );
}

export default MesajSolicitant;
