import React from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
    Button,
    IconButton,
    Divider,
    Box,
    Alert,
    TextField,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import MaterialLink from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import GoogleButton from "./components/GoogleButton";

import { useSelector, useDispatch } from "react-redux";
import { logIn, logOut, setRedirectURL } from "./redux/auth.slice";

const cookies = new Cookies();

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

function Login() {
    let navigate = useNavigate();
    let location = useLocation();

    const dispatch = useDispatch();

    const setDefaultSessionData = (sessionData) => {
        cookies.set("sessionData", sessionData, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 3600 * 48),
        });
        dispatch(logIn({ jwt: sessionData }));
    };

    React.useEffect(() => {
        if (location.state) {
            dispatch(setRedirectURL({ pathname: location.state.pathname }));
        }
    }, []);

    const handleSubmit = (event) => {
        const data = new FormData(event.currentTarget);

        let sessionData = "USER";

        if (data.get("email").toLowerCase().includes("primar")) sessionData = "PRIMAR";
        if (data.get("email").toLowerCase().includes("editor")) sessionData = "EDITOR";

        handleLogin(data)
            .then((response) => {
                if (response.jwt) {
                    cookies.set("sessionData", response.jwt, {
                        path: "/",
                        expires: new Date(Date.now() + 1000 * 3600 * 48),
                    });
                    dispatch(logIn(response));
                } else {
                    setDefaultSessionData(sessionData);
                }
            })
            .catch((error) => {
                setDefaultSessionData(sessionData);
            });

        event.preventDefault();
    };

    async function handleLogin(data) {
        // body: JSON.stringify({
        //     identifier: "flavius.matis+auth@gmail.com",
        //     password: "whatever2",
        // })
        try {
            const response = await fetch(API_URL + `auth/local`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    identifier: data.get("email"),
                    password: data.get("password"),
                }),
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    const handleModalClose = () => {
        navigate("/");
    };

    const RenderAlert = () => {
        if (!location.state) return null;
        switch (location.state.pathname) {
            case "/dashboard/certificat":
                return (
                    <Alert severity="info" sx={{ marginBottom: "1.5rem" }}>
                        Pentru a obtine un certificat de urbanism este nevoie sa fii autentificat.
                        Daca nu ai inca un cont poti creea unul in cateva secunde.
                    </Alert>
                );
            case "/dashboard/certificate":
                return (
                    <Alert severity="info" sx={{ marginBottom: "1.5rem" }}>
                        Pentru a vedea lista de certificate emise este nevoie sa fii autentificat.
                    </Alert>
                );
            case "/dashboard/alerte":
                return (
                    <Alert severity="info" sx={{ marginBottom: "1.5rem" }}>
                        Pentru a vedea alertele tale sau pentru a seta noi alerte este nevoie sa fii
                        autentificat.
                    </Alert>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleModalClose}
            onClose={handleModalClose}
            maxWidth="xs"
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Acceseaza platforma</span>
                <IconButton
                    color="default"
                    component="span"
                    onClick={handleModalClose}
                    sx={{ marginRight: "-0.75rem" }}
                >
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <RenderAlert />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresa email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Parola"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Grid container>
                        <Grid item xs>
                            <MaterialLink href="#" variant="body2">
                                Ai uitat parola?
                            </MaterialLink>
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 1, p: 1.5 }}
                    >
                        Intra in cont
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2, p: 1.5 }}
                        component={Link}
                        to="/signup"
                    >
                        Nu ai cont? Inregistreaza-te!
                    </Button>

                    <Divider sx={{ fontSize: "12px" }}>SAU</Divider>

                    <GoogleButton sx={{ mt: 2, mb: 1, p: 1.5 }}>
                        Autentificare cu Google
                    </GoogleButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Login;
