import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import Chip from "@mui/material/Chip";

import { Button, Box, Checkbox, Alert } from "@mui/material";
import { Card, CardActions, CardContent, Typography } from "@mui/material";

import PrevizualizareHarta from "./PrevizualizareHarta";
import AdaugaBeneficiar from "./AdaugaBeneficiar";
import AdaugaAmplasament from "./AdaugaAmplasament";

import BusinessIcon from "@mui/icons-material/Business";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@mui/material/IconButton";

import { Link, useParams } from "react-router-dom";

import axios from "axios";
import Spinner from "./components/Spinner";

import StergeBeneficiar from "./StergeBeneficiar";
import StergeAmplasament from "./StergeAmplasament";
import DetaliiCerere from "./DashboardCertificatDetalii";

import { useSnackbar } from "notistack";

import { useSelector, useDispatch } from "react-redux";

function BeneficiariCard({ index, beneficiar, onDelete, onEdit }) {
    return (
        <Card
            sx={{
                background: "#FFF",
                marginBottom: "1rem",
                boxShadow: "none",
                border: "1px solid",
            }}
        >
            {
                {
                    fizica: (
                        <CardContent sx={{ lineHeight: 2 }}>
                            <strong>
                                {beneficiar.prenume} {beneficiar.nume}
                            </strong>
                            <Chip sx={{ ml: 1 }} label="Persoană Fizică"></Chip>
                            <br />
                            <strong>CNP: </strong>
                            <span>{beneficiar.cnp}</span>
                            <br />
                            <strong>Email: </strong>
                            <span>{beneficiar.email}</span>
                            <br />
                            <strong>Telefon: </strong>
                            <span>{beneficiar.telefon}</span>
                            <br />
                            <strong>Adresă domiciliu: </strong>
                            <span>{beneficiar.adresa}</span>
                        </CardContent>
                    ),
                    juridica: (
                        <CardContent sx={{ lineHeight: 2 }}>
                            <strong>{beneficiar.companie}</strong>
                            <Chip sx={{ ml: 1 }} label="Persoană Juridică"></Chip>
                            <br />
                            <strong>CIF: </strong>
                            <span>{beneficiar.cif}</span>
                            <br />
                            <strong>Email: </strong>
                            <span>{beneficiar.email}</span>
                            <br />
                            <strong>Telefon: </strong>
                            <span>{beneficiar.telefon}</span>
                            <br />
                            <strong>Adresă domiciliu: </strong>
                            <span>{beneficiar.adresa}</span>
                        </CardContent>
                    ),
                }[beneficiar.tip]
            }

            <CardActions sx={{ p: 2, mt: -1 }}>
                <Button size="small" variant="outlined" onClick={(e) => onEdit(e, index)}>
                    Editeaza
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={(e) => onDelete(e, index)}
                >
                    Sterge
                </Button>
            </CardActions>
        </Card>
    );
}

function AmplasamenteCard({ amplasament, onDelete, index }) {
    const parcelData = document.layerData.parcels.features.filter(
        (parcel) => parcel.properties.cad.toString() === amplasament
    )[0];

    return (
        <Card
            sx={{
                background: "#FFF",
                marginBottom: "1rem",
                boxShadow: "none",
                border: "1px solid",
            }}
        >
            <CardContent sx={{ lineHeight: 2 }}>
                <strong>Numar Cadastral: </strong>
                <span>{parcelData.properties.cad || "50060"}</span>
                <br />
                <span>
                    <strong>Judetul: </strong>Bistrita-Nasaud
                </span>
                <br />
                <strong>UAT: </strong>
                <span>Bistrita</span>
                <br />
                <strong>Suprafata: </strong>
                <span>{parcelData.properties.area || "3000"} mp</span>
            </CardContent>
            <CardActions sx={{ p: 2, mt: -1 }}>
                <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={(e) => onDelete(e, index)}
                >
                    Sterge
                </Button>
            </CardActions>
        </Card>
    );
}

function Certificat() {
    const [beneficiari, setBeneficiari] = React.useState([
        {
            nume: "Popescu",
            prenume: "Vlad",
            email: "v.popescu@gmail.com",
            telefon: "0785123433",
            adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            cnp: "1850115125777",
            cif: "",
            companie: "",
            tip: "fizica",
        },
        // {
        //     nume: "",
        //     prenume: "",
        //     email: "beletage@gmail.com",
        //     telefon: "0742555555",
        //     adresa: "Judetul Cluj, Cluj-Napoca, Str. Aurel Vlaicu nr. 5, ap. 7",
        //     cnp: "",
        //     cif: "RO2372893",
        //     companie: "Beletage SRL",
        //     tip: "juridica",
        // },
    ]);
    const [amplasamente, setAmplasamente] = React.useState([]);
    const [obiectiv, setObiectiv] = React.useState("");
    const [scop, setScop] = React.useState({});
    const [beneficiarDialogVisibility, setBeneficiarDialogVisibility] = React.useState(false);
    const [dialogStergeBeneficiar, setDialogStergeBeneficiar] = React.useState(false);
    const [beneficiarSelectat, setBeneficiarSelectat] = React.useState(null);

    const [totalArea, setTotalArea] = React.useState([]);

    const [amplasamentSelectat, setAmplasamentSelectat] = React.useState(null);

    const [dialogStergeAmplasament, setDialogStergeAmplasament] = React.useState(false);

    const [amplasamentDialogVisibility, setAmplasamentDialogVisibility] = React.useState(false);
    const [parcelData, setParcelData] = React.useState();
    const [dataReady, setDataReady] = React.useState(false);
    const [isCluster, setIsCluster] = React.useState(true);

    const [solicitant, setSolicitant] = React.useState({
        nume: "Popescu",
        prenume: "Vlad",
        email: "v.popescu@gmail.com",
        telefon: "0785123433",
    });

    const handleChangeSolicitant = (e) => {
        setSolicitant({ ...solicitant, [e.target.name]: e.target.value });
    };

    const [files, setFiles] = React.useState([]);

    function handleFileUpload(e) {
        const newFiles = e.target.files;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(newFiles[i].name);
        }
        setFiles([...files, ...newFilesArray]);
    }

    function deleteFile(index) {
        var tempArray = [...files];
        tempArray.splice(index, 1);
        setFiles(tempArray);
    }

    const handleBeneficiarSave = (data) => {
        if (beneficiarSelectat) {
            beneficiari.forEach((beneficiar, index) => {
                if (beneficiar === beneficiarSelectat) {
                    var tempArray = [...beneficiari];
                    tempArray[index] = data;
                    setBeneficiari(tempArray);
                }
            });
        } else {
            setBeneficiari([...beneficiari, data]);
        }
        setBeneficiarDialogVisibility(false);
        setBeneficiarSelectat(null);
    };

    const deleteBeneficiar = (event, index) => {
        setDialogStergeBeneficiar(true);
        setBeneficiarSelectat(beneficiari[index]);
    };

    const editBeneficiar = (event, index) => {
        setBeneficiarSelectat(beneficiari[index]);
        setBeneficiarDialogVisibility(true);
    };

    const handleBeneficiarModalClose = () => {
        setBeneficiarDialogVisibility(false);
        setBeneficiarSelectat(null);
    };

    const handleStergeBeneficiarClose = () => {
        setDialogStergeBeneficiar(false);
        setBeneficiarSelectat(null);
    };

    const handleStergeBeneficiar = () => {
        beneficiari.forEach((beneficiar, index) => {
            if (beneficiar === beneficiarSelectat) {
                var tempArray = [...beneficiari];
                tempArray.splice(index, 1);
                setBeneficiari(tempArray);
                handleStergeBeneficiarClose();
            }
        });
    };

    const setParcelDataFromAmplasamente = () => {
        if (!document.layerData) return;
        let features = [];
        let amplasamenteArea = 0;
        amplasamente.forEach((element) => {
            const parcelData = document.layerData.parcels.features.filter(
                (parcel) => parcel.properties.cad.toString() === element
            )[0];
            features.push(parcelData);
            amplasamenteArea += parcelData.properties.area;
        });

        if (features.length) {
            setParcelData({
                type: "FeatureCollection",
                features: features,
            });
        } else {
            setParcelData(null);
        }
        setTotalArea(amplasamenteArea);
    };

    React.useEffect(() => {
        setParcelDataFromAmplasamente();
    }, [amplasamente]);

    const handleSaveAmplasament = (data) => {
        if (!amplasamente.includes(data)) setAmplasamente([...amplasamente, data]);
        setAmplasamentDialogVisibility(false);
    };

    const deleteAmplasament = (event, index) => {
        setAmplasamentSelectat(amplasamente[index]);
        setDialogStergeAmplasament(true);
    };

    const handleStergeAmplasamentClose = () => {
        setDialogStergeAmplasament(false);
        setAmplasamentSelectat(null);
    };

    const handleStergeAmplasament = () => {
        amplasamente.forEach((amplasament, index) => {
            if (amplasament === amplasamentSelectat) {
                var tempArray = [...amplasamente];
                tempArray.splice(index, 1);
                setAmplasamente(tempArray);
                handleStergeAmplasamentClose();
            }
        });
    };

    const initMapView = () => {
        if (parcela) {
            setAmplasamente([parcela]);
        } else {
            setAmplasamente(["89118"]);
            // ["88744", "83658"]
        }
        setDataReady(true);
    };

    const { parcela } = useParams();

    const userData = useSelector((state) => state.auth.userData);

    React.useEffect(() => {
        if (!document.layerData) {
            const getData = async () => {
                const response = await axios
                    .get(
                        // "https://gist.githubusercontent.com/dnz986/73e0c607db51c9953cc0ec4328f2126d/raw/7fc0f34df7d858eb2efb615684b39e4b6bd5dd92/gistfile1.txt"
                        // "http://192.168.1.122:8080/ancpi/parcels?location=55838"
                        // "http://10.10.10.76:8080/ancpi/parcels?location=55838"
                        // "https://gist.githubusercontent.com/flaviusmatis/9ab53c92bab48a4403c6db880b1f5aa0/raw/7aaaa830411182ccb76b5720c0a8341ab31ba624/parcele.json"
                        // "/api/all"
                        "/api?location=32394"
                    )
                    .catch(function (error) {
                        console.log(error.toJSON());
                    });

                if (response) {
                    document.layerData = response.data;
                    initMapView();
                }
            };
            getData();
        } else {
            initMapView();
        }

        if (userData && userData.role !== "USER")
            setSolicitant({
                nume: "",
                prenume: "",
                email: "",
                telefon: "",
            });

        document.getElementById("dashboard-scroll").scrollTo({
            top: 0,
            behavior: "smooth",
        });
        // https://stackoverflow.com/questions/53945763/componentdidmount-equivalent-on-a-react-function-hooks-component
    }, []);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const saveDraft = (status) => {
        status = status || "draft";
        let deepDataCopy = {
            id: null,
            createDate: 1674369525250,
            status: status,
            solicitant: solicitant,
            obiectiv: obiectiv,
            beneficiari: [...beneficiari],
            amplasamente: [],
            anexe: [],
            scop: scop,
        };

        amplasamente.forEach((amplasament, index) => {
            deepDataCopy.amplasamente.push({
                cad: amplasament,
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
            });
        });

        const saveData = async () => {
            const response = await axios
                .post("/api/certificate", deepDataCopy)
                .catch(function (error) {
                    console.log(error.toJSON());
                    enqueueSnackbar("Datele nu au putut fi salvate", { variant: "error" });
                });

            if (response && status === "draft") {
                enqueueSnackbar("Certificatul a fost salvat cu succes!", { variant: "success" });
            }
        };

        axios
            .get("/api/certificate/cluster", {
                params: {
                    parcels: amplasamente.join(","),
                },
            })
            .then(function (response) {
                if (response.data) {
                    setIsCluster(true);
                    saveData();
                } else {
                    setIsCluster(false);
                    enqueueSnackbar("Cererea nu poate fi salvata.", { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <BusinessIcon sx={{ mb: "-3px", mr: 0.75 }} />{" "}
                <span>Cerere pentru emiterea certificatului de urbanism</span>
            </Heading>
            {!dataReady && (
                <Box
                    sx={{
                        display: "flex",
                        minHeight: 160,
                        maxWidth: "960px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner />
                </Box>
            )}
            {dataReady && (
                <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Obiectiv:</Heading>
                        <Box component="form" noValidate autoComplete="off">
                            <p>Solicit eliberarea Certificatului de Urbanism pentru:</p>
                            <TextField
                                sx={{ background: "#FFF" }}
                                fullWidth
                                placeholder="ex. Constructie casa"
                                value={obiectiv}
                                onChange={(e) => setObiectiv(e.target.value)}
                            />
                        </Box>
                    </Paper>

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Solicitant:</Heading>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            sx={{ marginTop: "1.5rem" }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    "& .MuiTextField-root": { width: "100%", background: "#FFF" },
                                    mb: 2.5,
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Nume"
                                        name="nume"
                                        value={solicitant.nume}
                                        onChange={handleChangeSolicitant}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Prenume"
                                        name="prenume"
                                        value={solicitant.prenume}
                                        onChange={handleChangeSolicitant}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Adresa Email"
                                        name="email"
                                        value={solicitant.email}
                                        onChange={handleChangeSolicitant}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Telefon"
                                        name="telefon"
                                        value={solicitant.telefon}
                                        onChange={handleChangeSolicitant}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Lista Beneficiarilor:</Heading>
                        {beneficiari.map((beneficiar, index) => (
                            <BeneficiariCard
                                key={index}
                                index={index}
                                beneficiar={beneficiar}
                                onDelete={deleteBeneficiar}
                                onEdit={editBeneficiar}
                            />
                        ))}
                        {beneficiari.length < 1 && (
                            <h3
                                style={{
                                    padding: "25px 15px",
                                    border: "1px dotted",
                                    fontSize: "14px",
                                    borderRadius: 4,
                                }}
                            >
                                Nu aveti beneficiari. Este necesar cel putin un beneficiar pentru
                                obtinerea certificatului de urbanism.
                            </h3>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => setBeneficiarDialogVisibility(true)}
                        >
                            Adauga Beneficiar
                        </Button>
                    </Paper>

                    {beneficiarDialogVisibility && (
                        <AdaugaBeneficiar
                            open={beneficiarDialogVisibility}
                            editData={beneficiarSelectat}
                            handleModalClose={handleBeneficiarModalClose}
                            handleBeneficiarSave={handleBeneficiarSave}
                        />
                    )}

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Lista Amplasamentelor:</Heading>
                        {amplasamente.map((amplasament, index) => (
                            <AmplasamenteCard
                                key={index}
                                index={index}
                                amplasament={amplasament}
                                onDelete={deleteAmplasament}
                            />
                        ))}
                        {amplasamente.length < 1 && (
                            <h3
                                style={{
                                    padding: "25px 15px",
                                    border: "1px dotted",
                                    fontSize: "14px",
                                    borderRadius: 4,
                                }}
                            >
                                Nu aveti amplasamente. Este necesar cel putin un amplasament pentru
                                obtinerea certificatului de urbanism.
                            </h3>
                        )}
                        {totalArea > 0 && (
                            <Alert
                                color="secondary"
                                variant="filled"
                                icon={false}
                                sx={{
                                    mb: 2,
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    background: "slategray",
                                }}
                            >
                                Suprafata totala amplasamente: <strong>{totalArea} mp</strong>
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => setAmplasamentDialogVisibility(true)}
                        >
                            Adauga Amplasament
                        </Button>
                    </Paper>

                    <AdaugaAmplasament
                        open={amplasamentDialogVisibility}
                        handleModalClose={() => setAmplasamentDialogVisibility(false)}
                        handleSaveAmplasament={handleSaveAmplasament}
                    />

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Anexe:</Heading>
                        {!files.length && (
                            <Box
                                sx={{
                                    padding: 3,
                                    border: "1px dashed",
                                    fontSize: "0.9rem",
                                    fontWeight: 500,
                                    mb: 1,
                                }}
                            >
                                Nu aveti documente anexate.
                            </Box>
                        )}

                        {files.length > 0 &&
                            files.map((file, index) => (
                                <Paper
                                    key={index}
                                    sx={{
                                        p: "0.5rem 1rem",
                                        mb: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FilePresentIcon sx={{ fontSize: "1.3rem", mr: 0.5 }} />{" "}
                                        {file}
                                    </Box>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => deleteFile(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Paper>
                            ))}

                        <Button variant="contained" size="large" sx={{ mt: 1.5 }} component="label">
                            Incarca document
                            <input
                                hidden={true}
                                accept=".doc,.pdf"
                                multiple
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Paper>

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading sx={{ mb: 2 }}>Detalii Cerere:</Heading>
                        <DetaliiCerere handleDataChange={setScop} />
                    </Paper>

                    <StergeBeneficiar
                        open={dialogStergeBeneficiar}
                        handleClose={handleStergeBeneficiarClose}
                        handleDelete={handleStergeBeneficiar}
                        beneficiar={beneficiarSelectat}
                    />

                    <StergeAmplasament
                        open={dialogStergeAmplasament}
                        handleClose={handleStergeAmplasamentClose}
                        handleDelete={handleStergeAmplasament}
                        amplasament={amplasamentSelectat}
                    />

                    <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                        <Heading>Previzualizare pe Harta:</Heading>

                        <PrevizualizareHarta parcelData={parcelData} />
                        {!isCluster && (
                            <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                                Pentru emiterea unui certificat care continue mai multe parcele,
                                aceastea trebuie sa fie alipite.
                            </Alert>
                        )}
                    </Paper>
                    <Button variant="outlined" size="large" onClick={saveDraft} sx={{ mr: "1rem" }}>
                        Salveaza Draft
                    </Button>
                    <Button
                        onClick={() => saveDraft("depus")}
                        variant="contained"
                        size="large"
                        to="/dashboard/certificat-sumar"
                        component={Link}
                    >
                        Continua spre Plata
                    </Button>
                </Box>
            )}
        </Container>
    );
}

export default function DashboardCertificat() {
    return <Certificat />;
}
