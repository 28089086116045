import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const roles = {
    USER: {
        id: 1,
        email: "v.popescu@example.com",
        provider: "local",
        confirmed: true,
        blocked: false,
        createdAt: "2023-07-26T18:12:37.136Z",
        updatedAt: "2023-08-28T18:16:24.024Z",
        fullname: "Vlad Popescu",
        username: "v.popescu@example.com",
        role: "USER",
    },
    EDITOR: {
        id: 1,
        email: "editor@example.com",
        provider: "local",
        confirmed: true,
        blocked: false,
        createdAt: "2023-07-26T18:12:37.136Z",
        updatedAt: "2023-08-28T18:16:24.024Z",
        fullname: "Eduard Damian",
        username: "editor@example.com",
        role: "EDITOR",
    },
    PRIMAR: {
        id: 1,
        email: "t.dumitru@example.com",
        provider: "local",
        confirmed: true,
        blocked: false,
        createdAt: "2023-07-26T18:12:37.136Z",
        updatedAt: "2023-08-28T18:16:24.024Z",
        fullname: "Teodor Dumitru",
        username: "t.dumitru@example.com",
        role: "PRIMAR",
    },
};

const defaultState = {
    isAuth: false,
    jwt: null,
    userData: null,
    redirectURL: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: defaultState,
    reducers: {
        logIn: (state, action) => {
            state.jwt = action.payload.jwt;
            if (["USER", "EDITOR", "PRIMAR"].includes(action.payload.jwt)) {
                // FAKE SESSION
                state.userData = roles[action.payload.jwt];
            } else {
                // state.userData = action.payload.user;
            }
            state.isAuth = true;
        },
        logOut: () => {
            return defaultState;
        },
        setUserData: (state, action) => {
            let role = "USER";
            if (action.payload && action.payload.role) {
                if (action.payload.role.type === "editor") role = "EDITOR";
                if (action.payload.role.type === "primar") role = "PRIMAR";
                if (action.payload.role.type === "superuser") role = "PRIMAR";
                state.userData = { ...action.payload, role: role, originalRole: action.payload.role };
            } else {
                if (["USER", "EDITOR", "PRIMAR"].includes(state.jwt)) {
                    state.userData = roles[state.jwt];
                } else {
                    return defaultState;
                }
            }
        },
        setRedirectURL: (state, action) => {
            state.redirectURL = action.payload.pathname
        },
    },
});

export const { logIn, logOut, setUserData, setRedirectURL } = authSlice.actions;
export default authSlice.reducer;
