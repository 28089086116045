import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { Link, useNavigate, useParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";

import VisibilityIcon from "@mui/icons-material/Visibility";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Heading from "./components/DashboardHeading";

import { getStatusLabel, getStatusColor } from "./utils/sesizariUtils";

import { TransitionGroup, CSSTransition } from "react-transition-group";

// Generate Order Data
function createData(id, date, categorie, descriere, status) {
    return { id, date, categorie, descriere, status };
}

function TabelSesizari() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sesizari, setSesizari] = React.useState([
        createData(
            0,
            "25 Mar, 2022",
            "Parcări neregulamentare",
            "Buna ziua, permanent ni se blocheaza accesul la poarta si nu pitem iesi sau intra cu masina in propria curte.",
            "lucru"
        ),
        createData(
            1,
            "18 Mar, 2022",
            "Parcări neregulamentare",
            "Parcari pe colturile aleei. Nu putem lua curba sa mergem la locurile de parcare.",
            "rezolvat"
        ),
    ]);
    const { sesizareNoua } = useParams();

    React.useEffect(() => {
        if (sesizareNoua) {
            console.log(JSON.parse(base64_decode(sesizareNoua)));
            // return;
            enqueueSnackbar("Sesizarea a fost adaugata!", { variant: "success" });

            setTimeout(function () {
                setSesizari([
                    ...sesizari,
                    createData(
                        3,
                        "18 Oct, 2022",
                        "Parcări neregulamentare",
                        "Parcari pe colturile aleei. Nu putem lua curba sa mergem la locurile de parcare.",
                        "depus"
                    ),
                ]);
            }, 250);
        }
    }, []);

    return (
        <TableContainer>
            <Table
                size="small"
                sx={{
                    minWidth: 650,
                    "& .MuiTableCell-root": {
                        fontSize: "100%",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Categorie</TableCell>
                        <TableCell>Descriere Sesizare</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TransitionGroup component={TableBody}>
                    {sesizari.map((row) => (
                        <CSSTransition timeout={400} classNames="fade">
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.categorie}</TableCell>
                                <TableCell>{row.descriere}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={getStatusLabel(row.status)}
                                        sx={{
                                            background: getStatusColor(row.status),
                                            color: "#FFF",
                                            textTransform: "uppercase",
                                            fontWeight: 500,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right" sx={{ whiteSpace: "nowrap", pl: 1 }}>
                                    <IconButton color="primary">
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton color="error">
                                        <DeleteForeverIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </Table>
        </TableContainer>
    );
}

function Sesizari() {
    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ p: 3 }}>
                <Heading>Sesizarile Mele</Heading>
                <TabelSesizari />
                <Button
                    endIcon={<PostAddIcon />}
                    variant="contained"
                    to="/dashboard/sesizare"
                    component={Link}
                    sx={{ mt: 3 }}
                >
                    Sesizare Noua
                </Button>
            </Paper>
        </Container>
    );
}

export default Sesizari;
