import logoCluj from ".././images/logo-cluj.png";
import logoBistrita from ".././images/logo-bistrita.png";


const CLUJ_LIMITS = require("./cluj/limit.json");
const CLUJ_PARCURI = require("./cluj/parcuri.json");
const CLUJ_AEROPORT = require("./cluj/aeroport.json");
const CLUJ_POINTS = require("./cluj/points.json");

const BISTRITA_LIMITS = require("./bistrita/limitExtended.json");
const BISTRITA_JUDET = require("./bistrita/judet.json");
const BISTRITA_INTRAVILAN = require("./bistrita/intravilan.json");
const BISTRITA_PARCURI = require("./bistrita/parcuri.json");
const BISTRITA_AEROPORT = require("./bistrita/aeroport.json");
const BISTRITA_POINTS = require("./bistrita/points.json");

const CLUJ = {
    nume: "Cluj-Napoca",
    logo: logoCluj,
    limits: CLUJ_LIMITS,
    judet: BISTRITA_JUDET,
    parcuri: CLUJ_PARCURI,
    aeroport: CLUJ_AEROPORT,
    center: [46.7722665, 23.6013216],
    defaultZoom: 13,
    certificate: CLUJ_POINTS.certificate,
    autorizatii: CLUJ_POINTS.autorizatii
};

const BISTRITA = {
    nume: "Bistrița",
    logo: logoBistrita,
    limits: BISTRITA_LIMITS,
    judet: BISTRITA_JUDET,
    intravilan: BISTRITA_INTRAVILAN,
    parcuri: BISTRITA_PARCURI,
    aeroport: BISTRITA_AEROPORT,
    center: [47.1392, 24.4973],
    defaultZoom: 12,
    certificate: BISTRITA_POINTS.certificate,
    autorizatii: BISTRITA_POINTS.autorizatii
};

export { CLUJ, BISTRITA };
