import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Chip, Badge } from "@mui/material/";
import { Menu, MenuItem } from "@mui/material";

import LaunchIcon from "@mui/icons-material/Launch";
import MenuIcon from "@mui/icons-material/Menu";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from "@mui/icons-material/Article";
import LoginIcon from "@mui/icons-material/Login";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CampaignIcon from "@mui/icons-material/Campaign";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import BadgeIcon from "@mui/icons-material/Badge";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DataObjectIcon from "@mui/icons-material/DataObject";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BugReportIcon from "@mui/icons-material/BugReport";

import Tooltip from "@mui/material/Tooltip";

import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import LinkItem from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PeopleIcon from "@mui/icons-material/People";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import CalculateIcon from "@mui/icons-material/Calculate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import Avatar from "@mui/material/Avatar";

import Cookies from "universal-cookie";

import { grey, purple, blue } from "@mui/material/colors";

import { BISTRITA as CITY } from "./geojson/mapConfig.js";

import { useSelector, useDispatch } from "react-redux";

const hideOnMobile = {
    display: { xs: "none", md: "inherit" },
};

const menuStyle = {
    "& .MuiListItemButton-root": {
        color: "#212121",
    },
    "& .MuiListItemIcon-root": {
        color: "inherit",
    },
    "& .MuiListItemButton-root.Mui-selected": {
        background: "linear-gradient(45deg, rgba(25, 118, 210, 0.14), transparent)",
    },
};

function stringAvatar(name) {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
}

function AccountInfoUser({ userData }) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.25rem 1rem 0.5rem 0.5rem",
                }}
            >
                <Avatar
                    variant="square"
                    to="/dashboard"
                    component={Link}
                    sx={{
                        bgcolor: purple[500],
                        width: 96,
                        height: 96,
                        fontSize: "2rem",
                        fontWeight: 400,
                        m: 1,
                        textDecoration: "none",
                        borderRadius: "4px",
                        boxShadow:
                            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                    }}
                >
                    {stringAvatar(userData.fullname)}
                </Avatar>
                <div style={{ padding: "8px" }}>
                    <h3 style={{ margin: "0 0 4px 0" }}>{userData.fullname}</h3>
                    <p style={{ fontSize: "0.85rem", margin: "0 0 16px 0" }}>{userData.email}</p>
                    <Button
                        to="/logout"
                        component={Link}
                        variant="contained"
                        size="small"
                        startIcon={<PowerSettingsNewIcon />}
                    >
                        Deconectare
                    </Button>
                </div>
            </div>
            <Divider />
        </div>
    );
}

function AccountInfoPrimar({ userData }) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.25rem 1rem 0.5rem 0.5rem",
                }}
            >
                <Avatar
                    variant="square"
                    to="/dashboard"
                    component={Link}
                    sx={{
                        bgcolor: purple[500],
                        width: 96,
                        height: 96,
                        fontSize: "2rem",
                        fontWeight: 400,
                        m: 1,
                        textDecoration: "none",
                        borderRadius: "4px",
                        boxShadow:
                            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                    }}
                >
                    {stringAvatar(userData.fullname)}
                </Avatar>
                <div style={{ padding: "8px" }}>
                    <h3 style={{ margin: "0 0 4px 0" }}>{userData.fullname}</h3>
                    <p style={{ fontSize: "0.85rem", margin: "0 0 16px 0" }}>{userData.email}</p>

                    <Button
                        to="/logout"
                        component={Link}
                        variant="contained"
                        size="small"
                        startIcon={<PowerSettingsNewIcon />}
                    >
                        Deconectare
                    </Button>
                </div>
            </div>
            <p
                style={{
                    fontSize: "0.6rem",
                    margin: "-8px 0 16px 0",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    color: purple[500],
                    width: 126,
                    justifyContent: "center",
                }}
            >
                <BadgeIcon sx={{ fontSize: "16px", margin: "-2px 3px 0 0" }} /> PRIMAR
            </p>
            <Divider />
        </div>
    );
}

function AccountInfoEditor({ userData }) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.25rem 1rem 0.5rem 0.5rem",
                }}
            >
                <Avatar
                    variant="square"
                    to="/dashboard"
                    component={Link}
                    sx={{
                        bgcolor: blue[500],
                        width: 96,
                        height: 96,
                        fontSize: "2rem",
                        fontWeight: 400,
                        m: 1,
                        textDecoration: "none",
                        borderRadius: "4px",
                        boxShadow:
                            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                    }}
                >
                    {stringAvatar(userData.fullname)}
                </Avatar>
                <div style={{ padding: "8px" }}>
                    <h3 style={{ margin: "0 0 4px 0" }}>{userData.fullname}</h3>
                    <p style={{ fontSize: "0.85rem", margin: "0 0 16px 0" }}>{userData.email}</p>

                    <Button
                        to="/logout"
                        component={Link}
                        variant="contained"
                        size="small"
                        startIcon={<PowerSettingsNewIcon />}
                    >
                        Deconectare
                    </Button>
                </div>
            </div>
            <p
                style={{
                    fontSize: "0.6rem",
                    margin: "-8px 0 16px 0",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    color: blue[500],
                    width: 126,
                    justifyContent: "center",
                }}
            >
                <BadgeIcon sx={{ fontSize: "16px", margin: "-2px 3px 0 0" }} /> EDITOR
            </p>
            <Divider />
        </div>
    );
}

function UserOptions(props) {
    return (
        <React.Fragment>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/certificat"
                component={Link}
            >
                <ListItemIcon>
                    <PostAddIcon />
                </ListItemIcon>
                <ListItemText>Emitere Certificat</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/certificate"
                component={Link}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText>Cereri</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/sesizare"
                component={Link}
            >
                <ListItemIcon>
                    <AddLocationAltIcon />
                </ListItemIcon>
                <ListItemText>Depune o Sesizare</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/sesizarile-mele"
                component={Link}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText>Sesizari</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/alerte"
                component={Link}
            >
                <ListItemIcon>
                    <CampaignIcon />
                </ListItemIcon>
                <ListItemText>Alerte</ListItemText>
                <Chip label="3" size="small" color="primary" />
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/notificari"
                component={Link}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText>Notificari</ListItemText>
                <Chip label="2" size="small" color="primary" />
            </MenuItem>
        </React.Fragment>
    );
}

function PrimarOptions(props) {
    return (
        <React.Fragment>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/cereri"
                component={Link}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText>Cereri</ListItemText>
                <Chip label="3" size="small" color="primary" />
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/amenzi"
                component={Link}
            >
                <ListItemIcon>
                    <LocalPoliceIcon />
                </ListItemIcon>
                <ListItemText>Amenzi</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/sesizari"
                component={Link}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText>Sesizari</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/registratura"
                component={Link}
            >
                <ListItemIcon>
                    <CalculateIcon />
                </ListItemIcon>
                <ListItemText>Registratura</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/gestionare-conturi"
                component={Link}
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText>Gestionare Conturi</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/export"
                component={Link}
            >
                <ListItemIcon>
                    <UnarchiveIcon />
                </ListItemIcon>
                <ListItemText>Export Date</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/notificari"
                component={Link}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText>Notificari</ListItemText>
                <Chip label="2" size="small" color="primary" />
            </MenuItem>
        </React.Fragment>
    );
}

function EditorOptions(props) {
    return (
        <React.Fragment>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/cereri"
                component={Link}
            >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText>Cereri</ListItemText>
                <Chip label="3" size="small" color="primary" />
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/sesizari"
                component={Link}
            >
                <ListItemIcon>
                    <FmdGoodIcon />
                </ListItemIcon>
                <ListItemText>Sesizari</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/registratura"
                component={Link}
            >
                <ListItemIcon>
                    <CalculateIcon />
                </ListItemIcon>
                <ListItemText>Registratura</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/import"
                component={Link}
            >
                <ListItemIcon>
                    <DataObjectIcon />
                </ListItemIcon>
                <ListItemText>Import Date</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/tichete"
                component={Link}
            >
                <ListItemIcon>
                    <BugReportIcon />
                </ListItemIcon>
                <ListItemText>Tichete</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={props.onClick}
                sx={{ p: 1.5 }}
                to="/dashboard/notificari"
                component={Link}
            >
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText>Notificari</ListItemText>
                <Chip label="2" size="small" color="primary" />
            </MenuItem>
        </React.Fragment>
    );
}

const options = ["certificat de urbanism", "autorizatie de construire", "aviz arhitect sef"];

function Toolbar(props) {
    const session = useSelector((state) => state.auth.isAuth);
    const userData = useSelector((state) => state.auth.userData);

    const [userRole, setUserRole] = React.useState(null);

    const [isDashboard, setIsDashboard] = React.useState(false);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const open = Boolean(menuAnchorEl);
    const handleClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    React.useEffect(() => {
        if (props && props.isDashboard) setIsDashboard(props.isDashboard);
    }, []);

    React.useEffect(() => {
        if (userData) setUserRole(userData.role);
    }, [userData]);

    const [openPopper, setOpenPopper] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = (event, index) => {
        setOpenPopper(false);
    };

    const handleToggle = () => {
        setOpenPopper((prevOpen) => !prevOpen);
    };

    const handleClosePopper = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopper(false);
    };

    const [linksPopper, setLinksPopper] = React.useState(false);
    const linksRef = React.useRef(null);

    const handleLinksToggle = () => {
        setLinksPopper((prevOpen) => !prevOpen);
    };

    const handleCloseLinkPopper = (event) => {
        if (linksRef.current && linksRef.current.contains(event.target)) {
            return;
        }
        setLinksPopper(false);
    };

    const complexButton = (
        <Fragment>
            <Button
                variant="contained"
                startIcon={<PostAddIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ marginRight: 1, backgroundColor: grey[900], ...hideOnMobile }}
                ref={anchorRef}
                onClick={handleToggle}
            >
                Solicita documentatii
            </Button>

            <Popper
                sx={{
                    zIndex: 3000,
                }}
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4],
                        },
                    },
                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ border: "1px solid rgba(0,0,0,0.4)" }} elevation={3}>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                    sx={{ textTransform: "uppercase" }}
                                >
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === 0}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            to="/dashboard/certificat"
                                            component={Link}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );

    const sessionToolbar = (
        <div>
            {userRole === "USER" && !isDashboard && <Fragment>{complexButton}</Fragment>}
            {["PRIMAR", "EDITOR"].includes(userRole) && !isDashboard && (
                <Button
                    to="/dashboard"
                    component={Link}
                    variant="contained"
                    startIcon={<ManageAccountsIcon />}
                    sx={{ marginRight: 1, ...hideOnMobile }}
                    color="primary"
                >
                    Administrare Platforma
                </Button>
            )}
            {isDashboard && (
                <Button
                    to="/"
                    component={Link}
                    variant="outlined"
                    color="inherit"
                    startIcon={<KeyboardDoubleArrowLeftIcon />}
                    sx={{ marginRight: 1, ...hideOnMobile }}
                >
                    Înapoi la hartă
                </Button>
            )}
            <Divider sx={{ height: 28, m: 1, ...hideOnMobile }} orientation="vertical" />
            <Tooltip title="Meniu Principal" placement="bottom">
                <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    component="span"
                >
                    {session && (
                        <Badge
                            color="primary"
                            badgeContent={5}
                            sx={{
                                "& .MuiBadge-badge": {
                                    right: 3,
                                    top: 3,
                                    border: "1px solid #FFF",
                                    padding: "0 4px",
                                },
                            }}
                        >
                            {userRole === "PRIMAR" && (
                                <Avatar
                                    sx={{
                                        bgcolor: purple[500],
                                        fontSize: "1rem",
                                        textDecoration: "none",
                                    }}
                                >
                                    {stringAvatar(userData.fullname)}
                                </Avatar>
                            )}
                            {userRole === "EDITOR" && (
                                <Avatar
                                    sx={{
                                        bgcolor: blue[500],
                                        fontSize: "1rem",
                                        textDecoration: "none",
                                    }}
                                >
                                    {stringAvatar(userData.fullname)}
                                </Avatar>
                            )}
                            {userRole === "USER" && (
                                <Avatar
                                    sx={{
                                        bgcolor: purple[500],
                                        fontSize: "1rem",
                                        textDecoration: "none",
                                    }}
                                >
                                    {stringAvatar(userData.fullname)}
                                </Avatar>
                            )}
                        </Badge>
                    )}
                    {!session && <MenuIcon sx={{ color: grey[900], fontSize: "1.75rem" }} />}
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        transform: "translateX(8px) translateY(10px)",
                    },
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                sx={menuStyle}
            >
                {userRole === "USER" && <AccountInfoUser userData={userData} />}

                {["PRIMAR"].includes(userRole) && <AccountInfoPrimar userData={userData} />}

                {["EDITOR"].includes(userRole) && <AccountInfoEditor userData={userData} />}

                {userRole === "USER" && <UserOptions onClick={handleClose} />}

                {["PRIMAR"].includes(userRole) && <PrimarOptions />}

                {["EDITOR"].includes(userRole) && <EditorOptions />}

                <Divider />

                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/manual-utilizare"
                    component={Link}
                >
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText>Manual Utilizare</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/dashboard/cont"
                    component={Link}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Contul Meu</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/dashboard/siguranta"
                    component={Link}
                >
                    <ListItemIcon>
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText>Setari Siguranta</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );

    const publicToolbar = (
        <div>
            {complexButton}

            <Divider sx={{ height: 28, m: 1, ...hideOnMobile }} orientation="vertical" />

            <Tooltip title="Contul Meu" placement="bottom">
                <IconButton to="/dashboard" component={Link} sx={{ color: grey[900] }}>
                    <AccountCircleIcon sx={{ color: grey[900], fontSize: "1.75rem" }} />
                </IconButton>
            </Tooltip>
            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
            <Tooltip title="Meniu Principal" placement="bottom">
                <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    component="span"
                >
                    <MenuIcon sx={{ color: grey[900], fontSize: "1.75rem" }} />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        transform: "translateX(12px) translateY(12px)",
                    },
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                sx={menuStyle}
            >
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/dashboard/certificat"
                    component={Link}
                >
                    <ListItemIcon>
                        <PostAddIcon />
                    </ListItemIcon>
                    <ListItemText>Emitere Certificat</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/dashboard/sesizare"
                    component={Link}
                >
                    <ListItemIcon>
                        <AddLocationAltIcon />
                    </ListItemIcon>
                    <ListItemText>Depune o Sesizare</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/dashboard/alerte"
                    component={Link}
                >
                    <ListItemIcon>
                        <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText>Alerte</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleClose}
                    sx={{ p: 1.5 }}
                    to="/manual-utilizare"
                    component={Link}
                >
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText>Manual Utilizare</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} sx={{ p: 1.5 }} to="/login" component={Link}>
                    <ListItemIcon>
                        <LoginIcon />
                    </ListItemIcon>
                    <ListItemText>Autentificare</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );

    return (
        <div className="App-toolbar">
            <div>
                <Link to="/" className="App-logo-primarie">
                    <img src={CITY.logo} />
                    <em>
                        <strong>GIS</strong>
                    </em>
                </Link>
                <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                <span className="App-nume-primarie" onClick={handleLinksToggle} ref={linksRef}>
                    {CITY.nume} <KeyboardArrowDownIcon />
                </span>

                <Popper
                    sx={{
                        zIndex: 3000,
                    }}
                    open={linksPopper}
                    anchorEl={linksRef.current}
                    role={undefined}
                    transition
                    modifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [0, 8],
                            },
                        },
                    ]}
                    placement="bottom-start"
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper sx={{ border: "1px solid rgba(0,0,0,0.4)" }} elevation={3}>
                                <ClickAwayListener onClickAway={handleCloseLinkPopper}>
                                    <MenuList
                                        id="split-button-menu"
                                        autoFocusItem
                                        sx={{ textTransform: "uppercase" }}
                                    >
                                        <MenuItem
                                            href="https://gis-3d-flaviusmatis.vercel.app/?xyz=47.130067775987754%2C24.489809675659558%2C13.568194122837877&pitch=50"
                                            target="_blank"
                                            component={LinkItem}
                                        >
                                            Bistrita 3D
                                            <LaunchIcon
                                                sx={{ ml: 1, fontSize: "100%", color: "blue" }}
                                            />
                                        </MenuItem>

                                        <MenuItem
                                            href="https://gis-3d-flaviusmatis.vercel.app/?xyz=44.42324389179481%2C26.092523733498638%2C14.392334134880153&pitch=50"
                                            target="_blank"
                                            component={LinkItem}
                                        >
                                            Bucuresti 3D
                                            <LaunchIcon
                                                sx={{ ml: 1, fontSize: "100%", color: "blue" }}
                                            />
                                        </MenuItem>

                                        <MenuItem
                                            href="https://gis-3d-flaviusmatis.vercel.app/?xyz=46.76548377150442%2C23.596238465862484%2C13.088643599229917"
                                            target="_blank"
                                            component={LinkItem}
                                        >
                                            Cluj-Napoca 3D
                                            <LaunchIcon
                                                sx={{ ml: 1, fontSize: "100%", color: "blue" }}
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            href="https://gis-3d-flaviusmatis.vercel.app/"
                                            target="_blank"
                                            component={LinkItem}
                                        >
                                            UAT-uri Hunedoara
                                            <LaunchIcon
                                                sx={{ ml: 1, fontSize: "100%", color: "blue" }}
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            href="https://openlayers.vercel.app/"
                                            target="_blank"
                                            component={LinkItem}
                                        >
                                            Slatina - Stereo 70
                                            <LaunchIcon
                                                sx={{ ml: 1, fontSize: "100%", color: "blue" }}
                                            />
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            {session ? sessionToolbar : publicToolbar}
        </div>
    );
}

export default Toolbar;
