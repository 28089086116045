import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const defaultState = {
    data: [],
};

export const gpsSlice = createSlice({
    name: "gps",
    initialState: defaultState,
    reducers: {
        addGPS: (state, action) => {
            state.data = [...state.data, action.payload];
        },
        deleteGPS: (state, action) => {
            const index = state.data.findIndex((item) => item.id === action.payload.id);
            if (index > -1)
                state.data = [
                    ...state.data.slice(0, index), // everything before current post
                    ...state.data.slice(index + 1), // everything after current post
                ];
        },
    },
});

export const { addGPS, deleteGPS } = gpsSlice.actions;
export default gpsSlice.reducer;
