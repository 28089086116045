import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Checkbox } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const defaultData = {
    detalii: "",
    motiv: "altele",
};

function RespingereCerere(props) {
    let navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [formData, setFormData] = React.useState({});
    const [formErrors, setFormErrors] = React.useState({});

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const validateInput = (name, value) => {
        const validInput = !!value.trim();
        if (!validInput) {
            setFormErrors({ ...formErrors, [name]: true });
        } else {
            setFormErrors({ ...formErrors, [name]: false });
        }
    };

    const isValidForm = (inputs) => {
        let isValid = true;
        let errors = {};
        inputs.forEach((name) => {
            const validInput = !!formData[name].trim();
            if (!validInput) {
                errors[name] = true;
                isValid = false;
            } else {
                errors[name] = false;
            }
        });
        setFormErrors({ ...formErrors, ...errors });
        return isValid;
    };

    const handleSubmit = () => {
        if (isValidForm(["detalii"])) {
            props.handleModalClose(formData);
            setFormData(defaultData);
            enqueueSnackbar("Cererea a fost respinsa", { variant: "success" });
            navigate("/dashboard/cereri");
        }
    };

    const handleClose = () => {
        props.handleModalClose();
    };

    React.useEffect(() => {
        setFormData(defaultData);
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300, width: 640 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Respinge Cererea</span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box sx={{ pt: 1 }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Motiv</InputLabel>
                        <Select
                            name="motiv"
                            value={formData.motiv || "altele"}
                            label="Motiv"
                            onChange={handleFormChange}
                        >
                            <MenuItem value="zona">Parcelele nu apartin aceleiasi zone</MenuItem>
                            <MenuItem value="beneficiar">Date beneficiar gresite</MenuItem>
                            <MenuItem value="altele">Alt motiv</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        name="detalii"
                        value={formData.detalii}
                        onChange={handleFormChange}
                        label="Detalii motiv"
                        error={formErrors.detalii}
                        helperText={formErrors.detalii ? "Camp obligatoriu" : ""}
                        multiline
                        minRows={4}
                    />
                </Box>

                <FormGroup
                    sx={{
                        m: 1,
                    }}
                >
                    <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Trimite email de notificare utilizatorului"
                    />
                </FormGroup>
            </DialogContent>

            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSubmit}>
                    Respinge
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RespingereCerere;
