import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";

import { useSelector, useDispatch } from "react-redux";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function AddComment({ handleClose, handleSubmit }) {
    const [text, setText] = React.useState("");
    const [notificare, setNotificare] = React.useState(false);

    const saveComment = (e) => {
        if (text.trim()) {
            handleSubmit(text);
            setText("");
        }
    };
    return (
        <Box sx={{}}>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Scrie mesajul aici..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={6}
                        sx={{ mb: 1 }}
                        autoFocus
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notificare}
                                    onChange={(e) => setNotificare(e.target.checked)}
                                />
                            }
                            label="Trimite notificare prin email"
                        />
                        <Box
                            sx={{
                                padding: 1,
                                background: "#F6F6F6",
                                borderRadius: "4px",
                                mb: 2,
                                fontSize: "90%",
                                opacity: 0.75,
                                lineHeight: 1.5,
                            }}
                        >
                            In cazul in care se bifeaza optiunea "
                            <strong>Trimite notificare prin email</strong>", solicitantul
                            documentului va fi notificat in legatura cu mesajul primit.
                        </Box>
                    </Box>
                    <Button variant="contained" sx={{ mr: 1 }} onClick={saveComment}>
                        Trimite Mesaj
                    </Button>
                    <Button variant="outlined" color="inherit" startIcon={<AttachFileIcon/>}>
                        Ataseaza Fisiere
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function Reply({ username, handleClose, handleSubmit }) {
    const [text, setText] = React.useState("");

    const saveComment = (e) => {
        if (text.trim()) {
            handleSubmit(text);
            setText("");
        }
    };
    return (
        <Box sx={{ pb: 2 }}>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
                {/* <Avatar
                    sx={{ mr: 1, mt: "8px", bgcolor: stringToColor(username) }}
                    alt={username}
                /> */}
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Scrie mesajul aici..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        multiline
                        fullWidth
                        maxRows={4}
                        sx={{ mb: 1 }}
                        autoFocus
                    />

                    <Button variant="contained" sx={{ mr: 1 }} onClick={saveComment}>
                        Trimite Mesaj
                    </Button>

                    <Button variant="outlined" color="inherit" startIcon={<AttachFileIcon/>}>
                        Ataseaza Fisiere
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

function TrimiteMesaj(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [comments, setComments] = React.useState([]);

    const userData = useSelector((state) => state.auth.userData);

    const handleSubmit = (text) => {
        // props.handleModalClose();
        setComments([
            ...comments,
            {
                text: text,
                username: userData.fullname,
                date: new Date(),
            },
        ]);
        enqueueSnackbar("Mesajul a fost trimis", { variant: "info" });
    };

    const handleClose = () => {
        props.handleModalClose();
    };

    React.useEffect(() => {
        if (userData && userData.role === "USER")
            setComments([
                {
                    text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
                    username: "Radu Mihai",
                    date: new Date(),
                },
            ]);
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300, width: 640 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Mesaje</span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                {comments.map((comment, index) => (
                    <Box sx={{ mb: 1 }} key={index}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mb: 1,
                                }}
                            >
                                <Avatar
                                    sx={{ mr: 1, bgcolor: stringToColor(comment.username) }}
                                    alt={comment.username}
                                />
                                <Box sx={{ fontSize: "96%" }}>
                                    <Box sx={{ fontWeight: 500, pb: 0.25 }}>{comment.username}</Box>
                                    <Box sx={{ fontSize: "85%", opacity: 0.75 }}>
                                        {comment.date.toDateString()}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {comment.text && (
                            <Alert
                                icon={false}
                                severity="info"
                                sx={{ background: "#F6F6F6", fontSize: "14px" }}
                            >
                                {comment.text}
                            </Alert>
                        )}
                    </Box>
                ))}

                {comments.length === 0 && (
                    <AddComment handleClose={handleClose} handleSubmit={handleSubmit} />
                )}

                {comments.length !== 0 && (
                    <Reply
                        username={userData.fullname}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}

export default TrimiteMesaj;
