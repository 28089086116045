import { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, ScaleControl } from "react-leaflet";
import L from "leaflet";
import tileLayer from "./utils/tileLayer";

const center = [47.1392, 24.4973];

let parcelsGroup = null;

const Parcela = ({ map, parcelData }) => {
    const [initialized, setInitialized] = useState(false);

    if (map && parcelData && !initialized) {
        setInitialized(true);
        init();
    }

    function init() {
        const geoJsonStyle = {
            fillColor: "yellow",
            weight: 2,
            opacity: 1,
            color: "blue",
            fillOpacity: 0.25,
        };

        function zoomToParcel() {
            map.fitBounds(parcelsGroup.getBounds(), { maxZoom: 18 });
        }

        const unlabeledParcel = L.geoJSON(parcelData, {
            onEachFeature: onEachFeaturePopup,
            style: geoJsonStyle,
        });

        function onEachFeaturePopup(feature, layer) {
            const tooltipText = feature.properties.cad ? feature.properties.cad.toString() : null;
            if (tooltipText)
                layer.bindTooltip(tooltipText, {
                    direction: "top",
                });
            layer.on("click", function (e) {
                map.fitBounds(layer.getBounds(), { maxZoom: 18 });
            });
        }

        const labeledParcel = L.geoJSON(parcelData, {
            onEachFeature: onEachFeature,
            style: geoJsonStyle,
        });

        function onEachFeature(feature, layer) {
            const tooltipText = feature.properties.cad ? feature.properties.cad.toString() : null;
            if (tooltipText)
                layer.bindTooltip(tooltipText, {
                    permanent: true,
                    direction: "center",
                    className: "parcelLabel",
                });
        }

        parcelsGroup = L.featureGroup([labeledParcel]);

        map.addLayer(parcelsGroup);

        map.on("moveend", function () {
            map.removeLayer(parcelsGroup);
            if (map.getZoom() > 16) {
                parcelsGroup = L.featureGroup([labeledParcel]);
                map.addLayer(parcelsGroup);
            } else {
                parcelsGroup = L.featureGroup([unlabeledParcel]);
                map.addLayer(parcelsGroup);
            }
        });

        zoomToParcel();
    }

    useEffect(() => {
        if (parcelsGroup && map) {
            map.removeLayer(parcelsGroup);
            if (parcelData) init();
        }
    }, [parcelData]);

    return null;
};

const PrevizualizareHarta = ({ parcelData }) => {
    const [map, setMap] = useState(null);
    const [mapKey, setMapKey] = useState(0);

    useEffect(() => {
        if (parcelData) setMapKey(new Date().getTime());
    }, [parcelData]);

    return (
        <div style={{ height: "360px", width: "100%", border: "1px solid", position: "relative" }}>
            <MapContainer
                whenCreated={setMap}
                zoom={13}
                scrollWheelZoom={false}
                center={center}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer {...tileLayer} />
                <Parcela key={mapKey} map={map} parcelData={parcelData} />
                <ScaleControl position="bottomright" imperial={false} maxWidth="100" />
            </MapContainer>
        </div>
    );
};

export default PrevizualizareHarta;
