import * as React from "react";
import { Link } from "react-router-dom";

import LinkButton from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Heading from "./components/DashboardHeading";
import {
    BarChart,
    Bar,
} from "recharts";

const data = [
    {
        name: "Page A",
        uv: 2000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default function Deposits() {
    return (
        <React.Fragment>
            <Heading>Total Amenzi Incasate</Heading>
            <Typography component="p" variant="h4">
                32.400 RON
            </Typography>
            <div style={{ flex: 1 }}>
                <Typography color="text.secondary" sx={{ marginBottom: "1rem" }}>
                    Septembrie 2022
                </Typography>
                <BarChart
                    width={150}
                    height={60}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
            </div>
            <div>
                <LinkButton to="/dashboard/amenzi" component={Link} color="primary">
                    Vezi Toate Amenzile...
                </LinkButton>
            </div>
        </React.Fragment>
    );
}
