import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import { Box, Checkbox } from "@mui/material";

function AdaugaReclamatie(props) {
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <Dialog
            open={props.open}
            scroll="body"
            onBackdropClick={props.handleModalClose}
            onClose={props.handleModalClose}
            maxWidth="lg"
            sx={{
                "& .MuiDialog-paper": { minHeight: 300 },
            }}
        >
            <DialogTitle
                id="scroll-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>Adauga o inregistrare noua</span>
                <IconButton
                    color="default"
                    component="span"
                    onClick={props.handleModalClose}
                >
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box
                    sx={{
                        "& .MuiFormControl-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tip Intrare</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={10}
                            label="Tip Intrare"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Amenda</MenuItem>
                            <MenuItem value={20}>Reclamatie</MenuItem>
                            <MenuItem value={30}>Altele</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <div>
                        <TextField label="Adresa" />
                    </div>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <div>
                        <TextField label="Descriere" multiline minRows={4} />
                    </div>
                </Box>
                <Box
                    sx={{
                        m: 1,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100px",
                            border: "1px dotted #333",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F6F6F6",
                        }}
                    >
                        <DriveFolderUploadIcon sx={{ marginRight: "0.5rem" }} />{" "}
                        <span>Adauga imagini sau documente pentru aceasta inregistrare</span>
                    </div>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={props.handleModalClose}>Anuleaza</Button>
                <Button variant="contained" onClick={props.handleModalClose}>
                    Salveaza inregistrarea
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaReclamatie;
