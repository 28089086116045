import React from "react";
import PropTypes from "prop-types";

import { Button, Stack } from "@mui/material/";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

function StergeAlerta({ open, alerta, handleClose, handleDelete }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Sterge alerta</DialogTitle>
            <DialogContent>
                <p>
                    Alerta pentru zona{" "}
                    <strong style={{ background: "aliceblue", padding: "10px", borderRadius: 4 }}>
                        {alerta.obiectiv}
                    </strong>{" "}
                    va fi stearsa. Doriti sa continuati?
                </p>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} sx={{ m: 1 }} direction="row">
                    <Button variant="outlined" onClick={handleClose}>
                        Anulare
                    </Button>
                    <Button variant="contained" autoFocus onClick={handleDelete}>
                        Sterge alerta
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

StergeAlerta.propTypes = {
    open: PropTypes.bool,
    alerta: PropTypes.object,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func,
};

export default StergeAlerta;
