import L from "leaflet";

function dotIcon(color, size = 10) {
    const svgTemplate = `
      <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle fill="${color}" stroke="white" stroke-width="1" cx="5" cy="5" r="5" />
      </svg>
    `;
    let divIcon = new L.DivIcon({
        className: "custom-leaflet-dot",
        html: svgTemplate,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, 0 - size / 2 - 2],
    });
    return divIcon;
}

function customPinIcon(color, size = 40, animated = false) {
    const svgTemplate = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
          <path fill-opacity="0.25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/><path stroke="#fff" fill="${color}" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/>
      </svg>
    `;
    let divIcon = new L.DivIcon({
        className: animated ? "custom-animated-pin" : "",
        html: svgTemplate,
        iconSize: [size, size],
        iconAnchor: [size / 2, size],
        popupAnchor: [1, 0 - size - 2],
    });
    return divIcon;
}

function fontAwesomeIcon(color, size = 40) {
    const svgTemplate = `
        <div style='position:relative;color:#FFF'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker" overflow="visible">
                <path fill-opacity="0.25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
                <path stroke="#fff" fill="${color}" d="M 15.938,32 C 15.938,32 6,17.938 6,11.938 6,0.125 15.938,0 15.938,0 15.938,0 26,0.125 26,11.875 26,18.062 15.938,32 15.938,32 Z" />
            </svg>
            <div style="position:absolute;top:7px;left:50%;transform:translate(-50%, 0)">
                <i class="fa-solid fa-graduation-cap" style="width:18px;height:18px;"></i>
            <div>
        </div>
    `;
    let divIcon = new L.DivIcon({
        className: "custom-animated-pin",
        html: svgTemplate,
        iconSize: [size, size],
        iconAnchor: [size / 2, size],
        popupAnchor: [1, 0 - size - 2],
    });
    return divIcon;
}

function fontAwesomeIconFat({ color, size = 40, icon }) {
    const svgTemplate = `
        <div style='position:relative;color:#FFF'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker" overflow="visible">
                <path fill-opacity="0.25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
                <path stroke="#fff" fill="${color}" d="m 16.000001,0.00253095 c -6.5788717,0 -11.9310568,5.35434045 -11.9310568,11.93579605 0,9.805931 10.7434778,19.380572 11.2009938,19.783469 0.208558,0.183675 0.469256,0.275673 0.730063,0.275673 0.260807,0 0.521506,-0.0919 0.730172,-0.275673 0.4573,-0.40279 11.200883,-9.977431 11.200883,-19.783469 0,-6.5814556 -5.352185,-11.93579605 -11.931055,-11.93579605 z" />
            </svg>
            <div style="position:absolute;top:7px;left:50%;transform:translate(-50%, 0)">
                <i class="fa-solid ${icon}" style="width:18px;height:18px;"></i>
            <div>
        </div>
    `;
    let divIcon = new L.DivIcon({
        className: "custom-animated-pin",
        html: svgTemplate,
        iconSize: [size, size],
        iconAnchor: [size / 2, size],
        popupAnchor: [1, 0 - size - 2],
        tooltipAnchor: [1, 0 - size - 2],
    });
    return divIcon;
}

export { dotIcon, customPinIcon, fontAwesomeIcon, fontAwesomeIconFat };
