import React from "react";

import { Button, Stack, Chip } from "@mui/material/";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function VizualizareTichet(props) {
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>
                    Tichet #{props.ticket.id} din data{" "}
                    {new Date(props.ticket.attributes.createdAt).toLocaleDateString()}
                </span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "96%" }}>
                    <strong>Adresa Email:</strong> {props.ticket.attributes.email}
                    <br />
                    <strong>Telefon:</strong> {props.ticket.attributes.phone}
                    <br />
                    <strong>Descriere Email:</strong> {props.ticket.attributes.description}
                    <br />
                    <br />
                    <strong>Tipul Hartii:</strong> {props.ticket.attributes.context.mapType}
                    <br />
                    <strong>Latitudine:</strong> {props.ticket.attributes.context.lat}
                    <br />
                    <strong>Longitudine:</strong> {props.ticket.attributes.context.lng}
                    <br />
                    <strong>Nivel Zoom:</strong> {props.ticket.attributes.context.zoom}
                    <br />
                    <strong style={{ marginRight: "0.5rem" }}>Straturi Afisate:</strong>
                    {props.ticket.attributes.context.layers.map((item, index) => (
                        <Chip label={item} sx={{ mr: 1, mb: 1 }}></Chip>
                    ))}
                    <br />
                    <br />
                    <strong>Link Harta:</strong>{" "}
                    <a
                        href={props.ticket.attributes.context.link}
                        target="_blank"
                        style={{ wordBreak: "break-all" }}
                    >
                        {props.ticket.attributes.context.link}
                    </a>
                    <br />
                    <br />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default VizualizareTichet;
