import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Heading from "./components/DashboardHeading";
import Comment from "./components/Comment";

import { Button, Box } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubjectIcon from "@mui/icons-material/Subject";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockIcon from "@mui/icons-material/Lock";

import SemnareDialog from "./SemnareDialog";
import PDFSample from "./images/certificat.pdf";

import { Link, useParams } from "react-router-dom";

import semnatura1 from "./images/semnaturi/sign1.svg";
import semnatura2 from "./images/semnaturi/sign2.svg";
import semnatura3 from "./images/semnaturi/sign3.svg";
import semnatura4 from "./images/semnaturi/sign4.svg";

import { useSnackbar } from "notistack";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

function SignatureBox({ nume, pozitie, handleSemneaza, signed, canBeSigned, signature }) {
    return (
        <Grid item xs={12} md={6}>
            <h4
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.75rem",
                }}
            >
                <span>{nume}</span>
                <span
                    style={{
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "90%",
                    }}
                >
                    {pozitie}
                </span>
            </h4>
            {signed && (
                <Box
                    sx={{
                        padding: 3,
                        border: "1px dashed",
                        fontSize: "0.9rem",
                        fontWeight: 500,
                        mb: 2,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img src={signature} style={{ maxWidth: 100, height: 60 }} />
                </Box>
            )}
            {!signed && canBeSigned && (
                <Box
                    sx={{
                        padding: 3,
                        border: "1px dashed",
                        fontSize: "0.9rem",
                        fontWeight: 500,
                        mb: 2,
                    }}
                >
                    <Box
                        sx={{
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            startIcon={<HistoryEduIcon />}
                            onClick={handleSemneaza}
                        >
                            Semneaza certificatul
                        </Button>
                    </Box>
                </Box>
            )}
            {!signed && !canBeSigned && (
                <Box
                    sx={{
                        padding: 3,
                        border: "1px dashed",
                        background: "rgba(0,0,0,0.1)",
                        mb: 2,
                    }}
                >
                    <Box
                        sx={{
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <AccessTimeIcon sx={{ color: "rgba(0,0,0,0.5)", fontSize: "1.75rem" }} />
                    </Box>
                </Box>
            )}
            {!signed && (
                <p style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon sx={{ color: "orange", mr: 0.5 }} />
                    <span>Se asteapta semnatura</span>
                </p>
            )}
            {signed && (
                <p style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon sx={{ color: "mediumseagreen", mr: 0.5 }} />
                    <span>Semnat la data: 14/11/22 | 12:12:41 pm</span>
                </p>
            )}
        </Grid>
    );
}

function Semnare() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [canBeSigned, setCanBeSigned] = React.useState(true);
    const [signed, setSigned] = React.useState(false);


    const [viewSemnareDialog, setViewSemnareDialog] = React.useState();
    const [comments, setComments] = React.useState([
        {
            text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
            username: "Ion Popescu",
            date: new Date(),
            blocking: false,
            replies: [
                {
                    text: "Nu am inteles care este problema.",
                    username: "Radu Mihai",
                    date: new Date(),
                },
                {
                    text: "Explicatia este simpla...",
                    username: "Dan Pop",
                    date: new Date(),
                },
            ],
        },
        // {
        //     text: "Lipseste Anexa 2 pentru certificatele de tip ABC...",
        //     username: "Ion Popescu",
        //     date: new Date(),
        //     blocking: false,
        //     replies: [],
        // },
    ]);

    const handleSemneaza = () => {
        setViewSemnareDialog(true);
    };

    const handleSign = () => {
        setViewSemnareDialog(false);
        setSigned(true);
        enqueueSnackbar("Documentul a fost semnat", { variant: "success" });

    };

    const adaugaObservatie = () => {
        if (!comments.length || comments[comments.length - 1].text) {
            setComments([
                ...comments,
                {
                    text: "",
                    username: "Teodor Dumitru",
                    date: new Date(),
                    replies: [],
                },
            ]);
        }
    };

    const saveComment = (index, text, blocking) => {
        if (text.trim()) {
            let commentsCopy = comments;
            commentsCopy[index].text = text;
            commentsCopy[index].blocking = blocking;
            setComments([...commentsCopy]);
            enqueueSnackbar("Observatia a fost adaugata!", { variant: "success" });
            document.comments = commentsCopy;
        }
    };

    const deleteComment = (index) => {
        let commentsCopy = comments;
        commentsCopy.splice(index, 1);
        setComments([...commentsCopy]);
    };

    const addReply = (index, text, willUnblockIssue) => {
        if (text.trim()) {
            let commentsCopy = comments;
            commentsCopy[index].replies.push({
                text: text,
                username: "Teodor Dumitru",
                date: new Date(),
            });
            if (willUnblockIssue) commentsCopy[index].blocking = false;
            setComments([...commentsCopy]);
            enqueueSnackbar("Raspunsul a fost adaugat!", { variant: "success" });
        }
    };

    React.useEffect(() => {
        let hasBlockingIssues = false;
        comments.forEach((element) => {
            if (element.blocking) hasBlockingIssues = true;
        });
        setCanBeSigned(!hasBlockingIssues);
    }, [comments]);

    React.useEffect(() => {
        document.getElementById("dashboard-scroll").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <SubjectIcon sx={{ mb: "-5px", mr: 1.25 }} />
                <span>Semnare certificat urbanism</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js`}>
                    <div
                        style={{
                            border: "1px solid #b2b2b2",
                            marginBottom: "1rem",
                            borderRadius: "4px",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                background: "#EEE",
                                padding: "0.75rem",
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                borderBottom: "1px solid #d6d6d6",
                            }}
                        >
                            Previzualizare Certificat
                        </div>
                        <div
                            style={{
                                height: "842px",
                                position: "relative",
                            }}
                        >
                            <Viewer fileUrl={PDFSample} />
                        </div>
                    </div>
                </Worker>
                <Heading sx={{ pb: 1, mt: 3 }}>Observatii:</Heading>

                {!comments.length && (
                    <div
                        style={{
                            alignItems: "center",
                            border: "1px dashed rgba(0, 0, 0, .6)",
                            display: "flex",
                            height: "100px",
                            justifyContent: "center",
                            opacity: 0.5,
                            marginBottom: "1rem",
                        }}
                    >
                        Nu exista observatii.
                    </div>
                )}
                {comments.map((comment, index) => (
                    <Comment
                        data={comment}
                        index={index}
                        saveComment={saveComment}
                        deleteComment={deleteComment}
                        addReply={addReply}
                        sx={{ mb: 3 }}
                    />
                ))}

                <Button size="large" variant="contained" onClick={adaugaObservatie}>
                    Adauga observatie
                </Button>

                <Heading sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)", pb: 1, mt: 3 }}>
                    Semnaturi:
                </Heading>

                {!canBeSigned && (
                    <div
                        style={{
                            alignItems: "center",
                            border: "1px dashed rgba(0, 0, 0, .6)",
                            display: "flex",
                            height: "100px",
                            justifyContent: "center",
                            opacity: 0.5,
                            marginBottom: "1rem",
                        }}
                    >
                        Documentul are observatii care necesita rezolvare. Semnaturile pot fi
                        efectuate doar dupa rezolvarea observatiilor.
                    </div>
                )}

                {canBeSigned && (
                    <Grid container spacing={4} mb={3}>
                        <SignatureBox
                            nume="Teodor Dumitru"
                            pozitie="Primar"
                            signed={signed}
                            canBeSigned={true}
                            handleSemneaza={handleSemneaza}
                            signature={semnatura3}
                        />

                        <SignatureBox
                            nume="Mihai Ionescu"
                            pozitie="Director Executiv"
                            signed={false}
                            canBeSigned={false}
                            handleSemneaza={handleSemneaza}
                            signature={semnatura4}
                        />

                        <SignatureBox
                            nume="Ion Popescu"
                            pozitie="Secretar General"
                            signed={true}
                            canBeSigned={false}
                            handleSemneaza={handleSemneaza}
                            signature={semnatura1}
                        />

                        <SignatureBox
                            nume="Daniel Amariei"
                            pozitie="Arhitect Sef"
                            signed={true}
                            canBeSigned={false}
                            handleSemneaza={handleSemneaza}
                            signature={semnatura2}
                        />
                    </Grid>
                )}

                <Grid container spacing={2} mb={4}></Grid>

                <Button
                    variant="outlined"
                    size="large"
                    to="/dashboard/autorizare"
                    component={Link}
                    sx={{ mr: "1rem" }}
                >
                    Anuleaza
                </Button>

                {canBeSigned ? (
                    <Button
                        variant="contained"
                        size="large"
                        to="/dashboard/cereri/aprobat"
                        component={Link}
                        onClick={() =>
                            enqueueSnackbar("Modificarile au fost salvate", { variant: "success" })
                        }
                    >
                        Salveaza Modificarile
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        size="large"
                        to="/dashboard/cereri/aprobat"
                        disabled
                        component={Link}
                        startIcon={<LockIcon />}
                        onClick={() =>
                            enqueueSnackbar("Modificarile au fost salvate", { variant: "success" })
                        }
                    >
                        Salveaza Modificarile
                    </Button>
                )}
            </Box>
            {viewSemnareDialog && <SemnareDialog handleClose={() => setViewSemnareDialog(false)} handleSign={handleSign} />}
        </Container>
    );
}

export default function DashboardSemnare() {
    return <Semnare />;
}
