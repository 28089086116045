import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Cookies from 'universal-cookie';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const cookies = new Cookies();

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CookiesDialog() {

    const [acceptedCookies, setAcceptedCookies] = React.useState(false);

    const acceptCookies = () => {
        cookies.set('acceptedCookies', '1', { path: '/', expires: new Date(Date.now() + 1000*3600*48) });
        setAcceptedCookies(true);
    };

    const [showOptions, setShowOptions] = React.useState(false);

    const handleDialogOpen = () => {
        setShowOptions(true);
    };
    const handleDialogClose = () => {
        setShowOptions(false);
    };

    React.useEffect(() => {
        const readCookies = cookies.get('acceptedCookies');
        if (readCookies) setAcceptedCookies(readCookies);
    });

    const cookiesModal = (
        <div className="App-cookies">
            <h3 style={{ marginTop: 0 }}>Consimțământ de utilizare cookies</h3>
            <p style={{ textAlign: "justify", lineHeight: 1.3 }}>
                Pentru scopuri precum afișarea de conținut personalizat, folosim module cookie sau
                tehnologii similare. Apăsând Accept, ești de acord să permiți colectarea de
                informații prin cookie-uri sau tehnologii similare. Află in sectiunea{" "}
                <a href="">Politica de Cookies</a> mai multe despre cookie-uri, inclusiv despre
                posibilitatea retragerii acordului.
            </p>
            <Stack spacing={2} direction="row">
                <Button onClick={handleDialogOpen}>Configurează</Button>
                <Button onClick={acceptCookies} variant="contained">
                    Acceptă toate
                </Button>
            </Stack>
            <BootstrapDialog
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={showOptions}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
                    Configureaza setari cookies
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus
                        ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur
                        ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus
                        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus
                        ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur
                        ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus
                        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus
                        ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur
                        ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus
                        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus
                        ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur
                        ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus
                        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                        magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor fringilla [...]
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDialogClose}>
                        Salveaza setarile
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );

    return acceptedCookies ? null : cookiesModal;
}

export default CookiesDialog;
