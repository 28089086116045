import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";

import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import PrintIcon from "@mui/icons-material/Print";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import PDFSample from "./images/RLU Cluj_UTR ULiu.pdf";

import AdaugaAlerta from "./AdaugaAlerta";
import { useSnackbar } from "notistack";


const dialogStyle = {
    "& .MuiAccordionDetails-root h4": {
        // background: "#EEE",
        // padding: "10px",
        // borderRadius: "100px"
    },
    "& .MuiAccordionSummary-root h4": {
        margin: 0,
    },
    "&.Mui-expanded": {
        margin: "0 0 0 0",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
        minHeight: 0,
    },
    "& .MuiDialog-paper": { width: 800 },
    "& .MuiAccordionSummary-root": {
        backgroundColor: "#EEE",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 48,
    },
    "& .MuiAccordion-root": {
        background: "#F6F6F6",
        boxShadow: "none",
        marginBottom: "16px",
    },
    "& .MuiAccordionDetails-root": {
        padding: "16px 16px 16px",
    },
    "& .MuiAccordion-root:before": {
        display: "none",
    },
};

const mainParagraph = {
    borderTop: "1px dashed",
    // borderBottom: "1px dotted",
    background: "#dcefff",
    padding: "20px",
    margin: "-16px -16px 0",
    fontStyle: "italic",
    boxShadow: "rgba(33, 35, 38, 0.2) 0px 10px 10px -10px",
};

function DetaliiZona() {
    let navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const zona = "PUG lc_a - Regim Tehnic";
    const [dialogAlerta, setDialogAlerta] = React.useState(false);

    const handleModalClose = () => {
        navigate("/");
    };

    const handleDialogAlertaOpen = () => {
        setDialogAlerta(true);
    };

    const handleDialogAlertaClose = () => {
        setDialogAlerta(false);
    };

    const handleSaveAlert = () => {
        enqueueSnackbar("Alerta a fost salvata", {variant: "success"});
        handleDialogAlertaClose();
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                scroll="body"
                onBackdropClick={handleModalClose}
                onClose={handleModalClose}
                maxWidth="lg"
                sx={dialogStyle}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
                >
                    <span style={{ lineHeight: "40px" }}>{zona}</span>
                    <IconButton color="default" component="span" onClick={handleModalClose}>
                        <CloseIcon fontSize="30px" />
                    </IconButton>
                </DialogTitle>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ButtonGroup
                        size="small"
                        color="inherit"
                        variant="text"
                        sx={{
                            marginBottom: "-10px",
                            "& .MuiButtonGroup-grouped": {
                                border: "none !important",
                                margin: "0 3px",
                            },
                            "& .MuiButton-endIcon": {
                                marginLeft: "6px",
                            },
                        }}
                    >
                        <Button href={PDFSample} target="_blank" endIcon={<PictureAsPdfIcon sx={{ color: "red" }} />}>
                            Descarca PDF
                        </Button>
                        <Button endIcon={<PrintIcon sx={{ color: "#333" }} />}>Printeaza</Button>
                        <Button
                            endIcon={<NotificationsActiveIcon sx={{ color: "#333" }} />}
                            onClick={handleDialogAlertaOpen}
                        >
                            Seteaza Alerta
                        </Button>
                    </ButtonGroup>
                </div>
                <DialogContent>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h4>SECŢIUNEA 1. CARACTERUL ZONEI</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={mainParagraph}>
                                Terenuri cu destinaţie agricolă - arabil, păşuni, fâneţe, pepiniere
                                etc - situate pe teritoriul administrativ al municipiului, în
                                extravilan. Având în vedere principiile dezvoltării durabile, nevoia
                                conservării calităţii mediului, ca şi existenţa în intravilan a unei
                                rezerve de teren substanţiale destinate urbanizării, este posibilă
                                şi se impune conservarea şi protejarea terenurilor cu destinaţie
                                agricolă aflate în imediata apropiere a oraşului. Criterii economice
                                şi ecologice impun conservarea terenurilor de producţie şi a
                                unităţilor agricole din proximitatea oraşului, capabile să
                                aprovizioneze, din resurse locale şi pe trasee scurte pieţele locale
                                de produse agricole.
                            </Typography>
                            <h4>A. CONDIŢIONĂRI PRIMARE</h4>
                            <Typography>
                                Orice construcţii şi amenajări admisibile în zonă (construcţii şi
                                amenajări agricole, construcţii anexe pentru exploataţii agricole)
                                se vor amplasa şi realiza pe baza unor P.U.Z. sau P.U.D, după caz.
                                <br />
                                <br />
                                Se recomandă transformarea păşunilor în fânaţe.
                            </Typography>
                            <h4>C. REGLEMENTĂRI PENTRU SPAȚIUL PUBLIC</h4>
                            <Typography>Nu e cazul.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <h4>SECŢIUNEA 2. UTILIZARE FUNCŢIONALĂ</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={mainParagraph}>
                                Se va aplica lista utilizărilor / activităţilor corespunzătoare
                                fiecărei categorii de funcţiuni, conform Anexei 1 la prezentul
                                Regulament.
                            </Typography>
                            <h4>1. UTILIZĂRI ADMISE</h4>
                            <Typography>
                                Pajişti, fânaţe.
                                <br />
                                Păşunat, culturi agricole diverse, pepiniere.
                                <br />
                                Unităţi agricole / ferme existente.
                                <br />
                                Reţele magistrale, căi de comunicaţie, amenajări pentru îmbunătăţiri
                                funciare, reţele de telecomunicaţii, alte lucrări de infrastructură.
                            </Typography>
                            <h4>2. UTILIZĂRI ADMISE CU CONDIŢIONĂRI</h4>
                            <Typography>
                                Ferme şi unităţi zootehnice, numai în baza unor studii de impact
                                asupra sănătăţii populaţiei şi mediului înconjurător, cu realizarea
                                măsurilor şi a zonelor de protecţie sanitară pe care acestea le
                                impun.
                                <br />
                                Ciupercării, sere, alte clădiri de producţie, cu condiţia ca
                                suprafaţa terenului aferent exploataţiei agricole să fie mai mare de
                                2 ha.
                                <br />
                                Construcţii anexe şi amenajări pentru exploataţii agricole, cu
                                următoarele condiţii:
                                <br />
                                (a) să adăpostească activităţi dedicate nemijlocit şi exclusiv
                                exploataţiei agricole;
                                <br />
                                (b) suprafaţa minimă a exploataţiei va fi de 2 ha;
                                <br />
                                (c) parcela va avea acces dintr-un drum public sau de exploatare
                                agricolă (direct sau prin servitute)
                                <br />
                                (d) folosinţa agricolă va fi înscrisă în actele de proprietate -
                                arător, livadă, pomet, păşune, fânaţ;
                                <br />
                                (e) terenul va fi înscris în registrul agricol;
                                <br />
                                (f) exploataţia agricolă va fi înregistrată juridic;
                                <br />
                                (g) în cadrul exploataţiei va fi delimitată o zonă de anexe /
                                gospodărire în care pot fi amplasate construcţiile şi amenajările,
                                restul terenului conservându-şi destinaţia agricolă. Această zonă va
                                reprezenta maximum 10% din suprafaţa totală a exploataţiei agricole.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
            </Dialog>
            <AdaugaAlerta
                open={dialogAlerta}
                handleClose={handleDialogAlertaClose}
                alerta={{ obiectiv: zona }}
                handleSave={handleSaveAlert}
            />
        </React.Fragment>
    );
}

export default DetaliiZona;
