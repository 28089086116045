import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const PUZ = require(".././geojson/puz.json");

const defaultState = {
    data: PUZ,
    currentPuz: {
        id: null,
        createdAt: null,
        tip: "",
        descriere: "",
        memoriu: [],
        hcl: [],
        planuri: [],
        etapa: "anunt",
        desen: [],
    },
    puzHistory: []
};

export const puzSlice = createSlice({
    name: "puz",
    initialState: defaultState,
    reducers: {
        updatePuz: (state, action) => {
            state.data = action.payload;
        },
        deletePuz: (state, action) => {
            const index = state.data.findIndex((item) => item.id === action.payload.id);
            if (index > -1)
                state.data = [
                    ...state.data.slice(0, index), // everything before current post
                    ...state.data.slice(index + 1), // everything after current post
                ];
        },
        editPuz: (state, action) => {
            const index = state.data.findIndex((item) => item.id === action.payload.id);
            if (index > -1) state.currentPuz = state.data[index];
        },
        savePuz: (state, action) => {
            const index = state.data.findIndex((item) => item.id === state.currentPuz.id);
            if (index > -1) {
                state.data = [
                    ...state.data.slice(0, index), // everything before current post
                    state.currentPuz,
                    ...state.data.slice(index + 1), // everything after current post
                ];
                state.puzHistory = [
                    {
                        "after":
                        {
                            ...state.currentPuz
                        },
                        "before": null,
                        "user": {
                            ...action.payload
                        },
                        "ts_ms": Date.now(),
                    },
                    ...state.puzHistory
                ]
            } else {
                state.data = [
                    ...state.data,
                    { ...state.currentPuz, id: Date.now(), createdAt: Date.now() },
                ];
            }
        },
        setCurrentPuz: (state, action) => {
            state.currentPuz = action.payload;
        },
        resetCurrentPuz: (state, action) => {
            state.currentPuz = defaultState.currentPuz;
        },
    },
});

export const { updatePuz, editPuz, savePuz, deletePuz, setCurrentPuz, resetCurrentPuz } =
    puzSlice.actions;
export default puzSlice.reducer;
