import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Checkbox,
    TextField,
    Select,
    FormControl,
    FormGroup,
    FormControlLabel,
    MenuItem,
    InputLabel,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const CustomConstrolLabel = styled(FormControlLabel)(({ theme }) => ({
    alignItems: "flex-start",
    paddingBottom: "0.25rem",
    "& .MuiCheckbox-root": {
        marginTop: "-9px",
    },
}));

function SelectedContent({ selection, descriere, setDescriere }) {
    return (
        <React.Fragment>
            {
                {
                    0: <React.Fragment></React.Fragment>,
                    1: (
                        <FormGroup sx={{ paddingTop: "1rem" }}>
                            <CustomConstrolLabel
                                control={<Checkbox defaultChecked />}
                                label="Lucrari de constructie"
                            />
                            <CustomConstrolLabel
                                control={<Checkbox />}
                                label="Lucrari de desfiintare"
                            />
                            <CustomConstrolLabel
                                control={<Checkbox />}
                                label="Lucrări de construire, reconstruire, consolidare, modificare, extindere, reabilitare, schimbare de destinaţie sau de reparare a construcţiilor de orice fel, precum şi a instalaţiilor aferente acestora, cu excepţia celor prevăzute la art.12 din Legea nr.50/1991;"
                            />
                            <CustomConstrolLabel
                                control={<Checkbox />}
                                label="Lucrări de construire, reconstruire, extindere, reparare, consolidare, protejare, restaurare, conservare, precum şi orice alte lucrări, indiferent de valoarea lor, care urmează să fie efectuate la construcţii reprezentând monumente istorice, stabilite potrivit legii;"
                            />
                            <CustomConstrolLabel
                                control={<Checkbox />}
                                label="Lucrări de construire, reconstruire, modificare, extindere, reparare, modernizare şi reabilitare privind căile de comunicaţie de orice fel, drumurile forestiere, lucrările de artă, reţelele şi dotările tehnico-edilitare, lucrările hidrotehnice, amenajările de albii, lucrările de îmbunătăţiri funciare, lucrările de instalaţii de infrastructură, lucrările pentru noi capacităţi de producere, transport, distribuţie a energiei electrice şi/sau termice, precum şi de reabilitare şi retehnologizare a celor existente;"
                            />
                        </FormGroup>
                    ),
                    2: (
                        <FormGroup sx={{ paddingTop: "1rem" }}>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Vanzari"
                            />
                            <FormControlLabel control={<Checkbox />} label="Cumparari" />
                            <FormControlLabel control={<Checkbox />} label="Concesionari" />
                            <FormControlLabel control={<Checkbox />} label="Cesionari" />
                            <FormControlLabel control={<Checkbox />} label="Dezmembrari" />
                            <FormControlLabel control={<Checkbox />} label="Parcelari" />
                            <FormControlLabel control={<Checkbox />} label="Comasari" />
                            <FormControlLabel control={<Checkbox />} label="Partaje" />
                            <FormControlLabel control={<Checkbox />} label="Succesiuni" />
                        </FormGroup>
                    ),
                    3: (
                        <FormGroup sx={{ paddingTop: "1rem" }}>
                            <TextField
                                sx={{ background: "#FFF" }}
                                label="Denumire..."
                                fullWidth
                                multiline
                                minRows={2}
                                value={descriere}
                                onChange={(e) => setDescriere(e.target.value)}
                            />
                        </FormGroup>
                    ),
                    4: (
                        <FormGroup sx={{ paddingTop: "1rem" }}>
                            <TextField
                                sx={{ background: "#FFF" }}
                                label="Detalii cerere..."
                                fullWidth
                                multiline
                                minRows={3}
                                value={descriere}
                                onChange={(e) => setDescriere(e.target.value)}
                            />
                        </FormGroup>
                    ),
                    5: (
                        <FormGroup sx={{ paddingTop: "1rem" }}>
                            <TextField
                                sx={{ background: "#FFF" }}
                                label="Descriere scopuri..."
                                fullWidth
                                multiline
                                minRows={3}
                                value={descriere}
                                onChange={(e) => setDescriere(e.target.value)}
                            />
                        </FormGroup>
                    ),
                }[selection]
            }
        </React.Fragment>
    );
}

function DetaliiCerere({ handleDataChange }) {
    const [scopType, setScopType] = React.useState(1);
    const [descriere, setDescriere] = React.useState("");

    const handleChange = (event) => {
        setScopType(event.target.value);
    };

    React.useEffect(() => {
        handleDataChange({
            type: scopType,
            booleanOptions: {},
            textOptions: {
                descriere: descriere,
            },
            uploadOptions: {},
        });
    }, [scopType, descriere]);

    return (
        <React.Fragment>
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Scop</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={scopType}
                        label="Scop"
                        onChange={handleChange}
                        sx={{ background: "#FFF" }}
                        // readOnly
                    >
                        <MenuItem value={1}>
                            Elaborarea documentatiei pentru autorizarea executarii lucrarilor de
                            constructii
                        </MenuItem>
                        <MenuItem value={2}>
                            Operatiuni notariale privind circulatia imobiliara
                        </MenuItem>
                        <MenuItem value={3}>
                            Adjudecarea prin licitatie a proiectarii lucrarilor publice
                        </MenuItem>
                        <MenuItem value={4}>Cerere in justitie</MenuItem>
                        {/* <MenuItem value={5}>Alte scopuri prevazute de lege</MenuItem> */}
                        <MenuItem value={5}>Scopuri prevazute de lege</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <SelectedContent
                selection={scopType || 0}
                descriere={descriere}
                setDescriere={setDescriere}
            />
        </React.Fragment>
    );
}

export default DetaliiCerere;
