import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Marker, Popup, Tooltip } from "react-leaflet";
import PDFSampleAutorizatie from "./images/autorizatie.pdf";
import PDFSampleCertificat from "./images/certificat.pdf";
import { dotIcon } from "./utils/leafletIcons";

function MyMarkers({ data, color, size, type, handleClick, map }) {
    const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);
    const markers = data;
    const id = randomColor();
    const [zoomLevel, setZoomLevel] = React.useState(13);

    map.on("moveend", function () {
        setZoomLevel(map.getZoom());
    });

    size = (zoomLevel > size) ? zoomLevel : size;

    return markers.map((item, index) => (
        <Marker
            key={index}
            icon={dotIcon(color, size)}
            opacity={0.85}
            // eventHandlers={
            //     {
            //         // click: () => handleClick({ latlng: { lat: item[0], lng: item[1] } }),
            //     }
            // }
            eventHandlers={{
                click: (e) => {
                  map.setZoomAround({ lat: item[0], lng: item[1] }, 16);
                },
              }}
            position={{ lat: item[0], lng: item[1] }}
        >
            {zoomLevel < 16 && <Tooltip direction="bottom">C{id}</Tooltip>}
            {
                {
                    Certificat: (
                        <Popup>
                            <Box
                                sx={{
                                    borderBottom: "1px solid #EEE",
                                    mb: 1,
                                    pb: 0.5,
                                    fontSize: "110%",
                                }}
                            >
                                <strong>Certificat Urbanism:</strong>{" "}
                                <span style={{ textTransform: "uppercase" }}>C{id}</span>{" "}
                                | <strong>Data emitere:</strong> 23.08.2021
                            </Box>
                            <Button
                                sx={{ color: "#333" }}
                                size="small"
                                variant="outlined"
                                href={PDFSampleCertificat}
                                target="_blank"
                                startIcon={<PictureAsPdfIcon sx={{ color: "red" }} />}
                            >
                                Descarca Certificatul
                            </Button>
                        </Popup>
                    ),
                    Autorizatie: (
                        <Popup>
                            <Box
                                sx={{
                                    borderBottom: "1px solid #EEE",
                                    mb: 1,
                                    pb: 0.5,
                                    fontSize: "110%",
                                }}
                            >
                                <strong>Autorizatie de Constructie:</strong>{" "}
                                <span style={{ textTransform: "uppercase" }}>A{id}</span>{" "}
                                | <strong>Data emitere:</strong> 23.08.2021
                            </Box>
                            <Button
                                sx={{ color: "#333" }}
                                size="small"
                                variant="outlined"
                                href={PDFSampleAutorizatie}
                                target="_blank"
                                startIcon={<PictureAsPdfIcon sx={{ color: "red" }} />}
                            >
                                Descarca Autorizatia
                            </Button>
                        </Popup>
                    ),
                }[type]
            }
        </Marker>
    ));
}

MyMarkers.propTypes = {
    data: PropTypes.array,
    color: PropTypes.string,
    size: PropTypes.number,
    handleClick: PropTypes.func,
    type: PropTypes.string,
};

export default MyMarkers;
