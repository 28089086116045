import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup, LayersControl, Tooltip, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import tileLayer from "./utils/tileLayer";

import SesizareCard from "./SesizareCard";
import AmenziReclamatiiCard from "./AmenziReclamatiiCard";
import AmenziReclamatiiLayerControl from "./AmenziReclamatiiLayerControl";
import AdaugaReclamatie from "./AdaugaReclamatie";
import { useSnackbar } from "notistack";

import { customPinIcon, fontAwesomeIconFat } from "./utils/leafletIcons";

import { getCategoryIcon, getStatusColor, getCategoryLabel } from "./utils/sesizariUtils";

const center = [47.1392, 24.4973];

const sesizari = require("./geojson/sesizari.json");

const ShowMarkers = ({ markers, removeMarker, updateMarker }) => {
    return markers.map((marker, index) => {
        return (
            <Marker
                key={index}
                uniceid={index}
                position={marker}
                icon={customPinIcon("dodgerblue", 50)}
                draggable={true}
                eventHandlers={{
                    moveend(e) {
                        const { lat, lng } = e.target.getLatLng();
                        updateMarker([lat, lng]);
                    },
                }}
            >
                <Popup>
                    <button onClick={removeMarker}>sterge locatia x</button>
                </Popup>
            </Marker>
        );
    });
};

const MyMarkers = ({ map, editMode, autoLocationPoint, setManualPoint }) => {
    const [marker, setMarker] = useState([]);

    const handleMarkerAdd = (point) => {
        setMarker([point]);
    };

    const handleMarkerRemove = () => {
        setMarker([]);
        setManualPoint();
    };

    const handleMarkerUpdate = (point) => {
        handleMarkerAdd(point);
        setManualPoint(point);
    };

    const handleClick = (e) => {
        const { lat, lng } = e.latlng;
        handleMarkerAdd([lat, lng]);
        setManualPoint([lat, lng]);
    };

    useEffect(() => {
        if (!map || !editMode) return;
        map.on("click", handleClick);
    }, [map, editMode]);

    useEffect(() => {
        if (!map) return;
        // clean marker
        if (!editMode && marker.length) setMarker([]);
        // set pointer
        if (editMode) {
            map.getContainer().style.cursor = "crosshair";
        } else {
            map.getContainer().style.cursor = "grab";
            map.off("click");
        }
    }, [editMode]);

    useEffect(() => {
        if (!autoLocationPoint) return;
        handleMarkerAdd(autoLocationPoint);
    }, [autoLocationPoint]);

    return marker.length > 0 ? (
        <MarkerClusterGroup>
            <ShowMarkers
                markers={marker}
                removeMarker={handleMarkerRemove}
                updateMarker={handleMarkerUpdate}
            />
        </MarkerClusterGroup>
    ) : null;
};

const MapWrapper = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [amenzi, setAmenzi] = useState([
        { lat: 47.14242625297381, lng: 24.47325785732591 },
        { lat: 47.13109644609369, lng: 24.48090555927395 },
        { lat: 47.141669107837465, lng: 24.488673747241126 },
        { lat: 47.134633276445456, lng: 24.50409711427206 },
        { lat: 47.143347272145505, lng: 24.487648998938962 },
        { lat: 47.129568408495004, lng: 24.495483093479343 },
        { lat: 47.13729437992335, lng: 24.48506478126478 },
        { lat: 47.13889204702091, lng: 24.478697368013467 },
        { lat: 47.128933071563225, lng: 24.485749490091994 },
        { lat: 47.14330806303142, lng: 24.497189735384524 },
        { lat: 47.144308575954426, lng: 24.506630331309424 },
        { lat: 47.13287562038152, lng: 24.482754723037864 },
        { lat: 47.13874300499419, lng: 24.47705010837294 },
        { lat: 47.13172788343182, lng: 24.514271002838978 },
        { lat: 47.139452515182576, lng: 24.487912904729082 },
        { lat: 47.13130637698682, lng: 24.474770248573044 },
        { lat: 47.13831862333378, lng: 24.5169229946989 },
        { lat: 47.133836453417295, lng: 24.49634690962146 },
        { lat: 47.136195396259474, lng: 24.512300017797205 },
        { lat: 47.133774615982595, lng: 24.504679038852103 },
        { lat: 47.14185050825273, lng: 24.476455290088527 },
        { lat: 47.14629744956543, lng: 24.506277250152007 },
        { lat: 47.12807135520365, lng: 24.497005265492774 },
        { lat: 47.1328038051036, lng: 24.513383421046253 },
        { lat: 47.14176410672901, lng: 24.5064234359394 },
        { lat: 47.129347820718685, lng: 24.47927094919454 },
        { lat: 47.145085629622905, lng: 24.50252412921728 },
        { lat: 47.12833172327528, lng: 24.51162648408384 },
        { lat: 47.145722154912484, lng: 24.509245044625732 },
        { lat: 47.14150611360798, lng: 24.514533177096446 },
    ]);
    const [map, setMap] = useState(null);
    const [inspectorVisible, setInspectorVisible] = useState(false);
    const [adaugaReclamatieVisibility, setAdaugaReclamatieVisibility] = useState(false);
    const [autoLocationPoint, setAutoLocationPoint] = useState();
    const [point, setPoint] = useState();

    const [amenziVisibility, setAmenziVisibility] = useState(true);
    const [reclamatiiVisibility, setReclamatiiVisibility] = useState(false);

    const [sesizare, setSesizare] = useState(null);

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (!editMode) setPoint();
    }, [editMode]);

    useEffect(() => {
        if (map){
            map.on("click", () => toggleSesizare());
        }
    }, [map]);

    const toggleSesizare = (data) => {
        setSesizare(data);
    };

    const handleAutoLocation = (e) => {
        e.preventDefault();

        // location on found
        map.on("locationfound", function (e) {
            const { lat, lng } = map.getCenter();
            setAutoLocationPoint([lat, lng]);
            setPoint([lat, lng]);
        });
        // locataion on error
        map.on("locationerror", function () {});

        // start locate
        map.locate({ setView: true, enableHighAccuracy: true });
    };

    const setManualPoint = (point) => {
        setPoint(point);
    };

    const showInspector = () => {
        setEditMode(false);
        setInspectorVisible(true);
        setSesizare(null);
    };

    const hideInspector = () => {
        setInspectorVisible(false);
    };

    const salveazaAmenda = () => {
        if (!point) return;
        setAmenzi([...amenzi, { lat: point[0], lng: point[1] }]);
        setPoint();
        setEditMode(false);
        enqueueSnackbar("Amenda a fost adaugata", { variant: "success" });
    };

    const randomTitle = () =>
        Math.floor(Math.random() * 16777215)
            .toString(16)
            .toUpperCase();

    return (
        <div
            id="amenzi-reclamatii-map"
            style={{ height: "100%", width: "100%", position: "relative" }}
        >
            <AmenziReclamatiiLayerControl
                handleAmenzi={() => setAmenziVisibility(!amenziVisibility)}
                handleReclamatii={() => setReclamatiiVisibility(!reclamatiiVisibility)}
                toggleEditMode={() => setEditMode(!editMode)}
                editMode={editMode}
                handleAutoLocation={handleAutoLocation}
                point={point}
                salveazaAmenda={salveazaAmenda}
            />
            {inspectorVisible && <AmenziReclamatiiCard onClickAway={hideInspector} />}

            {sesizare && <SesizareCard sesizare={sesizare} /> }

            <MapContainer
                whenCreated={setMap}
                center={center}
                zoom={15}
                zoomControl={false}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer {...tileLayer} />
                <ZoomControl position="bottomright" />
                <MyMarkers
                    map={map}
                    autoLocationPoint={autoLocationPoint}
                    editMode={editMode}
                    setManualPoint={setManualPoint}
                />

                <LayersControl position="topright" collapsed={false}>
                    <LayersControl.Overlay name="Amenzi" checked={amenziVisibility}>
                        <FeatureGroup>
                            <MarkerClusterGroup>
                                {amenzi.map(({ lat, lng }, index) => (
                                    <Marker
                                        key={index}
                                        position={[lat, lng]}
                                        icon={fontAwesomeIconFat({
                                            color: "tomato",
                                            icon: "fa-clipboard-list",
                                        })}
                                        eventHandlers={{
                                            click() {
                                                showInspector();
                                            },
                                        }}
                                    >
                                        <Tooltip direction="top">{randomTitle()}</Tooltip>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </FeatureGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Reclamatii" checked={reclamatiiVisibility}>
                        <FeatureGroup>
                            <MarkerClusterGroup>
                                {sesizari.features.map((sesizare, index) => (
                                    <Marker
                                        key={index}
                                        position={[
                                            sesizare.geometry.coordinates[1],
                                            sesizare.geometry.coordinates[0],
                                        ]}
                                        icon={fontAwesomeIconFat({
                                            color: getStatusColor(sesizare.properties.status),
                                            icon: getCategoryIcon(sesizare.properties.category),
                                        })}
                                        eventHandlers={{
                                            click(e) {
                                                toggleSesizare(sesizare);
                                            },
                                        }}
                                    >
                                        <Tooltip direction="top">
                                            <strong>
                                                {getCategoryLabel(sesizare.properties.category)}
                                            </strong>
                                            <br />
                                            Data sesizare: 01.02.2023
                                        </Tooltip>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </FeatureGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>
            <AdaugaReclamatie
                open={adaugaReclamatieVisibility}
                handleModalClose={() => setAdaugaReclamatieVisibility(false)}
            />
        </div>
    );
};

export default MapWrapper;
