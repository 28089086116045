import React from "react";
import PropTypes from "prop-types";

import { Button, Stack } from "@mui/material/";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

function StergeAmplasament({ open, amplasament, handleClose, handleDelete }) {
    let nume = "";
    if (!amplasament) return null;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Sterge amplasament</DialogTitle>
            <DialogContent>
                <p>
                    Amplasamentul cu numarul{" "}
                    <strong style={{ background: "aliceblue", padding: "10px", borderRadius: 4 }}>
                        {amplasament}
                    </strong>{" "}
                    va fi sters. Doriti sa continuati?
                </p>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} sx={{ m: 1 }} direction="row">
                    <Button variant="outlined" onClick={handleClose}>
                        Anulare
                    </Button>
                    <Button variant="contained" autoFocus onClick={handleDelete}>
                        Sterge amplasamentul
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

StergeAmplasament.propTypes = {
    open: PropTypes.bool,
    amplasament: PropTypes.string,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func,
};

export default StergeAmplasament;
