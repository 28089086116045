const categoriiSesizari = [
    { label: "Construcții și terenuri", name: "constructii", icon: "fa-building" },
    { label: "Iluminat public", name: "iluminat", icon: "fa-lightbulb" },
    { label: "Trafic rutier și semne de circulație", name: "trafic", icon: "fa-car" },
    { label: "Mediu, locuri de joacă și spații verzi", name: "mediu", icon: "fa-leaf" },
    { label: "Străzi și trotuare", name: "strazi", icon: "fa-road" },
];

const tipuriSesizari = [
    { label: "Depuse", labelS: "Depusă", status: "depus", color: "#003995" },
    {
        label: "Redirecționate",
        labelS: "Redirecționată",
        status: "redirectionat",
        color: "#ff7f50",
    },
    { label: "În lucru", labelS: "În lucru", status: "lucru", color: "#1e90ff" },
    { label: "Rezolvate", labelS: "Rezolvată", status: "rezolvat", color: "#3cb371" },
];

function getStatusColor(status) {
    let color = "";
    tipuriSesizari.forEach(function (sesizare) {
        if (status === sesizare.status) color = sesizare.color;
    });
    return color;
}

function getStatusLabel(status) {
    let label = "";
    tipuriSesizari.forEach(function (sesizare) {
        if (status === sesizare.status) label = sesizare.labelS;
    });
    return label;
}

function getCategoryIcon(pointCategory) {
    let icon = "";
    categoriiSesizari.forEach(function (category) {
        if (pointCategory === category.name) icon = category.icon;
    });
    return icon;
}

function getCategoryLabel(pointCategory) {
    let label = "";
    categoriiSesizari.forEach(function (category) {
        if (pointCategory === category.name) label = category.label;
    });
    return label;
}

export {
    categoriiSesizari,
    tipuriSesizari,
    getStatusColor,
    getStatusLabel,
    getCategoryIcon,
    getCategoryLabel,
};
