import * as React from "react";

import { Box } from "@mui/material";

import { Link, useParams } from "react-router-dom";

import DashboardDocPreview from "./DashboardDocPreview";

function PrintDoc() {
    let { parcelId } = useParams();
    parcelId = parcelId ? parseInt(parcelId) : 89118;

    React.useEffect(() => {}, []);

    return (
        <Box className="map-print-wrapper">
            <DashboardDocPreview parcelId={parcelId} renderMode="view" />
        </Box>
    );
}

export default PrintDoc;
