import React from "react";
import {
    GeoJSON,
    MapContainer,
    TileLayer,
    ZoomControl,
    LayersControl,
    ScaleControl,
    Pane,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { useMapEvents } from "react-leaflet";

import MyMarkers from "./MapLeafDots";
import Analize from "./MapLeafAnalize";

import { BISTRITA as CITY } from "./geojson/mapConfig.js";

import ParcelsLayer from "./ParcelsLayer2";

import PubSub from "pubsub-js";

import { decode as base64_decode, encode as base64_encode } from "base-64";

import { useSelector, useDispatch } from "react-redux";

import Spinner from "./components/Spinner";
import MapFeedbackBox from "./components/MapFeedbackBox";
import RandomMarkerInMapBounds from "./RandomMarkers";
import mousePosition from "./utils/leaflet-mouse-position";

import {
    updateMapCenter,
    updateMapZoom,
    updateLayerVisibility,
    updateMapType,
} from "./redux/layers.slice";

// https://stackoverflow.com/questions/60174040/marker-icon-isnt-showing-in-leaflet
// https://github.com/PaulLeCam/react-leaflet/issues/453

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const { BaseLayer } = LayersControl;

let xyzParams = new URLSearchParams(window.location.search);
let xyzArray = [];
let layersParams = null;
let mapTypeParams = null;

try {
    xyzArray = xyzParams.get("xyz").split(",");
    layersParams = xyzParams.get("layers");
    mapTypeParams = xyzParams.get("mapType");
} catch (error) {
    // console.error(error);
}

let mapCenter = xyzArray[0] ? [xyzArray[0], xyzArray[1]] : CITY.center;
let mapZoom = xyzArray[2] || CITY.defaultZoom;

function MapLeaf() {
    const dispatch = useDispatch();

    const [map, setMap] = React.useState(null);
    const [dataLoaded, setDataLoaded] = React.useState(false);

    const [prevLayersOpacity, setPrevLayersOpacity] = React.useState({});

    const mapType = useSelector((state) => state.layers.mapType);
    const mapLayers = useSelector((state) => state.layers.mapLayers);
    const layersOpacity = useSelector((state) => state.layers.layersOpacity);

    const handleClick = ({ position, name, color, description }) => {
        PubSub.publish("AREA_CLICK", {
            position: position,
            name: name,
            color: color,
            description: description,
        });
    };

    const showParcelCard = (feature, clickLocation) => {
        PubSub.publish("AREA_CLICK", {
            position: { latlng: { lat: clickLocation[0], lng: clickLocation[1] } },
            parcel: feature,
        });
    };

    React.useEffect(() => {
        if (!map) {
            setPrevLayersOpacity({ ...layersOpacity });
            return;
        } else {
            ["accesGradinite", "accesScoli", "accesSpitale"].forEach(function (layer) {
                if (layersOpacity[layer] !== prevLayersOpacity[layer]) {
                    // TODO: this doesn't work because canvas, the pane is actually empty
                    map.getPane(layer).style.opacity = layersOpacity[layer] / 100;
                }
            });
            setPrevLayersOpacity({ ...layersOpacity });
        }
    }, [layersOpacity]);

    React.useEffect(() => {
        // component unmount
        return () => {
            setMap(null);
        };
    }, []);

    const limitaAdministrativa = (
        <GeoJSON
            style={{
                weight: 2,
                fillOpacity: 0,
            }}
            data={CITY.limits}
        />
    );

    const intravilan = (
        <GeoJSON
            style={{
                weight: 2,
                color: "orange",
            }}
            data={CITY.intravilan}
        />
    );

    const judet = (
        <GeoJSON
            style={{
                weight: 2,
                color: "#303f9f",
                fillOpacity: 0,
                opacity: 0.4,
            }}
            data={CITY.judet}
        />
    );

    function setLocationParams() {
        dispatch(updateMapCenter(mapCenter));
        dispatch(updateMapZoom(mapZoom));

        let params = new URLSearchParams();

        const searchArray = [mapCenter[0], mapCenter[1], mapZoom];

        params.set("xyz", searchArray.join(","));
        params.set("layers", base64_encode(JSON.stringify(mapLayers)));
        params.set("mapType", mapType);

        window.history.replaceState(
            {},
            document.title,
            window.location.origin + window.location.pathname + "?" + params.toString()
        );
    }

    function MapFeedbackComponent() {
        const map = useMapEvents({
            moveend: () => {
                mapZoom = map.getZoom();
                mapCenter = [map.getBounds().getCenter().lat, map.getBounds().getCenter().lng];
                setLocationParams();
            },
        });
        return null;
    }

    React.useEffect(() => {
        setLocationParams();
    }, [mapLayers, mapType]);

    const onDataLoaded = (data) => {
        let options = [];
        let names = [];
        const parcels = data.parcels;
        const pug = data.pug;
        for (let i = 0; i < pug.features.length; i++) {
            if (!names.includes(pug.features[i].properties.name))
                options.push({
                    label: pug.features[i].properties.name,
                    group: "PUG",
                });
            names.push(pug.features[i].properties.name);
        }
        for (let i = 0; i < parcels.features.length; i++) {
            options.push({
                label: parcels.features[i].properties.cad.toString(),
                group: "Parcele",
            });
        }
        PubSub.publish("SEARCH_DATA", options);
        setDataLoaded(true);
    };

    const onDataUnloaded = () => {
        setDataLoaded(false);
    };

    const handleSetMap = (map) => {
        if (layersParams) {
            const newObject = JSON.parse(base64_decode(layersParams));
            dispatch(
                updateLayerVisibility({
                    ...newObject,
                })
            );
        }
        if (mapTypeParams) {
            dispatch(updateMapType(mapTypeParams));
        }
        setLocationParams();
        setMap(map);

        L.control
            .mousePosition({
                // position: "bottomright",
                emptyString: "",
                wrapLng: false,
            })
            .addTo(map);
    };

    const mapWrapperID = "maps";

    return (
        <React.Fragment>
            <div id={mapWrapperID} style={{ height: "calc(100vh - 58px)", width: "100%" }}>
                {!dataLoaded && (
                    <MapFeedbackBox sx={{ top: 12 }}>
                        <Spinner size={24} />
                        <span style={{ marginLeft: "10px", paddingRight: "30px" }}>
                            Se încarcă datele...
                        </span>
                    </MapFeedbackBox>
                )}
                <MapContainer
                    whenCreated={handleSetMap}
                    center={mapCenter}
                    zoom={mapZoom}
                    // disable default zoom controls
                    zoomControl={false}
                    style={{ height: "100%", width: "100%" }}
                    maxZoom={19}
                    maxNativeZoom={19}
                    fadeAnimation={false}
                    preferCanvas={true}
                >
                    <MapFeedbackComponent />
                    <LayersControl>
                        <BaseLayer checked={mapType === "default"} name="OpenStreetMap">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                maxNativeZoom={19}
                                maxZoom={19}
                                // url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
                                // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                                // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                                // url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                // url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            />
                        </BaseLayer>
                        <BaseLayer checked={mapType === "satellite"} name="NASA Gibs Blue Marble">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                subdomains={["mt1", "mt2", "mt3"]}
                                maxNativeZoom={19}
                                maxZoom={19}
                            />
                        </BaseLayer>
                        {/* <BaseLayer checked={mapType === "dark"} name="Dark">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                            />
                        </BaseLayer> */}
                        <BaseLayer checked={mapType === "dark"} name="Light">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                                maxNativeZoom={19}
                                maxZoom={19}
                            />
                        </BaseLayer>
                    </LayersControl>
                    <ScaleControl position="bottomright" imperial={false} maxWidth="140" />
                    <ZoomControl position="bottomright" />
                    {mapLayers.certificate && (
                        <MyMarkers
                            data={CITY.certificate}
                            color="#5356fb"
                            size={12}
                            type="Certificat"
                            map={map}
                        />
                    )}
                    {mapLayers.autorizatii && (
                        <MyMarkers
                            data={CITY.autorizatii}
                            color="limegreen"
                            size={12}
                            type="Autorizatie"
                            map={map}
                        />
                    )}

                    <ParcelsLayer
                        map={map}
                        showParcelCard={showParcelCard}
                        onDataLoaded={onDataLoaded}
                        onDataUnloaded={onDataUnloaded}
                        handleZoneClick={handleClick}
                        mapWrapperID={mapWrapperID}
                    />

                    <Analize
                        map={map}
                        showAccesGradinite={mapLayers.accesGradinite}
                        showAccesScoli={mapLayers.accesScoli}
                        showAccesSpitale={mapLayers.accesSpitale}
                    />

                    <Pane name="limite" style={{ zIndex: 401 }}>
                        {mapLayers.limita && limitaAdministrativa}
                        {mapLayers.intravilan && intravilan}
                        {mapLayers.judet && judet}
                    </Pane>

                    <Pane name="parcels" style={{ zIndex: 410 }}></Pane>
                    <Pane name="labels" style={{ zIndex: 411 }}></Pane>

                    <Pane name="accesGradinite" style={{ zIndex: 423 }}></Pane>
                    <Pane name="accesScoli" style={{ zIndex: 424 }}></Pane>
                    <Pane name="accesSpitale" style={{ zIndex: 425 }}></Pane>

                    <RandomMarkerInMapBounds map={map} />
                </MapContainer>
            </div>
        </React.Fragment>
    );
}

export default MapLeaf;
