import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";

export default function DashboardDocsList({ setExistingAnexe }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopper = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    React.useEffect(() => {
        console.log(checked);
    }, [checked]);

    const handleSelection = (event) => {
        setExistingAnexe(checked);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                size="large"
                startIcon={<PictureAsPdfIcon sx={{ color: "white" }} />}
                sx={{ minWidth: 300, mr: "1rem", mb: "1rem" }}
                aria-describedby={id}
                onClick={handlePopper}
            >
                Alege un document...
            </Button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                sx={{ zIndex: 9000, minWidth: 300 }}
                placement="bottom"
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 5],
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box
                        sx={{
                            border: 1,
                            bgcolor: "background.paper",
                            borderRadius: "4px",
                            overflow: "hidden",
                        }}
                    >
                        <h4
                            style={{
                                padding: "1rem",
                                margin: 0,
                                borderBottom: "1px solid",
                                background: "#EEE",
                            }}
                        >
                            Documentele mele:
                        </h4>
                        <List
                            sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                maxHeight: "200px",
                                overflow: "auto",
                                padding: 0,
                            }}
                        >
                            {[1, 2, 3, 4, 5, 6].map((value, index) => {
                                const labelId = `checkbox-list-label-${value}`;
                                return (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton
                                            role={undefined}
                                            onClick={() => handleToggle(value)}
                                            dense
                                        >
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                id={labelId}
                                                primary={`Document - Anexa ${value}.pdf`}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Box sx={{ p: 1, borderTop: 1, background: "aliceblue" }}>
                            <Button
                                variant="contained"
                                onClick={handleSelection}
                                size="small"
                                fullWidth
                            >
                                Aplica Selectia
                            </Button>
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    );
}
