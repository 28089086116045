import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";

import { Box } from "@mui/material";

const sections = {
    section1: [
        "A. CONDIŢIONĂRI PRIMARE",
        "B. SERVITUȚI PENTRU OBIECTIVE DE UTILITATE PUBLICĂ AFLATE ÎN ZONĂ, ALTE RESTRICȚII",
        "C. REGLEMENTĂRI PENTRU SPAȚIUL PUBLIC",
    ],
    section2: [
        "1. UTILIZĂRI ADMISE",
        "2. UTILIZĂRI ADMISE CU CONDIŢIONĂRI",
        "3. UTILIZĂRI INTERZISE",
    ],
    section3: [
        "4. CARACTERISTICILE PARCELELOR: SUPRAFEŢE, FORME, DIMENSIUNI",
        "5. AMPLASAREA CLĂDIRILOR FAŢĂ DE ALINIAMENT",
        "6. AMPLASAREA CLĂDIRILOR FAŢĂ DE LIMITELE LATERALE ŞI POSTERIOARE ALE PARCELELOR",
        "7. AMPLASAREA CLĂDIRILOR UNELE FAŢĂ DE ALTELE PE ACEEAŞI PARCELĂ",
        "8. CIRCULAŢII ŞI ACCESE",
        "9. STAŢIONAREA AUTOVEHICULELOR",
        "10. ÎNĂLŢIMEA MAXIMĂ ADMISĂ A CLĂDIRILOR",
        "11. ASPECTUL EXTERIOR AL CLĂDIRILOR",
        "12. CONDIŢII DE ECHIPARE EDILITARĂ ŞI EVACUAREA DEŞEURILOR",
        "13. SPAŢII LIBERE ŞI SPAŢII PLANTATE",
        "14. ÎMPREJMUIRI",
    ],
    section4: [
        "15. PROCENT MAXIM DE OCUPARE A TERENULUI (P.O.T.)",
        "16. COEFICIENT MAXIM DE UTILIZARE A TERENULUI (C.U.T.)",
    ],
};

function renderRow(row, index) {
    return (
        <Box sx={{ mb: 3 }} key={index}>
            <label>
                <Box
                    sx={{
                        mb: 1,
                    }}
                >
                    {row}
                </Box>
                <TextField
                    sx={{ background: "rgba(255,255,255,1)" }}
                    fullWidth
                    multiline
                    minRows={3}
                />
            </label>
        </Box>
    );
}

function EditRLU(props) {
    const [categoriiPOT, setCategoriiPOT] = React.useState(["", ""]);
    const [categoriiCUT, setCategoriiCUT] = React.useState(["", ""]);

    const handleSubmit = () => {
        props.handleSave("data");
        props.handleClose();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>REGULAMENT LOCAL DE URBANISM (RLU)</span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box sx={{ mb: 3 }}>
                    <Box component="h4" sx={{ mb: 1.5 }}>
                        SECŢIUNEA 1. CARACTERUL ZONEI
                    </Box>
                    <TextField
                        sx={{ background: "#FFF" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Introducere sectiune"
                    />
                </Box>

                {sections["section1"].map((row, index) => renderRow(row, index))}

                <Box sx={{ mb: 3 }}>
                    <Box component="h4" sx={{ mb: 1.5 }}>
                        SECŢIUNEA 2. UTILIZARE FUNCŢIONALĂ
                    </Box>
                    <TextField
                        sx={{ background: "#FFF" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Introducere sectiune"
                    />
                </Box>

                {sections["section2"].map((row, index) => renderRow(row, index))}

                <Box sx={{ mb: 3 }}>
                    <Box component="h4" sx={{ mb: 1.5 }}>
                        SECŢIUNEA 3. CONDIŢII DE AMPLASARE, ECHIPARE ŞI CONFIGURARE A CLĂDIRILOR
                    </Box>
                    <TextField
                        sx={{ background: "#FFF" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Introducere sectiune"
                    />
                </Box>

                {sections["section3"].map((row, index) => renderRow(row, index))}

                <Box sx={{ mb: 3 }}>
                    <Box component="h4" sx={{ mb: 1.5 }}>
                        SECŢIUNEA 4. POSIBILITĂŢI MAXIME DE OCUPARE ŞI UTILIZARE A TERENULUI
                    </Box>
                    <TextField
                        sx={{ background: "#FFF" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Introducere sectiune"
                    />
                </Box>

                <label>
                    <Box
                        sx={{
                            mb: 1,
                        }}
                    >
                        15. PROCENT MAXIM DE OCUPARE A TERENULUI (P.O.T.)
                    </Box>
                </label>

                {categoriiPOT.map((row, index) => (
                    <React.Fragment>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    sx={{ background: "#FFF" }}
                                    fullWidth
                                    placeholder="Descriere categorie:"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <OutlinedInput
                                    sx={{ background: "#FFF" }}
                                    fullWidth
                                    placeholder="P.O.T."
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}

                <Button
                    variant="outlined"
                    size="large"
                    sx={{ mr: "1rem", mb: 3 }}
                    onClick={() => setCategoriiPOT([...categoriiPOT, ""])}
                >
                    + Adauga o categorie
                </Button>

                <Box sx={{ mb: 3 }}>
                    <TextField
                        sx={{ background: "rgba(255,255,255,1)" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Alte detalii..."
                    />
                </Box>

                <label>
                    <Box
                        sx={{
                            mb: 1,
                        }}
                    >
                        16. COEFICIENT MAXIM DE UTILIZARE A TERENULUI (C.U.T.)
                    </Box>
                </label>

                {categoriiCUT.map((row, index) => (
                    <React.Fragment>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    sx={{ background: "#FFF" }}
                                    fullWidth
                                    placeholder="Descriere categorie:"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <OutlinedInput
                                    sx={{ background: "#FFF" }}
                                    fullWidth
                                    placeholder="C.U.T."
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}

                <Button
                    variant="outlined"
                    size="large"
                    sx={{ mr: "1rem", mb: 3 }}
                    onClick={() => setCategoriiCUT([...categoriiCUT, ""])}
                >
                    + Adauga o categorie
                </Button>

                <Box sx={{ mb: 3 }}>
                    <TextField
                        sx={{ background: "rgba(255,255,255,1)" }}
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Alte detalii..."
                    />
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSubmit}>
                    {props.cont ? "Salveaza modificarile" : "Salveaza regulamentul"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditRLU;
