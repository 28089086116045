import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Heading from "./components/DashboardHeading";

import { useSelector, useDispatch } from "react-redux";

import PreviewIcon from "@mui/icons-material/Preview";

import { useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";

import VizualizareTichet from "./VizualizareTichet";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

function ImportGPS() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const gpsData = useSelector((state) => state.gps.data);
    const [tickets, setTickets] = React.useState([]);
    const [ticket, setTicket] = React.useState(null);

    const jwt = useSelector((state) => state.auth.jwt);

    function handleSave(e) {
        enqueueSnackbar("Datele au fost importate cu succes", {
            variant: "info",
        });
    }

    React.useEffect(() => {
        getTickets()
            .then((response) => {
                if (response) {
                    console.log(response);
                    setTickets(response.data);
                }
            })
            .catch((error) => {
                //
            });
    }, []);

    async function getTickets(data) {
        try {
            const response = await fetch(API_URL + `tickets?sort[0]=id:desc`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Heading>Tichete</Heading>

            <Paper
                sx={{
                    p: 3,
                    "& .MuiButton-root": { mt: 2 },
                }}
            >
                <TableContainer>
                    {!tickets.length && (
                        <p
                            style={{
                                padding: "1rem",
                                background: "#F6F6F6",
                                borderRadius: 4,
                                color: "#666",
                                margin: "1rem 0 0 0",
                            }}
                        >
                            Nu aveti tichete primite.
                        </p>
                    )}
                    {tickets.length > 0 && (
                        <Table
                            size="medium"
                            sx={{
                                minWidth: 650,
                                "& .MuiTableCell-root": {
                                    fontSize: "100%",
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Autor</TableCell>
                                    <TableCell>Numar Telefon</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tickets.map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>
                                            {new Date(
                                                item.attributes.createdAt
                                            ).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>{item.attributes.email}</TableCell>
                                        <TableCell>{item.attributes.phone}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                color="primary"
                                                onClick={() => setTicket(item)}
                                            >
                                                <PreviewIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton color="primary">
                                                <DeleteForeverIcon fontSize="small" color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
            </Paper>
            <br />

            {ticket && <VizualizareTichet handleClose={() => setTicket(null)} ticket={ticket} />}
        </Container>
    );
}

export default function DashboardImport() {
    return <ImportGPS />;
}
