import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Box } from "@mui/material";

function SchimbaRol(props) {
    const [rol, setRol] = React.useState(null);
    const [roluri, setRoluri] = React.useState([]);

    const handleFormChange = (e) => {
        setRol(e.target.value);
    };

    const handleSubmit = () => {
        props.handleModalClose(rol);
    };

    const handleClose = () => {
        props.handleModalClose();
    };

    React.useEffect(() => {
        setRol(props.cont.role.id);
        setRoluri(
            props.roluri.filter(function (rol) {
                return !["public", "superuser"].includes(rol.type);
            })
        );
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>
                    Schimba rolul utilizatorului {props.cont.fullname}
                </span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box
                    sx={{
                        "& .MuiFormControl-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel>Rol</InputLabel>
                        <Select name="rol" value={rol} label="Rol" onChange={handleFormChange}>
                            {roluri.map((rol, index) => (
                                <MenuItem value={rol.id} key={rol.id}>
                                    {rol.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <DialogActions
                    sx={{
                        mt: 2,
                    }}
                >
                    <Button onClick={handleClose}>Anuleaza</Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Salveaza modificarile
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default SchimbaRol;
