import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { customPinIcon, fontAwesomeIcon, fontAwesomeIconFat } from "./utils/leafletIcons";
import L from "leaflet";

import { useMap } from "react-leaflet";

import * as turf from "@turf/turf";

let SCOLI = require("./geojson/analizaScoli.json");
let SCOLI_POINTS = [
    {
        type: "Feature",
        properties: {
            label: "Școala Gimnazială Avram Iancu",
        },
        geometry: {
            coordinates: [47.14041, 24.5038],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Școala Gimnaziala Ștefan cel Mare",
        },
        geometry: {
            coordinates: [47.136961, 24.507137],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Școala Postliceală Sanitară Carol Davila",
        },
        geometry: {
            coordinates: [47.1283256, 24.4861149],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Şcoala Generală NR. 1",
        },
        geometry: {
            coordinates: [47.1294839, 24.4796242],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Liceul Tehnologic de Servicii Bistrita",
        },
        geometry: {
            coordinates: [47.1333558, 24.4880867],
            type: "Point",
        },
    },
];

let SPITALE = require("./geojson/analizaSpitale.json");
let SPITALE_POINTS = [
    {
        type: "Feature",
        properties: {
            label: "Spitalul Județean de Urgență Bistrița",
        },
        geometry: {
            coordinates: [47.140462, 24.5067016],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Spitalul de Boli infecțioase",
        },
        geometry: {
            coordinates: [47.125952, 24.4928458],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Spitalul Tbc",
        },
        geometry: {
            coordinates: [47.1373687, 24.5132682],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Spitalul județean Bistrița-Năsăud - Cronici",
        },
        geometry: {
            coordinates: [47.1288465, 24.4878501],
            type: "Point",
        },
    },
];

let GRADINITE = require("./geojson/analizaGradinite.json");
let GRADINITE_POINTS = [
    {
        type: "Feature",
        properties: {
            label: "Grădinița Prichindeii",
        },
        geometry: {
            coordinates: [47.1533206, 24.5186584],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Grădiniţa Cu Program Prelungit NR. 4",
        },
        geometry: {
            coordinates: [47.1423534, 24.5067834],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Grădiniţa Cu Program Prelungit NR. 14 Trenuleţul Veseliei",
        },
        geometry: {
            coordinates: [47.130677, 24.4834688],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Grădiniţa Logos",
        },
        geometry: {
            coordinates: [47.1473267, 24.4827902],
            type: "Point",
        },
    },
    {
        type: "Feature",
        properties: {
            label: "Grădinița cu Program Săptămânal „Dumbrava Minunată”",
        },
        geometry: {
            coordinates: [47.1313536, 24.5002536],
            type: "Point",
        },
    },
];

let RENDERED_SCOLI_LAYERS = [];
let RENDERED_SCOLI_POINTS = [];

let RENDERED_SPITALE_LAYERS = [];
let RENDERED_SPITALE_POINTS = [];

let RENDERED_GRADINITE_LAYERS = [];
let RENDERED_GRADINITE_POINTS = [];

// const colors = ["#edf8b9" , "#c7e9ad", "#9dd3a7", "#64abb0", "#377eb8" ];
// const colors = ["#f7fcf5" , "#c9eac2", "#7bc77c", "#2a924b", "#00441b" ];
// const colors = ["#fff5f0" , "#fcbea5", "#fb7050", "#d32020", "#67000d" ];

const colorsScoli = ["#ffea46", "#beaf6f", "#7d7c78", "#414d6b", "#00204d"];
// const colorsGradinite = ["#ffffd4" , "#fed98e", "#fe9929", "#d95f0e", "#993404" ];
const colorsGradinite = ["#f6eff7", "#bdc9e1", "#67a9cf", "#1c9099", "#016c59"];
// const colorsGradinite = ["#fcfbfd" , "#dcdbec", "#a39fcb", "#6a51a3", "#3f007d" ];
// const colorsGradinite = ["#0d0887" , "#7e03a8", "#cb4778", "#f89541", "#f0f921" ];

var myRenderer = L.svg({});

function renderLayer({ layerData, colors, step = 2, pane }) {
    for (let i = 0; i < layerData.features.length; i++) {
        const poly1 = layerData.features[i].geometry;
        const poly2 = layerData.features[i + 1] ? layerData.features[i + 1].geometry : null;
        let turfdifference = null;

        // console.log(poly1, poly2, layerData.features[i].properties.TOTAL_POP);

        if (poly2) turfdifference = turf.difference(poly1, poly2);

        layerData.features[i].properties.color = colors[i];
        layerData.features[i].properties.index = i;
        layerData.features[i].properties.area = turf.area(poly1);

        if (turfdifference) layerData.features[i].geometry = turfdifference.geometry;
    }

    return L.geoJSON(layerData, {
        onEachFeature: function (feature, layer) {
            let ratio = feature.properties.AA_MINS / 10;
            layer.bindTooltip(
                "<div><strong>Suprafata zona:</strong> " +
                    (feature.properties.area / 1000000).toFixed(3) +
                    " km<sup>2</sup><br/><strong>Populatie:</strong> " +
                    feature.properties.TOTAL_POP +
                    " locuitori<br/><strong>Timp deplasare:</strong> " +
                    (feature.properties.AA_MINS - step) +
                    "-" +
                    feature.properties.AA_MINS +
                    " min</div>",
                {
                    direction: "top",
                    sticky: true,
                }
            );
            layer.on("mouseover", function (e) {
                this.setStyle({
                    // weight: 1,
                    // opacity: 1,
                });
            });

            layer.on("mouseout", function (e) {
                this.setStyle({
                    // weight: feature.properties.index ? 0.5 : 0,
                    // opacity: 0.3,
                });
            });
            layer.setStyle({
                fillColor: feature.properties.color,
                color: "black",
                // color :"rgb(" +String(Math.round(255*ratio)) + "," + String(Math.round(255*(1-ratio)))+ ", 0)",
                weight: feature.properties.index ? 0.5 : 0,

                fillOpacity: 0.5 - (feature.properties.index * 0.1) / 2,
                // opacity: 0.4 + feature.properties.index*0.1,
                opacity: 0.3,
            });
        },
        renderer: myRenderer,
        pane: pane,
    });
}

function renderPoint(point, map, container, iconSettings) {
    container.push(
        L.marker(point.geometry.coordinates, {
            icon: fontAwesomeIconFat(iconSettings),
            // pane: "accesScoli",
        })
            .on("click", function (e) {
                map.setZoomAround(
                    {
                        lat: point.geometry.coordinates[0],
                        lng: point.geometry.coordinates[1],
                    },
                    17
                );
            })
            .bindTooltip(point.properties.label, {
                direction: "top",
            })
    );
}

function AnalizaScoli({ map, showAccesGradinite, showAccesScoli, showAccesSpitale }) {
    // const map = useMap();

    const [initiated, setInitiated] = React.useState(false);

    React.useEffect(() => {
        if (!map || initiated) return;

        RENDERED_SCOLI_LAYERS = [];
        RENDERED_SCOLI_POINTS = [];
        RENDERED_SPITALE_LAYERS = [];
        RENDERED_SPITALE_POINTS = [];
        RENDERED_GRADINITE_LAYERS = [];
        RENDERED_GRADINITE_POINTS = [];

        setInitiated(true);

        GRADINITE.forEach(function (feature) {
            RENDERED_GRADINITE_LAYERS.push(
                renderLayer({
                    layerData: feature,
                    colors: colorsGradinite,
                    pane: "accesGradinite",
                })
            );
        });

        GRADINITE_POINTS.forEach(function (point) {
            renderPoint(point, map, RENDERED_GRADINITE_POINTS, {
                color: "hotpink",
                size: 40,
                icon: "fa-child-reaching",
            });
        });

        SCOLI.forEach(function (feature) {
            RENDERED_SCOLI_LAYERS.push(
                renderLayer({
                    layerData: feature,
                    colors: colorsScoli,
                    pane: "accesScoli",
                })
            );
        });

        SCOLI_POINTS.forEach(function (point) {
            renderPoint(point, map, RENDERED_SCOLI_POINTS, {
                color: "#414d6b",
                size: 40,
                icon: "fa-graduation-cap",
            });
        });

        RENDERED_SPITALE_LAYERS.push(
            L.geoJSON(SPITALE, {
                onEachFeature: function (feature, layer) {
                    layer.bindTooltip(
                        "<strong>" +
                            feature.properties.name +
                            "</strong>" +
                            " <br/>Timp deplasare: " +
                            feature.properties.AA_MINS +
                            "min",
                        {
                            direction: "top",
                            sticky: true,
                        }
                    );

                    layer.setStyle({
                        color: "tomato",
                        weight: 1,
                    });

                    layer.on("mouseover", function (e) {
                        this.setStyle({
                            color: "#7d7c78",
                            fillOpacity: 0.3,
                        });
                    });

                    layer.on("mouseout", function (e) {
                        this.setStyle({
                            color: "tomato",
                            fillOpacity: 0.2,
                        });
                    });
                },
                renderer: myRenderer,
                pane: "accesSpitale",
            })
        );

        SPITALE_POINTS.forEach(function (point) {
            renderPoint(point, map, RENDERED_SPITALE_POINTS, {
                color: "tomato",
                size: 40,
                icon: "fa-h",
            });
        });
    }, [map]);

    React.useEffect(() => {
        if (!initiated) return;
        if (showAccesGradinite) {
            RENDERED_GRADINITE_LAYERS.forEach(function (layer) {
                map.addLayer(layer);
            });
            RENDERED_GRADINITE_POINTS.forEach(function (point) {
                point.addTo(map);
            });
        } else {
            RENDERED_GRADINITE_LAYERS.forEach(function (layer) {
                map.removeLayer(layer);
            });
            RENDERED_GRADINITE_POINTS.forEach(function (point) {
                map.removeLayer(point);
            });
        }
    }, [showAccesGradinite]);

    React.useEffect(() => {
        if (!initiated) return;
        if (showAccesScoli) {
            RENDERED_SCOLI_LAYERS.forEach(function (layer) {
                map.addLayer(layer);
            });
            RENDERED_SCOLI_POINTS.forEach(function (point) {
                point.addTo(map);
            });
        } else {
            RENDERED_SCOLI_LAYERS.forEach(function (layer) {
                map.removeLayer(layer);
            });
            RENDERED_SCOLI_POINTS.forEach(function (point) {
                map.removeLayer(point);
            });
        }
    }, [showAccesScoli]);

    React.useEffect(() => {
        if (!initiated) return;
        if (showAccesSpitale) {
            RENDERED_SPITALE_LAYERS.forEach(function (layer) {
                map.addLayer(layer);
            });
            RENDERED_SPITALE_POINTS.forEach(function (point) {
                point.addTo(map);
            });
        } else {
            RENDERED_SPITALE_LAYERS.forEach(function (layer) {
                map.removeLayer(layer);
            });
            RENDERED_SPITALE_POINTS.forEach(function (point) {
                map.removeLayer(point);
            });
        }
    }, [showAccesSpitale]);

    React.useEffect(() => {
        // component unmount
        return () => {
            if (initiated) {
                RENDERED_SCOLI_LAYERS.forEach(function (layer) {
                    map.removeLayer(layer);
                });
                RENDERED_SCOLI_POINTS.forEach(function (point) {
                    map.removeLayer(point);
                });
                RENDERED_SPITALE_LAYERS.forEach(function (layer) {
                    map.removeLayer(layer);
                });
                RENDERED_SPITALE_POINTS.forEach(function (point) {
                    map.removeLayer(point);
                });
                setInitiated(false);
            }
        };
    }, []);

    return null;
}

export default AnalizaScoli;
