import React from "react";

import "./App.css";
import Homepage from "./Homepage.js";
import Login from "./Login.js";
import Signup from "./Signup.js";
import Dashboard from "./Dashboard";
import DashboardSumar from "./DashboardSumar";
import DashboardAlerte from "./DashboardAlerte";
import AmenziReclamatii from "./AmenziReclamatii";
import DashboardCereri from "./DashboardCereri";
import DashboardCertificate from "./DashboardCertificate";
import DashboardSesizari from "./DashboardSesizari";
import DashboardSesizare from "./DashboardSesizare";
import DashboardCertificat from "./DashboardCertificat";
import DashboardPuz from "./DashboardPuz";
import DashboardSemnare from "./DashboardSemnare";
import DashboardAutorizare from "./DashboardAutorizare";
import PrintAll from "./PrintAll";
import PrintMap from "./PrintMap";
import PrintDoc from "./PrintDoc";
import TestMap from "./TESTMAP";

import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DetaliiZona from "./DetaliiZona";
import DocumenteAvize from "./DocumenteAvize";
import Cookies from "universal-cookie";
import ManualUtilizare from "./ManualUtilizare";
import DashboardRegistratura from "./DashboardRegistratura";
import DashboardCertificatSumar from "./DashboardCertificatSumar";
import DashboardCertificatSucces from "./DashboardCertificatSucces";
import DashboardCertificatPlata from "./DashboardCertificatPlata";
import DashboardCont from "./DashboardCont";
import DashboardSiguranta from "./DashboardSiguranta";
import DashboardImport from "./DashboardImport";
import DashboardConturi from "./DashboardConturi";
import Logout from "./Logout";
import EditRlu from "./EditRLUPage";
import ExportDate from "./ExportDate";
import Sesizari from "./Sesizari";

import PubSub from "pubsub-js";
import DashboardNotificari from "./DashboardNotificari";
import DashboardTichete from "./DashboardTichete";

import { useSelector, useDispatch } from "react-redux";
import { logIn, setUserData, setRedirectURL } from "./redux/auth.slice";

import LinearProgress from "@mui/material/LinearProgress";

const API_URL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL;

const theme = createTheme({
    palette: {
        // primary: {
        //   main: purple[500],
        // },
        // secondary: {
        //   main: green[500],
        // },
    },
    components: {
        // Name of the component ⚛️
        MuiButtonBase: {
            defaultProps: {
                // The default props to change
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        MuiPaper: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    boxShadow:
                        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                },
            },
        },
    },
});

function PrivateOutlet({ auth }) {
    const location = useLocation();
    return auth ? <Outlet /> : <Navigate to="/login" replace={true} state={location} />;
}

function LoginOutlet({ auth }) {
    return !auth ? <Outlet /> : <Navigate to="/" replace={true} />;
}

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const isAuth = useSelector((state) => state.auth.isAuth);
    const jwt = useSelector((state) => state.auth.jwt);
    const redirectURL = useSelector((state) => state.auth.redirectURL);

    const [session, setSession] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const sessionData = cookies.get("sessionData");
        if (sessionData) {
            setSession(sessionData);
            dispatch(logIn({ jwt: sessionData }));
        } else {
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (isAuth) {
            setSession(jwt);
            if (!["USER", "EDITOR", "PRIMAR"].includes(jwt)) {
                getUserData()
                    .then((result) => {
                        if (result) {
                            dispatch(setUserData(result));
                            setLoading(false);
                            redirectAfterLogin();
                        } else {
                            // console.log("errror 1");
                            setSession(null);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        // console.log("errror 2");
                        setSession(null);
                        setLoading(false);
                    });
            } else {
                dispatch(setUserData());
                setLoading(false);
                redirectAfterLogin();
            }
        }
    }, [isAuth]);

    async function getUserData() {
        try {
            const response = await fetch(API_URL + "users/me?populate=role", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (err) {
            // console.log(err);
        }
    }

    const redirectAfterLogin = () => {
        if (redirectURL) {
            navigate(redirectURL);
            dispatch(setRedirectURL({ pathname: null }));
        }
    };

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <Routes>
                    {" "}
                    <Route
                        path="*"
                        element={
                            <main>
                                <LinearProgress />
                            </main>
                        }
                    />
                </Routes>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <Routes>
                    <Route path="/" element={<Homepage />}>
                        <Route element={<LoginOutlet auth={session} />}>
                            <Route path="login" element={<Login />} />
                            <Route path="signup" element={<Signup />} />
                        </Route>
                        <Route path="detalii-zona" element={<DetaliiZona />} />
                        <Route path="manual-utilizare" element={<ManualUtilizare />} />
                        <Route path="manual-utilizare/:section" element={<ManualUtilizare />} />
                        <Route path="documente-avize" element={<DocumenteAvize />} />
                    </Route>
                    <Route path="logout" element={<Logout />} />
                    <Route path="harta-sesizari" element={<TestMap />} />
                    <Route path="/print" element={<PrintAll />} />
                    <Route path="/print/:parcelId" element={<PrintAll />} />
                    <Route path="/printdoc" element={<PrintDoc />} />
                    <Route path="/printdoc/:parcelId" element={<PrintDoc />} />
                    <Route path="/printmap" element={<PrintMap />} />
                    <Route path="/printmap/:parcelId" element={<PrintMap />} />
                    <Route path="/editrlu" element={<EditRlu />} />

                    <Route element={<PrivateOutlet auth={session} />}>
                        <Route path="/dashboard" element={<Dashboard />}>
                            <Route path="" element={<DashboardSumar />} />
                            <Route path="cereri" element={<DashboardCereri />} />
                            <Route path="cereri/:param" element={<DashboardCereri />} />
                            <Route path="alerte" element={<DashboardAlerte />} />
                            <Route path="notificari" element={<DashboardNotificari />} />
                            <Route path="tichete" element={<DashboardTichete />} />
                            <Route path="amenzi" element={<AmenziReclamatii />} />
                            <Route path="sesizarile-mele" element={<DashboardSesizari />} />
                            <Route path="certificat" element={<DashboardCertificat />} />
                            <Route path="certificat/:parcela" element={<DashboardCertificat />} />
                            <Route path="certificat-sumar" element={<DashboardCertificatSumar />} />
                            <Route
                                path="certificat-succes"
                                element={<DashboardCertificatSucces />}
                            />
                            <Route path="certificat-plata" element={<DashboardCertificatPlata />} />
                            <Route path="certificate" element={<DashboardCertificate />} />
                            <Route path="sesizari" element={<Sesizari />} />
                            <Route
                                path="sesizarile-mele/:sesizareNoua"
                                element={<DashboardSesizari />}
                            />
                            <Route path="sesizare" element={<DashboardSesizare />} />
                            <Route path="registratura" element={<DashboardRegistratura />} />
                            <Route path="gestionare-conturi" element={<DashboardConturi />} />
                            <Route path="cont" element={<DashboardCont />} />
                            <Route path="siguranta" element={<DashboardSiguranta />} />
                            <Route path="import" element={<DashboardImport />} />
                            <Route path="puz" element={<DashboardPuz />} />
                            <Route path="puz/:puzId" element={<DashboardPuz />} />
                            <Route path="autorizare" element={<DashboardAutorizare />} />
                            <Route path="semnare" element={<DashboardSemnare />} />
                            <Route path="export" element={<ExportDate />} />
                        </Route>
                    </Route>
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
