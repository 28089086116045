import { useState, useEffect } from "react";
import { MapContainer, TileLayer, ScaleControl, Pane } from "react-leaflet";
import L from "leaflet";
import tileLayer from "./utils/tileLayer";

const geoJsonStyle = {
    fillColor: "yellow",
    weight: 2,
    opacity: 1,
    color: "blue",
    fillOpacity: 0.5,
};

const allDataStyle = {
    fillColor: "white",
    weight: 2,
    opacity: 1,
    color: "grey",
    fillOpacity: 0.5,
};

const Parcela = ({ map, parcelData, denumire, allData }) => {
    const [initialized, setInitialized] = useState(false);
    const [parcelRef, setParcelRef] = useState(null);

    let layerRef = null;

    function zoomToParcel() {
        map.fitBounds(layerRef.getBounds(), { maxZoom: 18 });
    }

    function onEachFeaturePopup({ layer, denumire }) {
        const tooltipText = denumire ? denumire : "Denumire";
        if (tooltipText)
            layer.bindTooltip(tooltipText, {
                direction: "top",
                sticky: true,
            });
        layer.on("click", function (e) {
            zoomToParcel();
        });
        layerRef = layer;
    }

    useEffect(() => {
        if (map && !initialized) {
            setInitialized(true);
            const allParcels = L.geoJSON(allData, {
                style: allDataStyle,
            });

            // var labelLayer = L.canvas({ pane: "text" });
            // labelLayer.addTo(map);

            // setTimeout(function () {
            //     var ctx = labelLayer._ctx;
            //     var canvas = labelLayer._container;

            //     canvas.style.pointerEvents = "none";

            //     labelLayer.draw = function () {
            //         ctx.clearRect(0, 0, canvas.width, canvas.height);
            //         ctx.font = "12px sans-serif";
            //         ctx.textAlign = "center";
            //         ctx.textBaseline = "middle";
            //         ctx.fillStyle = "#000";
            //         allParcels.eachLayer(function (layer) {
            //             var centerLat = layer.getBounds().getCenter();
            //             var center = map.latLngToLayerPoint(centerLat);
            //             console.log(map, center);
            //             ctx.fillText("Label: " + layer.feature.properties.UTR, center.x, center.y);
            //         });
            //     };

            //     labelLayer.draw();

            //     map.on("moveend", function (e) {
            //         const zoomLevel = map.getZoom();
            //         if(zoomLevel > 16)labelLayer.draw();
            //     });
            // }, 10);

            const myParcel = L.geoJSON(parcelData, {
                onEachFeature: (feature, layer) =>
                    onEachFeaturePopup({
                        feature: feature,
                        layer: layer,
                        denumire: denumire,
                    }),
                style: geoJsonStyle,
            });
            map.addLayer(allParcels);

            map.addLayer(myParcel);
            setParcelRef(myParcel);
            zoomToParcel();
        }
    }, [map]);

    useEffect(() => {
        if (parcelRef !== null) {
            map.removeLayer(parcelRef);
            const myParcel = L.geoJSON(parcelData, {
                onEachFeature: (feature, layer) =>
                    onEachFeaturePopup({
                        feature: feature,
                        layer: layer,
                        denumire: denumire,
                    }),
                style: geoJsonStyle,
            });
            map.addLayer(myParcel);
            setParcelRef(myParcel);
        }
    }, [denumire]);

    return null;
};

const PrevizualizareHarta = ({ allData, parcelData, denumire }) => {
    const [map, setMap] = useState(null);

    return (
        <div style={{ height: "360px", width: "100%" }}>
            <MapContainer
                whenCreated={setMap}
                zoom={17}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer {...tileLayer} />
                <Parcela map={map} parcelData={parcelData} denumire={denumire} allData={allData} />
                <ScaleControl position="bottomright" imperial={false} maxWidth="100" />
                <Pane name="text" style={{ zIndex: 410 }}></Pane>
            </MapContainer>
        </div>
    );
};

export default PrevizualizareHarta;
