import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const defaultState = {
    mapCenter: null,
    mapZoom: null,
    mapLayers: {
        pug: true,
        puz: true,
        pud: true,
        zoneProtectie: true,
        certificate: false,
        autorizatii: false,
        parcels: true,
        buildings: true,
        parcari: false,
        accesGradinite: false,
        accesScoli: false,
        accesSpitale: false,
        intravilan: false,
        limita: true,
        judet: false,
        uats: false,
    },
    layersOpacity: {
        pug: 100,
        puz: 100,
        pud: 100,
        zoneProtectie: 100,
        certificate: 100,
        autorizatii: 100,
        parcels: 100,
        buildings: 100,
        parcari: 100,
        accesGradinite: 100,
        accesScoli: 100,
        accesSpitale: 100,
        intravilan: 100,
        limita: 100,
        judet: 100,
        uats: 100,
    },
    mapType: "default",
};

export const layersSlice = createSlice({
    name: "layers",
    initialState: defaultState,
    reducers: {
        updateLayerVisibility: (state, action) => {
            // console.log("updateLayerVisibility", action);
            state.mapLayers = { ...state.mapLayers, ...action.payload };
        },
        updateLayerOpacity: (state, action) => {
            // console.log("updateLayerOpacity", action);
            state.layersOpacity = { ...state.layersOpacity, ...action.payload };
        },
        updateMapType: (state, action) => {
            state.mapType = action.payload;
        },
        updateMapCenter: (state, action) => {
            state.mapCenter = action.payload;
        },
        updateMapZoom: (state, action) => {
            state.mapZoom = action.payload;
        },
    },
});

export const { updateLayerVisibility, updateLayerOpacity, updateMapType, updateMapCenter, updateMapZoom } = layersSlice.actions;
export default layersSlice.reducer;
