import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import { Button, Box, Checkbox } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import PrevizualizareHarta from "./PrevizualizareHartaDocumentatie";
import EditRLU from "./EditRLUDialog";

import LayersIcon from "@mui/icons-material/Layers";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Link, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setCurrentPuz, resetCurrentPuz, editPuz, savePuz } from "./redux/puz.slice";

import { useSnackbar } from "notistack";

const ETAPE = {
    anunt: "Anunt de Intentie",
    elaborare: "Elaborarea propunerilor",
    avizare: "Avizarea propunerilor",
    aprobare: "Aprobarea propunerilor",
    aprobat: "Aprobat de Consiliul Local",
    suspendat: "Suspendat",
    anulat: "Anulat",
    expirat: "Expirat",
};

const PUZ = [
    {
        type: "Feature",
        properties: { UTR: 2 },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [24.498175866251596, 47.140282171685811],
                    [24.497453886006046, 47.140767769847024],
                    [24.497123393438411, 47.14053761942931],
                    [24.497826434545416, 47.140035319070684],
                    [24.498175866251596, 47.140282171685811],
                ],
            ],
        },
    },
    {
        type: "Feature",
        properties: { UTR: 3 },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [24.49712339343841, 47.14053761942931],
                    [24.49669726890325, 47.140273753428346],
                    [24.49669710663346, 47.14027365294644],
                    [24.497445380909465, 47.139766280159705],
                    [24.497826434545416, 47.140035319070684],
                    [24.49712339343841, 47.14053761942931],
                ],
            ],
        },
    },
    {
        type: "Feature",
        properties: { UTR: 0 },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [24.49669710663346, 47.14027365294644],
                    [24.49669726890325, 47.140273753428346],
                    [24.49712339343841, 47.14053761942931],
                    [24.497453886006046, 47.140767769847024],
                    [24.49675224321471, 47.141239437441484],
                    [24.496733884238125, 47.14123620154027],
                    [24.49661792564021, 47.14131506740392],
                    [24.49591880671701, 47.14080179897468],
                    [24.49669710663346, 47.14027365294644],
                ],
            ],
        },
    },
];

function UploadComponent({
    files,
    buttonText,
    buttonName,
    handleUpload,
    acceptedExtensions,
    buttonColor = "primary",
}) {
    return (
        <React.Fragment>
            <Box
                sx={{
                    padding: 3,
                    border: "1px dashed",
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    mb: 2,
                }}
            >
                {files && !files.length && (
                    <Box sx={{ lineHeight: "22px", opacity: 0.5 }}>{acceptedExtensions}</Box>
                )}

                {files && files.length > 0 && (
                    <Box sx={{ lineHeight: "22px" }}>
                        {files.map((file, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "0.8rem",
                                    "&:not(:last-child)": {
                                        mb: 1,
                                    },
                                }}
                            >
                                <FilePresentIcon sx={{ fontSize: "1.3rem", mr: 0.5 }} /> {file}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
            <Button
                variant="contained"
                size="large"
                color={buttonColor}
                fullWidth
                component="label"
            >
                {buttonText}
                <input
                    hidden={true}
                    accept="image/*"
                    multiple
                    type="file"
                    name={buttonName}
                    onChange={handleUpload}
                />
            </Button>
        </React.Fragment>
    );
}

function Certificat() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { puzId } = useParams();

    const model = useSelector((state) => state.puz.currentPuz);
    const user = useSelector((state) => state.auth.userData);

    const [desene, setDesene] = React.useState([]);
    const [denumiri, setDenumiri] = React.useState([]);
    const [RLUs, setRLUs] = React.useState([]);

    const [editIndex, setEditIndex] = React.useState(null);

    React.useEffect(() => {
        if (model.desen.length) {
            let denumiriTemp = [];
            model.desen.forEach(function (desen, i) {
                denumiriTemp.push(desen.properties.name);
            });
            setDenumiri([...denumiriTemp]);
            setRLUs(new Array(model.desen.length).fill(""));
        }
    }, [model]);

    React.useEffect(() => {
        document.getElementById("dashboard-scroll").scrollTo(0, 0);
        if (puzId) {
            dispatch(editPuz({ id: parseInt(puzId) }));
        }
        // component unmount
        return () => {
            dispatch(resetCurrentPuz());
        };
    }, []);

    const handleEtapaChange = (event) => {
        dispatch(
            setCurrentPuz({
                ...model,
                [event.target.name]: event.target.value,
            })
        );
    };

    function handleDenumiri(e, index) {
        let newArray = [];
        denumiri.forEach(function (denumire, i) {
            const value = i === index ? e.target.value : denumire;
            newArray.push(value);
        });
        setDenumiri(newArray);
    }

    function handleSaveRLU(data) {
        let newArray = [];
        RLUs.forEach(function (rlu, i) {
            const value = i === editIndex ? data : rlu;
            newArray.push(value);
        });
        setRLUs(newArray);
    }

    function handleFileUpload(e) {
        const newFiles = e.target.files;
        const name = e.target.name;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(newFiles[i].name);
        }
        dispatch(
            setCurrentPuz({
                ...model,
                [name]: [...model[name], ...newFilesArray],
            })
        );
    }

    function handleDesenUpload(e) {
        const newFiles = e.target.files;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(newFiles[i].name);
        }
        if (desene.length) {
            // do nothing
        } else {
            setDesene([...newFilesArray]);
            dispatch(
                setCurrentPuz({
                    ...model,
                    desen: [...model.desen, ...PUZ],
                })
            );
        }
    }

    return (
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
            <Heading
                sx={{
                    background: "linear-gradient(35deg, #1876d2, rgba(0,0,0,0.25))",
                    color: "white",
                    padding: "1rem 0 1rem 3rem",
                    ml: "-1.5rem",
                    mr: "-1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "400",
                    verticalAlign: "middle",
                }}
            >
                <LayersIcon sx={{ mb: "-5px", mr: 1.25 }} />
                <span>Plan Urbanistic Zonal (PUZ)</span>
            </Heading>
            <Box sx={{ mx: 3, my: 3, maxWidth: "960px" }}>
                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Tip:</Heading>
                    <Box component="form" noValidate autoComplete="off">
                        <TextField
                            name="tip"
                            value={model.tip}
                            onChange={handleEtapaChange}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="ex. Liu"
                        />
                    </Box>
                </Paper>

                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Descriere:</Heading>
                    <Box component="form" noValidate autoComplete="off">
                        <TextField
                            name="descriere"
                            value={model.descriere}
                            onChange={handleEtapaChange}
                            sx={{ background: "#FFF" }}
                            fullWidth
                            placeholder="ex. LOCUINŢE CU REGIM REDUS DE ÎNĂLŢIME"
                        />
                    </Box>
                </Paper>

                <Paper sx={{ mb: 4, background: "transparent" }} elevation={0}>
                    <Heading>Etapa:</Heading>
                    <Box component="form" noValidate autoComplete="off">
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="etapa"
                                value={model.etapa}
                                onChange={handleEtapaChange}
                                sx={{ backgroundColor: "white" }}
                            >
                                {Object.entries(ETAPE).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>

                <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} md={6}>
                        <Heading>Memoriu:</Heading>
                        <UploadComponent
                            files={model.memoriu}
                            buttonText="Incarca memoriu"
                            buttonName="memoriu"
                            handleUpload={handleFileUpload}
                            acceptedExtensions="Extensii acceptate: .PDF sau .DOC"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Heading>HCL:</Heading>
                        <UploadComponent
                            files={model.hcl}
                            buttonText="Incarca HCL"
                            buttonName="hcl"
                            handleUpload={handleFileUpload}
                            acceptedExtensions="Extensii acceptate: .PDF sau .DOC"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} md={6}>
                        <Heading>Planuri:</Heading>
                        <UploadComponent
                            files={model.planuri}
                            buttonText="Incarca un plan"
                            buttonName="planuri"
                            handleUpload={handleFileUpload}
                            acceptedExtensions="Extensii acceptate: .PDF sau .DOC"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Heading
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                            <span>Desen tehnic:</span>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                }}
                            >
                                <FileDownloadIcon
                                    sx={{
                                        position: "relative",
                                        top: "8px",
                                        mr: 0.5,
                                    }}
                                />
                                <a
                                    href="#"
                                    style={{
                                        fontSize: "14px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Descarca Sablon
                                </a>
                            </span>
                        </Heading>
                        <UploadComponent
                            files={desene}
                            buttonText="Incarca desen"
                            buttonName="desen"
                            handleUpload={handleDesenUpload}
                            buttonColor={!!model.desen.length ? "secondary" : "secondary"}
                            acceptedExtensions="Extensii acceptate: .DWG sau .geojson"
                        />
                    </Grid>
                </Grid>

                {!model.desen.length && (
                    <Box
                        className="map-bg"
                        sx={{
                            height: "150px",
                            mb: 4,
                            // border: "1px solid #FFF",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                height: "100px",
                                background: "rgba(255,255,255,0.75)",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                lineHeight: 1.5,
                            }}
                        >
                            <span style={{ maxWidth: 500 }}>
                                Adauga un desen tehnic pentru vizualizare geospatiala si pentru
                                introducerea regulamentelor locale de urbanism (RLU)
                            </span>
                        </Box>
                    </Box>
                )}

                {!!model.desen.length && (
                    <React.Fragment>
                        {model.desen.map((puz, index) => (
                            <Paper
                                key={index + model.desen.length}
                                sx={{ mb: 2, background: "transparent" }}
                                elevation={0}
                            >
                                <Heading>Previzualizare desen tehnic:</Heading>
                                <Box mb={2}>
                                    <TextField
                                        sx={{ background: "#FFF" }}
                                        value={denumiri[index]}
                                        fullWidth
                                        placeholder="Denumire"
                                        onChange={(e) => handleDenumiri(e, index)}
                                    />
                                </Box>
                                <Paper
                                    sx={{
                                        border: "1px solid #FFF",
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                    }}
                                    elevation={1}
                                >
                                    <PrevizualizareHarta
                                        allData={model.desen}
                                        parcelData={puz}
                                        denumire={denumiri[index]}
                                    />
                                </Paper>
                                {RLUs[index] && (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{ mt: -10, ml: 1, zIndex: 1000 }}
                                        onClick={() => setEditIndex(index)}
                                    >
                                        Modifica Regulamet Local de Urbanism
                                    </Button>
                                )}

                                {!RLUs[index] && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        sx={{ mt: -10, ml: 1, zIndex: 1000 }}
                                        onClick={() => setEditIndex(index)}
                                    >
                                        + Adauga Regulamet Local de Urbanism
                                    </Button>
                                )}
                            </Paper>
                        ))}
                    </React.Fragment>
                )}

                {editIndex !== null && (
                    <EditRLU handleClose={() => setEditIndex(null)} handleSave={handleSaveRLU} />
                )}

                <Button
                    variant="outlined"
                    size="large"
                    to="/dashboard/cereri/documentatii"
                    component={Link}
                    sx={{ mr: "1rem", mb: 4 }}
                >
                    Anuleaza
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    to="/dashboard/cereri/documentatii"
                    onClick={() => {
                        dispatch(savePuz(user));
                        enqueueSnackbar("Documentul a fost salvat", { variant: "info" });
                    }}
                    component={Link}
                    sx={{ mb: 4 }}
                >
                    Salveaza PUZ-ul
                </Button>
            </Box>
        </Container>
    );
}

export default function DashboardCertificat() {
    return <Certificat />;
}
