import * as React from "react";

import { Box } from "@mui/material";
import DashboardDocPreview from "./DashboardDocPreview";

import PrevizualizareHarta from "./PrevizualizareHartaPrint";
import HeadingCertificatPrint from "./components/HeadingCertificatPrint";

import { Link, useParams } from "react-router-dom";
import { area } from "@turf/turf";

function PrintAll() {
    const [parcelData, setParcelData] = React.useState();
    const [parcelArea, setParcelArea] = React.useState(0);
    const [scale, setScale] = React.useState();

    let { parcelId } = useParams();
    parcelId = parcelId ? parseInt(parcelId) : 89118;

    React.useEffect(() => {
        if (parcelData) {
            setParcelArea(Math.round(area(parcelData)));
        }
    }, [parcelData]);

    return (
        <Box className="map-print-wrapper">
            <Box>
                <DashboardDocPreview parcelId={parcelId} renderMode="view" />
                <Box sx={{ pageBreakAfter: "always" }}>&nbsp;</Box>
                <Box sx={{ pageBreakBefore: "always" }}>&nbsp;</Box>
                <HeadingCertificatPrint logoHeight="110" />
                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, mt: 2 }}>
                    <Box
                        sx={{
                            border: "1px solid",
                            py: 1.5,
                            px: 2,
                            color: "mediumblue",
                            textTransform: "uppercase",
                            fontWeight: 600,
                            fontSize: "1.1rem",
                        }}
                    >
                        Conform cu extrasul de carte funciara
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                    <Box>
                        <strong>Număr Carte Funciară:</strong> {parcelId}
                    </Box>
                    <Box>
                        <strong>Scară Hartă:</strong> {scale}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                    <Box>
                        <strong>Suprafată:</strong> {parcelArea} mp
                    </Box>
                    <Box>
                        <strong>Dată Emitere:</strong> {new Date().toLocaleDateString()}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 7 }}>
                    <Box>
                        <strong>Adresă:</strong> Strada Calea Clujului, Bistrita
                    </Box>
                    <Box>
                        <strong>Număr Cerere:</strong> 28308393-1
                    </Box>
                </Box>

                <Box sx={{ mb: 8 }}>
                    <PrevizualizareHarta
                        setScale={setScale}
                        setParcelData={setParcelData}
                        parcelId={parcelId}
                    />
                </Box>
                <Box sx={{ textAlign: "center", fontWeight: 600, mb: 2, fontSize: "1.3rem" }}>
                    PLAN DE SITUAȚIE EXISTENTĂ
                </Box>
                <Box sx={{ textAlign: "center", fontWeight: 400, fontSize: "1.15rem" }}>
                    Anexa la CU nr. 1448 din 30-12-2022
                </Box>
            </Box>
        </Box>
    );
}

export default PrintAll;
