import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import PubSub from "pubsub-js";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LayersIcon from "@mui/icons-material/Layers";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { area, polygon, point, booleanPointInPolygon } from "@turf/turf";
import { getPlainText } from "./utils/draftUtils";

import { blue } from "@mui/material/colors";
import { Box, Divider, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";

function isMobile() {
    return window.innerHeight <= 720;
}

function getClickedParcel(data) {
    let clickedParcel = null;
    const clickPoint = point([data.position.latlng.lng, data.position.latlng.lat]);
    document.layerData.parcels.features.forEach(function (parcel, index) {
        const parcelPoly = polygon(parcel.geometry.coordinates[0]);
        if (booleanPointInPolygon(clickPoint, parcelPoly)) clickedParcel = parcel;
    });
    return clickedParcel;
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ParcelCardContent({ parcel, parcelArea }) {
    return (
        <React.Fragment>
            <Typography color="text.secondary" sx={{ mb: 1.5, fontSize: "0.9rem" }}>
                Număr carte funciară: <strong>{parcel.properties.cad.toString()}</strong>{" "}
                <Link
                    href={"/print/" + parcel.properties.cad.toString()}
                    target="_blank"
                    underline="always"
                >
                    &raquo;
                </Link>
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 2, fontSize: "0.9rem" }}>
                Suprafata: <strong>{parcelArea} mp</strong>
            </Typography>
            <Divider></Divider>
            <Typography sx={{ mb: 1.5, mt: 2, fontSize: "0.9rem" }}>
                &raquo;{" "}
                <Link
                    to={"/printmap/" + parcel.properties.cad.toString()}
                    component={RouterLink}
                    target="_blank"
                    underline="always"
                >
                    Plan de situatie existentă...
                </Link>
            </Typography>
            <Typography sx={{ fontSize: "0.9rem", pb: 1 }}>
                &raquo;{" "}
                <Link
                    to={"/dashboard/certificat/" + parcel.properties.cad.toString()}
                    component={RouterLink}
                    underline="always"
                >
                    Certificat de urbanism...
                </Link>
            </Typography>
        </React.Fragment>
    );
}

function Inspector() {
    const [expanded, setExpanded] = React.useState(isMobile() ? "" : "panel1");
    const [address, setAddress] = React.useState("...");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // const [inspectorData, setInspectorData] = React.useState(null);
    const [positionData, setPositionData] = React.useState(null);
    const [zoneName, setZoneName] = React.useState(null);
    const [zoneColor, setZoneColor] = React.useState(null);
    const [parcelArea, setParcelArea] = React.useState(0);
    const [parcel, setParcel] = React.useState(null);
    const [clickedParcel, setClickedParcel] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [showGeoDialog, setShowGeoDialog] = React.useState(false);
    const [geoJSON, setGeojson] = React.useState(null);

    const mySubscriber = function (msg, data) {
        setZoneName(data.name);
        setZoneColor(data.color);
        setDescription(data.description);
        setAddress("...");
        if (data.parcel) {
            setParcel(data.parcel);
            setParcelArea(Math.round(area(data.parcel)));
            getGeoJSON(data.parcel.properties.cad);
        } else {
            setParcel(null);
            const clickedParcel = getClickedParcel(data);
            setClickedParcel(clickedParcel);
            if (clickedParcel) {
                setParcelArea(Math.round(area(clickedParcel)));
            }
        }
        if (data.position) {
            // setExpanded(isMobile() ? "" : "panel1");
            setPositionData(data.position);
            getPosition([data.position.latlng.lat, data.position.latlng.lng]);
        }
    };

    useEffect(() => {
        const token = PubSub.subscribe("AREA_CLICK", mySubscriber);
        return () => {
            PubSub.unsubscribe(token);
            //PubSub.unsubscribe(mySubscriber);
        };
    });

    const getPosition = (location) => {
        const getDisplayName = async () => {
            const response = await axios.get("https://nominatim.openstreetmap.org/search.php", {
                params: { q: location.join(","), polygon_geojson: 1, format: "json" },
            });
            setAddress(response.data[0].display_name.split(", Bistrița-Năsăud")[0]);
        };
        getDisplayName();
    };

    const getGeoJSON = (location) => {
        const getData = async () => {
            const response = await axios.get("https://demo.topomaps.ro/api/parcels/" + location);
            setGeojson(response.data);
        };
        getData();
    };

    const handleHideOptions = (e) => {
        const mapWrapper = document.querySelector("#maps");
        if (
            mapWrapper.contains(e.target) &&
            (!e.target.classList.contains("leaflet-misc-pane") ||
                !e.target.classList.contains("leaflet-parcels-pane"))
        ) {
            setPositionData(null);
        }
    };

    return (
        <Paper
            component="form"
            sx={{
                display: positionData ? "inline-block" : "none",
                marginBottom: "1rem",
                position: "absolute",
                top: "1rem",
                right: "10px",
                zIndex: 401,
                // right: positionData ? "10px" : "-400px",
                // transition: "right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            }}
        >
            <ClickAwayListener onClickAway={handleHideOptions}>
                <Card
                    sx={{
                        width: parcel ? 260 : 360,
                        border: 0,
                        boxShadow: "none",
                    }}
                >
                    {!parcel && (
                        <React.Fragment>
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography sx={{ fontSize: "0.92rem" }}>
                                        <strong>
                                            {zoneName ? zoneName : "PUG lc_a - Regim Tehnic"}
                                        </strong>
                                        {zoneColor && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    width: 19,
                                                    height: 19,
                                                    border: "2px solid transparent",
                                                    borderRadius: 0,
                                                    backgroundColor: zoneColor,
                                                    position: "absolute",
                                                    top: 13,
                                                    right: 13,
                                                    opacity: 0.2,
                                                }}
                                            ></span>
                                        )}
                                        <span
                                            style={{
                                                display: "inline-block",
                                                width: 19,
                                                height: 19,
                                                border: zoneColor
                                                    ? "2px solid " + zoneColor
                                                    : "2px solid blue",
                                                borderRadius: 0,
                                                backgroundColor: "transparent",
                                                position: "absolute",
                                                top: 13,
                                                right: 13,
                                            }}
                                        ></span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: "0.92rem" }}>
                                        {getPlainText(description)}
                                    </Typography>
                                    <Stack
                                        spacing={2}
                                        sx={{ margin: "1rem 0 0 0 " }}
                                        direction="row"
                                    >
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            to="/documente-avize"
                                            component={RouterLink}
                                        >
                                            Documente/Avize
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            to="/detalii-zona"
                                            component={RouterLink}
                                        >
                                            Vezi detalii zona
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                            >
                                <AccordionSummary
                                    aria-controls="panel2d-content"
                                    id="panel2d-header"
                                >
                                    <Typography sx={{ fontSize: "0.92rem" }}>
                                        <strong>PUG M1 - Regim Tehnic</strong>
                                        <span
                                            style={{
                                                display: "inline-block",
                                                width: 19,
                                                height: 19,
                                                border: "2px solid green",
                                                borderRadius: 0,
                                                background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='5' height='5' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 250, 240,1)'/%3E%3Cpath d='m0 10h40v10h-40z' fill='rgba(72, 187, 120,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ")`,
                                                position: "absolute",
                                                top: 13,
                                                right: 13,
                                            }}
                                        ></span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: "0.92rem" }}>
                                        Zonă a marilor ansambluri monofuncţionale rezidenţiale
                                        construite înainte de 1990.
                                    </Typography>
                                    <Stack
                                        spacing={2}
                                        sx={{ margin: "1rem 0 0 0 " }}
                                        direction="row"
                                    >
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            to="/documente-avize"
                                            component={RouterLink}
                                        >
                                            Documente/Avize
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            to="/detalii-zona"
                                            component={RouterLink}
                                        >
                                            Vezi detalii zona
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            {clickedParcel && (
                                <Accordion
                                    expanded={expanded === "panel3"}
                                    onChange={handleChange("panel3")}
                                >
                                    <AccordionSummary
                                        aria-controls="panel3d-content"
                                        id="panel3d-header"
                                    >
                                        <Typography
                                            sx={{ fontSize: "0.92rem", paddingRight: "20px" }}
                                        >
                                            <strong>Parcela de Teren</strong>
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    width: 20,
                                                    height: 20,
                                                    border: "1px solid black",
                                                    borderRadius: 0,
                                                    backgroundColor: "rgba(255, 255, 0, 0.6)",
                                                    position: "absolute",
                                                    top: 13,
                                                    right: 13,
                                                }}
                                            ></span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ParcelCardContent
                                            parcel={clickedParcel}
                                            parcelArea={parcelArea}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </React.Fragment>
                    )}
                    {parcel && (
                        <React.Fragment>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: blue[600] }} aria-label="recipe">
                                        <LayersIcon />
                                    </Avatar>
                                }
                                sx={{
                                    "& span": {
                                        fontSize: "1rem !important",
                                    },
                                    position: "relative",
                                    zIndex: 10,
                                    background: "#FFF",
                                }}
                                // action={
                                //     <IconButton aria-label="settings">
                                //         <CloseIcon />
                                //     </IconButton>
                                // }
                                title={"Parcela de Teren"}
                                // subheader="Imobil"
                            />
                            <CardContent
                                sx={{
                                    py: 1,
                                    boxShadow:
                                        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                                    position: "relative",
                                    zIndex: 5,
                                }}
                            >
                                <ParcelCardContent parcel={parcel} parcelArea={parcelArea} />
                            </CardContent>
                        </React.Fragment>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            padding: "10px 1rem 10px 1rem",
                            fontSize: "0.8rem",
                            fontWeight: 500,
                            backgroundColor: "gold",
                            justifyContent: "space-between",
                        }}
                        onClick={handleHideOptions}
                    >
                        <Box sx={{ flexGrow: 1 }}>{address}</Box>
                        {parcel && (
                            <DataObjectIcon
                                sx={{
                                    borderLeft: "1px solid rgba(0,0,0,0.2)",
                                    fontSize: "1.25rem",
                                    opacity: 0.75,
                                    ml: 1,
                                    pl: 1,
                                    height: "100%",
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 1,
                                    },
                                }}
                                onClick={() => setShowGeoDialog(true)}
                            />
                        )}
                    </Box>
                </Card>
            </ClickAwayListener>

            {showGeoDialog && (
                <Dialog
                    open={true}
                    onClose={() => setShowGeoDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Coordonate Stereo 70 pentru parcela <strong>{parcel.properties.cad.toString()}</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ minWidth: "480px", pt: 1 }}>
                            <TextField
                                label="GeoJSON"
                                type="text"
                                name="xml"
                                value={geoJSON ? JSON.stringify(geoJSON, null, 4) :JSON.stringify(parcel, null, 4)}
                                multiline
                                rows={15}
                                fullWidth={true}
                                
                            />
                            </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={() => setShowGeoDialog(false)}
                            sx={{mr: 2, mb: 2, mt: -1}}
                        >
                            Inchide
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Paper>
    );
}

export default Inspector;
