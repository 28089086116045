import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";

// Inspired by the former Facebook spinners.
function CustomCircularProgress(props) {
    return (
        <Box sx={{ position: "relative", height: props.size, width: props.size }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
                }}
                size={props.size || 40}
                thickness={props.thickness || 4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: (theme) => (theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"),
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                size={props.size || 40}
                thickness={props.thickness || 4}
                {...props}
            />
        </Box>
    );
}

export default function CustomizedProgressBars(props) {
    return (
            <CustomCircularProgress {...props} />
    );
}
