import React from "react";

import { Button, Stack } from "@mui/material/";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

function StergeDocumentatie(props) {
    const handleDelete = () => {
        props.handleClose(true);
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Sterge documentatie</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    PUZ-ul <strong>{props.item.descriere}</strong> va fi sters. Doriti sa continuati?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} sx={{ m: 1 }} direction="row">
                    <Button variant="outlined" onClick={handleClose}>
                        Anulare
                    </Button>
                    <Button variant="contained" autoFocus onClick={handleDelete}>
                        Sterge documentatie
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default StergeDocumentatie;
