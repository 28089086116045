import { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, ScaleControl } from "react-leaflet";
import L from "leaflet";
import tileLayer from "./utils/tileLayer";
import { Button } from "@mui/material/";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { Link, useParams } from "react-router-dom";

const center = [47.1392, 24.4973];

let parcelsGroup = null;

const Parcela = ({ map, parcelData, setLayerInfo }) => {
    const [initialized, setInitialized] = useState(false);

    if (map && parcelData && !initialized) {
        setInitialized(true);
        init();
    }

    function init() {
        const geoJsonStyle = {
            fillColor: "yellow",
            weight: 2,
            opacity: 1,
            color: "blue",
            fillOpacity: 0.25,
        };

        function zoomToParcel() {
            map.fitBounds(parcelsGroup.getBounds(), { maxZoom: 18 });
        }

        const unlabeledParcel = L.geoJSON(parcelData, {
            onEachFeature: onEachFeaturePopup,
            style: geoJsonStyle,
        });

        function onEachFeaturePopup(feature, layer) {
            const tooltipText = feature.properties.name ? feature.properties.name : null;
            if (tooltipText)
                layer.bindTooltip(tooltipText, {
                    direction: "top",
                    sticky: true,
                });
            layer.on("click", function (e) {
                map.fitBounds(layer.getBounds(), { maxZoom: 18 });
                setLayerInfo(layer.feature.properties);
            });
        }

        const labeledParcel = L.geoJSON(parcelData, {
            onEachFeature: onEachFeature,
            style: geoJsonStyle,
        });

        function onEachFeature(feature, layer) {
            const tooltipText = feature.properties.name ? feature.properties.name : null;
            if (tooltipText)
                layer.bindTooltip(tooltipText, {
                    permanent: true,
                    direction: "center",
                    className: "parcelLabel",
                });

            layer.on("click", function (e) {
                setLayerInfo(layer.feature.properties);
            });
        }

        parcelsGroup = L.featureGroup([labeledParcel]);

        map.addLayer(parcelsGroup);

        map.on("moveend", function () {
            map.removeLayer(parcelsGroup);
            if (map.getZoom() > 16) {
                parcelsGroup = L.featureGroup([labeledParcel]);
                map.addLayer(parcelsGroup);
            } else {
                parcelsGroup = L.featureGroup([unlabeledParcel]);
                map.addLayer(parcelsGroup);
            }
        });

        map.on("click", function (e) {
            console.log(e.originalEvent.target.classList);

            if (!e.originalEvent.target.classList.contains("leaflet-interactive")) {
                setLayerInfo(null);
            }
        });

        zoomToParcel();
    }

    useEffect(() => {
        if (parcelsGroup && map) {
            map.removeLayer(parcelsGroup);
            if (parcelData) init();
        }
    }, [parcelData]);

    return null;
};

const PrevizualizareHarta = ({ parcelData, handleHistoryOpen }) => {
    const [map, setMap] = useState(null);
    const [mapKey, setMapKey] = useState(0);
    const [layerInfo, setLayerInfo] = useState(null);

    useEffect(() => {
        if (parcelData) setMapKey(new Date().getTime());
    }, [parcelData]);

    return (
        <div style={{ height: "360px", border: "1px solid", position: "relative" }}>
            {layerInfo && (
                <div
                    style={{
                        maxWidth: 260,
                        borderRadius: 4,
                        border: "2px solid rgba(0,0,0,0.2)",
                        // height: "30px",
                        position: "absolute",
                        left: 10,
                        bottom: 10,
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "10px",
                            borderRadius: 4,
                        }}
                    >
                        <div
                            style={{
                                padding: "0.5rem 0.5rem 1rem 0.5rem",
                                fontWeight: 500,
                                fontSize: "90%",
                                borderBottom: "1px solid #ddd",
                                marginBottom: "0.5rem",
                            }}
                        >
                            {layerInfo.name}
                        </div>
                        <Button
                            component={Link}
                            startIcon={<EditIcon />}
                            variant="outlined"
                            size="small"
                            sx={{ mr: 1 }}
                            to={"/dashboard/puz/" + layerInfo.id}
                        >
                            Editare
                        </Button>
                        <Button
                            startIcon={<HistoryIcon />}
                            variant="outlined"
                            color="warning"
                            size="small"
                            onClick={() => handleHistoryOpen(layerInfo.id)}
                        >
                            Istoric
                        </Button>
                    </div>
                </div>
            )}
            <MapContainer
                whenCreated={setMap}
                zoom={13}
                // scrollWheelZoom={false}
                center={center}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer {...tileLayer} />
                <Parcela
                    key={mapKey}
                    map={map}
                    parcelData={parcelData}
                    setLayerInfo={setLayerInfo}
                />
                <ScaleControl position="bottomright" imperial={false} maxWidth="100" />
            </MapContainer>
        </div>
    );
};

export default PrevizualizareHarta;
