import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CommentIcon from "@mui/icons-material/Comment";
import { Button, Chip, Box } from "@mui/material/";
import Spinner from "./components/Spinner";

import FontDownloadIcon from "@mui/icons-material/FontDownload";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import IconButton from "@mui/material/IconButton";

import PDFSample from "./images/certificat.pdf";

import axios from "axios";

import { useSelector, useDispatch } from "react-redux";

let certificateDefault = [
    {
        id: 348,
        createDate: 1677151656313,
        status: "depus",
        sursa: "auto",
        solicitant: {
            nume: "Popescu",
            prenume: "Vlad",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Imobil mixt",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "80547",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
    {
        id: 347,
        createDate: 1674669525250,
        status: "depus",
        sursa: "auto",
        solicitant: {
            nume: "Popescu",
            prenume: "Ion",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie casa",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "54938",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
    {
        id: 346,
        createDate: 1674669525250,
        status: "depus",
        sursa: "auto",
        solicitant: {
            nume: "Pop",
            prenume: "Vasile",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Reabilitare constructie",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "89118",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
    {
        id: 345,
        createDate: 1674369525250,
        status: "lucru",
        sursa: "manual",
        solicitant: {
            nume: "Popescu",
            prenume: "Ion",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Extindere constructie existenta",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Popescu",
                prenume: "Ion",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "76487",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {
            type: "1",
            booleanOptions: [
                {
                    lucrari1: false,
                },
                {
                    lucrari2: false,
                },
                {
                    lucrari3: true,
                },
                {
                    lucrari4: false,
                },
                {
                    lucrari5: false,
                },
                {
                    lucrari6: false,
                },
            ],
            textOptions: [
                {
                    descriere: "Text descriere suplimentara...",
                },
            ],
            uploadOptions: [],
        },
    },
    {
        id: 344,
        createDate: 1674369525260,
        status: "autorizat",
        sursa: "manual",
        solicitant: {
            nume: "Popescu",
            prenume: "Maria",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie duplex",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Ion",
                prenume: "Popescu",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "54764",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
            {
                cad: "54763",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
    {
        id: 343,
        createDate: 1674369525260,
        status: "autorizat",
        sursa: "auto",
        solicitant: {
            nume: "Georgescu",
            prenume: "Ana",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie casa",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Ion",
                prenume: "Popescu",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "67362",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
    {
        id: 342,
        createDate: 1674369525260,
        status: "respins",
        sursa: "auto",
        solicitant: {
            nume: "Georgescu",
            prenume: "Ana",
            email: "ion@gmail.com",
            telefon: "0742555555",
        },
        obiectiv: "Constructie casa",
        beneficiari: [
            {
                tip: "fizica",
                nume: "Ion",
                prenume: "Popescu",
                companie: "",
                cnp: "1850807373848",
                cif: "",
                email: "ion@gmail.com",
                telefon: "0742555555",
                adresa: "Judetul Cluj, Cluj-Napoca, Str. Albac, nr. 2, ap. 1",
            },
        ],
        amplasamente: [
            {
                cad: "67362",
                judet: "Bistrita-Nasaud",
                uat: "Bistrita",
                suprafata: "",
            },
        ],
        anexe: [
            {
                id: 3454353,
                name: "anexa1.pdf",
                url: "https://demo.topomaps.ro/upload/anexe/anexa1.pdf",
            },
        ],
        scop: {},
    },
];

const datestring = function (d) {
    const date = new Date(d);
    const month = date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    return date.getDate() + "/" + month + "/" + date.getFullYear().toString().slice(-2);
};

const getAmplasamente = function (amplasamente) {
    let tempArray = [];
    if (amplasamente)
        amplasamente.forEach((element) => {
            tempArray.push(element.cad);
        });

    return tempArray.join(", ");
};

const getUat = function (amplasamente) {
    let uat = "";
    if (amplasamente && amplasamente[0]) uat += amplasamente[0].uat;
    return uat;
};

const getSolicitant = function (solicitant) {
    let nume = "";
    if (solicitant) nume += solicitant.prenume + " " + solicitant.nume;
    return nume;
};

function TabelCereri(props) {
    const [data, setData] = React.useState([]);
    const userData = useSelector((state) => state.auth.userData);
    const [userRole, setUserRole] = React.useState(null);

    React.useEffect(() => {
        if (userData) setUserRole(userData.role);
    }, [userData]);

    React.useEffect(() => {
        function setDefaultData() {
            if (props.param === "aprobat") {
                if (userRole === "EDITOR") {
                    certificateDefault[0].status = "lucru";
                } else {
                    certificateDefault[0].status = "autorizat";
                }
            }
            if (props.onlyPending) {
                let filteredData = certificateDefault.filter((item, index) => index < 3);
                setData(filteredData);
            } else {
                setData(certificateDefault);
            }
        }

        const getData = async () => {
            const response = await axios.get("/api/certificate").catch(function (error) {
                console.log(error.toJSON());
            });

            if (response) {
                // setCertificate(response.data);
                setDefaultData();
            } else {
                setDefaultData();
            }
        };
        getData();
    }, []);

    return (
        <TableContainer>
            {!data.length && (
                <Box
                    sx={{
                        display: "flex",
                        minHeight: 160,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner />
                </Box>
            )}
            {data.length > 0 && (
                <Table
                    size="medium"
                    sx={{
                        minWidth: 650,
                        "& .MuiTableCell-root": {
                            fontSize: "100%",
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>Nr. Inreg.</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Solicitant</TableCell>
                            <TableCell>Obiectiv</TableCell>
                            <TableCell align="center">Amplasamente</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow
                                key={item.id}
                                sx={{
                                    background:
                                        index < 3 && item.status === "depus" ? "aliceblue" : "",
                                }}
                            >
                                <TableCell>
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            alignItems: "center",
                                            lineHeight: "1rem",
                                        }}
                                    >
                                        {
                                            {
                                                auto: (
                                                    <FontDownloadIcon
                                                        sx={{ mr: 1, opacity: 0.75 }}
                                                    />
                                                ),
                                                manual: (
                                                    <KeyboardIcon sx={{ mr: 1, opacity: 0.5 }} />
                                                ),
                                            }[item.sursa]
                                        }
                                        {item.id}
                                    </span>
                                </TableCell>
                                <TableCell>{datestring(item.createDate)}</TableCell>
                                <TableCell>{getSolicitant(item.solicitant)}</TableCell>
                                <TableCell>{item.obiectiv}</TableCell>
                                <TableCell align="center">
                                    {getAmplasamente(item.amplasamente)}
                                </TableCell>
                                <TableCell align="right" sx={{ minWidth: 150 }}>
                                    <Button
                                        color="inherit"
                                        endIcon={
                                            <CommentIcon
                                                sx={{
                                                    color: [2, 3].includes(index)
                                                        ? "skyblue"
                                                        : "rgba(0,0,0,0.2)",
                                                }}
                                            />
                                        }
                                        sx={{ color: "#222 !important" }}
                                        disabled
                                    >
                                        <span>{[2, 3].includes(index) && index - 1}</span>
                                    </Button>
                                    {
                                        {
                                            draft: <React.Fragment></React.Fragment>,
                                            depus: <React.Fragment></React.Fragment>,
                                            lucru: <React.Fragment></React.Fragment>,
                                            autorizat: (
                                                <React.Fragment>
                                                    <IconButton
                                                        href={PDFSample}
                                                        target="_blank"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <PictureAsPdfIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </React.Fragment>
                                            ),
                                            respins: <React.Fragment></React.Fragment>,
                                        }[item.status]
                                    }
                                    {
                                        {
                                            draft: <Chip label="DRAFT" />,
                                            depus: (
                                                <React.Fragment>
                                                    {userRole === "EDITOR" && (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            href="/dashboard/autorizare"
                                                            sx={{ minWidth: "130px" }}
                                                        >
                                                            Redacteaza
                                                        </Button>
                                                    )}
                                                    {userRole === "PRIMAR" && (
                                                        // <Chip
                                                        //     color="warning"
                                                        //     label="ÎN REDACTARE"
                                                        //     sx={{
                                                        //         minWidth: "130px",
                                                        //         background: "slateblue",
                                                        //     }}
                                                        // />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="medium"
                                                            href="/dashboard/semnare"
                                                            sx={{ minWidth: "130px" }}
                                                        >
                                                            Semneaza
                                                        </Button>
                                                    )}
                                                </React.Fragment>
                                            ),
                                            lucru: (
                                                <React.Fragment>
                                                    {userRole === "PRIMAR" && (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="medium"
                                                            href="/dashboard/semnare"
                                                            sx={{ minWidth: "130px" }}
                                                        >
                                                            Semneaza
                                                        </Button>
                                                    )}
                                                    {userRole === "EDITOR" && (
                                                        <Chip
                                                            variant="contained"
                                                            color="secondary"
                                                            label="ÎN APROBARE"
                                                            sx={{ minWidth: "130px" }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ),
                                            autorizat: <Chip label="AUTORIZAT" />,
                                            respins: (
                                                <Chip
                                                    variant="outlined"
                                                    label="CERERE RESPINSA"
                                                    size="small"
                                                    sx={{ fontSize: "0.75rem" }}
                                                    icon={<CancelIcon />}
                                                />
                                            ),
                                        }[item.status]
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}

export default TabelCereri;
