import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Heading from "./components/DashboardHeading";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Switch from "@mui/material/Switch";

import { Button, Box, Alert } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

function FormPaper(props) {
    return (
        <Paper
            sx={{
                p: 3,
                mb: 3,
                "& .MuiTypography-root": {
                    mb: 2,
                },
                ...props.sx,
            }}
            {...props}
        >
            {props.children}
        </Paper>
    );
}

function FormGrid(props) {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                maxWidth: "80ch",
                "& .MuiTextField-root": { width: "100%" },
                mb: 2,
                ...props.sx,
            }}
            {...props}
        >
            {props.children}
        </Grid>
    );
}

function Cont() {
    const [saveFeedback, setSaveFeedback] = React.useState(false);
    const [dateCont, setDateCont] = React.useState({});
    const userData = useSelector((state) => state.auth.userData);

    const handleChange = (e) => {
        setDateCont({
            ...dateCont,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        document.getElementById("dashboard-scroll").scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setSaveFeedback(true);
    };

    React.useEffect(() => {
        if (userData) {
            setDateCont({
                nume: userData.fullname.split(" ")[0],
                prenume: userData.fullname.split(" ")[1],
                email: userData.email,
            });
        }
    });

    return (
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <FormPaper>
                {saveFeedback && (
                    <React.Fragment>
                        <Alert severity="info">Modificarile au fost salvate cu succes.</Alert>
                        <br />
                    </React.Fragment>
                )}
                <Heading>Date Personale</Heading>
                <FormGrid component="form" noValidate autoComplete="off">
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nume *"
                            name="nume"
                            value={dateCont.nume}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Prenume *"
                            name="prenume"
                            value={dateCont.prenume}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="CNP *" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Adresa Email *"
                            name="email"
                            value={dateCont.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Arhitect - număr TNA" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Telefon *" value="" />
                    </Grid>
                </FormGrid>
            </FormPaper>

            <FormPaper>
                <Heading>Date Carte de Identitate</Heading>
                <FormGrid component="form" noValidate autoComplete="off">
                    <Grid item xs={12} md={6}>
                        <TextField label="Serie CI *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Numar CI *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Data expirării *" />
                    </Grid>{" "}
                    <Grid item xs={12} md={6}>
                        <TextField label="Eliberat de *" />
                    </Grid>
                </FormGrid>
            </FormPaper>

            <FormPaper>
                <Heading>Date Firma</Heading>
                <FormGrid component="form" noValidate autoComplete="off">
                    <Grid item xs={12} md={6}>
                        <TextField label="Denumire *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="CUI *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Județ *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Localitate *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Strada *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Numar *" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Bloc" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Etaj" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Scara" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Apartament" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Cod Postal *" />
                    </Grid>
                </FormGrid>
            </FormPaper>

            <Paper sx={{ p: 3, mb: 3 }}>
                <Heading>Management Notificari</Heading>
                <TableContainer component="form" noValidate autoComplete="off">
                    <Table
                        padding="none"
                        sx={{
                            minWidth: 650,
                            "& .MuiTableCell-root": {
                                py: 1.25,
                                fontSize: "100%",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">SMS</TableCell>
                                <TableCell align="right">In Aplicatie</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={4}>
                                    <strong>SESIZARI</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se primește o sesizare nouă
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se schimbă statusul unei sesizări
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={4}>
                                    <strong>CERERI</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se primește o cerere noua
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se schimbă statusul unei cereri
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se primesc noutăți în zonele de interes
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={4}>
                                    <strong>MESAJE</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Se primește un mesaj nou
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch defaultChecked />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Button variant="contained" size="large" onClick={handleSave}>
                Salveaza modificarile
            </Button>
        </Container>
    );
}

export default function DashboardCont() {
    return <Cont />;
}
