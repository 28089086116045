import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import politia from "./images/polita.jpg";


export default function AmendaCard({ onClickAway }) {
    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Card sx={{ maxWidth: 345, position: "absolute", top: 10, right: 10, zIndex: 2000 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            R
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title="Amenda Circulatie - Zorilor 21 A"
                    subheader="September 14, 2021"
                />
                <CardMedia
                    component="img"
                    height="240"
                    image={politia}
                    alt="Amenda"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        There are many variations of passages of Lorem Ipsum available, but the
                        majority have suffered alteration in some form, by injected humour, or
                        randomised words which don't look even slightly believable.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Editeaza</Button>
                    <Button size="small">Mai multe detalii...</Button>
                </CardActions>
            </Card>
        </ClickAwayListener>
    );
}
