import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";

import { Box, Checkbox } from "@mui/material";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const defaultData = {
    nrext: "",
    dateext: "",
    emitent: "",
    compartiment: "",
    nr: "27",
    date: "",
    continut: "",
    newOrder: true
};

function AdaugaNumarInregistrare(props) {
    const [formData, setFormData] = React.useState({});
    const [formErrors, setFormErrors] = React.useState({});

    const [files, setFiles] = React.useState([]);

    function handleFileUpload(e) {
        const newFiles = e.target.files;
        const newFilesArray = [];
        for (let i = 0, numFiles = newFiles.length; i < numFiles; i++) {
            newFilesArray.push(newFiles[i].name);
        }
        setFiles([...files, ...newFilesArray]);
    }

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const validateInput = (name, value) => {
        const validInput = !!value.trim();
        if (!validInput) {
            setFormErrors({ ...formErrors, [name]: true });
        } else {
            setFormErrors({ ...formErrors, [name]: false });
        }
    };

    const isValidForm = (inputs) => {
        let isValid = true;
        let errors = {};
        inputs.forEach((name) => {
            const validInput = !!formData[name].trim();
            if (!validInput) {
                errors[name] = true;
                isValid = false;
            } else {
                errors[name] = false;
            }
        });
        setFormErrors({ ...formErrors, ...errors });
        return isValid;
    };

    const handleSubmit = () => {
        if (isValidForm(["nrext", "dateext", "emitent", "compartiment", "continut"])) {
            props.handleModalClose(formData);
            setFormData(defaultData);
        }
    };

    const handleClose = () => {
        props.handleModalClose();
        if (props.inregistrare) setFormData(defaultData);
    };

    React.useEffect(() => {
        let inregistrare = props.inregistrare || defaultData;

        let today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        inregistrare.date = today;
        inregistrare.nr = props.lastNumber + 1;

        setFormData(inregistrare);
    }, []);

    return (
        <Dialog
            open={true}
            scroll="body"
            onBackdropClick={handleClose}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { minHeight: 300 } }}
        >
            <DialogTitle
                sx={{ display: "flex", justifyContent: "space-between", fontSize: "1.15rem" }}
            >
                <span style={{ lineHeight: "40px" }}>
                    {props.inregistrare ? "Editeaza inregistrarea" : "Rezervare numar inregistrare"}
                </span>
                <IconButton color="default" component="span" onClick={handleClose}>
                    <CloseIcon fontSize="30px" />
                </IconButton>
            </DialogTitle>
            <DialogContent component="form" noValidate autoComplete="off">
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "40ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="nr"
                            value={formData.nr}
                            onChange={handleFormChange}
                            label="Numar alocat automat"
                            variant="filled"
                            error={formErrors.nr}
                            helperText={formErrors.nr ? "Camp obligatoriu" : ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                "& .MuiFilledInput-input": { fontWeight: "bold" },
                                "& .MuiInputBase-root": { background: "aliceblue !important" },
                            }}
                        />
                        <TextField
                            name="date"
                            value={formData.date}
                            onChange={handleFormChange}
                            label="Data inregistrare"
                            variant="filled"
                            error={formErrors.date}
                            helperText={formErrors.date ? "Camp obligatoriu" : ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                "& .MuiFilledInput-input": { fontWeight: "bold" },
                                "& .MuiInputBase-root": { background: "aliceblue !important" },
                            }}
                        />
                    </div>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "40ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="nrext"
                            value={formData.nrext}
                            onChange={handleFormChange}
                            label="Numar extern"
                            error={formErrors.nrext}
                            helperText={formErrors.nrext ? "Camp obligatoriu" : ""}
                        />
                        <TextField
                            name="dateext"
                            value={formData.dateext}
                            onChange={handleFormChange}
                            label="Data externa (ZZ/LL/AAAA)"
                            error={formErrors.dateext}
                            helperText={formErrors.dateext ? "Camp obligatoriu" : ""}
                        />
                    </div>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "40ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="emitent"
                            value={formData.emitent}
                            onChange={handleFormChange}
                            label="Emitent"
                            error={formErrors.emitent}
                            helperText={formErrors.emitent ? "Camp obligatoriu" : ""}
                        />
                        <TextField
                            name="compartiment"
                            value={formData.compartiment}
                            onChange={handleFormChange}
                            label="Compartiment"
                            error={formErrors.compartiment}
                            helperText={formErrors.compartiment ? "Camp obligatoriu" : ""}
                        />
                    </div>
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "81.6ch" },
                    }}
                >
                    <div>
                        <TextField
                            name="continut"
                            value={formData.continut}
                            onChange={handleFormChange}
                            label="Continut pe scurt"
                            error={formErrors.continut}
                            helperText={formErrors.continut ? "Camp obligatoriu" : ""}
                        />
                    </div>
                </Box>
                <Box
                    sx={{
                         m: 1
                    }}
                >
                    <Box
                        sx={{
                            mb: 2.5,
                            border: "1px dotted #333",
                            borderRadius: "10px",

                            background: "#F6F6F6",
                            fontSize: "0.85rem",
                            lineHeight: 1.3,
                            fontWeight: 500,
                            overflow: "hidden",
                        }}
                    >
                        {files.length > 0 && (
                            <Box
                                sx={{
                                    p: 2,
                                    fontWeight: 400,
                                    borderBottom: "1px dotted",
                                    background: "#E6E6E6",
                                }}
                            >
                                {files.map((file, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "0.8rem",
                                            "&:not(:last-child)": {
                                                mb: 1,
                                            },
                                        }}
                                    >
                                        <FilePresentIcon sx={{ fontSize: "1.3rem", mr: 0.5 }} />{" "}
                                        {file}
                                    </Box>
                                ))}
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                p: 2,
                                "&:hover": {
                                    opacity: 0.7,
                                    background: "white",
                                },
                            }}
                            component="label"
                        >
                            <DriveFolderUploadIcon sx={{ marginRight: "0.5rem" }} />{" "}
                            <span>Adauga atasamente pentru aceasta inregistrare</span>
                            <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    m: 3,
                    marginTop: 0,
                }}
            >
                <Button onClick={handleClose}>Anuleaza</Button>
                <Button variant="contained" onClick={handleSubmit}>
                    {props.inregistrare ? "Salveaza modificarile" : "Salveaza inregistrarea"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdaugaNumarInregistrare;
